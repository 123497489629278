import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { DeviceDetectorService } from '../../../util/device-detector/device-detector.service';
import { EpubRenderService, SearchResult } from '../../services/rendition/epubrender.service';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { SearchService } from '../../services/search/search.service';
import { ToastService } from '../../../services/toast/toast.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  public get showSingle(): boolean {
    return this.searchService.showSingleResult;
  }
  public set showSingle(value: boolean) {
    this.searchService.showSingleResult = value;
  }
  public get keyword(): string {
    return this.searchService.searchQuery;
  }
  public set keyword(value: string) {
    this.searchService.searchQuery = value;
  }

  public finalKeyword: string = '';
  public get searchResults(): SearchResult[] {
    return this.searchService.searchResults;
  }
  public set searchResults(value: SearchResult[]) {
    this.searchService.searchResults = value;
  }
  
  public get currentSearchResult(): SearchResult | undefined {
    return this.searchService.currentSearchResult;
  }
  public set currentSearchResult(value: SearchResult  | undefined) {
    this.searchService.currentSearchResult = value;
  }

  public get searchIndex(): number {
    return this.searchService.searchIndex;
  }
  public set searchIndex(value: number) {
    this.searchService.searchIndex = value;
  }

  public searching: boolean = false;
  public searchPlaceHolder : string = '';

  public scrollUnlocked: boolean = false;
  scrollPosition: number = 0;
  @ViewChild(IonContent) content: IonContent | undefined;

  @Output() onEdit = new EventEmitter<any>();

  constructor(
    private epubRenderService: EpubRenderService,
    private changeDetectorRef: ChangeDetectorRef,
    public deviceDetector: DeviceDetectorService,
    public screenOrientation: ScreenOrientation,
    private translationService: L10nTranslationService,
    private searchService: SearchService,
    private toastService: ToastService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {
    this.screenOrientation.onChange().subscribe(async () => {
      if (!this.deviceDetector.isDesktop()) {
        this.scrollUnlocked =
          this.screenOrientation.type === 'landscape' ||
          this.screenOrientation.type === 'landscape-primary' ||
          this.screenOrientation.type === 'landscape-secondary';
        if (this.content) {
          await this.content.scrollToPoint(0, this.scrollPosition);
        }
      }
    });
  }

  ngOnInit() {
    this.translationService.onChange().subscribe({
      next: () => {
        this.searchPlaceHolder = this.translationService.translate('Search.Keyword');
      },
    });
    if (!this.deviceDetector.isDesktop()) {
      this.scrollUnlocked =
        this.screenOrientation.type === 'landscape' ||
        this.screenOrientation.type === 'landscape-primary' ||
        this.screenOrientation.type === 'landscape-secondary';
    }
  }

  onIonScroll(event: any) {
    if (event.detail.scrollTop !== 0) {
      this.scrollPosition = event.detail.scrollTop;
    }
  }

  toggleLayoutMode(event: any) {
    this.showSingle = !this.showSingle;
  }

  searchBack() {
    if (this.searchIndex > 0) {
      this.searchIndex--;
    }

    this.currentSearchResult = this.searchResults[this.searchIndex];
    this.changeDetectorRef.detectChanges();
  }

  searchNext() {
    if (this.searchIndex < this.searchResults.length - 1) {
      this.searchIndex++;
    }

    this.currentSearchResult = this.searchResults[this.searchIndex];
    this.changeDetectorRef.detectChanges();
  }

  private initSearchResults() {
    this.searchResults = [];
    this.searchIndex = 0;
    this.currentSearchResult = undefined;
  }

  async searchKeyword() {

    // Check if keyword is at least 3 characters long
    // This is to prevent searching for single characters which will result in a lot of results
    if (this.keyword.length < 3) {
      this.toastService.showWarningMessage(this.translationService.translate('Search.KeywordTooShort'));
      return;
    }
    
    this.initSearchResults();

    await this.showSpinner();

    this.searchResults = await this.epubRenderService.search(this.keyword);

    this.finalKeyword = this.keyword;

    setTimeout(() => {
      this.hideSpinner();
      this.currentSearchResult = this.searchResults[this.searchIndex];
      this.changeDetectorRef.detectChanges();

      //keep the results in storage when mobile
      if (!this.deviceDetector.isDesktop()) {
        localStorage.setItem('serchResults', JSON.stringify(this.searchResults));
        localStorage.setItem('keyWord', this.finalKeyword);
      }
    }, 500);
  }

  private async showSpinner() {
    this.searching = true;
  }

  /**
   * Opens the provided passage in the rendered epub
   * @param {SerchResult} result representing the text to scroll to.
  */
  goTo(result: SearchResult) {
    if(result.cfi && result.cfiDetails) {
      const cfi = result.cfi.substring(0, result.cfi.lastIndexOf('/4'));
      const cfiDetails = result.cfiDetails.substring(result.cfiDetails.indexOf('4/'));
  
      this.epubRenderService.gotoSearchResult(cfi + cfiDetails, result.stepsToNode, result.text);
      if (!this.deviceDetector.isDesktop()) this.onEdit.emit({ viewId: 'book' });
    }
  }

  private async hideSpinner() {
    this.searching = false;
  }
}
