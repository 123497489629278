export namespace Constants {
  export namespace URL {
    export const Books = 'books';
    export const Books_BookId_TO_OPEN = 'books/:id';
    export const Book = 'book';
    export const BookIdNavigation = 'book/:id';
    export const NotFound = '404';
    export const DiscussionNavigation = 'discussion';
    export const DiscussionIDChapterInformation = ':id/:chapterInformation';
    export const DiscussionDetail = 'discussiondetail/:id/:groupId';
    export const DiscussionDetailNavigation = 'discussion/discussiondetail';
    export const Authentication = 'authentication';
    export const AuthenticationWithToken = 'authentication/:token';
    export const AuthenticationWithSSOToken = 'authentication/SSOtoken';
    export const VerifyAccount = 'verify';
    export const ActivateAccount = 'activate';
    export const Customer = 'authentication/customer/:customername';
    export const Registration = 'registration';
    export const RegistrationNavigation = 'authentication/registration';
    export const Login = 'login';
    export const Reset = 'reset';
    export const ResetPasswordNavigation = 'authentication/reset';
    export const LoginNavigation = 'authentication/login';
    export const Group = 'group';
    export const GroupsDetail = ':id';
    export const CreateGroup = 'new';
    export const AddMembers = 'newMembers';
    export const AppInfo = 'appinfo';
  }
}
