import { ThemeService } from './../../services/themes/theme.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Service } from './../../services/rest-client/rest-client.service';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

@Component({
  selector: 'app-theme-switcher',
  templateUrl: './theme-switcher.component.html',
  styleUrls: ['./theme-switcher.component.scss'],
})
export class ThemeSwitcherComponent implements OnInit {
  public customers: any;

  constructor(
    private modalCtrl: ModalController,
    private restService: Service,
    private themeService: ThemeService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngOnInit() {
    this.getCustomers();
  }

  getCustomers() {
    this.restService.getCustomers().subscribe((data) => {
      this.customers = data;
    });
  }

  public activateTheme(sub_domain_name: any) {
    this.themeService.loadThemebyCustomerName(sub_domain_name);
  }

  async closeModal() {
    await this.modalCtrl.dismiss();
  }
}
