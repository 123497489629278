import { ClientMark } from '../../../PODO/clientMark';
import { Note } from '../../../services/rest-client/rest-client.service';
import { NoteService } from '../../services/note/note.service';
import { EpubRenderService } from '../../services/rendition/epubrender.service';
import { TexthighlightService } from '../../services/texthighlight/texthighlight.service';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  AfterViewInit,
  Output,
  EventEmitter,
  OnDestroy,
  ViewRef,
  ViewChildren,
  QueryList,
  ViewChild,
  NgZone,
  Inject,
} from '@angular/core';
import { NavItem } from 'epubjs/types/navigation';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { EpubCFI } from 'epubjs';
import { ClientBook } from '../../../PODO/clientBook';
import { BehaviorSubject, Subscription } from 'rxjs';
import moment from 'moment';
import { IonSelect, ModalController } from '@ionic/angular';
import { DialogComponent } from '../../../components/dialog/dialog.component';
import { DeviceDetectorService } from '../../../util/device-detector/device-detector.service';
import { IonContent } from '@ionic/angular';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { popoverDropdownEnterAnimation } from '../../../../animations/dropdown.animation';

@Component({
  selector: 'app-note-overview',
  templateUrl: './note-overview.component.html',
  styleUrls: ['./note-overview.component.scss'],
})
export class NoteOverviewComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly dropdownAnimation = popoverDropdownEnterAnimation;

  readonly minNoteText = 0;
  public allChapterString: string = '';
  private _marks: ClientMark[] = [];
  public get marks(): ClientMark[] {
    return this._marks;
  }
  public set marks(value: ClientMark[]) {
    this._marks = this.sortMarks(value);
    this.filterMarks(this.marks);
  }
  public errorMarks: ClientMark[] = [];
  public filteredMarks: ClientMark[] = [];
  public selectedColors: string[] = [
    '--book-contextMenu-color1',
    '--book-contextMenu-color2',
    '--book-contextMenu-color3',
    '--book-contextMenu-color4'
  ];
  public selectedChapter: string | undefined;
  public onlyNotes: boolean = false;
  public onlyErrorMarks: boolean = false;
  public refreshSubscription: Subscription | undefined;
  public refreshing: boolean = false;
  private today: string | undefined;
  private yesterday: string | undefined;
  public tooltipEdit: String;
  public tooltipLocate: String;
  public tooltipDelete: String;

  @Input() chapters: NavItem[] | undefined = [];
  @Input() book: ClientBook | undefined;
  @Input() shouldRefresh: BehaviorSubject<boolean> | undefined;
  @Output() onEdit = new EventEmitter<any>();
  @Output() menuValue = new EventEmitter<boolean>();

  // TODO https://github.com/ionic-team/ionic-framework/issues/12179
  @ViewChildren(IonSelect) ionSelects: QueryList<IonSelect> | undefined;
  @ViewChild(IonContent) content: IonContent | undefined;

  scrollPosition: number = 0;
  public scrollUnlocked: boolean = false;

  constructor(
    private texthighlightService: TexthighlightService,
    public noteService: NoteService,
    private epubrenderService: EpubRenderService,
    public translationService: L10nTranslationService,
    private changeDetectorRef: ChangeDetectorRef,
    private modalController: ModalController,
    public deviceDetector: DeviceDetectorService,
    public screenOrientation: ScreenOrientation,
    private ngZone: NgZone,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {
    this.today = this.translationService.translate('NoteOverview.Today');
    this.yesterday = this.translationService.translate('NoteOverview.Yesterday');
    this.allChapterString = this.translationService.translate('NoteOverview.All');
    this.selectedChapter = this.allChapterString;
    this.tooltipEdit = '';
    this.tooltipLocate = '';
    this.tooltipDelete = '';

    this.screenOrientation.onChange().subscribe(async () => {
      if (!this.deviceDetector.isDesktop()) {
        this.scrollUnlocked =
          this.screenOrientation.type === 'landscape' ||
          this.screenOrientation.type === 'landscape-primary' ||
          this.screenOrientation.type === 'landscape-secondary';
        if (this.content) {
          await this.content.scrollToPoint(0, this.scrollPosition);
        }
      }
    });
  }

  ngOnInit(): void {
    if (!this.deviceDetector.isDesktop()) {
      this.scrollUnlocked =
        this.screenOrientation.type === 'landscape' ||
        this.screenOrientation.type === 'landscape-primary' ||
        this.screenOrientation.type === 'landscape-secondary';
    }

    this.epubrenderService.getFlattenedChapters();
    if (this.shouldRefresh) {
      this.refreshSubscription = this.shouldRefresh.subscribe((shouldRefresh) => {
        if (shouldRefresh && !this.refreshing) {
          setTimeout(() => {
            this.loadData();
          }, 1000);
        }
      });
      this.translationService.onChange().subscribe({
        next: () => {
          this.tooltipEdit = this.translationService.translate('MenuTooltip.Edit');
          this.tooltipLocate = this.translationService.translate('MenuTooltip.Locate');
          this.tooltipDelete = this.translationService.translate('MenuTooltip.Delete');
        },
      });
    }
  }

  ngAfterViewInit() {
    this.setChapters();
    this.refreshing = true;
    this.loadData();

    setTimeout(() => {
      this.setSelectorUI();
    }, 100);
  }

  ngOnDestroy(): void {
    //was needed to pre store note, in case of slow server connection
    this.noteService.deletePreStoredNote();

    this.changeDetectorRef.detach();
    if (this.refreshSubscription) this.refreshSubscription.unsubscribe();
  }

  private setSelectorUI() {
    const ionSelects = document.querySelectorAll('ion-select');
    ionSelects.forEach((sel) => {
      if (sel && sel.shadowRoot) {
        const selectIcon = sel.shadowRoot.querySelectorAll('.select-icon')[0] as HTMLElement;
        if(selectIcon?.style) {
          selectIcon.style.height = '100%';
        }
        sel.shadowRoot.querySelectorAll('.select-icon').forEach((elem) => {
          const innerIconInner = elem.querySelector('.select-icon-inner') as HTMLElement;
          innerIconInner.style.position = 'initial';
          innerIconInner.style.border = 'none';
          innerIconInner.style.height = '100%';
          innerIconInner.style.width = '100%';
          innerIconInner.style.display = 'flex';
          innerIconInner.style.alignItems = 'center';
          innerIconInner.style.margin = '0';
          const newIcon = document.createElement('ion-icon');
          newIcon.setAttribute('name', 'caret-down-outline');
          innerIconInner.appendChild(newIcon);
        });
      }
    });
  }

  private setChapters() {
    this.chapters!.forEach((ch) => {
      ch.label = ch.label.trim();
    });
  }

  private async loadData() {
    this.clearList();
    await this.loadNotes();
    this.refreshing = false;
    this.notifyChanges();
  }

  private clearList() {
    this.marks = [];
    this.notifyChanges();
  }

  private notifyChanges() {
    if (!(this.changeDetectorRef as ViewRef).destroyed) {
      this.changeDetectorRef.detectChanges();
    }
  }

  private async loadNotes() {
    await this.addNotesToMarkList(this.noteService.notes);
  }

  private async addNotesToMarkList(notes: Note[]) {
    const marks: ClientMark[] = [];
    const errorMarks: ClientMark[] = [];
    notes = this.checkEditedNote(notes);
    for (const note of notes) {
      const bookId = note.bookId;
      const cfi = note.pageId!;
      const color = note.color!;
      const creationDate = note.creationDate;
      let text = await this.epubrenderService.getTextFromCfi(note.pageId);

      let chapter = this.epubrenderService.getLatestMainChapter(note.pageId);
      const noteText = note.note;
      const pageNumber = note.pageNumber ? note.pageNumber + 1 : NaN;
      const chapterName = chapter ? chapter.label : 'No chapter found';
      if (text !== undefined) {
        marks.push({ bookId, text, pageNumber, cfi, color, creationDate, chapterName, noteText });
      } else {
        errorMarks.push({ bookId, text, pageNumber, cfi, color, creationDate, chapterName, noteText });
      }
    }

    this.marks = marks;
    this.errorMarks = errorMarks;
  }

  private checkEditedNote(notes: Note[]) {
    //remove note without id, if there is one
    for (var i = 0; i < notes.length; i++) {
      if (notes[i].id == undefined) {
        {
          notes.splice(i, 1);
          break;
        }
      }
    }

    //get edited note from storage
    var editedNote = this.noteService.getPreStoredNote();

    var found = false;
    for (var i = 0; i < notes.length; i++) {
      if (notes[i].pageId! == editedNote?.pageId) {
        {
          notes[i].note = editedNote.note;
          found = true;
          break;
        }
      }
    }
    if (!found && editedNote != null) {
      notes.push(editedNote);
    }
    return notes;
  }

  public editMark(mark: ClientMark) {
    if (this.onEdit) {
      this.noteService.scrollToNote = mark;
      this.onEdit.emit({
        viewId: 'note',
        mark: mark,
      });
    }
  }

  private async closeDialog() {
    const modalElem = await this.modalController.getTop();
    if (modalElem) {
      modalElem.dismiss().then(async (res) => {
        if (this.content) {
          await this.content.scrollToPoint(0, this.scrollPosition);
        }
      });
    }
  }

  onIonScroll(event: any) {
    if (event.detail.scrollTop !== 0) {
      this.scrollPosition = event.detail.scrollTop;
    }
  }

  public async showConfirmationDialog(color: string, cfi: string, noteText: string) {
    if (cfi) {
      // let cssClass = 'confirm-dialog';
      // if (this.deviceDetector.isMobile()) cssClass = 'confirm-dialog-mobile';
      // if (this.deviceDetector.isTabletSize()) cssClass = 'confirm-dialog-tablet';

      let modalDiv = document.getElementById('append-modal');
      const dialog = await this.modalController.create({
        component: DialogComponent,
        cssClass: 'note-overview-modal',
        backdropDismiss: false,
        enterAnimation: undefined,
        leaveAnimation: undefined,
        componentProps: {
          message: this.translationService.translate('Dialog.DeleteNoteReally'),
          buttons: [
            {
              text: this.translationService.translate('Dialog.Cancel'),
              method: this.closeDialog.bind(this),
            },
            {
              text: this.translationService.translate('Dialog.Delete'),
              method: this.deleteMark.bind(this),
              style: 'fill',
            },
          ],
          color: color,
          cfi: cfi,
          noteText: noteText,
          bookNoteCustomCss: true,
        },
      });
      modalDiv?.appendChild(dialog);
      await dialog.present();
    }
  }

  public async deleteMark(color: string, cfi: string, noteText?: string | undefined) {
    //check if it is pre stored note
    var editedNote = this.noteService.getPreStoredNote();
    if (editedNote?.pageId == cfi) this.noteService.deletePreStoredNote();

    this.epubrenderService.removeAnnotation(cfi, 'highlight');
    await this.noteService.deleteNote(cfi);
    await this.texthighlightService.deleteTextHighlight(cfi);
    document.querySelectorAll(`a[data-epubcfi="${cfi}"]`).forEach((a) => a.remove());

    this.marks = this.marks.filter(mark => mark.cfi !== cfi);

    this.doRefresh();
    this.closeDialog();
  }

  private sortMarks(marks: ClientMark[]): ClientMark[] {
    const epubCFI: EpubCFI = new EpubCFI();
    return marks.sort((markA: ClientMark, markB: ClientMark) => {
      if (markA.pageNumber < markB.pageNumber) {
        return -1;
      } else if (markA.pageNumber > markB.pageNumber) {
        return 1;
      } else {
        return epubCFI.compare(markA.cfi, markB.cfi);
      }
    });
  }

  public displayCfi(cfi: string) {
    this.menuValue.emit(true);
    this.epubrenderService.displayCfi(cfi);
    if (!this.deviceDetector.isDesktop()) this.onEdit.emit({ viewId: 'book' });
  }

  public transformDate(miliseconds: number): string {
    return moment(miliseconds).calendar(null, {
      sameDay: `[${this.today}] - HH:mm`,
      nextWeek: 'DD/MM/YYYY - HH:mm',
      lastDay: `[${this.yesterday}] - HH:mm`,
      lastWeek: 'DD/MM/YYYY - HH:mm',
      sameElse: 'DD/MM/YYYY - HH:mm',
    });
  }

  public displayChaptername(index: number): boolean {
    if (index == 0) {
      return true;
    } else return this.filteredMarks[index]?.chapterName !== this.filteredMarks[index - 1]?.chapterName;
  }

  public toggleColor(val: string) {
    if (this.selectedColors.includes(val)) {
      this.selectedColors = this.selectedColors.filter((selectedColor) => selectedColor != val);
    } else {
      this.selectedColors.push(val);
    }
    
    this.filterMarks(this.marks);
  }

  public toggleChapter(chapter: string) {
    this.selectedChapter = chapter;
    this.filterMarks(this.marks);
  }

  public changeMarkVisibility(onlyNotes: boolean) {
    this.onlyNotes = onlyNotes;
    this.filterMarks(this.marks);
  }

  public changeErrorMarkVisibility(bool: boolean) {
    this.onlyErrorMarks = bool;
  }

  public doRefresh(event?: any) {
    this.loadData().then(() => {
      if (event) event.target.complete();
    });
  }

  filterMarks(marks: ClientMark[]): void {
    this.ngZone.run(() => {
      this.filteredMarks = marks
        .filter(mark => {
          return this.onlyNotes ? (mark.noteText && mark.noteText.length > this.minNoteText) : true
        })
        .filter(mark => {
          switch (this.selectedChapter) {
            case this.allChapterString:
              return mark.bookId == this.book!.id;
            default:
              return mark.chapterName === this.selectedChapter && mark.bookId == this.book!.id;
          }
        })
        .filter(mark => this.selectedColors
          .map(colorVariable => getComputedStyle(document.documentElement).getPropertyValue(colorVariable))
          .includes(mark.color)
        )
    })
  }
}
