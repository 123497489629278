import {UserService} from '../user/user.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpEventType} from '@angular/common/http';
import Epub from 'epubjs';
import {LinkGeneratorService} from '../link-generator/link-generator.service';
import {Directory, Filesystem} from '@capacitor/filesystem';
import {Zip} from '@awesome-cordova-plugins/zip/ngx';
import write_blob from 'capacitor-blob-writer';
import {StorageService} from '../storage/storage.service';
import {ClientBook} from '../../PODO/clientBook';
import {DatabaseService} from '../localDatabase/database-service.service';
import {BooksService, Note, TextHighlight} from '../rest-client/rest-client.service';
import { L10nTranslationService } from 'angular-l10n';
import {FileOpener} from '@awesome-cordova-plugins/file-opener/ngx';

const OPF_FILE_PATH = '/OEBPS/content.opf';

@Injectable({
    providedIn: 'root'
})
export class OfflineModeService {
    private rawFile: string | undefined;

    constructor(
        private http: HttpClient,
        private linkGenerator: LinkGeneratorService,
        private userService: UserService,
        private zip: Zip,
        private storageService: StorageService,
        private dataBaseService: DatabaseService,
        private booksService: BooksService,
        public translationService: L10nTranslationService,
        public fileOpener: FileOpener
    ) {
    }

    public getBookDownloadLink(bookId: number) {
        return new Promise((resolve, reject) => {
            this.linkGenerator.getDownloadLink(bookId).then((downloadLink) => {
                resolve(downloadLink);
            });
        });
    }

    public async openBook(filename: string) {
        await this.readFileFromDevice(filename);

        if (this.rawFile) {
            let bookObj = Epub(this.rawFile, {encoding: 'base64'});
            const b64 = this.rawFile.replace('data:application/epub+zip;base64,', '');
            bookObj.open(b64);
            await bookObj.opened;
            return bookObj;
        }
    }

    public nextPage(rendition: any) {
        rendition.next();
    }

    public prevPage(rendition: any) {
        rendition.prev();
    }

    public downloadFile(url: any) {
        return this.http.get(url, {
            responseType: 'blob',
            reportProgress: true,
            observe: 'events'
        });
    }

    deviceDownloadAttachment(base64: any, fileName: string, iOS: boolean): Promise<any> {
        return new Promise(async (resolve, reject) => {
            let directory = Directory.Documents;
            try {
                await Filesystem.writeFile({
                    path: fileName,
                    data: base64,
                    directory: directory
                }).then(async (writeFileResult: any) => {
                    if (iOS) {
                        resolve(this.fileOpener.showOpenWithDialog(writeFileResult.uri, 'application/json'));
                    }
                    resolve(writeFileResult.uri);
                }).catch(error => {
                    reject(error);
                });
            } catch (error) {
                reject(error);
            }
        });

    }

    async getAttachmentUri(fileName: string): Promise<string> {
        return new Promise(async (resolve, reject) => {
            let directory = Directory.Documents;
            try {
                await Filesystem.getUri({
                    directory: directory,
                    path: fileName
                }).then(async (getUriResult: any) => {
                    resolve(getUriResult.uri);
                }, (error) => {
                    console.log(error);
                });
            } catch (error) {
                console.error('Unable to read uri ', error);
            }
        });
    }

    public async readAttachmentFromDevice(filename: string) {
        try {
            let directory = Directory.Documents;
            let file = await Filesystem.readFile({
                path: filename,
                directory: directory
            });
            return file.data;
        } catch (e: any) {
            console.log(e)
        }
    }

    public async checkPermissionForReadAndWrite() {
        await Filesystem.checkPermissions().then(async res => {
            if (res.publicStorage == 'denied') {
                await Filesystem.requestPermissions().then(res => {
                    console.log("requestPermissions :: result :: ", res)
                });
            }
        })
    }

    public async writeFileToDevice(downloadedFile: string, userId: string, name: string) {
        let fileSaved = await Filesystem.writeFile({
            path: userId + '-' + name,
            data: downloadedFile,
            directory: Directory.Data
        });
        //location of file
        return fileSaved.uri;
    }

    public async deleteFile(path: string) {
        try {
            return await Filesystem.deleteFile({
                path: path,
                directory: Directory.Data
            });
        } catch (e: any) {
            console.error('Unable to delete file', e);
        }
    }

    public async getApplicationPath(filePath: string) {
        return await Filesystem.getUri({
            path: filePath,
            directory: Directory.Data
        });
    }

    public async deleteBook(book: any) {
        return await this.deleteDir(book.id.toString())
            .catch((error) => console.log('while deleting a book, got error: ', error))
            .then(() => {
                book.downloaded = false;
            });
    }

    public async deleteDir(path: string) {
        return await Filesystem.rmdir({
            path: path,
            directory: Directory.Data,
            recursive: true
        });
    }

    public async checkIfFileExists(fileName: string) {
        try {
            let fileInfo = await Filesystem.stat({
                path: fileName,
                directory: Directory.Data
            });
            return fileInfo;
        } catch (error) {
            console.log(error);
        }
    }

    public async readFileFromDevice(filename: string) {
        let file = await Filesystem.readFile({
            path: filename,
            directory: Directory.Data
        });

        this.rawFile = 'data:application/epub+zip;base64,' + file.data;
    }

    // get the size of the saved base64 file
    public formatBytes(bytes: number, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    async getTOC(bookObj: any) {
        try {
            // const book = bookObj.get();
            let tocId;
            // @ts-ignore
            for (let item of bookObj.spine.spineItems) {
                if (item.href && item.href.startsWith('Inhaltsverzeichnis' || 'TableofContent')) {
                    tocId = item.href;
                    break;
                }
            }
            if (tocId) {
                const section = bookObj.spine.get(tocId);
                const html = await section.load(bookObj.load.bind(bookObj));
                if (html) {
                    let toc = html.querySelector('#TOC');
                    if (toc) {
                        let tmpToc = toc.cloneNode(true);
                        if (tmpToc) {
                            // @ts-ignore
                            let anchors = tmpToc.querySelectorAll('a');
                            // @ts-ignore
                            for (let anchor of anchors) {
                                let href = anchor.getAttribute('href');
                                anchor.removeAttribute('href');
                                anchor.setAttribute('id', href);
                            }
                            // @ts-ignore
                            return tmpToc.outerHTML;
                        }
                    }
                }
            }
        } catch (e: any) {
            console.log('Error happen on getting toc ...');
            console.error(e);
        }
    }

    public getChapters(book: any) {
        return book.navigation.toc;
    }

    public navigateToPage(href: string, book: any) {
        let cfi = this.getCfiFromHref(href, book);
    }

    public async getCfiFromHref(href: string, book: any) {
        const id = href.split('#')[1];
        const item = book.spine.get(href);
        await item.load(book.load.bind(book));
        const el = id ? item.document.getElementById(id) : item.document.body;
        return item.cfiFromElement(el);
    }

    public async unzipFile(
        element: any,
        source: string,
        destination: string,
        deleteZipFileCb: () => void,
        setIsDownloadedCb: (val: boolean) => void
    ) {
        this.zip
            .unzip(source, destination, (progress: any) => {
                //console.log('Unzipping, ' + Math.round((progress.loaded / progress.total) * 100) + '%');
                element.textContent = Math.round((100 * progress.loaded) / progress.total);
                element.textContent = `${element.textContent}% ${this.translationService.translate('Books.Unzipping')}`;
            })
            .then((result) => {
                if (result === 0) {
                    // delete the zip file
                    deleteZipFileCb();
                    // declare that the book is downloaded
                    setIsDownloadedCb(true);
                    element.enabled = true;
                }
                if (result === -1) {
                    console.log('FAILED');
                    setIsDownloadedCb(false);
                    element.enabled = true;
                }
            });
    }

    async mkdir(name: string) {
        try {
            await Filesystem.mkdir({
                path: name,
                directory: Directory.Data,
                recursive: false
            });
        } catch (e: any) {
            console.error('Unable to make directory', e);
        }
    }

    public async downloadCover(url: string, bookId: string) {
        if(!url) return;

        let https = 'https://';
        let http = 'http://';
        let trimmedUrl = url;
        if (url.startsWith(http)) {
            trimmedUrl = url.substring(http.length);
        } else if (url.startsWith(https)) {
            trimmedUrl = url.substring(https.length);
            trimmedUrl = 'http://' + trimmedUrl;
        }
        let Filetype = '.' + trimmedUrl.substring(trimmedUrl.lastIndexOf('.') + 1);
        return this.downloadFile(trimmedUrl).subscribe(async (event) => {
            if (event.type === HttpEventType.DownloadProgress) {
                if (event.total) {
                }
            } else if (event.type === HttpEventType.Response) {
                await this.mkdir('BookCovers');
                await write_blob({
                    path: 'BookCovers/' + bookId + Filetype,
                    directory: Directory.Data,
                    blob: event.body!,
                    recursive: true,
                    on_fallback(error) {
                        console.error('cannot save the file ', error);
                    }
                });
            }
        });
    }

    public async downloadBookWithBlob(element: any, book: ClientBook) {
        element.disabled = true;
        const bookId = book.id;
        element.textContent = `${this.translationService.translate('Books.Initializing')} ...`;
        let downloadBookLink = (await this.getBookDownloadLink(bookId)) as string;
        this.downloadFile(downloadBookLink).subscribe(async (event) => {
            if (event.type === HttpEventType.DownloadProgress) {
                if (event.total) {
                    element.textContent = Math.round((100 * event.loaded) / event.total);
                    element.textContent = `${element.textContent}% ${this.translationService.translate('Books.Downloading')}`;
                }
            } else if (event.type === HttpEventType.Response) {
                await this.mkdir(bookId.toString());
                element.textContent = `${this.translationService.translate('Books.Unzipping')} ...`;
                write_blob({
                    path: this.userService.getUserID() + '-' + bookId,
                    directory: Directory.Data,
                    blob: event.body!,

                    recursive: true,
                    on_fallback(error) {
                        console.error('cannot save the file ', error);
                    }
                }).then((fileUri: string) => {
                    this.dataBaseService.setSyncDateByBookId(bookId, Date.now().toString());
                    this.getApplicationPath(`${bookId}`).then((res) => {
                        const rootPath = res.uri.toString();
                        this.unzipFile(
                            element,
                            fileUri,
                            rootPath,
                            async () => await this.deleteFile(`${this.userService.getUserID()}-${bookId}`),
                            (val: boolean) => (book.downloaded = val)
                        );
                    });
                });
                this.storageService.savePreferences(`size_of_id${bookId}`, this.formatBytes(event.body!.size, 2)).then(() => {
                    this.booksService
                        .texthighlightsGet(this.userService.getUserID(), bookId)
                        .subscribe((textHighlightList: TextHighlight[]) => {
                            textHighlightList.forEach((textHighLight) => {
                                this.dataBaseService.saveHighlight(
                                    textHighLight.id!,
                                    this.userService.getUserID(),
                                    bookId,
                                    textHighLight.cfi,
                                    textHighLight.start,
                                    textHighLight.end,
                                    textHighLight.spine,
                                    textHighLight.color,
                                    textHighLight.creationDate!,
                                    '1'
                                );
                            });
                        });
                    this.booksService.notesGet(bookId).subscribe((notesList: Note[]) => {
                        notesList.forEach((note) => {
                            this.dataBaseService.saveNote(
                                note.id!,
                                note.bookId!,
                                note.note,
                                note.pageId,
                                note.color,
                                note.pageNumber!,
                                note.creationDate!,
                                '1'
                            );
                        });
                    });
                });
            }
        });
    }
}
