<ion-header class="ion-modal-header">
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="end">
      <ion-button (click)="closeLocalStorage()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="header-title ion-text-left" l10nTranslate> Menu.LocalStorage </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="no-book-downloaded" *ngIf="downloadedBooks.length === 0">
    <ion-label l10nTranslate="">LocalStorage.NoBookDownloaded</ion-label>
  </div>
  <ion-grid *ngFor="let book of downloadedBooks">
    <ion-row>
      <ion-avatar class="avatar-profile">
        <img [src]="sanitizer.bypassSecurityTrustUrl(book.urlCover)" alt="eBook" />
      </ion-avatar>
      <ion-card-header class="ion-no-border">
        <ion-column>
          <ion-card-title class="ion-no-padding">
            <ion-label>{{ book.title }}</ion-label>
          </ion-card-title>
          <ion-card-subtitle class="book-info">
            <ion-label>
              {{ book.creator }} | {{ book.size }}
              <br />
              <ion-row>
                <ion-label l10nTranslate="">LocalStorage.LastSync</ion-label>
                <ion-label>&nbsp;|&nbsp;</ion-label>
                <ion-label>{{ book.lastSynchronized | date: 'dd.MM.yyyy HH:mm' }}</ion-label>
              </ion-row>
            </ion-label>
          </ion-card-subtitle>
        </ion-column>
      </ion-card-header>
    </ion-row>
    <ion-row class="book-action-buttons">
      <ion-button class="btn" [disabled]="!networkStatus.connected" (click)="syncHandler(book.id)" l10nTranslate="">
        LocalStorage.Synchronizing
      </ion-button>
      <ion-button class="btn" *ngIf="book.downloaded" (click)="delLocalBook(book)" l10nTranslate="">
        LocalStorage.RemoveDownload
      </ion-button>
    </ion-row>
  </ion-grid>
</ion-content>
