import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { RegexService } from './../../services/regex/regex.service';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { Constants } from '../../PODO/constants';
import { UserService } from '../../services/user/user.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { AlertController, MenuController } from '@ionic/angular';
import { ThemeService } from '../../services/themes/theme.service';
import { ToastService } from '../../services/toast/toast.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { BooksService } from '../../services/books/books.service';
import { PluginListenerHandle } from '@capacitor/core';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  formGroup = new UntypedFormGroup({
    email: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
  });
  public emailPlaceholder: String;
  public passwordPlaceholder: String;
  public wrongCredentialsError: boolean;
  public noNetworkConnection: boolean = false;
  public notVerified = false;
  public emailNotProvided = false;
  public resentConfirmationSuccess = false;
  networkListener: PluginListenerHandle | undefined;
  public native: boolean = false;

  constructor(
    private router: Router,
    private alertController: AlertController,
    public translationService: L10nTranslationService,
    private userService: UserService,
    private authService: AuthService,
    private menuCtrl: MenuController,
    public themeService: ThemeService,
    private toastService: ToastService,
    private spinningService: SpinnerService,
    private regexService: RegexService,
    public booksService: BooksService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {
    this.emailPlaceholder = '';
    this.passwordPlaceholder = '';
    this.wrongCredentialsError = false;

    if (this.userService.checkIfIDIsSet()) this.router.navigate([Constants.URL.Books]);
  }

  ngOnInit() {
    this.native = Capacitor.isNativePlatform();
    this.networkListener = Network.addListener('networkStatusChange', (status) => {
      this.ngOnInit();
    });
    /**
         * Allow /customer/:customername route only on test environment
         * to test customer themes otherwise redirect to authentication
         
        let customerName = this.route.snapshot.paramMap.get('customername');
        if (customerName && (!this.themeService.isCustomer(customerName) || environment.production)) {
           
            //Navigate to Authentication route to clear wrong URL path
             
           this.router.navigate([Constants.URL.Authentication])
        } else if (customerName && this.themeService.isCustomer(customerName) && !environment.production) {
            this.themeService.customerName = customerName;
            this.themeService.loadTheme();
        }
        */

    this.formGroup = new UntypedFormGroup({
      email: new UntypedFormControl(
        '',
        Validators.compose([Validators.required, Validators.pattern(this.regexService.getEmailPattern())])
      ),
      password: new UntypedFormControl('', Validators.compose([Validators.required])),
    });
    this.translationService.onChange().subscribe({
      next: () => {
        this.emailPlaceholder = this.translationService.translate('Authentication.Email');
        this.passwordPlaceholder = this.translationService.translate('Authentication.Password');
      },
    });
  }

  ionViewDidEnter() {
    this.menuCtrl.swipeGesture(false);
  }

  public async signIn() {
    const net = await Network.getStatus();

    if (!net.connected) {
      this.noNetworkConnection = true;
      await this.spinningService.hideSpinner();
      return;
    }

    this.wrongCredentialsError = false;

    let allowed = this.themeService.isAllowed(this.formGroup.controls['email'].value);
    if (allowed) {
      await this.spinningService.showSpinner();
      this.authService
        .loginUser(this.formGroup.controls['email'].value, this.formGroup.controls['password'].value)
        .subscribe(
          async (response) => {
            if (response.access_token) {
              this.authService.setToken(response.access_token);
              this.userService.setUser(response.access_token);
              this.router.navigate([Constants.URL.Books]);
              await this.spinningService.hideSpinner();
            }
          },
          async (error) => {
            await this.spinningService.hideSpinner();
            if (error.status === 400 || error.status === 403 || error.status === 500) {
              this.toastService.showErrorMessage(this.translationService.translate(`Authentication.${error.status}`));
            } else if (error.status === 401) {
              this.presentResendConfirmationAlert();
            } else if (error.status === 404) {
              this.emailNotProvided = false;
              this.resentConfirmationSuccess = false;
              this.wrongCredentialsError = true;
            }
          }
        );
    } else {
      this.toastService.showWarningMessage(this.translationService.translate('Authentication.NotAllowed'));
      console.warn('Not allowed for this customer.');
    }
  }

  async presentResendConfirmationAlert() {
    const alert = await this.alertController.create({
      header: this.translationService.translate('Authentication.NotVerifiedHeader'),
      message: this.translationService.translate('Authentication.NotVerified'),
      cssClass: 'buttonCss',
      buttons: [
        {
          text: this.translationService.translate('Authentication.ResendConfirmation'),
          role: 'ok',
          cssClass: 'close-button',
          handler: () => {
            this.resendConfirmation();
          },
        },
        {
          text: this.translationService.translate('Dialog.Cancel'),
          role: 'cancel',
          cssClass: 'close-button',
        },
      ],
    });
    alert.present();
  }

  public resendConfirmation() {
    this.spinningService.showSpinner();
    this.notVerified = false;
    this.emailNotProvided = false;
    this.resentConfirmationSuccess = false;
    const email = this.formGroup.controls['email'].value;
    if (!email) {
      this.emailNotProvided = true;
      this.notVerified = true;
      this.spinningService.hideSpinner();
    } else {
      this.notVerified = false;
      this.emailNotProvided = false;
      this.authService.resendVerificationToken(email).subscribe(
        (success) => {
          this.resentConfirmationSuccess = true;
          this.spinningService.hideSpinner();
          this.toastService.showSuccessMessage(this.translationService.translate('Authentication.ConfirmationResent'));
        },
        (error) => {
          this.spinningService.hideSpinner();
          this.toastService.showErrorMessage(this.translationService.translate(`Authentication.${error.status}`));
        }
      );
    }
  }

  public submitOnEnter(event: any) {
    if (event.key === 'Enter') {
      if (this.formGroup.valid) {
        this.signIn();
      }
    }
  }

  public SSOlogin() {
    this.authService.loginWithSSO();
  }

  public resetForm() {
    this.wrongCredentialsError = false;
  }

  public resetMailField() {
    this.formGroup.controls['email'].reset();
  }

  public resetPasswordField() {
    this.formGroup.controls['password'].reset();
  }

  public signUp() {
    this.router.navigate([Constants.URL.RegistrationNavigation]);
  }

  public navigateResetPassword() {
    this.router.navigate([Constants.URL.ResetPasswordNavigation]);
  }
}
