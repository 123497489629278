import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { ClientBook } from '../../PODO/clientBook';
import { Constants } from '../../PODO/constants';
import { BooksService } from './../books/books.service';

@Injectable({
  providedIn: 'root',
})
export class BookResolverService implements Resolve<ClientBook | undefined> {
  constructor(private booksService: BooksService, private router: Router) {}

  public resolve(route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): ClientBook | undefined {
    const navigateTo404 = (message: string) => {
      console.warn(message);
      this.router.navigate([`/${Constants.URL.Books}`]);
      return undefined;
    };

    const id = route.paramMap.get('id');
    if (!id) {
      return navigateTo404(`BookResolverService: No 'id' in route ${route.url}.`);
    }

    const books = this.booksService.books$.getValue();

    const b = books.find((book) => book.id.toString() === id);
    if (!b) {
      return navigateTo404(`BookResolverService: Id ${id} not found in books list.`);
    }
    return b;
  }
}
