import { L10nConfig } from 'angular-l10n';
import { i18nAsset } from './i18n';
export const l10nConfig: L10nConfig = {
    "format":"language-region",
    "providers":[
       {
          "name":"app",
          "asset":i18nAsset
       }
    ],
    "cache":true,
    "keySeparator":".",
    "defaultLocale":{
       "language":"de-DE",
       "currency":"EUR",
       "timeZone":"Europe/Berlin",
       "units":{
          "length":"kilometer"
       }
    },
    "schema":[
       {
          "locale":{
             "language":"en-GB",
             "currency":"GBP",
             "timeZone":"Europe/London",
             "units":{
                "length":"kilometer"
             }
          },
          "dir":"ltr",
          "text":"English"
       },
       {
          "locale":{
             "language":"de-DE",
             "currency":"EUR",
             "timeZone":"Europe/Berlin",
             "units":{
                "length":"kilometer"
             }
          },
          "dir":"ltr",
          "text":"Deutsch"
       }
    ]
 };