import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-burger',
  templateUrl: './burger.component.html',
  styleUrls: ['./burger.component.scss'],
})
export class BurgerComponent implements OnInit {
  @Input() open: boolean | undefined;

  @Input() inactive: Observable<void> = new Observable<void>();
  private eventsSubscription: Subscription = new Subscription();
  burgerInactive: boolean = false;

  constructor() {}

  ngOnInit() {
    this.eventsSubscription = this.inactive.subscribe((burgerInactive: any) => {
      this.burgerInactive = burgerInactive;
    });
  }
}
