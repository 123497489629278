import { Component, Inject, OnInit } from '@angular/core';
import { Network } from '@capacitor/network';
import { ModalController } from '@ionic/angular';
import { Service } from '../../../services/rest-client/rest-client.service';
import { ThemeService } from '../../../services/themes/theme.service';
import { ToastService } from '../../../services/toast/toast.service';
import { UserService } from '../../../services/user/user.service';
import { BooksService } from '../../../services/books/books.service';
import { UntypedFormGroup,  FormBuilder,  Validators, UntypedFormControl, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { ContactSupportComponent } from '../contact-support.component';
import { DeviceDetectorService } from '../../../util/device-detector/device-detector.service';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {

  public subject: string = '';
  public message: string = '';
  SupportMessage: Object = {
  };
  form: UntypedFormGroup;
  public userId: string = '';
  public books: any;
  public emailPlaceholder : string = '';
  public subjectPlaceholder : string = '';
  public bookPlaceholder : string = '';
  public subjectValuePlaceholder : string = '';

  constructor(
    private modalCtrl: ModalController,
    private userService: UserService,
    private themeService: ThemeService,
    private restService: Service,
    private toastService: ToastService,
    private booksService: BooksService,
    public deviceDetector: DeviceDetectorService,
    private translationService: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")]),
      email2: new UntypedFormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$")]),
      subject: new UntypedFormControl('', Validators.required),
      subjectValue: new UntypedFormControl('', Validators.required),
      book: new UntypedFormControl('', Validators.required),
      message: new UntypedFormControl('', Validators.required)
   }, {validators : [this.matchEmails, this.setSubject]}
   );
  }

  async ngOnInit() {
    await this.loadProfile();
    this.booksService.books$.subscribe(
      books => {
        this.books = books;
      }
    )
    this.userId = this.userService.getUserID();
    this.translationService.onChange().subscribe({
      next: () => {
        this.emailPlaceholder = this.translationService.translate('Support.emailPlaceholder');
        this.subjectPlaceholder = this.translationService.translate('Support.subjectPlaceholder');
        this.bookPlaceholder = this.translationService.translate('Support.bookPlaceholder');
        this.subjectValuePlaceholder = this.translationService.translate('Support.subjectValuePlaceholder');
      },
    });
  }

  async sendMessage(form: any) {
    const network = await Network.getStatus();

    if (network.connected === false) {
      this.toastService.showErrorMessage('Nachricht wurde nicht verschickt, überprüfen Sie ihre Internetverbindung');
      this.modalCtrl.dismiss();
      return;
    }

    const message = {
      sender_name: 'Litello Support',
      sender_email: 'noreply@litello.com',
      recipients: ['support@litello.com'],
      subject: '@Support: ' + this.form.value.subject + ' ' + this.form.value.subjectValue + this.form.value.book,
      body_text: this.getEmailBodyText(),
      body_html: this.getEmailBodyHtml()
    };

    this.restService.postCSMessage(message).subscribe(
      (data) => {
        this.toastService.showSuccessMessage('Nachricht wurde versandt!');
        this.modalCtrl.dismiss();
      },
      (error) => {
        this.toastService.showErrorMessage(
          'Nachricht wurde nicht verschickt, da ein Problem mit dem Server aufgetreten ist'
        );
      }
    );
  }

  private loadProfile() {
    const userID = this.userService.getUserID();
    if (userID) {
      this.restService.getProfile(userID).subscribe(
        (profile) => {
          if(profile.email){
            this.form.controls['email'].setValue(profile.email);
            this.form.controls['email2'].setValue(profile.email);
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  matchEmails(frm: AbstractControl){
    const pass = frm.get('email')?.value;
    const confirmPass = frm.get('email2')?.value;
    pass === confirmPass ? frm.get('email2')?.setErrors(null) :frm.get('email2')?.setErrors({'EmailsNotMatching' : true});
    return pass === confirmPass ? null :{'EmailsNotMatching' : true};
  }

  setSubject(frm: AbstractControl){
    const pass = frm.get('subject')?.value;
    if(pass === 'Problem mit einem LitelloBook'){
      frm.get('subjectValue')?.setErrors(null)
    }else if(pass === 'Sonstiges'){
      frm.get('book')?.setErrors(null)
    } else if(pass === 'Problem mit dem Webreader'){
      frm.get('subjectValue')?.setErrors(null)
      frm.get('book')?.setErrors(null)
    }
    return null
  }

public onSubmitTemplateBased(message : any) {
    this.sendMessage(message);
}

  public async goBack(){
    await this.modalCtrl.dismiss();
    let cssClass = this.deviceDetector.isDesktop() ? 'ion-main-modal' : 'ion-main-modal-mobile';
    const popover = await this.modalCtrl.create({
      component: ContactSupportComponent,
      cssClass: cssClass,
  })
  return await popover.present();
  }

  async closeModal() {
    await this.modalCtrl.dismiss();
    // await this.openProfile();
  }

  private getEmailBodyText(): string {
    return `User ID: ${this.userId} 
User email: ${this.userService.getEmail()} 
Contact email: ${this.form.value.email} 
Customer: ${this.themeService.customerName} 
Reason: ${this.form.value.subject} 
Book: ${this.form.value.book || '-'} 

Subject: ${this.form.value.subjectValue || this.form.value.subject} 

Message:
${this.form.value.message}
`;
  }

  private getEmailBodyHtml(): string {
    return  `<html><head></head>
      <body>
        <h1>
          UserID: ${this.userId}'
          <br>
          User email: ${this.userService.getEmail()} 
          <br>
          Contact email: ${this.form.value.email} 
          <br>
          Customer: ${this.themeService.customerName} 
          <br>
          Reason: ${this.form.value.subject} 
          <br>
          Book: ${this.form.value.book || '-'} 
        </h1>
        <br>
        <p> Subject:  ${this.form.value.subjectValue || this.form.value.subject} </p>
        <br>
        <p> Message:  </p>
        <p>
          ${this.form.value.message}
        </p>
      </body>
    </html>`
  }
}
