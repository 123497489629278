<ion-header *ngIf="!selectedThread">
  <div class="communication-overview-header">
    <ion-select #groupSelect
      [placeholder]="noGroupsPlaceholder"
      class="select-chapter"
      interface="popover"
      [(value)]="selectedGroup"
    >
      <litello-dropdown-list
        [listItems]="groupMemberships"
        [listItemDisplayTextProperty]="'title'"
        [selectElement]="groupSelect.el"
      ></litello-dropdown-list>
    </ion-select>
  </div>
</ion-header>
<ion-header class="header threadsContainer thread" *ngIf="selectedThread" #post>
  <div class="message">
      {{ selectedThread.message }}
  </div>
  <div class="details">
    <div class="author">
      <ion-avatar class='avatar'>
        <img class="avatar__image"
          [src]='getImageURL(selectedThread.ownerId)'
          (load)="onAvatarLoad($event.target.parentElement)"
          (error)="onAvatarError($event.target.parentElement)"
        >
        <div class="avatar__initials">{{ selectedThread.nickName | slice:0:2 | uppercase }}</div>
      </ion-avatar>
      <span class="name">{{ selectedThread.nickName }}</span>
    </div>
    <div style="flex-grow: 1;"></div>
    <div class="time">{{ (selectedThread.editingDate || selectedThread.creationDate) | fixDateFormat | date: 'dd.MM.yyyy HH:mm' }}</div>
    <ion-icon slot="icon-only" name="trash-outline"
      class="delete"
      *ngIf="selectedThread.ownerId === this.userService.getUserID()"
      (click)="$event.stopPropagation(); showConfirmationDialog(selectedThread)"
    ></ion-icon>
  </div>
</ion-header>
<ion-content
  class="communication-overview-content"
  id="communication-overview-content"
>
  <div class="threadsContainer">
    <div *ngIf="threads && !threads.length" class="no-threads" l10nTranslate>Communication.NoThreads</div>
    <ion-card *ngFor="let thread of threads" class="thread" 
      (click)="thread.parentId ? null : selectedThread = thread"
    >
      <div class="message">{{ thread.message }}</div>
      <div class="details">
        <div class="author">
          <ion-avatar class='avatar'>
            <img class="avatar__image"
              [src]='getImageURL(thread.ownerId)'
              (load)="onAvatarLoad($event.target.parentElement)"
              (error)="onAvatarError($event.target.parentElement)"
            >
            <div class="avatar__initials">{{ thread.nickName | slice:0:2 | uppercase }}</div>
          </ion-avatar>
          <span class="name">{{ thread.nickName }}</span>
        </div>
        <div style="flex-grow: 1;"></div>
        <div class="time">{{ (thread.editingDate || thread.creationDate) | fixDateFormat | date: 'dd.MM.yyyy HH:mm' }}</div>
        <ion-icon slot="icon-only" name="trash-outline"
          class="delete"
          *ngIf="thread.ownerId === this.userService.getUserID()"
          (click)="$event.stopPropagation(); showConfirmationDialog(thread)"
        ></ion-icon>
      </div>
      <div *ngIf="!selectedThread" class="comments">
        <div style="flex-grow: 1;"></div>
        <div>
          <span>{{ (comments[thread.id] && comments[thread.id].length) || '0' }}{{' '}}</span>
          <span l10nTranslate>Communication.Comments</span>
        </div>
      </div>
    </ion-card>
  </div>
</ion-content>
<div *ngIf="selectedGroup && selectedGroup.role === 'admin'" id="threadInput">
  <ion-textarea
    placeholder="{{ postPlaceholder }}"
    [(ngModel)]="postText"
    inputmode="text"
  >
  </ion-textarea>
  <div class="note-buttons">
    <!-- <ion-button class="note-button-cancel" (click)="showConfirmationDialog()" l10nTranslate>Dialog.Cancel</ion-button> -->
    <ion-button class="note-button-save" (click)="savePost()" l10nTranslate>Dialog.Save</ion-button>
  </div>
</div>
