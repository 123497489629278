import { Component, Inject, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService } from '../../util/device-detector/device-detector.service';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import marked from 'marked';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent implements OnInit {
  public loading: boolean = false;

  public imprintHtml: any;

  constructor(
    private modalCtrl: ModalController,
    private http: HttpClient,
    public deviceDetector: DeviceDetectorService,
    private translationService: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngOnInit() {
    this.loadImprint();
  }

  public refresh(event: any) {
    this.loadImprint();
    event.target.complete();
  }

  /**
   * Loads the imprint from the markdown file.
   * The language is determined by the current locale.
   */
  private loadImprint() {
    this.loading = true;
    const locale = this.getLanguageCode();

    this.getImprint(locale).subscribe({
      next: (imprint) => {
        this.imprintHtml = marked(imprint);
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  /**
   * Gets the url to the imprint markdown file.
   * All locales except "de" load the english version.
   * @param lang language code. (two letter code, e.g. "de")
   * @returns url to the markdown file as an observable.
   */
  private getImprint(lang: string): Observable<any> {
    let url = lang === 'de' ? 'assets/IMPRINT.md' : 'assets/IMPRINT_EN.md';
    return this.http.get(url, { responseType: 'text' });
  }

  /**
   * Returns the language code of the current locale.
   * @returns {string} The language code of the current locale. (de, en, fr, etc.)
   */
  private getLanguageCode(): string {
    return this.translationService.getLocale().language;
  }

  /**
   * Closes the modal.
   */
  public async closeDataPrivacy() {
    this.modalCtrl.dismiss();
  }
}
