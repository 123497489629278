import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private userId: string = '';
  private token: string = '';
  private email: string = '';

  constructor(
    private jwtHelper: JwtHelperService
  ) {}

  public checkIfIDIsSet() {
    const token = localStorage.getItem('token');
    return this.userId !== '' || token !== null;
  }
  public getUsername() {
    if (this.userId === '')
      return null;

    const token = this.jwtHelper.tokenGetter();
    if (typeof token === 'string')
      return this.jwtHelper.decodeToken(token).iss;
    return null;
  }

  public getUserID() {
    return this.userId;
  }

  public setUser(userToken: string) {
    this.userId = this.jwtHelper.decodeToken(userToken).userId;
    this.email = this.jwtHelper.decodeToken(userToken).email;
    this.token = userToken;
  }

  public deleteUser() {
    this.userId = '';
  }

  public getEmail() {
    return this.email;
  }
}
