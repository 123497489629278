import { AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { ToastService } from '../../../services/toast/toast.service';
import { ContextmenuService } from '../../services/contextmenu/contextmenu.service';
import { NoteService } from '../../services/note/note.service';
import { EpubRenderService } from '../../services/rendition/epubrender.service';
import { TexthighlightService } from '../../services/texthighlight/texthighlight.service';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
})
export class ContextMenuComponent implements OnInit, AfterViewInit {
  public selectedColor: string = '';
  public color1 = '';
  public color2 = '';
  public color3 = '';
  public color4 = '';
  public selectedValue: string = '';

  @Output() openPanel = new EventEmitter<any>();
  @Output('onTextHighlightCreate') textHighlightCreateCallback = new EventEmitter<any>();

  @Output() onContextMenuColorChange = new EventEmitter<any>();

  @Output() openNoteOverview = new EventEmitter<boolean>();
  @Input() cp: number = 0;

  @Input() isHighlightingEnabled: boolean = false;

  constructor(
    public contextmenuService: ContextmenuService,
    private epubRenderService: EpubRenderService,
    private texthighlightService: TexthighlightService,
    private noteService: NoteService,
    private toastService: ToastService,
    private translationService: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngOnInit() {
    this.contextmenuService.updateDismiss(false);
  }

  ngAfterViewInit(){
    this.color1 = getComputedStyle(document.documentElement).getPropertyValue('--book-contextMenu-color1');
    this.color2 = getComputedStyle(document.documentElement).getPropertyValue('--book-contextMenu-color2');
    this.color3 = getComputedStyle(document.documentElement).getPropertyValue('--book-contextMenu-color3');
    this.color4 = getComputedStyle(document.documentElement).getPropertyValue('--book-contextMenu-color4');
  }

  toggleNoteOverview() {
    this.openNoteOverview.emit();
  }

  public async detachHighlightSelection() {
    const cfiRange = this.contextmenuService.cfiRange;
    this.noteService.deletePreStoredNote();
    await this.noteService.deleteNote(cfiRange);
    this.texthighlightService.deleteTextHighlight(cfiRange);
    this.epubRenderService.removeAnnotation(cfiRange, 'highlight');
    document.querySelectorAll(`a[data-epubcfi="${cfiRange}"]`).forEach((a) => a.remove());
    this.contextmenuService.toggleMenu('hide');
    this.textHighlightCreateCallback.emit();
  }

  public changeHighlight(value: string): void {
    this.selectedValue = value;
    let color = getComputedStyle(document.documentElement).getPropertyValue(value);
    const cfiRange = this.contextmenuService.cfiRange;
    this.contextmenuService.color = color;
    if(this.isHighlightingEnabled) this.epubRenderService.changeSelectionColor(color);
    this.updateSelectionData(cfiRange, color);
    const cleanCfi = TexthighlightService.cleanCfi(cfiRange);
    document.querySelectorAll(`[data-epubcfi="${cleanCfi}"]`).forEach((g) => {
      if (this.epubRenderService.getCfiPosition(cfiRange)) {
        g.setAttribute('fill', color);
      }
    });
  }

  private async updateSelectionData(cfiRange: string, color: string) {
    this.noteService.updateNote(cfiRange, color, this.contextmenuService.notetext).then(() => {
      this.textHighlightCreateCallback.emit();
    });
  }

  public async openTheCreateNotePanel() {
    this.toggleNoteOverview();
    this.contextmenuService.toggleMenu('hide');
    if (this.openPanel) this.openPanel.emit({ navigateTo: 'note' });
  }

  public copyContextMenuText() {
    const copyText = this.contextmenuService.text;
    const tempTextArea = document.createElement('textarea');
    document.body.appendChild(tempTextArea);
    tempTextArea.value = copyText;
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    this.toastService.showSuccessMessage(this.translationService.translate('Contextmenu.Copied'));
    this.contextmenuService.toggleMenu('hide');
  }
}
