import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { IonContent, IonTextarea, ModalController } from '@ionic/angular';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { popoverDropdownEnterAnimation } from '../../../../animations/dropdown.animation';
import { environment } from '../../../../environments/environment';
import { DialogComponent } from '../../../components/dialog/dialog.component';
import { ClientBook } from '../../../PODO/clientBook';
import { BooksService, CommunicationThread, GroupMembership } from '../../../services/rest-client/rest-client.service';
import { ToastService } from '../../../services/toast/toast.service';
import { UserService } from '../../../services/user/user.service';
import { DeviceDetectorService } from '../../../util/device-detector/device-detector.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-communication-overview',
  templateUrl: './communication-overview.component.html',
  styleUrls: ['./communication-overview.component.scss'],
})
export class CommunicationOverviewComponent implements OnInit, OnDestroy {
  dropdownAnimation = popoverDropdownEnterAnimation;

  @Input() book: ClientBook | undefined;

  private groupMemberships: GroupMembership[] | undefined;
  private _selectedGroup: GroupMembership | undefined;
  public get selectedGroup(): GroupMembership | undefined {
    return this._selectedGroup;
  }
  public set selectedGroup(value: GroupMembership | undefined) {
    this._selectedGroup = value;
    this.selectedThread = null;
    this.allThreads = null;
    if(this.selectedGroup) {
      this.fetchThreads(this.selectedGroup);
    }
  }

  private allThreads: CommunicationThread[] | null = null;
  public get threads(): CommunicationThread[] {
    return this.selectedThread ? this.comments[this.selectedThread.id] : this.allThreads!;
  }

  @ViewChild(IonContent) content: IonContent | undefined;
  scrollPosition: number = 0;

  postPlaceholder = this.translationService.translate('Communication.WritePost');
  postText: string | null = null;

  noGroupsPlaceholder: string = this.translationService.translate('Communication.NoGroups');

  public comments: {[key: string]: CommunicationThread[]} = {};

  private _selectedThread: CommunicationThread | null = null;
  public get selectedThread(): CommunicationThread | null {
    return this._selectedThread;
  }
  @Input() public set selectedThread(value: CommunicationThread | null) {
    this._selectedThread = value;
    this.selectedThreadChange.emit(value);
  }
  @Output() selectedThreadChange = new EventEmitter<CommunicationThread | null>();

  private mutationObserver: MutationObserver;

  constructor(
    private translationService: L10nTranslationService,
    private deviceDetector: DeviceDetectorService,
    private screenOrientation: ScreenOrientation,
    private toastService: ToastService,
    private modalController: ModalController,
    private booksService: BooksService,
    public userService: UserService,
    private elRef: ElementRef,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {
    this.mutationObserver = new MutationObserver(this.onMutate.bind(this));
    this.mutationObserver.observe(this.elRef.nativeElement, { childList: true });
    this.screenOrientation.onChange().subscribe(async () => {
      if (!this.deviceDetector.isDesktop()) {
        if (this.content) {
          await this.content.scrollToPoint(0, this.scrollPosition);
        }
      }
    });
  }

  async ngOnInit(): Promise<void> {
    try {
      this.groupMemberships = await this.fetchCommunicationGroupMemberships();
      if((this.groupMemberships && this.groupMemberships.length)) {
        this.selectedGroup = this.groupMemberships[0];
      }
    } catch (error) {
      
    }
  }

  ngOnDestroy(): void {
    this.mutationObserver.disconnect();
  }
  
  private async fetchCommunicationGroupMemberships(): Promise<GroupMembership[]> {
    return await firstValueFrom(this.booksService.getCommunicationGroupMemberships(this.userService.getUserID()));
  }

  private async fetchThreads(group: GroupMembership): Promise<void> {
    try {
      const allPosts = await firstValueFrom(this.booksService.getCommunicationThreads(
        this.userService.getUserID(),
        group.id
      ));

      const comments: { [key: string]: CommunicationThread[] } = {};
      allPosts.forEach(thread => {
        if(thread.parentId) {
          if(!comments[thread.parentId]) comments[thread.parentId] = [];
          comments[thread.parentId].push(thread);
        }
      });
      this.comments = comments;
      this.allThreads = allPosts.filter(post => !post.parentId);      
    } catch (error) {
      this.allThreads = [];
    }
  }

  onIonScroll(event: any) {
    if (event.detail.scrollTop !== 0) {
      this.scrollPosition = event.detail.scrollTop;
    }
  }

  public onAvatarLoad(element: HTMLElement): void {
    element.classList.add('avatar__loaded');
  }

  public onAvatarError(element: HTMLElement): void {
    element.classList.add('avatar_error');
  }

  public async showConfirmationDialog(thread: CommunicationThread) {
    let modalDiv = document.getElementById('append-modal');
    const dialog = await this.modalController.create({
      component: DialogComponent,
      cssClass: 'note-overview-modal modal-create-note',
      backdropDismiss: false,
      enterAnimation: undefined,
      leaveAnimation: undefined,
      componentProps: {
        message: this.translationService.translate('Dialog.DeletePostReally'),
        buttons: [
          { text: this.translationService.translate('Dialog.Cancel'), method: this.closeDialog.bind(this) },
          {
            text: this.translationService.translate('Dialog.Delete'),
            method: this.deletePost.bind(this, thread),
            style: 'fill',
          },
        ],
        bookNoteCustomCss: true,
      },
    });
    modalDiv?.appendChild(dialog);
    await dialog.present();
  }

  private async closeDialog() {
    const modalElem = await this.modalController.getTop();
    if (modalElem) {
      modalElem.dismiss();
    }
  }

  public async savePost() {
    if(!this.postText || !this.postText.trim() || !this.selectedGroup) return;
    this.booksService.createCommunicationThread(
      this.userService.getUserID(),
      this.selectedGroup!.id,
      this.postText,
      this.selectedThread?.id
    ).subscribe(res => {
      this.postText = null;
      this.fetchThreads(this.selectedGroup!);
    });
  }

  deletePost(thread: CommunicationThread): void {
    this.booksService.deleteCommunicationThread(this.userService.getUserID(), thread.id, thread.groupId).subscribe(
      res => {
        this.toastService.showSuccessMessage(this.translationService.translate('Communication.SuccessfullyDeleted'));
        if(this.selectedThread && (thread.id === this.selectedThread.id)) this.selectedThread = null;
        this.fetchThreads(this.selectedGroup!);
        this.closeDialog();
      },
      err => {
        this.toastService.showErrorMessage(err.error);
      }
    );
  }

  public getImageURL(userId: string): string {
    return `${environment.USER_ASSETS_URL}/avatar/${userId}.png`;
  }

  public onMutate(mutations: MutationRecord[]) {
    const addedNodes = mutations.map(x => x.addedNodes).reduce((acc, curr) => acc.concat(Array.from(curr)), [] as Node[]);
    const inputContainer: Node | undefined = addedNodes.find(node => node instanceof HTMLElement && node.id === 'threadInput');
    if(inputContainer) {
      const textarea = inputContainer.firstChild as unknown as IonTextarea;
      textarea.autoGrow = false;
      setTimeout(() => textarea.autoGrow = true, 100);
    }
  }
}
