import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { ContactSupportComponent } from './../contact-support.component';
import { ModalController } from '@ionic/angular';
import { Component, Inject, OnInit } from '@angular/core';
import { DeviceDetectorService } from '../../../util/device-detector/device-detector.service';
import { TIPPS_DE } from '../../../PODO/tipps';
import { TIPPS_EN } from '../../../PODO/tipps';

const LOCALE_KEY = 'locale';
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})

export class HelpComponent implements OnInit {

  public Tipp: any;
  public Tipps: any;

  constructor(
    private modalCtrl: ModalController,
    public deviceDetector: DeviceDetectorService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) { }

  ngOnInit() {
    let language = localStorage.getItem(LOCALE_KEY);
    if (language?.startsWith('en')) {
      this.Tipps = TIPPS_EN;
    } else if (language?.startsWith('de')) {
      this.Tipps = TIPPS_DE;
    } else {
      this.Tipps = TIPPS_DE;
    }
  }

  public openAccordeon(item: any) {
    if (document.getElementById(item.id)!.style.display === "block") {
      document.getElementById(item.id)!.style.display = "none";
      item.opened = false;
    } else {
      document.getElementById(item.id)!.style.display = "block";
      item.opened = true;
    }
  }

  async closeModal() {
    await this.modalCtrl.dismiss();
  }

  public async goBack() {
    await this.modalCtrl.dismiss();
    let cssClass = this.deviceDetector.isDesktop() ? 'ion-main-modal' : 'ion-main-modal-mobile';
    const popover = await this.modalCtrl.create({
      component: ContactSupportComponent,
      cssClass: cssClass,
    })
    return await popover.present();
  }
}
