import { ThemeService } from './../../services/themes/theme.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RegexService } from '../../services/regex/regex.service';
import { Profile, Service } from '../../services/rest-client/rest-client.service';
import { UserService } from '../../services/user/user.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { DeviceDetectorService } from '../../util/device-detector/device-detector.service';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  public formGroup: UntypedFormGroup | undefined;
  public profile: Profile | undefined = undefined;
  oldPasswordCheck: boolean | undefined = undefined;

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: UntypedFormBuilder,
    private regexService: RegexService,
    private restService: Service,
    private userService: UserService,
    public deviceDetector: DeviceDetectorService,
    private spinnerService: SpinnerService,
    public themeService: ThemeService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.formGroup = this.formBuilder.group(
      {
        oldPassword: new UntypedFormControl(
          '',
          Validators.compose([Validators.required, Validators.pattern(this.regexService.getPasswordPattern())])
        ),
        newPassword: new UntypedFormControl(
          '',
          Validators.compose([Validators.required, Validators.pattern(this.regexService.getPasswordPattern())])
        ),
        newPasswordConfirm: new UntypedFormControl(
          '',
          Validators.compose([Validators.required, Validators.pattern(this.regexService.getPasswordPattern())])
        ),
      },
      {
        validators: this.passwordCheck.bind(this),
      }
    );
  }

  passwordCheck(formGroup: UntypedFormGroup) {
    const password = formGroup.get('newPassword')?.value;
    const confirmPassword = formGroup.get('newPasswordConfirm')?.value;

    if (formGroup.get('newPassword')?.value !== '' && formGroup.get('newPassword')?.hasError('pattern')) {
      formGroup.get('newPassword')?.setErrors({ incorrect: true });
    } else if (
      (formGroup.get('newPasswordConfirm')?.value !== '' && formGroup.get('newPasswordConfirm')?.hasError('pattern')) ||
      password !== confirmPassword
    ) {
      formGroup.get('newPasswordConfirm')?.setErrors({ incorrect: true });
    } else if (
      password === confirmPassword &&
      formGroup.get('newPasswordConfirm')?.value !== '' &&
      !formGroup.get('newPasswordConfirm')?.hasError('pattern')
    ) {
      formGroup.get('newPasswordConfirm')?.setErrors(null);
      formGroup.get('newPassword')?.setErrors(null);
    }
  }

  async changePassword() {
    await this.createProfile();
    try {
      const userID = this.userService.getUserID();
      if (this.profile)
        this.restService.updateProfile(userID, this.profile).subscribe(
          (updatedProfile) => {
            this.oldPasswordCheck = true;
            this.spinnerService.hideSpinner();
            this.closeModal();
          },
          (error) => {
            this.spinnerService.hideSpinner();
            console.error(error.response);
            if (JSON.parse(error.response) === 'Old password is not correct!') {
              this.oldPasswordCheck = false;
            }
          }
        );
    } catch (e: any) {
      console.error(e);
    }
  }

  async createProfile() {
    let oldPassword = this.formGroup?.get('oldPassword')?.value;
    let password = this.formGroup?.get('newPassword')?.value;

    if (oldPassword && password)
      this.profile = new Profile({
        oldPassword: oldPassword,
        password: password,
      });
  }

  async closeModal() {
    await this.modalCtrl.dismiss();
    // await this.openProfile();
  }

  // public async openProfile() {
  //   let cssClass = this.deviceDetector.isDesktop() ? 'ion-main-modal' : 'ion-main-modal-mobile'
  //   const popover = await this.modalCtrl.create({
  //     component: ProfileComponent,
  //     cssClass: cssClass,
  //     componentProps:{
  //       isEditMode: true
  //     }
  //   });
  //   return await popover.present();
  // }
}
