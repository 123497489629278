<div class="section language">
  <ion-item color="transparent" class="lang-select">
    <ng-container *ngFor="let lang of languages; last as isLast; let _i = index">
      <ion-label
        class="regular"
        color="medium"
        (click)="changeLanguage(lang)"
        [ngClass]="{ 'selected-lang': lang.localeCode === selectedLang.localeCode }"
      >
        {{ lang.langCode }}
      </ion-label>
      <div class="lang-select-divider" *ngIf="!isLast"></div>
    </ng-container>
  </ion-item>
</div>
