<ion-header class="ion-modal-header">
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="end">
      <ion-button (click)="closeCredits()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="header-title ion-text-left" l10nTranslate> Credits.Credits </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="!online">
  <ion-grid>
    <ion-row>
      <ion-col size="2"> </ion-col>

      <ion-col size="8">
        <ion-text color="dark">
          <h5 l10nTranslate="">Credits.NetworkMessage</h5>
        </ion-text>
      </ion-col>

      <ion-col size="2"> </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-content *ngIf="online" scrollY="false">
  <ion-spinner class="spinner" *ngIf="loading" name="crescent"></ion-spinner>
  <div class="credits-content">
    <div *ngFor="let c of credits" class="credits-content-item">
      <ion-grid>
        <ion-row class="item-row">
          <ion-col size="4">
            <ion-label l10nTranslate> Credits.Component<span>:</span> </ion-label>
          </ion-col>
          <ion-col class="item-result-col">
            <div class="custom-icon"></div>
            <div class="item-result">{{ c.component }}</div>
          </ion-col>
        </ion-row>
        <ion-row class="item-row">
          <ion-col size="4">
            <ion-label l10nTranslate> Credits.Owner<span>:</span> </ion-label>
          </ion-col>
          <ion-col class="item-result-col">
            <div class="custom-icon"></div>
            <div class="item-result">{{ c.owner }}</div>
          </ion-col>
        </ion-row>
        <ion-row class="item-row">
          <ion-col size="4">
            <ion-label l10nTranslate> Credits.License<span>:</span> </ion-label>
          </ion-col>
          <ion-col class="item-result-col">
            <div class="custom-icon"></div>
            <div class="item-result">{{ c.license }}</div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</ion-content>
