import { VerifyAccountComponent } from './authentication/verify-account/verify-account.component';
import { SSOWithTokenComponent } from './authentication/ssowith-token/ssowith-token.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BooksComponent } from './books/books/books.component';
import { AuthGuardService } from './guards/authGuard.service';
import { Constants } from './PODO/constants';
import { BookResolverService } from './services/book-resolver/book-resolver.service';
import { SelectivePreloadingStrategyService } from './services/selective-preloading-strategy/selective-preloading-strategy.service';
import { SsoComponent } from './authentication/sso/sso.component';
import { LoginComponent } from './authentication/login/login.component';
import { ThemeInitializationGuard } from './guards/theme-initialization.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [ThemeInitializationGuard],
    children: [
      {
        path: Constants.URL.Customer,
        component: LoginComponent,
      },
      {
        path: Constants.URL.Authentication,
        loadChildren: () => import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
      },
      {
        path: Constants.URL.AuthenticationWithSSOToken,
        component: SSOWithTokenComponent,
      },
      {
        path: Constants.URL.AuthenticationWithToken,
        component: SsoComponent,
      },
      {
        path: Constants.URL.VerifyAccount,
        component: VerifyAccountComponent,
      },
      {
        path: Constants.URL.ActivateAccount,
        component: VerifyAccountComponent,
      },
      {
        path: '',
        canActivate: [AuthGuardService],
        children: [
          {
            path: Constants.URL.BookIdNavigation,
            loadChildren: () => import('./book/book.module').then((m) => m.BookModule),
            resolve: {
              book: BookResolverService,
            },
            data: {
              preload: true,
            },
          },
          {
            path: '',
            pathMatch: 'full',
            redirectTo: Constants.URL.Books,
          },
          {
            path: Constants.URL.Books,
            component: BooksComponent,
          },
          {
            path: Constants.URL.Books_BookId_TO_OPEN,
            component: BooksComponent,
          },
          {
            path: '**',
            redirectTo: Constants.URL.Books,
          },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    preloadingStrategy: SelectivePreloadingStrategyService,
    relativeLinkResolution: 'legacy'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
