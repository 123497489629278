import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RegexService {
  private email: string =
    "[a-zA-ZäöüÄÖÜ0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-ZäöüÄÖÜ0-9!#$%&'*+/=?^_`" +
    '{|}~-]+)*@(?:[a-zA-ZäöüÄÖÜ0-9](?:[a-zA-ZäöüÄÖÜ0-9-]*[a-zA-ZäöüÄÖÜ0-9])?\\.)+[a-zA-ZäöüÄÖÜ0-9](?:[a-zA-ZäöüÄÖÜ0-9-]*[a-zA-ZäöüÄÖÜ0-9])?';
  private word: string = '^[a-zäöüA-ZÄÖÜ -]+$';
  private username: string = '^[a-zäöüÄÖÜ.-_s ]{0,32}$';
  private password: string = '^.{8,32}$';
  private createThreadOrGroup = '^[\\sa-zäöüA-ZÄÖÜ0-9-&]+$';

  constructor() {}
  public getEmailPattern(): string {
    return this.email;
  }

  public getPasswordPattern() {
    return this.password;
  }

  public getWordPattern(): string {
    return this.word;
  }

  public getUsernamePattern(): string {
    return this.username;
  }

  public getCreateThreadOrGroupPattern(): string {
    return this.createThreadOrGroup;
  }
}
