import { DatabaseService } from './../../../services/localDatabase/database-service.service';
import { BookDatabaseService } from './../../../services/book-database/book-database.service';
import { Injectable } from '@angular/core';
import { EpubCFI } from 'epubjs';
import { BehaviorSubject, firstValueFrom, forkJoin, Observable } from 'rxjs';
import { BooksService, Note } from '../../../services/rest-client/rest-client.service';
import { UserService } from '../../../services/user/user.service';
import { TexthighlightService } from '../texthighlight/texthighlight.service';
import { Network } from '@capacitor/network';
import { Capacitor } from '@capacitor/core';
import { v4 as uuid } from 'uuid';
import { ClientMark } from '../../../PODO/clientMark';

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  private notes$: BehaviorSubject<Note[]>;
  private _bookID: number = 0;
  readonly lengthzero = 0;

  public scrollToNote: ClientMark | null = null;

  constructor(
    private restNoteService: BooksService,
    private userService: UserService,
    private dataBaseService: DatabaseService,
    private bookDatabaseService: BookDatabaseService,
  ) {
    this.notes$ = new BehaviorSubject(<Note[]>[]);
  }

  //store note in localstorage in case there is weak internet connection
  setPreStoredNote(userID: string, bookID: number, storeNote: Note) {
    const date: Date = new Date();

    storeNote.bookId = bookID;
    storeNote.userId = userID;
    storeNote.creationDate = date.valueOf();

    localStorage.setItem('edited_note', JSON.stringify(storeNote));
  }

  getPreStoredNote() {
    var retrievedNote = localStorage.getItem('edited_note');

    return retrievedNote ? JSON.parse(retrievedNote) : null;
  }

  deletePreStoredNote() {
    localStorage.removeItem('edited_note');
  }

  /**
   * Creates new note of cfi range with color and text
   * @param cfiRange - ePub cfi range of selection
   * @param color - highlight color
   * @param notetext - text of note
   */
  public async createNote(cfiRange: string, color: string, notetext: string) {
    let successfullySaved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    const id = uuid();

    try {
      if (!this.userService.checkIfIDIsSet()) {
        console.log('Note cannot be created, user ID does not exist.');
        return;
      }
      const cfiStart: EpubCFI = new EpubCFI(cfiRange);
      const spine = cfiStart.spinePos;
      const newNote: any = {
        pageId: TexthighlightService.cleanCfi(cfiRange),
        note: notetext,
        color: color,
        pageNumber: spine,
        userId: this.userService.getUserID(),
        creationDate: Date.now(),
        bookId: this._bookID,
      };
      if (this.notes.some((note) => note.pageId === newNote.pageId)) {
        return;
      }

      if (this.userService.checkIfIDIsSet()) {
        const status = await Network.getStatus();

        if (status.connected && Capacitor.isNativePlatform()) {
          await this.restNoteService.notePost(this._bookID, newNote).subscribe(
            async (note) => {
              if (note) {
                newNote.id = note.id;
                const isSavedOnLocal: boolean = await this.dataBaseService.saveNote(
                  newNote.id,
                  newNote.bookId!,
                  newNote.note,
                  newNote.pageId,
                  newNote.color,
                  newNote.pageNumber!,
                  newNote.creationDate!,
                  '1'
                );
                if (isSavedOnLocal) {
                  //pre store the note in case server connection is slow
                  this.setPreStoredNote(this.userService.getUserID(), this._bookID, newNote);
                  this.notes.push(newNote);
                  successfullySaved.next(true);
                } else {
                  successfullySaved.next(false);
                }
              }
            },
            (error) => {
              console.log('storing on the remote database failed!!!', error);
              successfullySaved.next(false);
            }
          );
        } else if (!Capacitor.isNativePlatform() && status.connected) {
          //pre store the note in case server connection is slow
          this.setPreStoredNote(this.userService.getUserID(), this._bookID, newNote);

          await this.restNoteService.notePost(this._bookID, newNote).subscribe(
            (note) => {
              if (note) {
                this.notes.push(note);
                successfullySaved.next(true);
              }
            },
            (error) => {
              successfullySaved.next(false);
            }
          );
        } else if (Capacitor.isNativePlatform() && !status.connected) {
          newNote.id = id;
          const isSaved: boolean = await this.dataBaseService.saveNote(
            newNote.id,
            newNote.bookId!,
            newNote.note,
            newNote.pageId,
            newNote.color,
            newNote.pageNumber!,
            newNote.creationDate!,
            '2'
          );
          if (isSaved) {
            this.notes.push(newNote);
            successfullySaved.next(true);
          } else {
            successfullySaved.next(false);
          }
        }
      }
    } catch (e: any) {
      console.log('Failed to load notes ... 0');
      console.error(e);
    }
    return successfullySaved;
  }

  public async synchronizeData(bookID: number) {
    // TODO fix it: adding note text creates a new note on local db not updating it
    let note: Note = {
      id: '',
      userId: '',
      bookId: undefined,
      pageId: '',
      pageNumber: 0,
      note: '',
      creationDate: 0,
      color: '',
      keyword: '',
    };
    let resultObservable: BehaviorSubject<Note> = new BehaviorSubject<Note>(note);
    // const localNotes: LocalNotes[] = await this.dataBaseService.getNotesByBookId(bookID);
    // console.log(localNotes);
    // for await (const note of localNotes) {
    //   switch (note.synchronized) {
    //     case '1':
    //       console.log('Oranges are $0.59 a pound.');
    //       break;
    //     case '2':
    //       await this.restNoteService
    //           .notePost(this.userService.getUserID(), bookID, note)
    //           .subscribe((note)=>{
    //             resultObservable.next(note);
    //           })
    //     case '3':
    //       console.log('Mangoes and papayas are $2.79 a pound.');
    //       // expected output: "Mangoes and papayas are $2.79 a pound."
    //       break;
    //     case '4':
    //     default:
    //       console.log(`Sorry, we are out of .`);
    //   }
    // }
    //Push unsynchronized notes before loading new ones
    let arrayOfOb1: Array<Observable<any>> = new Array<Observable<Note>>();
    try {
      //Push newly created ones
      this.notes = await this.dataBaseService.getUnsynchronizedNotesByBookId(bookID, '2');
      if (this.notes) {
        for (let i = 0; i < this.notes.length; i++) {
          this.notes[i].userId = this.userService.getUserID();
          arrayOfOb1.push(await this.restNoteService.notePost(bookID, this.notes[i]));
          await this.dataBaseService.deleteNotesBybookIdAndSynchronized(bookID, '2');
        }
      }
      this.notes = [];
      // delete locally deleted ones
      this.notes = await this.dataBaseService.getUnsynchronizedNotesByBookId(bookID, '4');
      if (this.notes) {
        for (let i = 0; i < this.notes.length; i++) {
          arrayOfOb1.push(
            await this.restNoteService.noteDelete(this.notes[i].id!, bookID)
          );
          await this.dataBaseService.deleteNotesBybookIdAndSynchronized(bookID, '4');
        }
      }

      this.notes = [];
      //update unupdated notes
      this.notes = await this.dataBaseService.getUnsynchronizedNotesByBookId(bookID, '3');
      if (this.notes) {
        for (let i = 0; i < this.notes.length; i++) {
          arrayOfOb1.push(await this.restNoteService.notePost(bookID, this.notes[i]));
          // .subscribe((res) => {
          //   console.log("note post is done for sync 3. The data is : ", res)
          // },
          //     error => {
          //       console.log("Unsuccessful sync for flag 3 with error", error)
          //     }
          //     );
          await this.dataBaseService.deleteNotesBybookIdAndSynchronized(bookID, '3');
        }
      }
      //delete notes created in online-mode before in order to prevent duplicates
      await this.dataBaseService.deleteNotesBybookIdAndSynchronized(bookID, '1');
      await this.bookDatabaseService.setlastSynchronizationDatebyBookId(bookID);
      const observable = forkJoin(arrayOfOb1);
      return observable;
    } catch (e: any) {
      console.log(e);
    }
  }

  /**
   * Get notes of logged in user by book id
   * @param bookID - book id to get notes from
   */
  public async loadNotes(bookID: number) {
    const notesSubject: BehaviorSubject<Note[]> = new BehaviorSubject<Note[]>([]);
    if (!this.userService.checkIfIDIsSet())
      return notesSubject;

    const status = await Network.getStatus();
    this.notes = [];
    if (Capacitor.isNativePlatform() && status.connected) {
      const observable = await this.synchronizeData(this._bookID);
      observable!.subscribe({
        next: (value) => console.log(value),
        complete: async () => {
          this.notes = await firstValueFrom(this.restNoteService.notesGet(bookID));
          notesSubject.next(this.notes);
          if (this.notes) {
            for (let i = 0; i < this.notes.length; i++) {
              this.dataBaseService.saveNote(
                this.notes[i].id!,
                this._bookID,
                this.notes[i].note,
                this.notes[i].pageId,
                this.notes[i].color,
                this.notes[i].pageNumber!,
                this.notes[i].creationDate!,
                '1'
              );
            }
          }
        },
      });
    } else if (!Capacitor.isNativePlatform() && status.connected) {
      try {
        if (!this.userService.checkIfIDIsSet())
          throw new Error('User ID does not exist');

        this.notes = await firstValueFrom(this.restNoteService.notesGet(bookID));
        notesSubject.next(this.notes);
      } catch (e: any) {
        this.notes = [];
        console.error(e);
      }
    } else if (Capacitor.isNativePlatform() && !status.connected) {
      this.notes = await this.dataBaseService.getNotesByBookId(bookID);
      notesSubject.next(this.notes);
    }

    return notesSubject;
  }

  /**
   * Update text note by cfi range
   * @param cfiRange - CFI range of note to update
   * @param color - Color to update
   * @param notetext - note text to update
   */
  public async updateNote(cfiRange: string, color: string, notetext: string) {
    try {
      cfiRange = TexthighlightService.cleanCfi(cfiRange);
      const note = this.notes.find((note) => TexthighlightService.cleanCfi(note.pageId) === cfiRange);
      if (!note) {
        console.log(`Note by cfi range: ${cfiRange} not found!`);
        return;
      }

      if (note && note.id && this.userService.checkIfIDIsSet()) {
        const status = await Network.getStatus();
        note.color = color;
        note.note = notetext;
        if (status.connected && Capacitor.isNativePlatform()) {
          //pre store the note in case server connection is slow
          //this.setPreStoredNote(this.userService.getUserID(), this._bookID, note);
          this.restNoteService.notePost(this._bookID, note).subscribe();
          this.notes.forEach((n) => {
            if (n.id === note.id) {
              note.color = color;
              note.note = notetext;
            }
          });
          this.dataBaseService.updateNote(
            note.id,
            this._bookID,
            note.note,
            note.pageId,
            note.color,
            note.pageNumber!,
            '1'
          );
        } else if (!Capacitor.isNativePlatform() && status.connected) {
          this.setPreStoredNote(this.userService.getUserID(), this._bookID, note);
          this.restNoteService.notePost(this._bookID, note).subscribe();
          this.notes.forEach((n) => {
            if (n.id === note.id) {
              note.color = color;
              note.note = notetext;
            }
          });
        } else if (Capacitor.isNativePlatform() && !status.connected) {
          this.dataBaseService.updateNote(
            note.id,
            this._bookID,
            note.note,
            note.pageId,
            note.color,
            note.pageNumber!,
            '3'
          );
        }
      }
    } catch (e: any) {
      console.log('Failed to update note by cfi range: ', cfiRange);
      console.error(e);
    }
  }

  /**
   * Deletes note by cfi range
   * @param cfiRange - CFI range of note to delete
   */
  public async deleteNote(cfiRange: string): Promise<void> {
    try {
      cfiRange = TexthighlightService.cleanCfi(cfiRange);
      const note = this.notes.find((note) => TexthighlightService.cleanCfi(note.pageId) === cfiRange);
      if (!note) {
        console.log(`Note by cfi range: ${cfiRange} not found!`);
        return;
      }
      if (note && note.id && this.userService.checkIfIDIsSet()) {
        const status = await Network.getStatus();
        if (status.connected && Capacitor.isNativePlatform()) {
          this.dataBaseService.deleteNoteById(note.id);
          this.restNoteService.noteDelete(note.id, this._bookID).subscribe();
          this.notes = this.notes.filter((note) => note.pageId !== cfiRange);
        } else if (!Capacitor.isNativePlatform() && status.connected) {
          this.restNoteService.noteDelete(note.id, this._bookID).subscribe();
          this.notes = this.notes.filter((note) => note.pageId !== cfiRange);
        } else if (Capacitor.isNativePlatform() && !status.connected) {
          this.dataBaseService.updateNote(
            note.id,
            this._bookID,
            note.note,
            note.pageId,
            note.color,
            note.pageNumber!,
            '4'
          );
          this.notes = this.notes.filter((note) => note.pageId !== cfiRange);
        }
      }
    } catch (e: any) {
      console.log('Failed to delete note by cfi range: ', cfiRange);
      console.error(e);
    }
  }

  public addNoteMarks(cfiRange: string, rect: ClientRect | null) {
    const cleanCfi = TexthighlightService.cleanCfi(cfiRange);
    document.querySelectorAll(`g[data-epubcfi="${cleanCfi}"][data-type="note"]`).forEach((g) => {
      if (rect) {
        if (g.querySelectorAll(`image[data-type="anchor-note"]`).length > this.lengthzero) {
          return;
        }
        g.appendChild(this.createNoteMark(cfiRange, rect));
      }
    });
  }

  public createNoteMark(cfiRange: string, rect: ClientRect) {
    const anchor = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    anchor.setAttribute('width', '10');
    anchor.setAttribute('height', '10');
    anchor.setAttribute('x', `${rect.right}`);
    anchor.setAttribute('y', `${rect.top - 5}`);
    anchor.setAttributeNS('http://www.w3.org/1999/xlink', 'href', '/assets/icon/block.svg');
    anchor.setAttributeNS(null, 'data-epubcfi', cfiRange);
    anchor.setAttributeNS(null, 'data-type', 'anchor-note');

    return anchor;
  }

  set bookID(bookID: number) {
    this._bookID = bookID;
  }

  get notes(): Note[] {
    return this.notes$.value;
  }

  set notes(notes: Note[]) {
    this.notes$.next(notes);
  }

  public findNoteByCfiRange(cfiRange: string) {
    const cleanCfi = TexthighlightService.cleanCfi(cfiRange);
    return this.notes.find((note) => note.pageId === cleanCfi);
  }
}
