<ion-content [ngClass]="bookNoteCustomCss ? 'book-note-content' : 'dialog-content'">
  <div [ngClass]="bookNoteCustomCss ? 'book-note-container' : 'container'">
    <div class="message">{{ message }}</div>
    <div class="buttons">
      <div *ngFor="let b of buttons">
        <ion-button
          [ngClass]="buttons.length > 2 ? 'more-buttons' : 'two-buttons'"
          (click)="onClick(b.method)"
          [className]="b.style === 'fill' ? 'fill-bg' : 'not-fill'"
          fill="{{ bookNoteCustomCss ? 'none' : b.style === 'fill' ? 'solid' : 'outline' }}"
          disabled="{{ b.disable ? true : false }}"
        >
          {{ b.text }}
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>
