<ion-header *ngIf="deviceDetector.isMobile() || !this.deviceDetector.isDesktop()">
  <ion-toolbar>
    <ion-grid>
      <ion-row>
        <ion-col class="emptyCol"></ion-col>
        <ion-col size="8" class="titleCol" l10nTranslate>
          <ion-label class="title">Bookmenu.TableOfContent</ion-label>
        </ion-col>
        <ion-col class="closeCol">
          <ion-icon class="closeBtn" (click)="exitEditor()" src="../../assets/icon/appMenuClose.svg"></ion-icon>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="innerContainer" *ngIf="!toc">
    <div *ngFor="let chapter of chapters">
      <div class="section">
        <h2 *ngIf="chapter.subitems.length === 0" (click)="goToSpecificPage(chapter)">{{ chapter.label }}</h2>
        <div *ngIf="chapter.subitems.length === 0" id="divider"></div>
        <p *ngIf="chapter.subitems.length > 0" class="chapter" (click)="goToSpecificPage(chapter)">
          {{ chapter.label }}
        </p>
        <div *ngIf="chapter.subitems.length > 0" id="subDivider"></div>
        <div *ngFor="let subChapter of chapter.subitems">
          <p class="subChapter" (click)="goToSpecificPage(subChapter)">{{ subChapter.label }}</p>
          <div *ngFor="let subitem of subChapter.subitems">
            <p class="subItem" (click)="goToSpecificPage(subitem)">{{ subitem.label }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="toc-container toc" *ngIf="toc">
    <div class="column row" [innerHTML]="tocValue"></div>
  </div>
</ion-content>
