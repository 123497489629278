<ion-chip>
  <ion-button (click)="openTheCreateNotePanel()" fill="clear" id="note-button">
    <ion-icon class="note-icon" src="/assets/icon/noteOverviewMenu.svg"></ion-icon>

    <ion-label l10nTranslate=""> Contextmenu.PrivateNoteLabel</ion-label>
  </ion-button>

  <div class="divider"></div>
  <ion-button
    id="context-menu-changeHighlightPrimary-btn"
    (click)="changeHighlight('--book-contextMenu-color1')"
    class="color-button primary"
    shape="round"
  >
    <ion-icon
      *ngIf="color1 === contextmenuService.color"
      class="color"
      src="/assets/icon/checkmark-outline.svg"
    ></ion-icon>
  </ion-button>
  <ion-button
    id="context-menu-changeHighlightGreen-btn"
    (click)="changeHighlight('--book-contextMenu-color2')"
    class="color-button green"
    shape="round"
  >
    <ion-icon
    *ngIf="color2 === contextmenuService.color"
      class="color"
      src="/assets/icon/checkmark-outline.svg"
    ></ion-icon>
  </ion-button>
  <ion-button
    id="context-menu-changeHighlightBlue-btn"
    (click)="changeHighlight('--book-contextMenu-color3')"
    class="color-button blue"
    shape="round"
  >
    <ion-icon
    *ngIf="color3 === contextmenuService.color"
      class="color"
      src="/assets/icon/checkmark-outline.svg"
    ></ion-icon>
  </ion-button>
  <ion-button
    id="context-menu-changeHighlight-btn"
    (click)="changeHighlight('--book-contextMenu-color4')"
    class="color-button purple"
    shape="round"
  >
    <ion-icon
    *ngIf="color4 === contextmenuService.color"
      class="color"
      src="/assets/icon/checkmark-outline.svg"
    ></ion-icon>
  </ion-button>

  <div class="divider"></div>
  <ion-icon
    id="context-menu-trash-btn"
    (click)="detachHighlightSelection()"
    class="delete-button"
    src="/assets/icon/trashbinIconWhite.svg"
  ></ion-icon>

  <div class="divider divider-last"></div>
  <ion-icon
    id="context-menu-copy-btn"
    (click)="copyContextMenuText()"
    src="/assets/icon/copyIcon.svg"
    class="copy-icon"
  ></ion-icon>
</ion-chip>
