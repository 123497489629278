import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { IonContent } from '@ionic/angular';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SpinnerService } from '../../../services/spinner/spinner.service';
import { DeviceDetectorService } from '../../../util/device-detector/device-detector.service';
import { ContextmenuService } from '../../services/contextmenu/contextmenu.service';
import { NoteService } from '../../services/note/note.service';
import { EpubRenderService } from '../../services/rendition/epubrender.service';
import { TexthighlightService } from '../../services/texthighlight/texthighlight.service';
import { ClientMark } from '../../../PODO/clientMark'

@Component({
  selector: 'app-create-note',
  templateUrl: './create-note.component.html',
  styleUrls: ['./create-note.component.scss'],
})
export class CreateNoteComponent implements OnInit, OnDestroy {
  private _timestamp: number | undefined;
  public text: string = '';
  public cfiRange: string = '';
  public notetext: string = '';
  private initialNoteText: string = '';
  public title: string = '';
  public notePlaceholder: string = '';
  private today: string | undefined;
  public selectedColor: string = '';
  public selectedColorPropertyName: string = '';
  private initialColor: string = '';
  public pageNumber: number = 0;
  public selectedColors: string[] = [];

  @Input() navigateAway!: Subject<string | undefined>;
  @Input() isEdit: boolean = false;
  @Input() currentPage: number = 0;
  @Output() onColorChange = new EventEmitter<any>();
  @Output() onTogglePanel = new EventEmitter<any>();

  public scrollUnlocked: boolean = false;
  @ViewChild(IonContent) content: IonContent | undefined;

  private triggerNoteSave: Subject<void> = new Subject();
  private triggerNoteSave$ = this.triggerNoteSave.pipe(debounceTime(3000));

  private subscriptions: Subscription[] = [];

  constructor(
    private contextMenuService: ContextmenuService,
    private noteService: NoteService,
    private renderingService: EpubRenderService,
    private localization: L10nTranslationService,
    private deviceDetector: DeviceDetectorService,
    public screenOrientation: ScreenOrientation,
    private spinningService: SpinnerService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {
    this.selectedColors.push('--book-contextMenu-color1');
    this.selectedColors.push('--book-contextMenu-color2');
    this.selectedColors.push('--book-contextMenu-color3');
    this.selectedColors.push('--book-contextMenu-color4');
    this.screenOrientation.onChange().subscribe(async () => {
      if (!this.deviceDetector.isDesktop()) {
        this.scrollUnlocked =
          this.screenOrientation.type === 'landscape' ||
          this.screenOrientation.type === 'landscape-primary' ||
          this.screenOrientation.type === 'landscape-secondary';
      }
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.navigateAway.subscribe(async (navigateTo: string | undefined) => {  
        await this.exitEditor(navigateTo);
      }),
      this.triggerNoteSave$.subscribe(async () => {
        await this.handleNote();
      })
    );

    this.text = this.contextMenuService.text;
    this.initialColor = this.contextMenuService.color;
    for(let i = 0; i< this.selectedColors.length; i++){
      if(getComputedStyle(document.documentElement).getPropertyValue(this.selectedColors[i]) === this.initialColor){
        this.selectedColorPropertyName = this.selectedColors[i]
      }
    }
    this.selectedColor = this.initialColor;
    this.cfiRange = this.contextMenuService.cfiRange;
    this._timestamp = this.contextMenuService.timestamp;
    this.title = this.localization.translate(`${this.isEdit ? 'CreateNote.EditTitle' : 'CreateNote.Title'}`);
    this.notePlaceholder = this.localization.translate('CreateNote.NotePlaceholder');
    this.today = this.localization.translate('CreateNote.Today');
    this.notetext = this.contextMenuService.notetext;
    this.initialNoteText = this.notetext;
    this.pageNumber = this.contextMenuService.page;
    
    if (!this.deviceDetector.isDesktop()) {
      this.scrollUnlocked =
        this.screenOrientation.type === 'landscape' ||
        this.screenOrientation.type === 'landscape-primary' ||
        this.screenOrientation.type === 'landscape-secondary';
    }
  }

  async ngOnDestroy() {
    await this.handleNote();
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }

  public async toggleColor(val: string) {
    let color = '';
    this.selectedColorPropertyName = val;
    if (this.selectedColors.includes(val)) {
      color = getComputedStyle(document.documentElement).getPropertyValue(val);
    } 
    this.selectedColor = color;
    await this.handleNote();
    this.onColorChange.emit(color);
  }

  public async handleNote() {
    if (this.initialNoteText !== this.notetext || this.selectedColor !== this.initialColor) {
      const noteToEdit = this.noteService.findNoteByCfiRange(this.cfiRange.toString());
      if (noteToEdit) {
        await this.updateNote().catch((e) => {
          console.log('Could not update a note with error: ', e);
        });
        this.initialNoteText = this.notetext;
        this.initialColor = this.selectedColor;
      } else {
        await this.createNewNote().catch((e) => {
          console.log('Could not create a note with error: ', e);
        });
        this.initialNoteText = this.notetext;
        this.initialColor = this.selectedColor;
      }
    }
  }

  public onNoteChange() {
    this.triggerNoteSave.next();
  }

  public async hideSpinner() {
    await this.spinningService.hideSpinner();
  }

  private async updateAnnotation(type: string) {
    let info = '';
    const cleanCfiRange = TexthighlightService.cleanCfi(this.cfiRange);
    if (type === 'note') {
      await this.noteService.addNoteMarks(this.cfiRange, this.renderingService.getCfiPosition(cleanCfiRange));
      info = 'note';
    }
    await this.renderingService.removeAnnotation(this.cfiRange, 'highlight');
    await this.renderingService.createAnnotation('highlight', this.cfiRange, this.selectedColor, 'note');
  }

  private async updateAnnotationAndAddNoteMarks(addNoteMarks: boolean) {
    let info = '';
      this.renderingService.removeAnnotation(this.cfiRange, 'highlight');
      if (addNoteMarks) {
        info = 'note';
      }
      this.renderingService.createAnnotation('highlight', this.cfiRange, this.selectedColor, info).then((res) => {
        if (addNoteMarks) {
          this.noteService.addNoteMarks(this.cfiRange, this.renderingService.getCfiPosition(this.cfiRange));
        }
      });
  }

  private async createNewNote() {
    await this.noteService
      .createNote(this.cfiRange, this.selectedColor, this.notetext)
      .then((res) => {
        if (res) {
          res.subscribe(async (e) => {
            if (e) {
              await this.updateAnnotationAndAddNoteMarks(this.notetext.length > 0);
              await this.hideSpinner();
            }
          });
        }
      })
      .catch((e) => {
        console.log('Could not create a note with error: ', e);
      });
    
    this.initialNoteText = this.notetext;
  }

  private async updateNote() {
    // @ts-ignore
    await this.noteService
      .updateNote(this.cfiRange, this.selectedColor, this.notetext)
      .then()
      .catch(() => console.log('failed to update note'));
    //await this.updateAnnotation(this.notetext.length === 0 ? 'highlight' : 'note')
    //.catch(e=>console.log("Could not updateAnnotation with error: ", e));
    await this.updateAnnotationAndAddNoteMarks(this.notetext.length > 0).catch((e) =>
      console.log('Could not updateAnnotationAndAddNoteMarks with error: ', e)
    );

    this.initialNoteText = this.notetext;
  }

  public exitEditor = async (navigateTo?: string): Promise<void> => {
    await this.handleNote();
    this.onTogglePanel.emit({ navigateTo: navigateTo || 'overview', editing: false });

    // Set scroll position for new mark
    if (this.noteService.scrollToNote === null) {
      let mark = { 
        cfi: TexthighlightService.cleanCfi(this.cfiRange) 
      };
      this.noteService.scrollToNote = mark as ClientMark;
    }
  }

  /*private async showErrorAlert() {
        const dialog = await this.modalController.create({
            component: DialogComponent,
            cssClass: 'confirm-dialog',
            componentProps: {
                'message': this.localization.translate('Dialog.NoteTextNeeded'),
                'buttons': [
                    {'text': this.localization.translate('Dialog.OK'), 'method': () => dialog.dismiss()},
                ]
            }
        });

        await dialog.present();
    }*/

  get timestamp() {
    if (!this._timestamp) {
      this._timestamp = Date.now();
    }
    return moment(this._timestamp).calendar(null, {
      sameDay: `[${this.today}] - HH:mm`,
      nextWeek: 'DD/MM/YYYY - HH:mm',
      lastDay: 'DD/MM/YYYY - HH:mm',
      lastWeek: 'DD/MM/YYYY - HH:mm',
      sameElse: 'DD/MM/YYYY - HH:mm',
    });
  }
}
