type PopoverSize = 'cover' | 'auto';
export type PositionReference = 'trigger' | 'event';
export type PositionSide = 'top' | 'right' | 'bottom' | 'left' | 'start' | 'end';
type PositionAlign = 'start' | 'center' | 'end';
interface ReferenceCoordinates {
    top: number;
    left: number;
    width: number;
    height: number;
}
interface PopoverPosition {
    top: number;
    left: number;
    referenceCoordinates?: ReferenceCoordinates;
    arrowTop?: number;
    arrowLeft?: number;
    originX: string;
    originY: string;
}
interface PopoverStyles {
    top: number;
    left: number;
    bottom?: number;
    originX: string;
    originY: string;
    checkSafeAreaLeft: boolean;
    checkSafeAreaRight: boolean;
    arrowTop: number;
    arrowLeft: number;
    addPopoverBottomClass: boolean;
}

export const getPopoverDimensions = (size: PopoverSize, contentEl: HTMLElement, triggerEl?: HTMLElement) => {
    const contentDimentions = contentEl.getBoundingClientRect();
    const contentHeight = contentDimentions.height;
    let contentWidth = contentDimentions.width;
    
    if (size === 'cover' && triggerEl) {
        const triggerDimensions = triggerEl.getBoundingClientRect();
        contentWidth = triggerDimensions.width;
    }
    
    return {
        contentWidth,
        contentHeight,
    };
};


/**
* Positions a popover by taking into account
* the reference point, preferred side, alignment
* and viewport dimensions.
*/
export const getPopoverPosition = (
    isRTL: boolean,
    contentWidth: number,
    contentHeight: number,
    arrowWidth: number,
    arrowHeight: number,
    reference: PositionReference,
    side: PositionSide,
    align: PositionAlign,
    defaultPosition: PopoverPosition,
    triggerEl?: HTMLElement,
    event?: MouseEvent | CustomEvent
): PopoverPosition => {
    let referenceCoordinates = {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
    };
    
    /**
    * Calculate position relative to the
    * x-y coordinates in the event that
    * was passed in
    */
    switch (reference) {
        case 'event':
        if (!event) {
            return defaultPosition;
        }
        
        const mouseEv = event as MouseEvent;
        
        referenceCoordinates = {
            top: mouseEv.clientY,
            left: mouseEv.clientX,
            width: 1,
            height: 1,
        };
        
        break;
        
        /**
        * Calculate position relative to the bounding
        * box on either the trigger element
        * specified via the `trigger` prop or
        * the target specified on the event
        * that was passed in.
        */
        case 'trigger':
        default:
        const customEv = event as CustomEvent;
        
        /**
        * ionShadowTarget is used when we need to align the
        * popover with an element inside of the shadow root
        * of an Ionic component. Ex: Presenting a popover
        * by clicking on the collapsed indicator inside
        * of `ion-breadcrumb` and centering it relative
        * to the indicator rather than `ion-breadcrumb`
        * as a whole.
        */
        const actualTriggerEl = (triggerEl ||
            customEv?.detail?.ionShadowTarget ||
            customEv?.target) as HTMLElement | null;
            if (!actualTriggerEl) {
                return defaultPosition;
            }
            const triggerBoundingBox = actualTriggerEl.getBoundingClientRect();
            referenceCoordinates = {
                top: triggerBoundingBox.top,
                left: triggerBoundingBox.left,
                width: triggerBoundingBox.width,
                height: triggerBoundingBox.height,
            };
            
            break;
        }
        
        /**
        * Get top/left offset that would allow
        * popover to be positioned on the
        * preferred side of the reference.
        */
        const coordinates = calculatePopoverSide(
            side,
            referenceCoordinates,
            contentWidth,
            contentHeight,
            arrowWidth,
            arrowHeight,
            isRTL
        );
        
        /**
        * Get the top/left adjustments that
        * would allow the popover content
        * to have the correct alignment.
        */
        const alignedCoordinates = calculatePopoverAlign(align, side, referenceCoordinates, contentWidth, contentHeight);
        
        const top = coordinates.top + alignedCoordinates.top;
        const left = coordinates.left + alignedCoordinates.left;
        
        const { arrowTop, arrowLeft } = calculateArrowPosition(
            side,
            arrowWidth,
            arrowHeight,
            top,
            left,
            contentWidth,
            contentHeight,
            isRTL
            );
            
            const { originX, originY } = calculatePopoverOrigin(side, align, isRTL);
            
            return { top, left, referenceCoordinates, arrowTop, arrowLeft, originX, originY };
        };
                
/**
* Adjusts popover positioning coordinates
* such that popover does not appear offscreen
* or overlapping safe area bounds.
*/
export const calculateWindowAdjustment = (
    side: PositionSide,
    coordTop: number,
    coordLeft: number,
    bodyPadding: number,
    bodyWidth: number,
    bodyHeight: number,
    contentWidth: number,
    contentHeight: number,
    safeAreaMargin: number,
    contentOriginX: string,
    contentOriginY: string,
    triggerCoordinates?: ReferenceCoordinates,
    coordArrowTop = 0,
    coordArrowLeft = 0,
    arrowHeight = 0
): PopoverStyles => {
    let arrowTop = coordArrowTop;
    const arrowLeft = coordArrowLeft;
    let left = coordLeft;
    let top = coordTop;
    let bottom;
    let originX = contentOriginX;
    let originY = contentOriginY;
    let checkSafeAreaLeft = false;
    let checkSafeAreaRight = false;
    const triggerTop = triggerCoordinates
    ? triggerCoordinates.top + triggerCoordinates.height
    : bodyHeight / 2 - contentHeight / 2;
    const triggerHeight = triggerCoordinates ? triggerCoordinates.height : 0;
    let addPopoverBottomClass = false;
    
    /**
    * Adjust popover so it does not
    * go off the left of the screen.
    */
    if (left < bodyPadding + safeAreaMargin) {
        left = bodyPadding;
        checkSafeAreaLeft = true;
        originX = 'left';
        /**
        * Adjust popover so it does not
        * go off the right of the screen.
        */
    } else if (contentWidth + bodyPadding + left + safeAreaMargin > bodyWidth) {
        checkSafeAreaRight = true;
        left = bodyWidth - contentWidth - bodyPadding;
        originX = 'right';
    }
    
    /**
    * Adjust popover so it does not
    * go off the top of the screen.
    * If popover is on the left or the right of
    * the trigger, then we should not adjust top
    * margins.
    */
    if (triggerTop + triggerHeight + contentHeight > bodyHeight && (side === 'top' || side === 'bottom')) {
        if (triggerTop - contentHeight > 0) {
            top = triggerTop - contentHeight - triggerHeight - (arrowHeight - 1);
            arrowTop = top + contentHeight;
            originY = 'bottom';
            addPopoverBottomClass = true;
            
            /**
            * If not enough room for popover to appear
            * above trigger, then cut it off.
            */
        } else {
            bottom = bodyPadding;
        }
    }
    
    return {
        top,
        left,
        bottom,
        originX,
        originY,
        checkSafeAreaLeft,
        checkSafeAreaRight,
        arrowTop,
        arrowLeft,
        addPopoverBottomClass,
    };
};
                    
/**
* Calculates the required top/left
* values needed to position the popover
* content on the side specified in the
* `side` prop.
*/
const calculatePopoverSide = (
    side: PositionSide,
    triggerBoundingBox: ReferenceCoordinates,
    contentWidth: number,
    contentHeight: number,
    arrowWidth: number,
    arrowHeight: number,
    isRTL: boolean
) => {
    const sideLeft = {
        top: triggerBoundingBox.top,
        left: triggerBoundingBox.left - contentWidth - arrowWidth,
    };
    const sideRight = {
        top: triggerBoundingBox.top,
        left: triggerBoundingBox.left + triggerBoundingBox.width + arrowWidth,
    };
    
    switch (side) {
        case 'top':
        return {
            top: triggerBoundingBox.top - contentHeight - arrowHeight,
            left: triggerBoundingBox.left,
        };
        case 'right':
        return sideRight;
        case 'bottom':
        return {
            top: triggerBoundingBox.top + triggerBoundingBox.height + arrowHeight,
            left: triggerBoundingBox.left,
        };
        case 'left':
        return sideLeft;
        case 'start':
        return isRTL ? sideRight : sideLeft;
        case 'end':
        return isRTL ? sideLeft : sideRight;
    }
};
                        
/**
* Calculates the required top/left
* offset values needed to provide the
* correct alignment regardless while taking
* into account the side the popover is on.
*/
const calculatePopoverAlign = (
    align: PositionAlign,
    side: PositionSide,
    triggerBoundingBox: ReferenceCoordinates,
    contentWidth: number,
    contentHeight: number
) => {
    switch (align) {
        case 'center':
        return calculatePopoverCenterAlign(side, triggerBoundingBox, contentWidth, contentHeight);
        case 'end':
        return calculatePopoverEndAlign(side, triggerBoundingBox, contentWidth, contentHeight);
        case 'start':
        default:
        return { top: 0, left: 0 };
    }
};
                            
/**
* Calculate the end alignment for
* the popover. If side is on the x-axis
* then the align values refer to the top
* and bottom margins of the content.
* If side is on the y-axis then the
* align values refer to the left and right
* margins of the content.
*/
const calculatePopoverEndAlign = (
    side: PositionSide,
    triggerBoundingBox: ReferenceCoordinates,
    contentWidth: number,
    contentHeight: number
) => {
    switch (side) {
        case 'start':
        case 'end':
        case 'left':
        case 'right':
        return {
            top: -(contentHeight - triggerBoundingBox.height),
            left: 0,
        };
        case 'top':
        case 'bottom':
        default:
        return {
            top: 0,
            left: -(contentWidth - triggerBoundingBox.width),
        };
    }
};
                                
/**
* Calculate the center alignment for
* the popover. If side is on the x-axis
* then the align values refer to the top
* and bottom margins of the content.
* If side is on the y-axis then the
* align values refer to the left and right
* margins of the content.
*/
const calculatePopoverCenterAlign = (
    side: PositionSide,
    triggerBoundingBox: ReferenceCoordinates,
    contentWidth: number,
    contentHeight: number
) => {
    switch (side) {
        case 'start':
        case 'end':
        case 'left':
        case 'right':
        return {
            top: -(contentHeight / 2 - triggerBoundingBox.height / 2),
            left: 0,
        };
        case 'top':
        case 'bottom':
        default:
        return {
            top: 0,
            left: -(contentWidth / 2 - triggerBoundingBox.width / 2),
        };
    }
};
                                    
/**
* Calculates where the arrow positioning
* should be relative to the popover content.
*/
const calculateArrowPosition = (
    side: PositionSide,
    arrowWidth: number,
    arrowHeight: number,
    top: number,
    left: number,
    contentWidth: number,
    contentHeight: number,
    isRTL: boolean
) => {
    /**
    * Note: When side is left, right, start, or end, the arrow is
    * been rotated using a `transform`, so to move the arrow up or down
    * by its dimension, you need to use `arrowWidth`.
    */
    const leftPosition = {
        arrowTop: top + contentHeight / 2 - arrowWidth / 2,
        arrowLeft: left + contentWidth - arrowWidth / 2,
    };
    
    /**
    * Move the arrow to the left by arrowWidth and then
    * again by half of its width because we have rotated
    * the arrow using a transform.
    */
    const rightPosition = { arrowTop: top + contentHeight / 2 - arrowWidth / 2, arrowLeft: left - arrowWidth * 1.5 };
    
    switch (side) {
        case 'top':
        return { arrowTop: top + contentHeight, arrowLeft: left + contentWidth / 2 - arrowWidth / 2 };
        case 'bottom':
        return { arrowTop: top - arrowHeight, arrowLeft: left + contentWidth / 2 - arrowWidth / 2 };
        case 'left':
        return leftPosition;
        case 'right':
        return rightPosition;
        case 'start':
        return isRTL ? rightPosition : leftPosition;
        case 'end':
        return isRTL ? leftPosition : rightPosition;
        default:
        return { arrowTop: 0, arrowLeft: 0 };
    }
};
                                        
/**
* Determines the transform-origin
* of the popover animation so that it
* is in line with what the side and alignment
* prop values are. Currently only used
* with the MD animation.
*/
const calculatePopoverOrigin = (side: PositionSide, align: PositionAlign, isRTL: boolean) => {
    switch (side) {
        case 'top':
        return { originX: getOriginXAlignment(align), originY: 'bottom' };
        case 'bottom':
        return { originX: getOriginXAlignment(align), originY: 'top' };
        case 'left':
        return { originX: 'right', originY: getOriginYAlignment(align) };
        case 'right':
        return { originX: 'left', originY: getOriginYAlignment(align) };
        case 'start':
        return { originX: isRTL ? 'left' : 'right', originY: getOriginYAlignment(align) };
        case 'end':
        return { originX: isRTL ? 'right' : 'left', originY: getOriginYAlignment(align) };
    }
};

const getOriginXAlignment = (align: PositionAlign) => {
    switch (align) {
        case 'start':
        return 'left';
        case 'center':
        return 'center';
        case 'end':
        return 'right';
    }
};

const getOriginYAlignment = (align: PositionAlign) => {
    switch (align) {
        case 'start':
        return 'top';
        case 'center':
        return 'center';
        case 'end':
        return 'bottom';
    }
};

/**
 * Gets the root context of a shadow dom element
 * On newer browsers this will be the shadowRoot,
 * but for older browser this may just be the
 * element itself.
 *
 * Useful for whenever you need to explicitly
 * do "myElement.shadowRoot!.querySelector(...)".
 */
export const getElementRoot = (el: HTMLElement, fallback: HTMLElement = el) => {
    return el.shadowRoot || fallback;
};