import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError as _observableThrow } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ClientBook } from '../../PODO/clientBook';

const AUTHORIZATION: string = "Authorization";

export interface ISyncService {
  /**
   * @param userId
   * @param customerName
   * @return 200 response
   */
  getThemes(customerName: string): Observable<any>;
}

@Injectable({
  providedIn: 'root',
})
export class Service implements ISyncService {
  private http: HttpClient;
  private baseUrl: string;

  constructor(
    @Inject(HttpClient) http: HttpClient,
  ) {
    this.http = http;
    this.baseUrl = environment.production ? 'https://api.litello.com' : 'https://api-staging.litello.com';
  }

  getThemeSyncdate(customername: string): Observable<ThemeSyncDate> {
    if (!customername) throw new Error("The parameter 'customername' must be defined.");
    const url = `${this.baseUrl}/themes/${customername}/updatedtime`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.get<ThemeSyncDate>(url, {headers});
  }

  getThemes(customername: string): Observable<any> {
    if (!customername) throw new Error("The parameter 'customername' must be defined.");
    const url = `${this.baseUrl}/themes/${customername}`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.get(url, {headers});
  }

  getProfile(userid: string): Observable<Profile> {
    if (!userid) throw new Error("The parameter 'userid' must be defined.");    
    const url = `${this.baseUrl}/users/${userid}/profiles`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.get<Profile>(url, {headers});
  }

  samples(): Observable<any> {
    const url = `${this.baseUrl}/books/samples`;

    return this.http.get(url);
  }

  updateProfile(userid: string, profile: Profile): Observable<Profile> {
    if (!userid) throw new Error("The parameter 'userid' must be defined.");
    const url = `${this.baseUrl}/users/${userid}/profiles`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.put<Profile>(url, profile, {headers});
  }

  postCSMessage(message: any): Observable<any> {
    const url = `${this.baseUrl}/email/support`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.post(url, message, {headers});
  }

  getCustomers(): Observable<any> {
    const url = `${this.baseUrl}/customers`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.get(url, {headers});
  }

  credits(): Observable<any> {
    const url = `${this.baseUrl}/credits`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.get(url, {headers});
  }
}

export class Profile implements IProfile {
  id?: number | undefined;
  email?: string | undefined;
  firstname?: string | undefined;
  lastname?: string | undefined;
  nickname?: string | undefined;
  active?: boolean | undefined;
  biography?: string | undefined;
  oldPassword?: string | undefined;
  password?: string | undefined;

  constructor(data?: IProfile) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property)) (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.id = _data['id'];
      this.email = _data['email'];
      this.firstname = _data['firstname'];
      this.lastname = _data['lastname'];
      this.nickname = _data['nickname'];
      this.active = _data['active'];
      this.biography = _data['biography'];
      this.oldPassword = _data['oldPassword'];
      this.password = _data['password'];
    }
  }

  static fromJS(data: any): Profile {
    data = typeof data === 'object' ? data : {};
    let result = new Profile();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['id'] = this.id;
    data['email'] = this.email;
    data['firstname'] = this.firstname;
    data['lastname'] = this.lastname;
    data['nickname'] = this.nickname;
    data['active'] = this.active;
    data['biography'] = this.biography;
    data['oldPassword'] = this.oldPassword;
    data['password'] = this.password;
    return data;
  }
}

export interface IProfile {
  id?: number | undefined;
  email?: string | undefined;
  firstname?: string | undefined;
  lastname?: string | undefined;
  nickname?: string | undefined;
  active?: boolean | undefined;
  biography?: string | undefined;
  oldPassword?: string | undefined;
  password?: string | undefined;
}

export interface ILoginService {
  loginPost(login: Login): Observable<UserToken>;
}

@Injectable({
  providedIn: 'root',
})
export class LoginService implements ILoginService {
  private http: HttpClient;
  private baseUrl: string;
  private baseShopUrl: string;

  constructor(
    @Inject(HttpClient) http: HttpClient,
  ) {
    this.http = http;
    this.baseUrl = environment.production ? 'https://api.litello.com' : 'https://api-staging.litello.com';
    this.baseShopUrl = 'https://shop.litello.com';
  }


  activateAccount(userid: string, code: string): Observable<any> {
    const url = `${this.baseUrl}/users/${userid}/activate?code=${code}`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.post(url, code, {headers});
  }

  verifyAccount(userid: string, code: any): Observable<any> {
    const url = `${this.baseUrl}/users/${userid}/verify?code=${code}`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.post(url, code, {headers});
  }

  resetPassword(resetPassword: ResetPassword): Observable<ResetPasswordResponse> {
    const url = `${this.baseShopUrl}/store-api/account/recovery-password`;

    const headers = new HttpHeaders({
      'sw-access-key': environment.ACCESS_KEY,
      'sw-context-token': environment.CONTEXT_TOKEN,
    });

    return this.http.post(url, resetPassword, { headers });
  }

  loginPost(login: Login): Observable<UserToken> {
    const url = `${this.baseUrl}/users/login`;

    return this.http.post(url, login);
  }

  postSSOTokenID(token: any): Observable<UserToken> {
    const url = `${this.baseUrl}/auth`;

    return this.http.post(url, token);
  }

  loginTokenPost(token: any): Observable<UserToken> {
    const url = `${this.baseUrl}/users/login/token`;

    return this.http.post(url, token);
  }

  resendVerificationToken(email: string): Observable<any> {
    if (!email) throw new Error("The parameter 'email' must be defined and cannot be null.");
    const url = `${this.baseUrl}/confirm/resend?email=${email}`;

    return this.http.get(url);
  }
}

export interface IBooksService {
  booksGet(userid: string): Observable<ClientBook[]>;

  downloadGet(format: string, bookid: number): Observable<any>;

  lastpageGet(bookid: number): Observable<LastPage>;

  /**
   * @return 201 response
   */
  lastpagePost(bookid: number, lastPage: LastPage): Observable<LastPage>;

  /**
   * @return 204 response
   */
  lastpageDelete(lastpageid: string, bookid: number): Observable<void>;

  notesGet(bookid: number): Observable<Note[]>;

  /**
   * @return 201 response
   */
  notePost(bookid: number, notes: Note): Observable<Note>;

  /**
   * @return 204 response
   */
  noteDelete(noteid: string, bookid: number): Observable<void>;

  texthighlightsGet(userid: string, bookid: number): Observable<TextHighlight[]>;

  /**
   * @return 201 response
   */
  texthighlightPost(userid: string, bookid: number, textHighlight: TextHighlight): Observable<TextHighlight>;

  /**
   * @return 201 response
   */
  texthighlightPut(
    texthighlightid: string,
    userid: string,
    bookid: number,
    textHighlight: TextHighlight
  ): Observable<TextHighlight>;

  /**
   * @return 204 response
   */
  texthighlightDelete(texthighlightid: string, userid: string, bookid: number): Observable<void>;

  getAttachments(bookId: number): Observable<Attachment[]>;
}

@Injectable({
  providedIn: 'root',
})
export class BooksService implements IBooksService {
  private http: HttpClient;
  private baseUrl: string;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(
    @Inject(HttpClient) http: HttpClient,
  ) {
    this.http = http;
    this.baseUrl = environment.production ? 'https://api.litello.com' : 'https://api-staging.litello.com';
  }

  booksGet(userid: string): Observable<ClientBook[]> {
    if (!userid) throw new Error("The parameter 'userid' must be defined.");    
    const url = `${this.baseUrl}/users/${userid}/books`;

    return this.http.get<ClientBook[]>(url);
  }

  booksSize(userid: string): Observable<any> {
    if (!userid) throw new Error("The parameter 'userid' must be defined.");    
    const url = `${this.baseUrl}/users/${userid}/books/count`;
    
    return this.http.get(url);
  }
  getSSO(): Observable<any> {
    const url = `${this.baseUrl}/auth`;

    return this.http.get(url);
  }

  getSSO2(url: string): Observable<any> {
    return this.http.get(url);
  }

  downloadGet(format: string, bookid: number): Observable<any> {
    if (!bookid) throw new Error("The parameter 'bookid' must be defined.");
    if (!format) throw new Error("The parameter 'format' must be defined and cannot be null.");
    const url = `${this.baseUrl}/books/${bookid}/download?format=${format}`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.get(url, {headers});
  }

  lastpageGet(bookid: number): Observable<LastPage> {
    if (!bookid) throw new Error("The parameter 'bookid' must be defined.");
    const url = `${this.baseUrl}/books/${bookid}/lastPage`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.get<LastPage>(url, {headers});
  }

  lastpagePost(bookid: number, lastPage: LastPage): Observable<LastPage> {
    if (!bookid) throw new Error("The parameter 'bookid' must be defined.");
    const url = `${this.baseUrl}/books/${bookid}/lastPage`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.post(url, lastPage, {headers});
  }

  getZoomLevel(bookid: number): Observable<any> {
    if (!bookid) throw new Error("The parameter 'bookid' must be defined.");
    const url = `${this.baseUrl}/books/${bookid}/font-percentage`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.get(url, {headers})
  }

  postZoomLevel(bookid: number, zoomLevel: any): Observable<any> {
    if (!bookid) throw new Error("The parameter 'bookid' must be defined.");
    const url = `${this.baseUrl}/books/${bookid}/font-percentage`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.post(url, zoomLevel, {headers});
  }

  lastpageDelete(lastpageid: string, bookid: number): Observable<void> {
    if (!lastpageid) throw new Error("The parameter 'lastpageid' must be defined.");
    if (!bookid) throw new Error("The parameter 'bookid' must be defined.");
    const url = `${this.baseUrl}/books/${bookid}/lastPage/${lastpageid}`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.delete<void>(url, {headers});
  }

  notesGet(bookid: number): Observable<Note[]> {
    if (!bookid) throw new Error("The parameter 'bookid' must be defined.");
    const url = `${this.baseUrl}/books/${bookid}/notes`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.get<Note[]>(url, {headers});
  }

  notePost(bookid: number, notes: Note): Observable<Note> {
    if (!bookid) throw new Error("The parameter 'bookid' must be defined.");
    const url = `${this.baseUrl}/books/${bookid}/notes`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.post<Note>(url, notes, {headers});
  }

  /**
   * @return 204 response
   */
  noteDelete(noteid: string, bookid: number): Observable<void> {
    if (!noteid) throw new Error("The parameter 'noteid' must be defined.");
    if (!bookid) throw new Error("The parameter 'bookid' must be defined.");
    const url = `${this.baseUrl}/books/${bookid}/notes/${noteid}`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.delete<void>(url, {headers});
  }

  texthighlightsGet(userid: string, bookid: number): Observable<TextHighlight[]> {
    if (!userid) throw new Error("The parameter 'userid' must be defined.");
    if (!bookid) throw new Error("The parameter 'bookid' must be defined.");
    const url = `${this.baseUrl}/users/${userid}/books/${bookid}/texthighlights`;

    return this.http.get<TextHighlight[]>(url);
  }

  texthighlightPost(userid: string, bookid: number, textHighlight: TextHighlight): Observable<TextHighlight> {
    if (!userid) throw new Error("The parameter 'userid' must be defined.");
    if (!bookid) throw new Error("The parameter 'bookid' must be defined.");
    const url = `${this.baseUrl}/users/${userid}/books/${bookid}/texthighlights`;

    return this.http.post<TextHighlight>(url, textHighlight);
  }

  texthighlightPut(
    texthighlightid: string,
    userid: string,
    bookid: number,
    textHighlight: TextHighlight
  ): Observable<TextHighlight> {
    if (!texthighlightid) throw new Error("The parameter 'texthighlightid' must be defined.");
    if (!userid) throw new Error("The parameter 'userid' must be defined.");
    if (!bookid) throw new Error("The parameter 'bookid' must be defined.");
    const url = `${this.baseUrl}/users/${userid}/books/${bookid}/texthighlights/${texthighlightid}`;

    return this.http.put<TextHighlight>(url, textHighlight);
  }

  texthighlightDelete(texthighlightid: string, userid: string, bookid: number): Observable<void> {
    if (!texthighlightid) throw new Error("The parameter 'texthighlightid' must be defined.");
    if (!userid) throw new Error("The parameter 'userid' must be defined.");
    if (!bookid) throw new Error("The parameter 'bookid' must be defined.");
    const url = `${this.baseUrl}/users/${userid}/books/${bookid}/texthighlights/${texthighlightid}`;

    return this.http.delete<void>(url);
  }

  getAttachments(bookId: number): Observable<Attachment[]> {
    if (!bookId) throw new Error("The parameter 'bookId' must be defined.");
    const url = `${this.baseUrl}/books/${bookId}/attachments`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.get<Attachment[]>(url, {headers});
  }

  getCollections(bookId: number): Observable<Collections> {
    const url = `${this.baseUrl}/books/${bookId}/collections`;

    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });

    return this.http.get<Collections>(url, {headers});
  }

  getCommunicationThreads(executorId: string, groupId: string): Observable<CommunicationThread[]> {
    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });
    return this.http.get<CommunicationThread[]>(
      `${this.baseUrl}/users/${executorId}/communication/groups/${groupId}/thread`, {headers}
    )
  }

  createCommunicationThread(executorId: string, groupId: string, message: string, parentThreadId = 'none'): Observable<string> {
    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });
    return this.http.post<string>(
      `${this.baseUrl}/users/${executorId}/communication/groups/${groupId}/thread/${parentThreadId}`,
      { message },
      { headers }
    );
  }

  editCommunicationThread(executorId: string, threadId: number, groupId: number, message: string): Observable<string> {
    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });
    return this.http.put<string>(
      `${this.baseUrl}/users/${executorId}/communication/groups/${groupId}/thread/${threadId}`,
      { message },
      { headers }
    );
  }

  deleteCommunicationThread(executorId: string, threadId: string, groupId: string): Observable<string> {
    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });
    return this.http.delete<string>(
      `${this.baseUrl}/users/${executorId}/communication/groups/${groupId}/thread/${threadId}`, {headers}
    );
  }

  getCommunicationGroupMemberships(executorId: string): Observable<GroupMembership[]> {
    const headers = new HttpHeaders({
      "Accept": "application/json",
      AUTHORIZATION: localStorage.getItem('token') || ""
    });
    return this.http.get<GroupMembership[]>(
      `${this.baseUrl}/users/${executorId}/communication/groups/memberships`, {headers}
    );
  }
}
export interface IGroupsService {
  downloadGet(userid: string, messageid: string, threadid: string, groupid: string): Observable<any>;
}

export interface TextHighlight {
  id?: string | undefined;
  userId?: string | undefined;
  bookId?: number | undefined;
  cfi: string;
  start: string;
  end: string;
  spine: number;
  color: string;
  creationDate?: number | undefined;
}

export interface iconsType {
  id: number | undefined;
  logo: string | undefined;
  loginLogo: string | undefined;
  loginBg: string | undefined;
  dashboardImg: string | undefined;
  dashboardFooterIcon: number | undefined;
  dashboardIntroImage: number | undefined;
  dashboardHeaderLogo: string | undefined;
  dashboardHeaderBgImage: string | undefined;
  dashboardTopRightLogo: string | undefined;
  forgotPasswordPageBackgroundImage: string | undefined;
  registrationPageBgImage: string | undefined;
  loginPageBgImage: string | undefined;
  loginLogoDuplicate: string | undefined;
}

export interface ThreadMessagesMessageItemLikeItem {
  username?: string | undefined;
  userId?: string | undefined;
  messageId?: string | undefined;
  creationDate?: number | undefined;
}

export interface Group {
  id?: string | undefined;
  userId?: string | undefined;
  creationDate?: number | undefined;
  updatedDate?: number | undefined;
  title: string;
  member?: Members | undefined;
}

export interface CloudFrontUrl {
  fileName?: string | undefined;
  originalName?: string | undefined;
  requiredHeader?: { [key: string]: string } | undefined;
  signedUrl?: string | undefined;
}

export interface Message {
  id?: string | undefined;
  parentId?: string | undefined;
  threadId?: string | undefined;
  userId?: string | undefined;
  userName?: string | undefined;
  creationDate?: number | undefined;
  messageBody?: string | undefined;
  contentName?: string | undefined;
  contentUrl?: string | undefined;
  edit: boolean;
  userLiked?: boolean | undefined;
  like?: FavoriteMessage[] | undefined;
}

export interface InvitationWithSize {
  sender?: Invitation[] | undefined;
  receiver?: Invitation[] | undefined;
  size: number;
}

export interface FavoriteMessage {
  username?: string | undefined;
  userId?: string | undefined;
  messageId?: string | undefined;
  creationDate?: number | undefined;
  updatedDate?: number | undefined;
  deleted?: boolean | undefined;
}

export interface ForumUpload {
  id?: string | undefined;
  userId?: string | undefined;
  messageId?: string | undefined;
  fileName?: string | undefined;
  fileUrl?: string | undefined;
  uploadDate?: number | undefined;
}

export interface ThreadMessagesMessageItem {
  id?: string | undefined;
  parentId?: string | undefined;
  threadId?: string | undefined;
  userId?: string | undefined;
  userName?: string | undefined;
  creationDate?: number | undefined;
  messageBody?: string | undefined;
  contentName?: string | undefined;
  contentUrl?: string | undefined;
  edit: boolean;
  message?: ThreadMessagesMessageItem[] | undefined;
  like?: ThreadMessagesMessageItemLikeItem[] | undefined;
}

export interface InviteResponse {
  level?: string | undefined;
  invitation?: Invitation | undefined;
  message?: string | undefined;
}

export interface Note {
  id?: string | undefined;
  userId?: string | undefined;
  bookId?: number | undefined;
  pageId: string;
  pageNumber?: number | undefined;
  note: string;
  creationDate?: number | undefined;
  color: string;
  keyword?: string | undefined;
}

export interface LocalNotes {
  id?: string | undefined;
  userId?: string | undefined;
  bookId?: string | undefined;
  pageId: string;
  pageNumber?: number | undefined;
  note: string;
  creationDate?: number | undefined;
  color: string;
  keyword?: string | undefined;
  synchronized: string;
}

export interface UserToken {
  access_token?: string | undefined;
  refreshToken?: string | undefined;
  expirationTime?: number | undefined;
  bookId?: number | undefined;
}

export interface ThreadMessages {
  id?: string | undefined;
  parentId?: string | undefined;
  threadId?: string | undefined;
  userId?: string | undefined;
  userName?: string | undefined;
  creationDate?: number | undefined;
  updatetDate?: number | undefined;
  messageBody?: string | undefined;
  contentName?: string | undefined;
  contentUrl?: string | undefined;
  edit: boolean;
  message?: ThreadMessagesMessageItem[] | undefined;
  like?: ThreadMessagesMessageItemLikeItem[] | undefined;
  userLiked?: boolean | undefined;
  assets: string[];
}

export interface Invitation {
  id?: string | undefined;
  groupId: string;
  sender?: string | undefined;
  receiver?: string | undefined;
  name?: string | undefined;
  nickName?: string | undefined;
  groupName?: string | undefined;
  creationDate?: number | undefined;
  updatedDate?: number | undefined;
  accept?: boolean | undefined;
  email: string;
}

export interface ErrorDto {
  message?: string | undefined;
}

export interface Data {
  id: number;
  userId?: string | undefined;
  email: string;
  hashPassword: string;
  nickName: string;
}

export interface Login {
  email?: string | undefined;
  passHashed?: string | undefined;
}

export interface ResetPassword {
  email?: string | undefined;
  storefrontUrl?: string | undefined;
}

export interface ResetPasswordResponse {
  success?: string | undefined;
  apiAlias?: string | undefined;
  errors?: any | undefined;
}

export interface Thread {
  id?: string | undefined;
  groupId: string;
  nickName?: string | undefined;
  userId: string;
  creationDate?: number | undefined;
  title: string;
  chapterId?: string | undefined;
  bookId: string;
  discription: string;
  message?: Message | undefined;
  messageCount: number;
}

export interface Bookmark {
  id?: string | undefined;
  userId?: string | undefined;
  bookId?: string | undefined;
  pageId: string;
}

export interface RelationGroupBooks {
  id?: string | undefined;
  groupId?: string | undefined;
  creationDate?: number | undefined;
  bookId?: string | undefined;
}

export interface LastPage {
  id?: string | undefined;
  userId?: string | undefined;
  creationDate?: number | undefined;
  bookId?: number | undefined;
  cfi?: string | undefined;
  pageCount?: number;
}

export interface Members {
  id?: string | undefined;
  userId?: string | undefined;
  userName?: string | undefined;
  sender?: string | undefined;
  groupId?: string | undefined;
  notify: boolean;
  creationDate?: number | undefined;
  updatedDate?: number | undefined;
  roleId?: string | undefined;
  acceptRules: boolean;
}

export interface Credit {
  id: number | undefined;
  component: string | undefined;
  owner: string | undefined;
  license: string | undefined;
  link: string | undefined;
}

export interface Attachment {
  id: number;
  filename: string;
  description: string;
  created: string;
  type: string;
  size: number;
  url: string;
  percent?: number;
}

export class SwaggerException extends Error {
  message: string;
  status: number;
  response: string;
  headers: { [key: string]: any };
  result: any;

  constructor(message: string, status: number, response: string, headers: { [key: string]: any }, result: any) {
    super();

    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }

  protected isSwaggerException = true;

  static isSwaggerException(obj: any): obj is SwaggerException {
    return obj.isSwaggerException === true;
  }
}

function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any
): Observable<any> {
  if (result !== null && result !== undefined) return _observableThrow(result);
  else return _observableThrow(new SwaggerException(message, status, response, headers, null));
}

function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next('');
      observer.complete();
    } else {
      let reader = new FileReader();
      reader.onload = (event) => {
        observer.next((<any>event.target).result);
        observer.complete();
      };
      reader.readAsText(blob);
    }
  });
}

export type Collections = {
  [collectionId: number]: ClientBook[]
}

export type CommunicationThread = {
  id: string,
  parentId: string,
  message: string,
  ownerId: string,
  nickName: string,
  groupId: string,
  creationDate: string,
  edited: boolean,
  editingDate: string,
  removed: boolean,
  removalDate: string,
}

export type GroupMembership = {
  id: string,
  title: string,
  role: 'admin' | 'coach' | 'member'
}

export type ThemeSyncDate = {
  id: number,
  customer_name: string,
  updated_at: number | null
}