import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { L10nTranslationService } from 'angular-l10n';
import { ToastService } from '../../services/toast/toast.service';
import { UserService } from '../../services/user/user.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { Constants } from '../../PODO/constants';
import { ThemeService } from '../../services/themes/theme.service';

@Component({
  selector: 'app-sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss'],
})
export class SsoComponent implements OnInit {
  private bookId: number | undefined;

  constructor(
    public translationService: L10nTranslationService,
    private router: Router,
    public themeService: ThemeService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private userService: UserService,
    private authService: AuthService,
    private spinningService: SpinnerService
  ) {}

  ngOnInit() {
    let token = this.route.snapshot.paramMap.get('token');
    let customerName = this.themeService.customerName;
    if (token && customerName) {
      this.spinningService.showSpinner();
      this.authService.logoutUser();
      this.authService.loginWithToken(customerName, token).subscribe(
        (response) => {
          if (response.access_token) {
            this.authService.setToken(response.access_token);
            this.userService.setUser(response.access_token);
            this.bookId = response.bookId;
            if (this.bookId) {
              this.router.navigate([`/${Constants.URL.Books_BookId_TO_OPEN}`, { id: this.bookId }]);
            } else {
              this.router.navigate([Constants.URL.Books]);
            }

            this.spinningService.hideSpinner();
          }
        },
        (error) => {
          this.spinningService.hideSpinner();
          if (error.status === 400 || error.status === 403 || error.status === 500) {
            this.toastService.showErrorMessage(this.translationService.translate(`Authentication.${error.status}`));
          } else if (error.status === 401) {
            this.toastService.showErrorMessage(this.translationService.translate(`Authentication.InvalidToken`));
          }
          this.authService.logoutUser();
          this.router.navigate([Constants.URL.Login]);
        }
      );
    }
  }
}
