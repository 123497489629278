import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class DeviceDetectorService {
  constructor(private platform: Platform) {}

  public isDesktop() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return false;
    }
    if (
      /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
    ) {
      return false;
    }
    return true;
  }

  public isAndroid = () => this.platform.is('android');

  public isIOS = () => this.platform.is('ios');

  public isMobile = () => this.platform.is('mobile') && !this.platform.is('ipad') && !this.platform.is('tablet');

  public isTabletSize = () => this.platform.is('ipad') || this.platform.is('tablet');

  public isMobileWeb = () => this.platform.is('mobileweb');
}
