import { NgModule } from "@angular/core";
import { FocusDirective } from "./focus.directive";

@NgModule({
    imports: [],
    declarations: [
        FocusDirective
    ],
    exports: [
        FocusDirective
    ]
})
export class DirectivesModule {}
