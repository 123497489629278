import { AfterViewChecked, AfterViewInit, Component, ElementRef, Inject, Input, OnDestroy, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Cropper from 'cropperjs';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ToastService } from '../../services/toast/toast.service';
import { S3Service } from '../../services/rest-client/s3.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { UserService } from '../../services/user/user.service';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';

type CropperDimensions = { height: number, width: number };

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss'],
})
export class ImageCropperComponent implements AfterViewInit, AfterViewChecked, OnDestroy {

  @Input() imageSource: string = '';

  @ViewChild('container') container: ElementRef | undefined;

  private cropperDimensions: Subject<CropperDimensions> = new Subject();
  private imageLoaded: Subject<HTMLImageElement> = new Subject();
  private initializeCropperSubscription: Subscription = combineLatest(
    [this.cropperDimensions, this.imageLoaded]
  ).pipe(take(1)).subscribe((e) => {
    this.isCropperInitialized = true;
    this.createCropper(...e);
    this.initializeCropperSubscription.unsubscribe();
  });

  private isCropperInitialized: boolean = false;

  private cropper: Cropper | undefined;

  constructor(
    private modalCtrl: ModalController,
    private loadingSpinner: SpinnerService,
    private s3: S3Service,
    private userService: UserService,
    private toast: ToastService,
    private translationService: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngAfterViewInit(): void {
    
  }
  ngAfterViewChecked(): void {
    if(
      !this.isCropperInitialized
      && this.container?.nativeElement.offsetHeight
      && this.container?.nativeElement.offsetWidth
    ) {
      this.cropperDimensions.next({
        height: this.container.nativeElement.offsetHeight,
        width: this.container.nativeElement.offsetWidth
      })
    }
  }

  ngOnDestroy(): void {
    this.cropper?.destroy();
  }

  public cancel(): void {
    this.modalCtrl.dismiss(false);
  }
  
  public confirm(): void {
    this.uploadImage();
  }
  
  private onUploadSuccess(imageData: string) {
    this.modalCtrl.dismiss(imageData);
  }

  public onImageLoaded(target: HTMLImageElement): void {
    this.imageLoaded.next(target);
  }

  private createCropper(dimensions: CropperDimensions, element: HTMLImageElement): void {
    this.cropper = new Cropper(element, {
      responsive: true,
      restore: true,
      minCanvasHeight: dimensions.height,
      minCanvasWidth: dimensions.width,
      viewMode: 2,
      dragMode: 'crop',
      autoCropArea: 0.75,
      aspectRatio: 1,
      background: false
      // minContainerHeight: dimensions.height,
      // minContainerWidth: dimensions.width,
    });
  }

  private uploadImage() {
    const canvas = this.cropper?.getCroppedCanvas({
      width: 300,
      height: 300,
      minWidth: 300,
      minHeight: 300,
      maxWidth: 300,
      maxHeight: 300,
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
    });

    if(canvas) {
      canvas.toBlob(async (blob) => {
        if(!blob) {
          this.toast.showErrorMessage(this.translationService.translate('UnknownError'));
          return;
        }

        if(blob.size > 350000) {
          this.toast.showErrorMessage(this.translationService.translate('FileSizeError'));
          return;
        }

        await this.loadingSpinner.showSpinner();
          try {
            const filename = `${this.userService.getUserID()}.png`;
            await this.s3.upload(filename, this.userService.getUserID(), new File([blob], filename));
            await this.loadingSpinner.hideSpinner();
            this.onUploadSuccess(canvas.toDataURL('image/png', 1));
          } catch (error) {
            this.toast.showErrorMessage(this.translationService.translate('UnknownError'));
            await this.loadingSpinner.hideSpinner();

          }
      }, 'image/png');
    }
  }
    
}
