<div
  class="burger"
  [ngClass]="{
    'active-burger-menu-open': open && burgerInactive,
    'inactive-burger-menu-open': open && !burgerInactive,
    'inactive-burger-menu-closed': !open && !burgerInactive,
    'active-burger-menu-closed': !open && burgerInactive
  }"
>
  <div [ngStyle]="open ? { transform: 'rotate(45deg)' } : { transform: 'rotate(0)' }"></div>
  <div
    [ngStyle]="open ? { transform: 'translateX(5px)', opacity: '0' } : { transform: 'translateX(0)', opacity: '1' }"
  ></div>
  <div [ngStyle]="open ? { transform: 'rotate(-45deg)' } : { transform: 'rotate(0)' }"></div>
</div>
