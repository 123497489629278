import { Pipe, PipeTransform } from '@angular/core';
/*
 * The timestamps are generated by our Lambdas using the custom format: '%Y-%m-%d %H:%M:%S'
 * We need a valid JS date format to work with, so this pipe replaces the whitespace with 'T'.
 * Usage:
 *   datestringFromDB | fixDateFormat
*/
@Pipe({
  name: 'fixDateFormat'
})
export class FixDateFormatPipe implements PipeTransform {
  transform(value: string): string {
    if(!value || (typeof value !== 'string')) return "";
    return value.replace(' ', 'T');
  }
}