import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Credit, Service } from '../../services/rest-client/rest-client.service';
import { Network } from '@capacitor/network';
import { PluginListenerHandle } from '@capacitor/core';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss'],
})
export class CreditsComponent implements OnInit, OnDestroy {
  public credits: Credit[] = [];
  public loading: boolean = false;
  networkListener: PluginListenerHandle | undefined;
  public online: boolean = true;

  constructor(
    private modalCtrl: ModalController,
    private generalService: Service,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {
    Network.addListener('networkStatusChange', (status) => {
      this.online = status.connected;
      if (this.online) {
        this.loadCredits();
      }
    });
  }

  async ngOnInit() {
    this.credits = [];
    this.online = (await Network.getStatus()).connected;
    if (this.online) {
      this.loadCredits();
    }
  }

  private loadCredits() {
    this.loading = true;

    this.generalService.credits().subscribe({
      next: (credits) => {
        this.credits = credits;
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  public async closeCredits() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy(): void {
    this.networkListener?.remove();
  }
}
