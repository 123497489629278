import { BookDatabaseService } from './../../../services/book-database/book-database.service';
import { DatabaseService } from './../../../services/localDatabase/database-service.service';
import { Injectable } from '@angular/core';
import { EpubCFI } from 'epubjs';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { BooksService, TextHighlight } from '../../../services/rest-client/rest-client.service';
import { UserService } from '../../../services/user/user.service';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { v4 as uuid } from 'uuid';

Network.addListener('networkStatusChange', (status) => {});

@Injectable({
  providedIn: 'root',
})
export class TexthighlightService {
  readonly positiveIndex = 1;
  private texthighlights$: BehaviorSubject<TextHighlight[]>;
  private _bookID: number | undefined;
  constructor(
    private restTextHighlightService: BooksService,
    private userService: UserService,
    private databaseService: DatabaseService,
    private bookDatabaseService: BookDatabaseService
  ) {
    this.texthighlights$ = new BehaviorSubject(<TextHighlight[]>[]);
  }

  /**
   * Removes invalid expressions on cfi range like ['epub-main']
   * @param range - cfi range to clean
   * @param after - start position to find invalid expressions
   */
  public static cleanRange(range: string, after: number) {
    let startPos = range.indexOf('[', after);
    let endPos = range.indexOf(']', after) + 1;
    while (startPos !== -1 && endPos !== -1) {
      let invalidExpression = range.substring(startPos, endPos);
      range = range.replace(invalidExpression, '');
      startPos = range.indexOf('[', endPos);
      endPos = range.indexOf(']', startPos) + 1;
    }
    return range;
  }

  public static cleanCfi(cfi: string) {
    const checkPos = cfi.lastIndexOf('!') + 1;
    if (checkPos !== -1) {
      cfi = this.cleanRange(cfi, checkPos);
    }
    return cfi;
  }

  /**
   * Creates new text highlight of cfi range and color
   * @param cfiRange - ePub cfi range of selection
   * @param color - highlight color
   */
  public async createTextHighlight(cfiRange: string, color: string) {
    const id = uuid();
    try {
      if (!this.userService.checkIfIDIsSet()) {
        console.log('Text highlighting cannot be created, user ID does not exist.');
        return;
      }
      const cfiStart: EpubCFI = new EpubCFI(cfiRange);
      const spine = cfiStart.spinePos;
      cfiStart.collapse(true);
      let start: string = cfiStart
        .toString()
        .substring(
          cfiStart.toString().lastIndexOf('!') + this.positiveIndex,
          cfiStart.toString().length - this.positiveIndex
        );

      const cfiEnd: EpubCFI = new EpubCFI(cfiRange);
      cfiEnd.collapse(false);
      let end: string = cfiEnd
        .toString()
        .substring(
          cfiEnd.toString().lastIndexOf('!') + this.positiveIndex,
          cfiEnd.toString().length - this.positiveIndex
        );

      start = TexthighlightService.cleanRange(start, 0);
      end = TexthighlightService.cleanRange(end, 0);
      cfiRange = TexthighlightService.cleanCfi(cfiRange);

      const newTexthighlight: TextHighlight = {
        cfi: cfiRange,
        start: start,
        end: end,
        spine: spine,
        color: color,
        creationDate: Date.now(),
        bookId: this._bookID,
        userId: this.userService.getUserID(),
      };
      newTexthighlight.id = id;

      if (this.userService.checkIfIDIsSet() && this._bookID) {
        // Browser
        if(!Capacitor.isNativePlatform()){
          this.restTextHighlightService
            .texthighlightPost(this.userService.getUserID(), this._bookID, newTexthighlight)
            .subscribe((textHighlight) => {
              if (textHighlight) {
                this.texthighlights.push(textHighlight);
              }
            });
            return;
        }

        // Native
        const status = await Network.getStatus();
        if (status.connected) {
          this.restTextHighlightService
            .texthighlightPost(this.userService.getUserID(), this._bookID, newTexthighlight)
            .subscribe((textHighlight) => {
              if (textHighlight) {
                this.texthighlights.push(textHighlight);
              }
            });
          this.texthighlights = [];
          this.synchronizeData(this._bookID);
          this.bookDatabaseService.setlastSynchronizationDatebyBookId(this._bookID);
          
          try {
            this.texthighlights = await firstValueFrom(this.restTextHighlightService.texthighlightsGet(this.userService.getUserID(), this._bookID));
          }
          catch (error: any) {
            this.texthighlights = [];
            console.log(error.message);
          }
          
          // Save highlights to database
          for (let i = 0; i < this.texthighlights.length; i++) {
            this.databaseService.saveHighlight(
              this.texthighlights[i].id!,
              this.userService.getUserID(),
              this._bookID,
              this.texthighlights[i].cfi,
              this.texthighlights[i].start,
              this.texthighlights[i].end,
              this.texthighlights[i].spine,
              this.texthighlights[i].color,
              this.texthighlights[i].creationDate!,
              '1'
            );
          }

          // Add new highlight to database
          this.databaseService.saveHighlight(
            id,
            this.userService.getUserID(),
            this._bookID,
            newTexthighlight.cfi,
            newTexthighlight.start,
            newTexthighlight.end,
            newTexthighlight.spine,
            newTexthighlight.color,
            newTexthighlight.creationDate!,
            '1'
          );
        } else {
          this.texthighlights.push(newTexthighlight);
          this.databaseService.saveHighlight(
            id,
            newTexthighlight.userId!,
            newTexthighlight.bookId!,
            newTexthighlight.cfi,
            newTexthighlight.start,
            newTexthighlight.end,
            newTexthighlight.spine,
            newTexthighlight.color,
            newTexthighlight.creationDate!,
            '2'
          );
        }
      }
    } catch (e: any) {
      console.log('Failed to create text highlight');
      console.error(e);
    }
  }

  public async synchronizeData(bookID: number) {
    try {
      // Push new highlights to server
      this.texthighlights = await this.databaseService.getUnsynchronizedHighlightsByBookId(bookID, '2');
      if (this.texthighlights) {
        for (let i = 0; i < this.texthighlights.length; i++) {
          this.restTextHighlightService
            .texthighlightPost(this.userService.getUserID(), bookID, this.texthighlights[i])
            .subscribe();
          this.databaseService.deleteHighlightsBybookIdAndSynchronized(bookID, '2');
        }
      }

      this.texthighlights = [];

      // Delete locally deleted ones
      this.texthighlights = await this.databaseService.getUnsynchronizedHighlightsByBookId(bookID, '4');

      if (this.texthighlights) {
        for (let i = 0; i < this.texthighlights.length; i++) {
          this.restTextHighlightService
            .texthighlightDelete(this.texthighlights[i].id!, this.userService.getUserID(), this._bookID!)
            .subscribe();
          this.databaseService.deleteHighlightsBybookIdAndSynchronized(bookID, '4');
        }
      }

      this.texthighlights = [];

      // Update unupdated higlights

      this.texthighlights = await this.databaseService.getUnsynchronizedHighlightsByBookId(bookID, '3');
      if (this.texthighlights) {
        for (let i = 0; i < this.texthighlights.length; i++) {
          this.restTextHighlightService
            .texthighlightPut(
              this.texthighlights[i].id!,
              this.userService.getUserID(),
              bookID,
              this.texthighlights[i]
            )
            .subscribe();
          this.databaseService.deleteHighlightsBybookIdAndSynchronized(bookID, '3');
        }
      }

      //delete notes created in online-mode before in order to prevent duplicates
      this.databaseService.deleteHighlightsBybookIdAndSynchronized(bookID, '1');
      this.bookDatabaseService.setlastSynchronizationDatebyBookId(bookID);
    } catch (e: any) {
      console.log(e);
    }
  }

  /**
   * Get text highlights of logged in user by book id
   * @param bookID - book id to get highlights from
   * @returns - array of text highlights
   */
  public async loadTextHighlights(bookID: number) {
    const status = await Network.getStatus();

    //Load from local database if no internet connection
    if (!status.connected) {
      this.texthighlights = [];
      this.texthighlights = await this.databaseService.getHighlightByBookId(bookID);
      return this.texthighlights;
    }

    //Load from server if internet connection is available
    if (Capacitor.isNativePlatform()) {
      // Native
      await this.synchronizeData(bookID);
      try {
        if (this.userService.checkIfIDIsSet()) {
          this.texthighlights = await firstValueFrom(this.restTextHighlightService.texthighlightsGet(this.userService.getUserID(), bookID));

          for (let i = 0; i < this.texthighlights.length; i++) {
            this.databaseService.saveHighlight(
              this.texthighlights[i].id!,
              this.userService.getUserID(),
              bookID,
              this.texthighlights[i].cfi,
              this.texthighlights[i].start,
              this.texthighlights[i].end,
              this.texthighlights[i].spine,
              this.texthighlights[i].color,
              this.texthighlights[i].creationDate!,
              '1'
            );
          }
        } else {
          this.texthighlights = [];
        }
        this.bookDatabaseService.setlastSynchronizationDatebyBookId(bookID);
      } catch (e: any) {
        console.error(e);
      }
    } else {
      // Browser
      try {
        if (this.userService.checkIfIDIsSet()) {
          this.texthighlights = await firstValueFrom(this.restTextHighlightService.texthighlightsGet(this.userService.getUserID(), bookID));
        } else {
          this.texthighlights = [];
        }
      } catch (e: any) {
        console.error(e);
      }
    } 
    return this.texthighlights;
  }

  /**
   * Update text highlight by cfi range
   * @param cfiRange - CFI range of highlight to update
   * @param color - Color to update
   */
  public async updateTextHighlight(cfiRange: string, color: string) {
    try {
      cfiRange = TexthighlightService.cleanCfi(cfiRange);
      const textHighlight = this.texthighlights.find((th) => TexthighlightService.cleanCfi(th.cfi) === cfiRange);
      if (!textHighlight) {
        console.log(`Text highlight by cfi range: ${cfiRange} not found!`);
        return;
      }

      if (textHighlight && textHighlight.id && this.userService.checkIfIDIsSet()) {
        const status = await Network.getStatus();
        if (status.connected && Capacitor.isNativePlatform()) {
          this.databaseService.updateHighlight(
            textHighlight.id,
            this.userService.getUserID(),
            this._bookID!,
            textHighlight.cfi,
            textHighlight.start,
            textHighlight.end,
            textHighlight.spine,
            textHighlight.color,
            '1'
          );
          textHighlight.color = color;
          this.restTextHighlightService
            .texthighlightPut(textHighlight.id, this.userService.getUserID(), this._bookID!, textHighlight)
            .subscribe();
          this.texthighlights.forEach((h) => {
            if (h.id === textHighlight.id) {
              h.color = color;
            }
          });
        } else if (!Capacitor.isNativePlatform() && status.connected) {
          textHighlight.color = color;
          this.restTextHighlightService
            .texthighlightPut(textHighlight.id, this.userService.getUserID(), this._bookID!, textHighlight)
            .subscribe();
          this.texthighlights.forEach((h) => {
            if (h.id === textHighlight.id) {
              h.color = color;
            }
          });
        } else if (Capacitor.isNativePlatform() && !status.connected) {
          this.databaseService.updateHighlight(
            textHighlight.id,
            this.userService.getUserID(),
            this._bookID!,
            textHighlight.cfi,
            textHighlight.start,
            textHighlight.end,
            textHighlight.spine,
            textHighlight.color,
            '3'
          );
        }
      }
    } catch (e: any) {
      console.log('Failed to update text highlight by cfi range: ', cfiRange);
      console.error(e);
    }
  }

  /**
   * Deletes text highlight by cfi range
   * @param cfiRange - CFI range of highlight to delete
   */
  public async deleteTextHighlight(cfiRange: string): Promise<void> {
    try {
      cfiRange = TexthighlightService.cleanCfi(cfiRange);
      const textHighlight = this.texthighlights.find((th) => TexthighlightService.cleanCfi(th.cfi) === cfiRange);
      if (!textHighlight) {
        console.log(`Text highlight by cfi range: ${cfiRange} not found!`);
        return;
      }

      if (textHighlight && textHighlight.id && this.userService.checkIfIDIsSet()) {
        const status = await Network.getStatus();
        if (status.connected && Capacitor.isNativePlatform()) {
          this.restTextHighlightService
            .texthighlightDelete(textHighlight.id, this.userService.getUserID(), this._bookID!)
            .subscribe();
          this.texthighlights = this.texthighlights.filter((th) => TexthighlightService.cleanCfi(th.cfi) !== cfiRange);
        } else if (!Capacitor.isNativePlatform() && status.connected) {
          this.restTextHighlightService
            .texthighlightDelete(textHighlight.id, this.userService.getUserID(), this._bookID!)
            .subscribe();
          this.texthighlights = this.texthighlights.filter((th) => TexthighlightService.cleanCfi(th.cfi) !== cfiRange);
        } else if (Capacitor.isNativePlatform() && !status.connected) {
          this.databaseService.updateHighlight(
            textHighlight.id,
            this.userService.getUserID(),
            this._bookID!,
            textHighlight.cfi,
            textHighlight.start,
            textHighlight.end,
            textHighlight.spine,
            textHighlight.color,
            '4'
          );
          this.texthighlights = this.texthighlights.filter((th) => TexthighlightService.cleanCfi(th.cfi) !== cfiRange);
        }
      }
    } catch (e: any) {
      console.log('Failed to delete text highlight by cfi range: ', e);
      console.error(e);
    }
  }

  set bookID(bookID: number) {
    this._bookID = bookID;
  }

  get texthighlights(): TextHighlight[] {
    return this.texthighlights$.value;
  }

  set texthighlights(texthighlights: TextHighlight[]) {
    this.texthighlights$.next(texthighlights);
  }

  public findTextHighlightByCfiRange(cfiRange: string) {
    return this.texthighlights.find((th) => th.cfi === cfiRange);
  }
}
