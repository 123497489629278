import { AuthService } from './../services/auth.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeService } from '../../services/themes/theme.service';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html',
  styleUrls: ['./verify-account.component.scss'],
})
export class VerifyAccountComponent implements OnInit {

  private userId: string = '';
  private code: string = '';
  public status: string = '';

  constructor(
    public themeService: ThemeService,
    private route: ActivatedRoute,
    private authService : AuthService,
    private routing: Router,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
    this.userId = queryParams['userId'];
    this.code = queryParams['code'];
    if(this.userId === '' ||this.code === '' ||this.userId === undefined ||this.code === undefined){
      this.status = 'linkError';
      //this.routing.navigate(['login'])
      return;
    }
    this.route.url.subscribe(
      data=> {
        if(data[0].path === 'verify'){
          this.status = 'verifying';
          this.authService.verifyAccount(this.userId, this.code).subscribe(
            success=>{
              this.status='verified'
            },
            error=>{
              this.status = 'linkError';
            }
          )
        } else if(data[0].path === 'activate'){
          this.status = 'activating';
          this.authService.activateAccount(this.userId, this.code).subscribe(
            success=>{
              this.status='activated';

            },
            error=>{
              this.status = 'linkError';
            }
          )
        }
      }
    )
  });
  }

  public openLoginPage(){
    this.routing.navigate(['login'])
  }

}
