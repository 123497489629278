import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  @Input() message: string = '';
  @Input() buttons: Array<object> = [];
  @Input() color: string = '';
  @Input() cfi: string = '';
  @Input() noteText: string | undefined;
  @Input() bookNoteCustomCss: boolean = false;

  constructor() {}

  ngOnInit() {}

  onClick(callback: Function) {
    if (this.cfi) {
      callback(this.color, this.cfi, this.noteText);
    } else {
      callback();
    }
  }
}
