import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PluginListenerHandle } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { ModalController } from '@ionic/angular';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { Subscription } from 'rxjs';
import { ClientBook } from '../../PODO/clientBook';
import { BooksService } from '../../services/books/books.service';
import { AlertDlgService } from '../../services/dialogs/alert-dlg.service';
import { ConfirmationDlgService } from '../../services/dialogs/confirmation-dlg.service';
import { DatabaseService } from '../../services/localDatabase/database-service.service';
import { OfflineModeService } from '../../services/offline-mode/offline-mode.service';
import { Note, TextHighlight } from '../../services/rest-client/rest-client.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { StorageService } from '../../services/storage/storage.service';
import { ThemeService } from '../../services/themes/theme.service';
import { UserService } from '../../services/user/user.service';
import { NoteService } from './../../book/services/note/note.service';
import { TexthighlightService } from './../../book/services/texthighlight/texthighlight.service';
import { BookDatabaseService } from './../../services/book-database/book-database.service';

@Component({
  selector: 'app-local-storage',
  templateUrl: './local-storage.component.html',
  styleUrls: ['./local-storage.component.scss'],
})
export class LocalStorageComponent implements OnInit, OnDestroy {
  public isMenuOpen: boolean = false;
  public books: ClientBook[] = [];
  public downloadedBooks: ClientBook[] = [];
  public booksCount: number = 0;
  private subscriptions: Subscription[] = [];
  public networkStatus: any;
  networkListener: PluginListenerHandle | undefined;
  imgSrc: string | ArrayBuffer =
    'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png';
  constructor(
    private modalCtrl: ModalController,
    private booksService: BooksService,
    public themeService: ThemeService,
    public offlineModeService: OfflineModeService,
    public userService: UserService,
    public storageService: StorageService,
    private localization: L10nTranslationService,
    private confirmationDlgService: ConfirmationDlgService,
    private alertDlgService: AlertDlgService,
    public noteService: NoteService,
    public textHighlightService: TexthighlightService,
    private dataBaseService: DatabaseService,
    public bookDatabaseService: BookDatabaseService,
    public sanitizer: DomSanitizer,
    private spinningService: SpinnerService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  async ngOnInit() {
    this.networkListener = Network.addListener('networkStatusChange', (status) => {
      this.ngOnInit();
    });
    this.networkStatus = await Network.getStatus();
    this.subscriptions.push(
      this.booksService.books$.subscribe((books) => {
        this.onBooksLoadSuccess(books);
      })
    );
    this.downloadedBooks = await this.getDownloadedBooks(this.books);
  }

  public delLocalBook(book: any) {
    let unSyncData: Array<any> = [];
    this.dataBaseService.getUnsynchronizedHighlightsByBookId(book.id, '2').then((res: TextHighlight[]) => {
      unSyncData = [...unSyncData, ...res];
      this.dataBaseService.getUnsynchronizedNotesByBookId(book.id, '2').then((res: Note[]) => {
        unSyncData = [...unSyncData, ...res];
        if (unSyncData.length === 0) {
          this.confirmationDlgService.showConfirmationDlg(
            book,
            this.localization.translate('Dialog.Delete'),
            this.localization.translate('Dialog.Cancel'),
            this.deleteLocalBook.bind(this, book)
          );
        } else {
          if (this.networkStatus?.connected) {
            this.spinningService.showSpinner();
            this.noteService.synchronizeData(book.id).then(() => {
              this.textHighlightService.synchronizeData(book.id).then(() => {
                this.noteService.loadNotes(book.id).then(() => {
                  this.dataBaseService.setSyncDateByBookId(book.id, Date.now().toString()).then(() => {
                    this.spinningService.hideSpinner();
                    this.confirmationDlgService.showConfirmationDlg(
                      book,
                      this.localization.translate('Dialog.Delete'),
                      this.localization.translate('Dialog.Cancel'),
                      this.deleteLocalBook.bind(this, book)
                    );
                  });
                });
              });
            });
          } else {
            this.alertDlgService.showAlertDlg(this.localization.translate('Dialog.SyncBeforeDelete'));
          }
        }
      });
    });
  }

  private async deleteLocalBook(book: any) {
    await this.spinningService.showSpinner();
    this.removeDownloadHandler(book)
      .catch((e) => console.log(e))
      .finally(() => this.spinningService.hideSpinner());
  }

  private onBooksLoadSuccess(books: any[]) {
    this.books = books;
  }

  public async closeLocalStorage() {
    await this.modalCtrl.dismiss();
  }

  public async syncHandler(bookId: any) {
    if (this.networkStatus.connected) {
      await this.spinningService.showSpinner();

      await this.noteService.synchronizeData(bookId.toString());
      await this.textHighlightService.synchronizeData(bookId.toString());

      await this.noteService.loadNotes(bookId.toString());

      await this.dataBaseService
        .setSyncDateByBookId(bookId.toString(), Date.now().toString())
        .catch((e) => console.log(e))
        .finally(() => this.spinningService.hideSpinner());
      this.ngOnInit();
    }
  }

  public async removeDownloadHandler(book: any) {
    this.offlineModeService
      .deleteBook(book)
      .catch((error) => console.log('while deleting a book, got error: ', error))
      .then(() => {
        this.dataBaseService.delTxtHighLightByBookId(book.id).then(() => {
          this.dataBaseService.delNoteByBookId(book.id);
        });
        book.downloaded = false;
        this.downloadedBooks = this.downloadedBooks.filter((x) => x !== book);
        this.storageService
          .removePreferences(`size_of_id${book.id.toString()}`)
          .catch((error) => console.log('while deleting size of the book, got error: ', error))
          .then(() => console.log(`successfully removed the size of book ${book.id}`));
        this.storageService
          .removePreferences(`path_of_id${book.id.toString()}`)
          .catch((error) => console.log('while deleting path of the book, got error: ', error))
          .then(() => console.log(`successfully removed the path of book ${book.id}`));
      });
  }

  protected getDownloadedBooks(books: ClientBook[]) {
    const downloadedBooks: ClientBook[] = books.filter((book) => book.downloaded);
    downloadedBooks.forEach((x) => {
      this.storageService
        .getPreferences(`size_of_id${x.id}`)
        .then((res) => (res ? (x.size = res) : (x.size = '')));
      this.dataBaseService.getSyncDateByBookId(x.id).then((res) => {
        x.lastSynchronized = res;
      });
    });
    return downloadedBooks;
  }

  ngOnDestroy(): void {
    this.subscriptions = [];
  }
}
