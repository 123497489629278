import { Injectable } from '@angular/core';
import { SearchResult } from '../rendition/epubrender.service';

@Injectable()
export class SearchService {
  public searchQuery: string = '';
  public searchResults: SearchResult[] = [];
  public showSingleResult: boolean = false;

  public currentSearchResult: SearchResult | undefined;
  public searchIndex: number = 0;

  public reset(): void {
    this.searchQuery = '';
    this.searchResults = [];
    this.showSingleResult = false;
    this.currentSearchResult = undefined;
    this.searchIndex = 0;
  }
}
