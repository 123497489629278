import { OfflineModeService } from './../offline-mode/offline-mode.service';
import { ClientBook } from './../../PODO/clientBook';
import { BookDatabaseService } from './../book-database/book-database.service';
import { Network } from '@capacitor/network';
import { Capacitor } from '@capacitor/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { BooksService as BooksApiClient } from '.././rest-client/rest-client.service';
import { Service as SampleBookService } from '.././rest-client/rest-client.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class BooksService {
  private alreadyOpenedBook: boolean = false;

  booksList: ClientBook[] = [];

  public get books$() {
    return this._books$;
  }

  public networkStatus: any;

  private readonly _books$: BehaviorSubject<any[]>;

  constructor(
    private booksApiClient: BooksApiClient,
    private userService: UserService,
    private sampleBookService: SampleBookService,
    private bookDatabaseService: BookDatabaseService,
    private offlineModeService: OfflineModeService
  ) {
    this._books$ = new BehaviorSubject<any>([]);
  }

  private isLoggedIn = () => this.userService.checkIfIDIsSet();

  public getOpendedStatus() {
    return this.alreadyOpenedBook;
  }

  public setOpendedStatus(opened: boolean) {
    this.alreadyOpenedBook = opened;
  }

  public async loadBooks(onError: Function) {
    const net = await Network.getStatus();

    let userId = this.userService.getUserID();

    if (Capacitor.isNativePlatform() && net.connected) {
      this.bookDatabaseService.DeleteAllBooksByUserId(userId);
      this.bookDatabaseService.deleteAllContents();
      try {
        const books = this.isLoggedIn()
          ? await this.booksApiClient.booksGet(userId).toPromise()
          : await this.sampleBookService.samples().toPromise();
        this.books$.next(books);
        this.books$.forEach(async (book: any) => {
          {
            for (let i = 0; i < book.length; i++) {
              for (let y = 0; y < book[i].contents.length; y++) {
                this.bookDatabaseService.saveContent(
                  book[i].id,
                  book[i].contents[y].id,
                  book[i].contents[y].de,
                  book[i].contents[y].en,
                  book[i].contents[y].value
                );
              }
              await this.offlineModeService.downloadCover(book[i].urlCover, book[i].id);
              this.bookDatabaseService.saveBook(
                book[i].id,
                userId,
                book[i].id,
                1, //lastPage : number
                1, //lastSynchronized : number
                1, //synchronized : number
                false, //downloaded : boolean
                book[i].quizzes,
                1, //lastOpened
                book[i].title,
                book[i].creator,
                'BookCovers/' + book[i].id + '.' + book[i].urlCover?.substring(book[i].urlCover.lastIndexOf('.') + 1),
                book[i].signedUrl,
                book[i].lastRead,
                book[i].webBookUrl,
                book[i].opf_file,
                book[i].examples,
                book[i].exercises,
                book[i].pages,
                book[i].videos,
                book[i].size
              );
            }
          }
        });
      } catch (e: any) {
        onError(e);
      }
    } else if (Capacitor.isNativePlatform() && (!net.connected)) {
      this.booksList = this.bookDatabaseService.getAllBooksByUserId(this.userService.getUserID());
      this.books$.next(this.booksList);
    } else if (!Capacitor.isNativePlatform()) {
      try {
        const books = this.isLoggedIn()
          ? await firstValueFrom(this.booksApiClient.booksGet(this.userService.getUserID()))
          : await firstValueFrom(this.sampleBookService.samples());
        this.books$.next(books);
      } catch (e: any) {
        onError(e);
      }
    }
    return this.booksList;
  }
}
