<ion-header class="ion-modal-header">
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="end">
      <ion-button (click)="closeChangelog()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="header-title ion-text-left" l10nTranslate> Changelog.Changelog </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content scrollY="false" class="changelog-content">
  <!--  <ion-grid>-->
  <ion-refresher slot="fixed" (ionRefresh)="refresh($event)">
    <ion-refresher-content> </ion-refresher-content>
  </ion-refresher>
  <ion-spinner class="spinner" *ngIf="loading" name="crescent"></ion-spinner>
  <!--    <ion-row >-->
  <!--      <ion-col>-->
  <div id="contents" class="contents"></div>
  <!--      </ion-col>-->
  <!--    </ion-row>-->
  <!--  </ion-grid>-->
</ion-content>
