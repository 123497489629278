import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { ThemeService } from '../../services/themes/theme.service';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

@Component({
  selector: 'litello-app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() isMenuOpened: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isMenuOpen: boolean = false;
  constructor(
    private menuCtrl: MenuController,
    public themeService: ThemeService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngOnInit() {
    this.isMenuOpened.subscribe((opened) => {
      this.toggleMenuState(opened);
    });
  }

  ngOnDestroy(): void {}

  public closeMenu() {
    this.menuCtrl.close();
    this.toggleMenuState(false);
  }

  public openMenu() {
    this.menuCtrl.open();
    this.toggleMenuState(true);
  }

  public toggleMenuState(open: boolean) {
    this.isMenuOpen = open;
  }
}
