import { SupportComponent } from './support/support.component';
import { HelpComponent } from './help/help.component';
import { UserService } from './../../services/user/user.service';
import { Component, Inject, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DeviceDetectorService } from '../../util/device-detector/device-detector.service';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

@Component({
  selector: 'app-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss'],
})
export class ContactSupportComponent implements OnInit {
  public userId: string = '';

  constructor(
    private modalCtrl: ModalController,
    private userService: UserService,
    public deviceDetector: DeviceDetectorService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngOnInit() {
    this.userId = this.userService.getUserID();
  }

  public async openTipps(tipp: string){
    await this.modalCtrl.dismiss();
    let cssClass = this.deviceDetector.isDesktop() ? 'ion-main-modal' : 'ion-main-modal-mobile';
    const popover = await this.modalCtrl.create({
      component: HelpComponent,
      cssClass: cssClass,
      componentProps: {
        Tipp: tipp
      }
  })
  return await popover.present();
}

public async contactSupport(){
  await this.modalCtrl.dismiss();
  let cssClass = this.deviceDetector.isDesktop() ? 'ion-main-modal-large' : 'ion-main-modal-mobile';
  const popover = await this.modalCtrl.create({
    component: SupportComponent,
    cssClass: cssClass,
})
return await popover.present();
}

 public async closeModal() {
    await this.modalCtrl.dismiss();
    // await this.openProfile();
  }
}
