import { Directive, ElementRef, AfterViewInit, Input } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { ClientMark } from '../../PODO/clientMark';
import { NoteService } from '../services/note/note.service';

@Directive({
  selector: '[scrollToNote]',
})
export class ScrollToNoteDirective implements AfterViewInit {
  @Input() scrollToNote!: { content: IonContent, mark: ClientMark };

  constructor(
    private readonly elementRef: ElementRef,
    private readonly noteService: NoteService
  ) {}

  ngAfterViewInit(): void {
    if(this.scrollToNote.mark.cfi === this.noteService.scrollToNote?.cfi) {
      const targetElement = this.elementRef.nativeElement as HTMLElement;
      this.scrollToNote.content.getScrollElement().then((scrollElement) => {
        scrollElement.scrollTo({
          top: targetElement.offsetTop
        })
      })
      
      this.noteService.scrollToNote = null;
    }
  }
}
