import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { L10nTranslationService } from 'angular-l10n';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(public toastController: ToastController, public localization: L10nTranslationService) {}
  readonly minDuration = 4000;
  readonly maxDuration = 6000;

  public showSuccessMessage(message: string) {
    this.presentToast(message, 'success', this.minDuration);
  }

  public showErrorMessage(message: string) {
    this.presentToast(message, 'danger', -1);
  }

  public showWarningMessage(message: string) {
    this.presentToast(message, 'warning', this.maxDuration);
  }

  public showMessage(message: string) {
    this.presentToast(message, 'warning', this.maxDuration);
  }

  async presentToast(message: string, color: string, duration: number) {
    const toast = await this.toastController.create({
      position: 'top',
      message: message,
      color: color,
      duration: duration,
      buttons: ["OK"],
    });
    toast.present();
  }

  async presentToastWithOptions() {
    const toast = await this.toastController.create({
      header: 'Toast header',
      message: this.localization.translate('Toast.Close'),
      position: 'top',
      buttons: [
        {
          side: 'start',
          icon: 'star',
          text: this.localization.translate('Toast.Favorite'),
          handler: () => {},
        },
        {
          text: this.localization.translate('Toast.Done'),
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    toast.present();
  }
}
