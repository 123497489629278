import { ToastService } from './../../services/toast/toast.service';
import { Capacitor } from '@capacitor/core';
import { OfflineModeService } from '../../services/offline-mode/offline-mode.service';
import { BooksService } from '../../services/books/books.service';
import { LastPageService } from '../services/lastpage/last-page.service';
import { TexthighlightService } from '../services/texthighlight/texthighlight.service';
import { ContextmenuService } from '../services/contextmenu/contextmenu.service';
import { EpubRenderService } from '../services/rendition/epubrender.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuController, ModalController, NavController, Platform } from '@ionic/angular';
import { UserService } from '../../services/user/user.service';
import { Contents, EpubCFI } from 'epubjs';
import { Animations } from './book.component.animations';
import { NoteService } from '../services/note/note.service';
import { ImagePopupComponent } from '../components/image-popup/image-popup.component';
import { BehaviorSubject, firstValueFrom, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { NavItem } from 'epubjs/types/navigation';
import { SlidePanelState } from '../components/slide-panel/slide-panel.component';
import { ClientMark } from '../../PODO/clientMark';
import { BookHeaderComponent } from '../components/book-header/book-header.component';
import { Attachment, BooksService as BooksApiService, Collections, CommunicationThread, Note } from '../../services/rest-client/rest-client.service';
import { ThemeService } from '../../services/themes/theme.service';
import { DeviceDetectorService } from '../../util/device-detector/device-detector.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Constants } from '../../PODO/constants';
import { StatusBar } from '@capacitor/status-bar';
import { debounceTime, distinctUntilChanged, skip } from 'rxjs/operators';
import { Network } from '@capacitor/network';
import { ClientBook } from '../../PODO/clientBook';
import { StorageService } from '../../services/storage/storage.service';
import { SearchService } from '../services/search/search.service';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

const OPF_FILE_PATH = '/OEBPS/content.opf';
export const EPUB_AREA_ID = 'currentArea';
const COLLECTION_STORAGE_KEY = 'book_collection';

interface History {
  pageNumber: number;
}

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss'],
  animations: Animations,
})
export class BookComponent implements OnInit, OnDestroy, AfterViewInit {

  public isOnline: boolean = false;

  @HostListener('window:resize', ['$event']) onWindowResize($event: UIEvent) {
    this.handleWindowResize();
  }

  @HostListener('document:keydown', ['$event']) async onKeyDown($event: KeyboardEvent) {
    if (
      this.slidePanelState === SlidePanelState.CLOSED &&
      this.tableOfContentsOpened === false &&
      !(await this.modalCtrl.getTop())
    ) {
      switch ($event.key) {
        case 'ArrowLeft':
          this.prev();
          break;
        case 'ArrowRight':
          this.next();
          break;
        case 'ArrowUp':
          this.scrollUp();
          break;
        case 'ArrowDown':
          this.scrollDown();
          break;
      }
    }
  }

  readonly expLength = 2;
  readonly lastLength = 1;
  secondLastLength = 2;

  public readonly EPUB_AREA_ID = 'currentArea';
  private readonly SIGNED_URL_EXPIRED_IN = 4 * 60 * 1000;

  @ViewChild('epubArea', { read: ElementRef })
  private _contents: any;
  private _cfiRange: any;

  private epubArea: ElementRef | undefined;
  private bookHeaderRef: ComponentRef<BookHeaderComponent> | undefined;

  public book: any | undefined = undefined;
  public maxPages: number = 1;

  private _currentPage: number = 0;
  public get currentPage(): number {
    return this._currentPage;
  }
  public set currentPage(value: number) {
    if (
      value !== this._currentPage &&
      value < this.maxPages &&
      value > -1
    ) {
      this._currentPage = value;
      this.changePageEmitter.next(this.currentPage);
      this.ref.detectChanges();
    }
  }
  private changePageEmitter: Subject<number> = new Subject();
  private forceManualPageChange: boolean = false;

  public bookisrendering: boolean = false;

  public chapters: NavItem[] | undefined = undefined;
  public tableOfContentsOpened: boolean = false;
  public selectedChapter: string = '';
  public redirectedChapter: string = '';

  public slidePanelState: SlidePanelState = SlidePanelState.CLOSED;
  public activatedViewId: string = '';

  private newTextSelections: Map<string, number> = new Map();
  private textSelection: Selection | undefined;
  private lastSelection: any = {};
  private selectedMarkNoteTxt: string = '';
  private highlightsLoaded: boolean = false;

  private readonly MOUSE_CLICK_LEFT: number = 0;
  private mouseUpTriggered: boolean = false;
  private histories: History[] = [];
  private subscriptions: Subscription[] = [];

  private tableScrollLeft: number = 0;
  private tableScrollSetupStatus: BehaviorSubject<string> = new BehaviorSubject<string>('onprogress');
  private scrollPosMap: Map<number, any> = new Map<number, number>();

  public menuMobileView: boolean = false;
  public menuMobileViewChanged: Subject<boolean> = new Subject<boolean>();

  public attachmentLink: string = '';
  public linkNameChanges: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public scrollingEnabled: boolean = true;
  public scrolling: Subject<void> = new Subject<void>();
  public pageHistory: Subject<void> = new Subject<void>();
  public emitClosedActiveMenu: Subject<void> = new Subject<void>();

  emitMobileViewValueToChild($event: any) {
    this.menuMobileViewChanged.next($event);
  }

  emitEventToChild($event: any) {
    this.scrolling.next($event);
  }

  emitHistoryEventToChild($event: any) {
    this.pageHistory.next($event);
  }

  emitMenuChanges($event: any) {
    this.emitClosedActiveMenu.next($event);
  }

  public isEdit: boolean = false;
  public navigateAway: Subject<string | undefined> = new Subject<string | undefined>();
  public isMenuOpen: boolean = false;
  public shouldRefresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public toc: any;

  get zoom(): number {
    return this.rendering.textZoomLevel.value;
  }

  public bookCoverUrl: any = '';
  public bookTitle: any = '';
  public attachmentsExists: boolean = false;

  public touchStartPosX: number = 0;
  public touchEndPosX: number = 0;
  public touchStartPosY: number = 0;
  public touchEndPosY: number = 0;
  public textSelectionIOSFlag = false;

  public markClickFlag = false;

  public isNoteActivated = () => this.activatedViewId === 'note';

  public isEditing = () =>
    this.activatedViewId === 'note' ||
    this.activatedViewId === 'thread-message' ||
    this.activatedViewId === 'thread-new';

  public isLoggedIn = () => this.userService.checkIfIDIsSet();

  public isHighlightingEnabled: boolean = false;

  public bookCollection: ClientBook[] | undefined;
  public selectedThread: CommunicationThread | null = null;

  constructor(
    private rendering: EpubRenderService,
    private route: ActivatedRoute,
    private userService: UserService,
    private contextmenuService: ContextmenuService,
    private textHighlightService: TexthighlightService,
    private menuCtrl: MenuController,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private noteService: NoteService,
    private ref: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private lastPageService: LastPageService,
    public platform: Platform,
    private renderer: Renderer2,
    public themeService: ThemeService,
    private booksApiService: BooksApiService,
    public deviceDetector: DeviceDetectorService,
    private spinningService: SpinnerService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private booksService: BooksService,
    private offlineBookservice: OfflineModeService,
    private toastService: ToastService,
    private storageService: StorageService,
    private searchService: SearchService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {
    fromEvent(window, 'popstate').subscribe((e) => {
      this.spinningService.hideSpinner();
    });

    Network.getStatus().then((status) => {
      this.isOnline = status.connected;
    });
    Network.addListener('networkStatusChange', async (status) => {
      this.isOnline = status.connected;
    });
  }

  public ngOnInit() {
    this.subscriptions.push(
      this.changePageEmitter.pipe(debounceTime(300), distinctUntilChanged()).subscribe(this.onPageChange),
      this.rendering.textZoomLevel.subscribe((value) => {
        this.updateHeaderChapter();
        this.ref.detectChanges();
      }),
      this.rendering.textZoomLevel.pipe(debounceTime(3000), distinctUntilChanged()).pipe(skip(1)).subscribe((zoomLevel) => {
        this.saveZoomLevel(zoomLevel)
      }),
      this.tableScrollSetupStatus.subscribe((status) => this.onTableStatusChange(status))
    );
  }

  async ionViewWillEnter() {
    if (Capacitor.getPlatform() === 'ios') {
      await StatusBar.hide();
    }
    this.menuCtrl.enable(true, 'main-menu').then((enabled) => {
      if (!enabled) {
        console.warn('Menu control is not enabled.');
      }
    });
    this.handleWindowResize();
  }

  async ionViewWillLeave() {
    this.closePanel();
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.detachBookHeader();
    if (Capacitor.getPlatform() === 'ios') {
      await StatusBar.show();
    }
  }

  private detachBookHeader() {
    // @ts-ignore
    const headerToolbars: NodeList = document.querySelectorAll('.on-header');
    headerToolbars.forEach((toolbar) => {
      // @ts-ignore
      toolbar.style.display = 'block';
    });
    const toolBarElm: HTMLIonToolbarElement | null = document.querySelector('ion-toolbar');
    if (toolBarElm) {
      if (!this.deviceDetector.isDesktop()) {
        const appMenuButton = toolBarElm.querySelector('ion-button');
        if (appMenuButton) {
          appMenuButton.style.display = 'block';
        }
      }

      const appLangSelect = toolBarElm.querySelector('select');
      if (appLangSelect) {
        appLangSelect.style.display = 'block';
      }
      const appIconImg = toolBarElm.querySelector('img');
      if (appIconImg) appIconImg.style.visibility = 'visible';

      const appBookHeader = toolBarElm.querySelector('app-book-header');
      if (appBookHeader) {
        toolBarElm.removeChild(appBookHeader);
      }
    }
  }

  /**
   * TODO research for better way
   * why is it injected and not using the component selector into the html?
   */
  private insertBookHeader() {
    if (this.bookHeaderRef) return;
    // @ts-ignore
    const headerToolbars: NodeList = document.querySelectorAll('.on-header');
    headerToolbars.forEach((toolbar: any) => {
      toolbar.style.display = 'none';
    });

    const toolBarElm: HTMLIonToolbarElement | null = document.querySelectorAll('ion-toolbar').item(0);

    if (toolBarElm) {
      if (!this.deviceDetector.isDesktop()) {
        const appMenuButton = toolBarElm.querySelector('ion-button');
        if (appMenuButton) {
          appMenuButton.style.display = 'none';
        }
      }

      const appLangSelect = toolBarElm.querySelector('select');
      if (appLangSelect) {
        appLangSelect.style.display = 'none';
      }
      const appIconImg = toolBarElm.querySelector('img');
      if (appIconImg) appIconImg.style.visibility = 'collapse';

      const bookHeaderInstance = this.createBookHeaderInstance();

      bookHeaderInstance.onNavigate.subscribe(() => {
        this.navigateToPrevPageAndSwap();
      });
      bookHeaderInstance.onToggleTableOfContents.subscribe(() => this.toggleTableOfContentsView());
      bookHeaderInstance.openBookLibrary.subscribe(() => this.openBookLibrary());
      bookHeaderInstance.zoomIn.subscribe(() => this.zoomIn());
      bookHeaderInstance.zoomOut.subscribe(() => this.zoomOut());

      bookHeaderInstance.isHighlightingEnabled = this.isHighlightingEnabled;
      bookHeaderInstance.toggleHighlightingEmitter.subscribe((enabled: boolean) => this.toggleHighlighting(enabled));

      if (!this.deviceDetector.isDesktop()) {
        bookHeaderInstance.onToggleSearch.subscribe(() => this.toggleSearchView());
        bookHeaderInstance.onToggleNoteOverview.subscribe(() => this.toggleNoteOverview());
      }
      bookHeaderInstance.notifyChanges();
      const domElem = (this.bookHeaderRef!!.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      if (domElem) {
        toolBarElm.appendChild(domElem);
      }
    }
  }

  public doSomethingOnScroll($event: any) {
    const fab = <HTMLElement>document.querySelector('ion-fab')!;

    if ($event.detail.deltaY < -100) {
      if (fab) {
        //fab.style.display = "block";
        //document.querySelector('ion-fab')!.className += " active";
        document.getElementById('book-fab-btn')!.className = 'ion-fabactive';
      }
    } else if ($event.detail.deltaY > 100) {
      if (fab) {
        //fab.style.display = "none";
        document.getElementById('book-fab-btn')!.className = 'ion-fabactive2';
      }
    }
  }

  //redirect to Book

  redirect(id: number, anchor: string) {
    let bookExists = false;
    this.booksService.books$.value.forEach((book) => {
      if (book.id === id) {
        bookExists = true;
        this.renderRedirectedBook(book, anchor);
      }
    });
    if (!bookExists) {
      this.toastService.showErrorMessage('The Book: ' + id + ' does not exist in your Library');
    }
  }

  async renderRedirectedBook(book: ClientBook, anchor: string) {
    console.log("renderRedirectedBook")
    if (Capacitor.isNativePlatform()) {
      if (!book.downloaded) {
        this.toastService.showErrorMessage('Book: ' + book.title + ' must be downloaded before you can open it');
        return;
      }
    }

    this.closePanel();
    this.rendering.stopRendering();
    this.bookisrendering = true;
    this.spinningService.showSpinner();

    this.searchService.reset();
    this.book = book;
    this.ref.detectChanges();
    this.epubArea && (this.epubArea.nativeElement.style.opacity = 0);
    this.bookCoverUrl = this.book?.urlCover;
    this.bookTitle = this.book?.title;

    if (!this.deviceDetector.isDesktop() && !this.menuMobileView) {
      this.removeLitelloAppHeader();
    }

    this.rendering.setZoom((await this.getLastZoomLevel()) || 0);

    let lastPage: string;
    if (anchor) {
      this.redirectedChapter = anchor;
      lastPage = '0';
    } else {
      lastPage = (await this.lastPageService.getLastPage(book.id)).cfi || '0';
    }

    let bookFile;
    if (book.opf_file) {
      bookFile = book.opf_file;
    } else {
      bookFile = await this.fetchSignedUrl(book.id);
    }

    if (lastPage && bookFile) {
      if (!book.downloaded) {
        await this.renderBook(book.id, bookFile, lastPage).then(
          (test) => {
            if (this.redirectedChapter !== '') {
              this.goToPageToc(this.redirectedChapter);
              this.redirectedChapter = '';
            } else {
              this.bookisrendering = false;
            }
          }
        );
      } else {
        let filePath: string = '';
        this.offlineBookservice.getApplicationPath(`${book.id.toString()}${OPF_FILE_PATH}`).then(async (res) => {
          const path = Capacitor.convertFileSrc(res.uri.toString());
          filePath = path!;
          await this.renderBook(book.id, filePath, lastPage).then(
            (test) => {
              if (this.redirectedChapter !== '') {
                this.goToPageToc(this.redirectedChapter);
                this.redirectedChapter = '';
              } else {
                this.bookisrendering = false;
              }
            }
          );
        });
      }
    } else {
      console.error(`Error, bookFile: ${bookFile}, lastPage: ${lastPage}`);
    }
  }

  public goToPageToc(href: string) {
    this.rendering.getCfiFromHref(href).then((cfi) => {
      if (cfi) {
        this.rendering.goToCfi(cfi).then(() => {
          //this.onNavigate.emit(cfi);
          const hash = href.substring(href.indexOf('#'));
          setTimeout(() => {
            this.rendering.goToSubSection(hash);
          }, 500);
          this.bookisrendering = false;
        });
      }
    });
  }

  private createBookHeaderInstance() {
    if (!this.bookHeaderRef) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(BookHeaderComponent);
      this.bookHeaderRef = componentFactory.create(this.injector);
    }
    return <BookHeaderComponent>this.bookHeaderRef.instance;
  }

  public openMenu() {
    this.isMenuOpen = true;
  }

  public closeMenu() {
    this.isMenuOpen = false;
    this.menuCtrl.close().then((closed) => {
      if (!closed) console.warn('Menu control not closed.');
    });
  }

  private onTableStatusChange(status: string) {
    switch (status) {
      case 'done':
        this.bringSvgMaskGroupToTop();
        setTimeout(() => {
          this.setNoteMarks();
        }, 500);
        break;
      case 'onprogress':
        break;
      case 'retry':
        break;
      default:
        break;
    }
  }

  public openToolbar() {
    //    this.setState({
    //        showNotification: !this.state.showNotification,
    //    });

    if (document.getElementById('book-fab-btn')!.className === 'ion-fabactive2') {
      document.getElementById('book-fab-btn')!.className = 'ion-fabactive';
    } else {
      document.getElementById('newToolbar')!.className = 'newToolbaractive'; // toolbar
      document.querySelector('ion-fab')!.style.display = 'none'; // ionFabb
    }
  }

  public closeToolbar() {
    document.getElementById('newToolbar')!.className = 'newToolbarinactive'; // toolbar
    document.querySelector('ion-fab')!.style.display = 'block'; // ionFab
  }

  public saveZoomLevel(zoomLevel: number) {
    this.lastPageService.saveZoomLevelByBookId(this.book.id, zoomLevel);
  }

  public async getLastZoomLevel(): Promise<number | undefined> {
    return await this.lastPageService.getZoomLevelByBookId(this.book.id);
  }

  public notifyChanges() {
    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }

  public ngOnDestroy() {
    this.rendering.stopRendering();
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.searchService.reset();
  }

  public openBookLibrary() {
    this.booksService.setOpendedStatus(true);
    this.router.navigate([Constants.URL.Books]);
    this.showLitelloAppHeader();
  }

  public async ngAfterViewInit() {
    console.log('ngAfterViewInit')
    this.subscriptions.push(
      this.route.data.subscribe(async (data) => {
        this.spinningService.showSpinner();
        this.book = data.book;
        this.epubArea && (this.epubArea.nativeElement.style.opacity = 0);
        this.bookCoverUrl = this.book?.urlCover;
        this.bookTitle = this.book?.title;

        if (!this.deviceDetector.isDesktop() && !this.menuMobileView) {
          this.removeLitelloAppHeader();
        }

        await this.checkIfAttachmentsExists(data.book.id);

        this.bookCollection = await this.getBookCollection(data.book.id);

        this.rendering.setZoom((await this.getLastZoomLevel()) || 0);

        const lastPage: string = (await this.lastPageService.getLastPage(data.book.id)).cfi || '0';
        let bookFile;
        if (data.book.opf_file) {
          bookFile = data.book.opf_file;
        } else {
          let bookId = data.book.id.toString();
          bookFile = await this.fetchSignedUrl(bookId);
        }
        if (lastPage && bookFile) {
          if (!data.book.downloaded) {
            await this.renderBook(data.book.id, bookFile, lastPage);
          } else {
            let filePath: string = '';
            this.offlineBookservice.getApplicationPath(`${data.book.id.toString()}${OPF_FILE_PATH}`).then(async (res) => {
              const path = Capacitor.convertFileSrc(res.uri.toString());
              filePath = path!;
              await this.renderBook(data.book.id, filePath, lastPage);
            });
          }
        } else {
          console.error(`Error, bookFile: ${bookFile}, lastPage: ${lastPage}`);
        }
      })
    );
  }

  checkIfAttachmentsExists(bookId: any) {
    return this.booksApiService
      .getAttachments(bookId)
      .subscribe((attachmentsList: Attachment[]) => {
        this.attachmentsExists = attachmentsList && attachmentsList.length > 0;
      });
  }

  fetchBookCollection(bookId: number): Observable<Collections> {
    return this.booksApiService.getCollections(bookId);
  }

  public async getBookCollection(bookId: number): Promise<ClientBook[] | undefined> {
    const online = await Network.getStatus();
    if (online.connected) {
      const collections = await firstValueFrom(this.fetchBookCollection(bookId));
      const collection = Object.values(collections)?.[0];
      if (collection && Capacitor.isNativePlatform()) {
        collection.forEach(book => this.storageService.savePreferences(`${COLLECTION_STORAGE_KEY}_${book.id}`, JSON.stringify(collection)));
      }
      return collection?.filter(x => x.id !== bookId);
    } else {
      const collectionJson = await this.storageService.getPreferences(`${COLLECTION_STORAGE_KEY}_${bookId}`);
      if (collectionJson) {
        const collection: ClientBook[] = JSON.parse(collectionJson);
        return collection?.filter(x => x.id !== bookId);
      }
    }
  }

  private removeLitelloAppHeader() {
    const appTable = <HTMLElement>document.querySelector('app-slide-panel')!;
    appTable.querySelector('ion-content')!.style.top = '0px';

    const header = <HTMLElement>document.querySelector('litello-app-header')!;
    if (header) {
      header.style.display = 'none';
    }

    const content = <HTMLElement>document.getElementById('book-content')!;
    if (content) {
      content.style.paddingTop = '0px';
    }

    const context = <HTMLElement>document.querySelector('app-context-menu')!;
    if (context) {
      context.style.display = 'none';
    }
  }

  private showLitelloAppHeader() {
    const header = <HTMLElement>document.querySelector('litello-app-header')!;
    if (header) {
      header.style.display = 'block';
    }
    const content = <HTMLElement>document.getElementById('book-content')!;
    if (content) {
      content.style.paddingTop = '60px';
    }
    const appTable = <HTMLElement>document.querySelector('app-slide-panel')!;
    appTable.querySelector('ion-content')!.style.top = '60px';
  }

  private async fetchSignedUrl(bookId: number) {
    let signedUrl = await this.getLocalSignedUrl(bookId);

    if (signedUrl == null) {
      const contentWithSignedUrl = await this.downloadSignedUrl(bookId);
      if (contentWithSignedUrl != null) {
        signedUrl = contentWithSignedUrl.signedUrl;
        if (signedUrl != null) this.updateLocalSignedUrl(bookId, signedUrl);
        else console.error(`signedUrl is not available.`);
      }
    }

    return signedUrl;
  }

  private updateLocalSignedUrl(bookId: number, signedUrl: string) {
    localStorage.setItem(`bid_${bookId}_url`, `${signedUrl}_exp_${Date.now()}`);
  }

  private async getLocalSignedUrl(bookId: number) {
    const bidInfo = localStorage.getItem(`bid_${bookId}_url`);
    if (bidInfo != null) {
      const urlAndExp = bidInfo.split('_exp_');
      if (urlAndExp.length == this.expLength) {
        const url = urlAndExp[0];
        const exp = urlAndExp[1];
        if (Date.now() - Number(exp) < this.SIGNED_URL_EXPIRED_IN) {
          return url;
        } else {
          localStorage.removeItem(`bid_${bookId}_url`);
        }
      }
    }
    return null;
  }

  private async downloadSignedUrl(bookId: number) {
    return this.booksApiService.downloadGet('web', bookId).toPromise();
  }

  private async renderBook(bookId: number, signedUrl: string, lastPage: string) {
    console.log("renderBook");
    await this.rendering
      .renderTo(
        this.EPUB_AREA_ID,
        signedUrl,
        (pageNumber: number) => {
          if (this.forceManualPageChange === false) {
            this.currentPage = pageNumber;
          }
          this.forceManualPageChange = false;
        },
        lastPage
      )
      .then(
        async () => {
          console.log("rendering.renderTo.then");
          await this.asyncSetTotalPages();
          this.currentPage = parseInt(lastPage) || 0;
          this.addPlaceHolderToBookPage();
          this.setBookIdForHighlightServices(this.book.id);

          this.rendering.attachCallback('touchstart', this.onTouchstartListener.bind(this));
          this.rendering.attachCallback('touchend', this.onTouchendListener.bind(this));
          this.rendering.attachCallback('markClicked', this.onMarkClick.bind(this));
          this.rendering.attachCallback('rendered', this.onRender.bind(this));
          this.rendering.attachCallback('relocated', this.onRelocate.bind(this));
          this.rendering.attachCallback('click', this.onPageClick.bind(this));
          this.rendering.attachCallback('mouseup', this.onMouseUp.bind(this));
          this.rendering.attachCallback('mousedown', this.onMouseDown.bind(this));
          this.rendering.attachCallback('keydown', this.onKeyDown.bind(this));

          this.restoreScrollPosition();

          this.initTextSelectionsForNewScroll();
          this.insertBookHeader();

          this.ref.detectChanges();
          if (!this.highlightsLoaded) {
            this.loadHighlightsAndNotes(bookId);
            //this.setNoteMarks();
          }
          await this.spinningService.hideSpinner();
          this.rendering.getFlattenedChapters();
          this.loadChapters();
          return true
        },
        async (error) => {
          console.error("Couldn't open the book, \n " + JSON.stringify(error));
          await this.spinningService.hideSpinner();
        }
      );
  }

  private async loadHighlightsAndNotes(bookId: number) {
    this.rendering.removeAllSelections();
    const subject = await this.noteService.loadNotes(bookId);
    subject.subscribe(async (notes) => {
      await this.createAnnotationsWithNote(notes);
      //await this.createAnnotationsWithoutNote(bookId.toString());
    });
    this.highlightsLoaded = true;
    // .then(async notes => {
    //   await this.createAnnotationsWithNote(notes);
    //   await this.createAnnotationsWithoutNote(bookId.toString());
    // });
  }

  private async createAnnotationsWithNote(notes: Note[] | undefined) {
    if (notes) {
      const createNoteAnnotations = async () => {
        let type: string = '';
        for await (const note of notes) {
          let info = '';
          if (note.note && note.note.length > 0) {
            info = 'note';
          } else {
            type = 'highlight';
          }
          await this.rendering.createAnnotation('highlight', note.pageId!, note.color, info);
        }
        this.setNoteMarks();
      };

      await createNoteAnnotations();
    } else {
      console.log('notes are empty');
    }
  }

  private setBookIdForHighlightServices(bookId: number) {
    this.textHighlightService.bookID = bookId;
    this.noteService.bookID = bookId;
  }

  private addPlaceHolderToBookPage() {
    const placeholder = document.createElement('div');
    placeholder.setAttribute('style', 'height:0');
    placeholder.setAttribute('class', 'placeholder');
    if (this.epubArea) {
      this.epubArea.nativeElement.appendChild(placeholder);
      this.epubArea.nativeElement.style.opacity = 1;
    }
  }

  private async asyncSetTotalPages() {
    await this.rendering.totalPages().then((totalPages) => {
      this.lastPageService.setPageCount(totalPages);
      this.maxPages = totalPages;
    });
  }

  private onRender = (e: any) => {
    /**
     * Replace video solution not works for streaming files.
     * When video is streaming then the absolute path of streaming file will be resolved to
     * chunks url. Passing chunk url as source of another video player is corrupted, because its the
     * resolved chunk url second time request of same chunk url will get not found response from stream provider.
     * Without replacing it streams fine.
     */
    //this.replaceVideo();
  }

  private onRelocate = () => {
    this.setCurrentChapter();
    this.setUpScrollListenerAndMaskToTableElements();
  }

  //This function is used for page swiping
  private onTouchstartListener = (e: any) => {
    this.touchStartPosX = e.changedTouches[0].clientX;
    this.touchStartPosY = e.changedTouches[0].screenY;
  }

  //This function is also used for page swiping
  private onTouchendListener = (e: any) => {
    this.touchEndPosX = e.changedTouches[0].clientX;
    this.touchEndPosY = e.changedTouches[0].screenY;

    setTimeout(() => {
      if (this.deviceDetector.isIOS()) this.showContextMenu();
    }, 200);

    //This condition is used on iOS Device and when text was not selected
    if (this.deviceDetector.isIOS() && !this.textSelectionIOSFlag) this.handleGesture();

    //This condition is used for devices which are not on ios
    if (!this.deviceDetector.isIOS()) this.handleGesture();

    this.textSelectionIOSFlag = false;
  }

  //This function is used to handle the page swiping gesture
  private handleGesture() {
    let swipeDiff = this.touchStartPosX - this.touchEndPosX;
    let scrollDiff = this.touchStartPosY - this.touchEndPosY;

    //This condition checks to make sure if there  is no scrolling involved
    if (scrollDiff > 50 || scrollDiff < -50) return;
    if (this.touchEndPosX < this.touchStartPosX && swipeDiff > 50) {
      //move to next page
      this.next();
    }
    if (this.touchEndPosX > this.touchStartPosX && swipeDiff < -50) {
      //move to previous page
      this.prev();
    }
  }

  private onMouseDown = (e: MouseEvent) => {
    this.mouseUpTriggered = false;
    switch (e.button) {
      case this.MOUSE_CLICK_LEFT:
        if (this.contextmenuService.isOpened()) {
          this.contextmenuService.toggleMenu('hide');
          if (this.lastSelection.selectionObject) {
            this.rendering.removeAllSelections();
            this.rendering.removeAnnotation(this.lastSelection.cfiRange, 'highlight');
            this.lastSelection = {};
          }
        }
        break;
      default:
        break;
    }
  }

  private onPageClick = (e: MouseEvent) => {
    if (this._cfiRange && this._contents) {
      this.onTextSelectAndroid(this._cfiRange, this._contents);
      this._cfiRange = null;
      this._contents = null;
    }
    if (this.isEdit) {
      return;
    } else {
      if (e.target && (e.target as HTMLElement).tagName === 'IMG') {
        this.handleImageClick(e);
        return;
      } else if ((e.target && (e.target as HTMLElement).tagName === 'A') || this.checkIfAtagIsParent(e)) {
        let anchor: HTMLAnchorElement = <HTMLAnchorElement>e.target;
        if (this.checkIfAtagIsParent(e)) anchor = <HTMLAnchorElement>(e.target as HTMLElement).parentNode;
        if (anchor?.href?.includes('attachments')) {
          this.attachmentLink = anchor.href.split(':')[1];
          if (this.activatedViewId !== 'attachment') {
            this.togglePanel({ navigateTo: 'attachment', editing: false });
          }
          this.notifyChanges();
          return;
        } else if (anchor?.href?.startsWith('book')) {
          const bookLink: string[] = anchor.href.split('::');
          const linkId = parseInt(bookLink[1]);
          const linkAnchor = bookLink[2];
          this.redirect(linkId, linkAnchor);
        } else if (anchor?.href && anchor.href.includes('#')) {
          this.handleSubSectionClick(anchor);
        }
      }
    }
  }

  //TODO:: change this in the book link
  // dhl book has the <a> with child <span> and <SVG> - on click registered them, not <a> tag!
  checkIfAtagIsParent(e: any) {
    return (
      (e.target && (e.target as HTMLElement).tagName === 'SPAN') ||
      (e.target &&
        (e.target as HTMLElement).tagName === 'SVG' &&
        (e.target as HTMLElement).parentNode?.nodeName === 'A')
    );
  }

  private handleSubSectionClick(anchor: HTMLAnchorElement) {
    const hash = anchor.href.substring(anchor.href.indexOf('#'));
    setTimeout(() => {
      this.rendering.goToSubSection(hash);
    }, 500);
  }

  private handleImageClick(e: MouseEvent) {
    const srcElement: HTMLImageElement = <HTMLImageElement>e.target;
    if (
      !srcElement!.currentSrc.toLocaleLowerCase().includes('cover')
      && !srcElement.classList.contains('no-app-modal')
    ) {
      this.modalCtrl
        .create({
          component: ImagePopupComponent,
          componentProps: { src: srcElement.currentSrc },
          cssClass: 'ion-main-modal-mobile',
        })
        .then((popover) => {
          popover.present().then(() => console.debug('Image modal is present.'));
        });
    }
  }

  /**
   * Opens the context menu. This function has become a lot more complex as the result of a workaround on iOS. iOS shows a system text selection menu by default.
   * This used to behave very differently, not closing when our context menu would. This is fixed by manually resetting the selection every time which forces to hide the iOS selection menu.
   */
  private onTextSelect = (cfiRange: EpubCFI, contents: Contents): void => {
    if (this.deviceDetector.isAndroid()) {
      this._cfiRange = cfiRange;
      this._contents = contents;
    }
    //This else part covers the devices other than android such as Browsers and iOS
    else {
      //This condition is used when selecting text on ios device then it disable swiping
      if (this.deviceDetector.isIOS()) this.textSelectionIOSFlag = true;
      if (this.isEdit) {
        return;
      }

      let s = contents.document.getSelection();
      if (s && this._hasSelectionChanged(s)) {
        this.storeTextSelection(s, cfiRange, contents);
        if (this.mouseUpTriggered) {
          if (this.deviceDetector.isDesktop()) {
            this.addTextSelection(s);
            this.textSelection = undefined;
            this.mouseUpTriggered = false;
          }
        }
      }
    }
  }

  private onTextSelectAndroid(cfiRange: EpubCFI, contents: Contents) {
    let s = contents.document.getSelection();

    //Check if the selection is the same as the last one and not empty
    if (!(s && this._hasSelectionChanged(s)))
      return;

    this.storeTextSelection(s, cfiRange, contents);
    this.showContextMenu();
  }

  private onMouseUp = () => {
    this.mouseUpTriggered = true;

    if (this.isEdit) {
      return;
    }

    if (this.textSelection) {
      if (this.deviceDetector.isDesktop()) {
        this.addTextSelection(this.textSelection);
        this.textSelection = undefined;
      }
      if (this.deviceDetector.isAndroid() && this.lastSelection) {
        this.newTextSelections.set(this.lastSelection.cfiRange.toString(), this.tableScrollLeft);
        this.createTextHighlight(this.contextmenuService.cfiRange.toString(), this.contextmenuService.color);
      }
    }
  }

  private storeTextSelection(s: Selection, cfiRange: EpubCFI, contents: Contents) {
    this.textSelection = s;

    const cfiRangeAsString = cfiRange.toString();
    this.lastSelection = {
      anchorOffset: s.anchorOffset,
      focusOffset: s.focusOffset,
      selectionObject: s,
      cfiRange: cfiRange,
      range: contents.range(cfiRangeAsString),
    };
  }

  private addTextSelection(selection: Selection) {
    let r = selection.getRangeAt(0);
    selection.removeAllRanges();
    selection.addRange(r);

    this.showContextMenu();
  }

  private showContextMenu() {
    // Check if the selection is not empty
    if (!this.lastSelection?.cfiRange)
      return;

    const cfiRangeAsString: string = this.lastSelection.cfiRange.toString();
    const range: Range = this.lastSelection.range;
    const text: string = range.toString();
    const rects: DOMRectList = range.getClientRects();

    this.contextmenuService.isNewSelection = true;
    this.contextmenuService.notetext = '';
    this.contextmenuService.displayContextMenu(text, this.deviceDetector.isMobile(), rects, cfiRangeAsString);
    this.newTextSelections.set(cfiRangeAsString, this.tableScrollLeft);
    this.createTextHighlight(this.contextmenuService.cfiRange.toString(), this.contextmenuService.color);
  }

  /**
   * Checks if the new selection is different from the previous one. This check exists
   * @param n The new selection
   */
  private _hasSelectionChanged(n: Selection) {
    if (!this.lastSelection) {
      return true;
    }
    const o = this.lastSelection;
    return o.anchorOffset != n.anchorOffset || o.focusOffset != n.focusOffset;
  }

  private onMarkClick = (cfiRange: EpubCFI, data: any, iframeView: any): void => {
    //The setTimeout avoids contextmenu to disappear
    setTimeout(() => {
      this.markClickFlag = false;
    }, 500);

    this.markClickFlag = true;

    if (this.isEdit) {
      return;
    }
    const contents = iframeView;
    const cfiRangeAsString = cfiRange.toString();
    const range = contents.range(cfiRangeAsString);
    const rects = range.getClientRects();
    const text = range.toString();

    const loadedMark: any = this.findMarkFromLoadedData(cfiRangeAsString);

    this.selectedMarkNoteTxt = loadedMark.note ? loadedMark.note : '';

    this.contextmenuService.color = loadedMark ? loadedMark.color : data.color;
    this.ref.detectChanges();

    if (this.isHighlightingEnabled) this.rendering.changeSelectionColor(data.color);

    if (loadedMark) {
      this.contextmenuService.notetext = (loadedMark as Note).note || '';
      this.contextmenuService.timestamp = loadedMark.creationDate;
    }
    // This method can probably be abstracted with showContextMenu()
    this.contextmenuService.displayContextMenu(text, this.deviceDetector.isMobile(), rects, cfiRangeAsString);
    this.newTextSelections.set(cfiRangeAsString, this.tableScrollLeft);
  }

  private findMarkFromLoadedData(cfiRange: string) {
    return (
      this.noteService.findNoteByCfiRange(cfiRange) || this.textHighlightService.findTextHighlightByCfiRange(cfiRange)
    );
  }

  public cleanTextHighlight() {
    this.handleSelection();
    this.shouldRefresh.next(true);
    this.lastSelection = {};
  }

  private setUpScrollListenerAndMaskToTableElements() {
    const tableElements = this.getTableElements();
    if (tableElements && tableElements.length > 0) {
      let count = 0;
      tableElements.forEach((tableElm) => {
        const tableContainer = tableElm.parentElement;
        if (tableContainer && (tableContainer.style.overflowX == 'auto' || tableContainer.scrollWidth > innerWidth)) {
          this.setScrollListenersToTableContainer(tableContainer).then(() => {
            this.addSvgGroupLayer()
              .then((maskLayer) => {
                this.attachMaskToHideOverflowScroll(maskLayer, tableContainer).then(() => {
                  count++;
                  this.tableScrollSetupStatus.next(count == tableElements.length ? 'done' : 'onprogress');
                });
              })
              .catch((e) => {
                console.debug('addSvgGroupLayer(): Error, ' + e);
                this.tableScrollSetupStatus.next('retry');
              });
          });
        }
      });
    } else {
      this.tableScrollSetupStatus.next('done');
    }
  }

  private async attachMaskToHideOverflowScroll(maskSvgLayer: SVGSVGElement, tableContainer: HTMLElement) {
    const tableContainerParentRect = tableContainer.parentElement!.getBoundingClientRect();
    const tableClientRect = tableContainer.getBoundingClientRect();

    if (tableContainerParentRect.left == 0) {
      const maskOuterLeft = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      maskOuterLeft.setAttributeNS(null, 'class', 'mask');
      maskOuterLeft.setAttributeNS(null, 'x', '0');
      maskOuterLeft.setAttributeNS(null, 'y', `${tableClientRect.top}`);
      maskOuterLeft.setAttributeNS(null, 'width', `${tableClientRect.left}`);
      maskOuterLeft.setAttributeNS(null, 'height', `${tableClientRect.height}`);
      maskOuterLeft.setAttributeNS(null, 'fill', 'var(--ion-background-color)');

      const maskOuterRight = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
      maskOuterRight.setAttributeNS(null, 'class', 'mask');
      maskOuterRight.setAttributeNS(null, 'x', `${tableClientRect.right}`);
      maskOuterRight.setAttributeNS(null, 'y', `${tableClientRect.top}`);
      maskOuterRight.setAttributeNS(null, 'width', `${tableClientRect.left}`);
      maskOuterRight.setAttributeNS(null, 'height', `${tableClientRect.height}`);
      maskOuterRight.setAttributeNS(null, 'fill', 'var(--ion-background-color)');
      maskSvgLayer.appendChild(maskOuterLeft);
      maskSvgLayer.appendChild(maskOuterRight);
      return Promise.resolve();
    }

    const { color, wrapper } = this.pickTableBackgroundColor(tableContainer);
    const innerMaskWidth = (innerWidth - tableClientRect.width) / 2;
    const maskInnerLeft = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    maskInnerLeft.setAttributeNS(null, 'class', 'mask');
    maskInnerLeft.setAttributeNS(null, 'x', `${tableClientRect.left - (innerWidth - tableClientRect.width) / 2}`);
    maskInnerLeft.setAttributeNS(null, 'y', `${tableClientRect.top}`);
    maskInnerLeft.setAttributeNS(null, 'width', `${innerMaskWidth}`);
    maskInnerLeft.setAttributeNS(null, 'height', `${tableClientRect.height}`);
    maskInnerLeft.setAttributeNS(null, 'fill', `${color}`);

    const maskInnerRight = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    maskInnerRight.setAttributeNS(null, 'class', 'mask');
    maskInnerRight.setAttributeNS(null, 'x', `${tableClientRect.right}`);
    maskInnerRight.setAttributeNS(null, 'y', `${tableClientRect.top}`);
    maskInnerRight.setAttributeNS(null, 'width', `${innerMaskWidth}`);
    maskInnerRight.setAttributeNS(null, 'height', `${tableClientRect.height}`);
    maskInnerRight.setAttributeNS(null, 'fill', `${color}`);

    const outerMaskWidth = wrapper!.toString() == 'body' ? 0 : (wrapper as HTMLDivElement).getBoundingClientRect().left;
    const outerRightMaskX =
      wrapper!.toString() == 'body' ? tableClientRect.right : (wrapper as HTMLDivElement).getBoundingClientRect().right;

    const maskOuterLeft = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    maskOuterLeft.setAttributeNS(null, 'class', 'mask');
    maskOuterLeft.setAttributeNS(null, 'x', '0');
    maskOuterLeft.setAttributeNS(null, 'y', `${tableClientRect.top}`);
    maskOuterLeft.setAttributeNS(null, 'width', `${outerMaskWidth == 0 ? tableClientRect.left : outerMaskWidth}`);
    maskOuterLeft.setAttributeNS(null, 'height', `${tableClientRect.height}`);
    maskOuterLeft.setAttributeNS(null, 'fill', 'var(--ion-background-color)');

    const maskOuterRight = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
    maskOuterRight.setAttributeNS(null, 'class', 'mask');
    maskOuterRight.setAttributeNS(null, 'x', `${outerRightMaskX}`);
    maskOuterRight.setAttributeNS(null, 'y', `${tableClientRect.top}`);
    maskOuterRight.setAttributeNS(null, 'width', `${outerMaskWidth == 0 ? tableClientRect.left : outerMaskWidth}`);
    maskOuterRight.setAttributeNS(null, 'height', `${tableClientRect.height}`);
    maskOuterRight.setAttributeNS(null, 'fill', 'var(--ion-background-color)');

    maskSvgLayer.appendChild(maskInnerLeft);
    maskSvgLayer.appendChild(maskInnerRight);
    maskSvgLayer.appendChild(maskOuterLeft);
    maskSvgLayer.appendChild(maskOuterRight);

    return Promise.resolve();
  }

  private pickTableBackgroundColor(tableContainer: HTMLElement) {
    if (tableContainer.parentElement) {
      const computedColor = getComputedStyle(tableContainer.parentElement).backgroundColor;
      if (computedColor != 'rgba(0, 0, 0, 0)') {
        return { color: computedColor, wrapper: tableContainer.parentElement };
      } else {
        if (tableContainer.parentElement.parentElement) {
          const parentComputedColor = getComputedStyle(tableContainer.parentElement.parentElement).backgroundColor;
          if (parentComputedColor != 'rgba(0, 0, 0, 0)') {
            return { color: parentComputedColor, wrapper: tableContainer.parentElement.parentElement };
          } else {
            if (tableContainer.parentElement.parentElement.parentElement) {
              const parentParentComputedColor = getComputedStyle(
                tableContainer.parentElement.parentElement.parentElement
              ).backgroundColor;
              if (parentParentComputedColor != 'rgba(0, 0, 0, 0)')
                return {
                  color: parentParentComputedColor,
                  wrapper: tableContainer.parentElement.parentElement.parentElement,
                };
            }
          }
        }
      }
    }
    return { color: 'var(--ion-background-color)', wrapper: 'body' };
  }

  private async addSvgGroupLayer(): Promise<SVGSVGElement> {
    const svgMaskGroupLayer = document.getElementsByClassName('mask-group').item(0);

    if (svgMaskGroupLayer) {
      return Promise.resolve(svgMaskGroupLayer as SVGSVGElement);
    }

    const newLayer = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    newLayer.setAttributeNS(null, 'class', 'mask-group');
    newLayer.setAttributeNS(null, 'pointer-events', 'none');
    newLayer.setAttributeNS(null, 'height', `100%`);
    newLayer.setAttributeNS(null, 'width', '100%');
    newLayer.style.position = 'absolute';
    newLayer.style.left = '0';
    newLayer.style.top = '0';

    const epubViews = document.getElementsByClassName('epub-view');
    if (epubViews.length > 0) {
      epubViews[0].append(newLayer);
    } else {
      return Promise.reject('epubView not exists.');
    }

    return Promise.resolve(newLayer);
  }

  private bringSvgMaskGroupToTop() {
    const svgMaskGroupLayer = document.getElementsByClassName('mask-group').item(0);
    if (svgMaskGroupLayer) {
      (svgMaskGroupLayer as HTMLElement).style.zIndex = '10';
    }
  }

  private async setScrollListenersToTableContainer(tableContainer: HTMLElement) {
    if (tableContainer.onscroll != null) {
      return;
    }
    tableContainer.style.overflowX = 'auto';
    tableContainer.addEventListener('scroll', this.onTableScrollListener);
  }

  private getTableElements(): NodeListOf<HTMLTableElement> | undefined {
    const iframe = document.querySelector('iframe');
    if (iframe) {
      const contentDocument = iframe.contentDocument;
      if (contentDocument) {
        return contentDocument.body.querySelectorAll('table');
      }
    }
  }

  private onTableScrollListener = (event: Event) => {
    const elm = <HTMLDivElement>event.target;
    this.tableScrollLeft = elm.scrollLeft;
    document.querySelectorAll(`[class$='-texthighlight']`).forEach((highlightElm) => {
      const highlightRect = highlightElm.querySelector('rect');
      if (highlightRect && this.isInsideTableElement(highlightRect, elm)) {
        let scrollX = elm.scrollLeft * -1;
        const dataEpubCfiAsString = highlightElm.getAttribute('data-epubcfi');
        if (dataEpubCfiAsString && this.newTextSelections.has(dataEpubCfiAsString)) {
          scrollX += this.newTextSelections.get(dataEpubCfiAsString) || 0;
        }
        highlightElm.setAttribute('transform', `translate(${scrollX},0)`);
      }
    });
  }

  private isInsideTableElement(elm: SVGRectElement, table: Element) {
    const tableRect = table.getBoundingClientRect();
    return elm.y.baseVal.value >= tableRect.top && elm.y.baseVal.value + elm.height.baseVal.value <= tableRect.bottom;
  }

  private initTextSelectionsForNewScroll() {
    this.newTextSelections.clear();
    this.tableScrollLeft = 0;
    this.tableScrollSetupStatus.next('onprogress');
  }

  private saveHistory(pageNumber: number) {
    if (this.histories.length > 0 && this.histories[this.histories.length - this.lastLength].pageNumber === pageNumber) {
      return;
    }

    this.histories.push({ pageNumber });

    this.emitHistoryEventToChild(this.histories);
    this.updateHeaderChapter();
  }

  private onChangeScrollingEnabled(scroll: any) {
    this.scrollingEnabled = scroll;
  }

  public navigateToPrevPageAndSwap() {
    if (this.histories.length >= 2) {
      const current = this.histories[this.histories.length - this.lastLength];
      const last = this.histories[this.histories.length - this.secondLastLength];

      if (last) {
        if (last.pageNumber == current.pageNumber) {
          this.histories.pop();
          this.navigateToPrevPageAndSwap();
          return;
        }

        // this.histories[this.histories.length - 2] = current;
        // this.histories[this.histories.length - this.secondLastLength] = last;

        this.goToPage(last.pageNumber);
        this.secondLastLength += 1;
        const checkLast = this.histories[this.histories.length - this.secondLastLength];
        if (!checkLast) {
          this.secondLastLength = 2;
          this.histories = [];
        }
        return;
      }
    }
  }

  public navigateToPrevHistory() {
    if (this.histories.length == 0) {
      this.navCtrl.back();
    } else {
      const last = this.histories[this.histories.length - this.lastLength];
      if (last) {
        if (last.pageNumber == this.currentPage) {
          this.histories.pop();
          this.navigateToPrevHistory();
          return;
        }
        this.goToPage(last.pageNumber);
        return;
      }
      this.navCtrl.back();
    }
  }

  private goToPage(page: number) {
    this.rendering.displayCfi(page.toString())
  }

  private setCurrentChapter() {
    const info = this.rendering.getChapterInformationForDiscussion();
    if (info) {
      this.toggleChapter(info.selectedChapter);
    }
  }

  private loadChapters() {
    this.rendering.getTOC().then((toc) => {
      this.toc = toc;
    });
    this.rendering.getChapters().then((chapters) => {
      this.chapters = chapters;
    });
  }

  public toggleChapter(chapter: string) {
    this.selectedChapter = chapter;
    this.updateHeaderChapter();
  }

  public toggleTableOfContentsView() {
    this.closePanel();
    this.panelClosed();
    this.tableOfContentsOpened = !this.tableOfContentsOpened;
    if (this.bookHeaderRef) {
      const bookHeaderInstance = <BookHeaderComponent>this.bookHeaderRef.instance;
      bookHeaderInstance.tableOfContentsOpened = this.tableOfContentsOpened;
      bookHeaderInstance.notifyChanges();
    }
    this.ref.detectChanges();
  }

  /**
   * This is mobile only.
   * */
  public toggleSearchView() {
    if ((this.tableOfContentsOpened = true)) {
      this.tableOfContentsOpened = !this.tableOfContentsOpened;
    }
    this.togglePanel({ navigateTo: 'search', editing: false });
    this.ref.detectChanges();
  }

  /**
   * This is mobile only.
   * */
  public toggleAttachmentView() {
    if ((this.tableOfContentsOpened = true)) {
      this.tableOfContentsOpened = !this.tableOfContentsOpened;
    }
    this.togglePanel({ navigateTo: 'attachment', editing: false });
    this.ref.detectChanges();
  }

  /**
   * This is mobile only.
   * */
  public toggleNoteOverview() {
    if (this.tableOfContentsOpened) {
      this.tableOfContentsOpened = false;
    }
    this.togglePanel({ navigateTo: 'overview', editing: false });
    this.ref.detectChanges();
  }

  public toggleCollectionOverview() {
    if (this.tableOfContentsOpened) {
      this.tableOfContentsOpened = false;
    }
    this.togglePanel({ navigateTo: 'collection', editing: false });
    this.ref.detectChanges();
  }

  public toggleCommunicationOverview() {
    if (this.tableOfContentsOpened) {
      this.tableOfContentsOpened = false;
    }
    this.togglePanel({ navigateTo: 'communication', editing: false });
    this.ref.detectChanges();
  }

  public openAttachmentOverview() {
    if (this.tableOfContentsOpened) {
      this.tableOfContentsOpened = false;
    }
    this.togglePanel({ navigateTo: 'attachment', editing: false });
    this.ref.detectChanges();
  }

  public openCollectionOverview() {
    if (this.tableOfContentsOpened) {
      this.tableOfContentsOpened = false;
    }
    this.togglePanel({ navigateTo: 'collection', editing: false });
    this.ref.detectChanges();
  }

  private updateHeaderChapter() {
    if (this.bookHeaderRef) {
      const bookHeaderInstance = <BookHeaderComponent>this.bookHeaderRef.instance;
      bookHeaderInstance.book = this.book;
      bookHeaderInstance.bookCoverSrc = this.bookCoverUrl;
      bookHeaderInstance.bookTitle = this.bookTitle;
      bookHeaderInstance.selectedChapter = this.selectedChapter;
      bookHeaderInstance.tableOfContentsOpened = this.tableOfContentsOpened;
      bookHeaderInstance.zoom = this.zoom;
      bookHeaderInstance.previousPageButton = this.histories.length >= 2;
      bookHeaderInstance.notifyChanges();
    }
  }

  private handleWindowResize = () => {
    if (window.innerWidth < 700 && !this.menuMobileView) {
      this.menuMobileView = true;
      this.emitMobileViewValueToChild(this.menuMobileView);
      this.removeLitelloAppHeader();
    } else if (window.innerWidth >= 700 && this.menuMobileView) {
      this.menuMobileView = false;
      this.emitMobileViewValueToChild(this.menuMobileView);
      this.showLitelloAppHeader();
    }
  }

  private saveScrollPosition() {
    this.rendering.getScrollTop().then((top) => {
      if (top > 0) {
        this.scrollPosMap.set(this.currentPage, top);
      }
    });
  }

  private restoreScrollPosition() {
    let scrollPos = this.scrollPosMap.get(this.currentPage);
    if (scrollPos !== undefined) {
      this.rendering.scrollTo(scrollPos).then(() => console.debug(`Scroll position restored ${scrollPos}`));
    }
  }

  public onColorChange(color: string) {
    if (this.isHighlightingEnabled) this.rendering.changeSelectionColor(color);
    this.setNoteMarks();
  }

  public onContextMenuColorChange(color: string) {
    this.noteService
      .updateNote(this.contextmenuService.cfiRange.toString(), color, this.selectedMarkNoteTxt)
      .then(() => {
        this.cleanTextHighlight();
        this.selectedMarkNoteTxt = '';
      });
  }

  private createTextHighlight(cfi: string, color: string) {
    this.prepareAnnotation(cfi, color);
    this.noteService.createNote(cfi, color, '').then(() => {
      this.cleanTextHighlight();
    });
  }

  private updateHighlightColor(cfi: string, color: string) {
    this.prepareAnnotation(cfi, color);
    this.textHighlightService.updateTextHighlight(cfi, color).then(() => {
      this.cleanTextHighlight();
    });

    this.ref.detectChanges();
  }

  private prepareAnnotation(cfi: string, color: string) {
    this.contextmenuService.color = color;
    this.rendering.removeAllSelections();
    this.rendering.removeAnnotation(cfi, 'highlight');
    this.rendering.createAnnotation('highlight', cfi, color, '');
  }

  private updateSelectedMark(mark?: ClientMark) {
    if (mark) {
      this.contextmenuService.cfiRange = mark.cfi;
      this.contextmenuService.color = mark.color;
      this.contextmenuService.text = mark.text;
      this.contextmenuService.timestamp = mark.creationDate;
      this.contextmenuService.page = mark.pageNumber;
      if (mark.noteText) this.contextmenuService.notetext = mark.noteText;
      else this.contextmenuService.notetext = '';
    }
  }

  public toggleBookView(params: any) {
    switch (params.viewId) {
      case 'note':
        this.isEdit = true;
        this.updateSelectedMark(params.mark);
        break;
      case 'search':
        this.isEdit = true;
        break;
      case 'attachment':
        this.isEdit = true;
        break;
      case 'book':
        this.isEdit = false;
        if (!this.deviceDetector.isDesktop()) {
          this.closePanel();
          this.createBookHeaderInstance().activatedView = 'book';
        }
        break;
      default:
        this.isEdit = false;
        break;
    }

    this.activatedViewId = params.viewId;
  }

  public togglePanel(params: { navigateTo?: string; editing: boolean }) {
    //avoid toggling only when mark was clicked. Also avoid contextmenu disappear
    if (this.markClickFlag) {
      this.contextmenuService.toggleMenu('show');
      return;
    }

    this.contextmenuService.page = this.currentPage;
    if (this.slidePanelState === SlidePanelState.CLOSED && params.navigateTo) {
      this.openPanel(params.navigateTo);
      return;
    }
    if (this.activatedViewId === params.navigateTo) {
      this.closePanel();
      return;
    }

    if (params.editing) {
      this.navigateAway.next(params.navigateTo);
    } else {
      if (params.navigateTo) this.openPanel(params.navigateTo);
      else this.closePanel();
    }
  }

  private openPanel(viewId: string) {
    this.slidePanelState = SlidePanelState.OPENED;
    this.contextmenuService.updatePanelState(this.slidePanelState);
    this.activatedViewId = viewId;
    this.adjustHeaderNoteIcon(true);
    this.isEdit = viewId === 'note';
  }

  public closePanel() {
    this.adjustHeaderNoteIcon(false);
    this.slidePanelState = SlidePanelState.CLOSED;
    this.contextmenuService.updatePanelState(this.slidePanelState);
    this.activatedViewId = '';
    this.isEdit = false;
    this.attachmentLink = '';
  }

  //mobile: adjust the note icon at the top according the view
  public adjustHeaderNoteIcon(iconFilled: boolean) {
    if (!this.deviceDetector.isDesktop()) {
      this.createBookHeaderInstance().noteIcon = iconFilled;

      this.createBookHeaderInstance().notifyChanges();
    }
  }

  public panelOpened(panelWidth: number) {
    const epubViewElm = document.getElementById('book-content');
    const topBarItems = document.getElementById('topBarItems');
    const toolbarElm = document.getElementById('footerbarIdForSideBar');
    if (epubViewElm) {
      const deltaX = panelWidth / 2 + 40;

      this.renderer.setStyle(epubViewElm, '-webkit-transform', `translate3d(-${deltaX}px, 0, 0)`);
      this.renderer.setStyle(epubViewElm, 'transform', `translate3d(-${deltaX}px, 0, 0)`);
      this.renderer.setStyle(epubViewElm, 'transition', `300ms ease-in-out`);
      this.handleWindowResize();

      if (toolbarElm) {
        this.renderer.setStyle(toolbarElm, '-webkit-transform', `translate3d(-${deltaX}px, 0, 0)`);
        this.renderer.setStyle(toolbarElm, 'transform', `translate3d(-${deltaX}px, 0, 0)`);
        this.renderer.setStyle(toolbarElm, 'transition', `300ms ease-in-out`);
      }
      if (topBarItems) {
        this.renderer.setStyle(topBarItems, '-webkit-transform', `translate3d(-${deltaX}px, 0, 0)`);
        this.renderer.setStyle(topBarItems, 'transform', `translate3d(-${deltaX / 2}px, 0, 0)`);
        this.renderer.setStyle(topBarItems, 'transition', `300ms ease-in-out`);
      }
    }

    this.contextmenuService.toggleMenu('hide');
    this.handleSelection();
  }

  public panelClosed() {
    const epubViewElm = document.getElementById('book-content');
    const topBarItems = document.getElementById('topBarItems');
    const toolbarElm = document.getElementById('footerbarIdForSideBar');
    if (epubViewElm) {
      this.renderer.setStyle(epubViewElm, '-webkit-transform', `translate3d(0, 0, 0)`);
      this.renderer.setStyle(epubViewElm, 'transform', `translate3d(0, 0, 0)`);
      this.renderer.setStyle(epubViewElm, 'transition', `300ms ease-in-out`);
      this.handleWindowResize();

      if (toolbarElm) {
        this.renderer.setStyle(toolbarElm, '-webkit-transform', `translate3d(0, 0, 0)`);
        this.renderer.setStyle(toolbarElm, 'transform', `translate3d(0, 0, 0)`);
        this.renderer.setStyle(toolbarElm, 'transition', `300ms ease-in-out`);
      }
      if (topBarItems) {
        this.renderer.setStyle(topBarItems, '-webkit-transform', `translate3d(0, 0, 0)`);
        this.renderer.setStyle(topBarItems, 'transform', `translate3d(0, 0, 0)`);
        this.renderer.setStyle(topBarItems, 'transition', `300ms ease-in-out`);
      }
    }
  }

  public onNavigate() {
    this.tableOfContentsOpened = false;
    this.createBookHeaderInstance().activatedView = 'book';
  }

  public unloadVideos() {
    //This unloading fixes a flashing of content, where the text was removed during page swipe but the video placeholders remained.
    Array.prototype.forEach.call(document.querySelectorAll('app-video-placeholder'), function (node: any) {
      node.parentNode.removeChild(node);
    });
  }

  public prev = (): void => {
    this.currentPage = this.currentPage - 1;
    this.forceManualPageChange = true;
  }

  public next = (): void => {
    this.currentPage = this.currentPage + 1;
    this.forceManualPageChange = true;
  }

  private scrollUp = (): void => {
    this.rendering.scroll(-50);
  }

  private scrollDown = (): void => {
    this.rendering.scroll(50);
  }

  public onBookContentScrollEnd(): void {
    this.saveScrollPosition();
  }

  public zoomIn(): void {
    if (this.rendering.textZoomLevel.value + 1 <= this.rendering.DEFAULT_MAXZOOM) this.rendering.setZoom(this.rendering.textZoomLevel.value + 1);
  }
  public zoomOut(): void {
    if (this.rendering.textZoomLevel.value - 1 >= this.rendering.DEFAULT_MINZOOM) this.rendering.setZoom(this.rendering.textZoomLevel.value - 1);
  }

  private setNoteMarks() {
    const noteElms = document.querySelectorAll(`g[data-type="note"]`);
    if (noteElms.length == 0) {
      return;
    }
    noteElms.forEach((g) => {
      if (g.querySelectorAll(`image[data-type="anchor-note"]`).length > 0) {
        return;
      }
      const dataEpubcfi = g.getAttribute('data-epubcfi');
      if (dataEpubcfi) {
        const rect = this.rendering.getCfiPosition(dataEpubcfi);
        if (rect) {
          g.appendChild(this.noteService.createNoteMark(dataEpubcfi, rect));
          //this.ref.detectChanges();
        }
      }
    });
  }

  private handleSelection() {
    this.contextmenuService.isNewSelection = false;
    if (this.lastSelection && this.lastSelection.selectionObject) {
      this.lastSelection.selectionObject.removeAllRanges();
    }
  }

  /**
   * Fires 300ms after the 'currentPage' variable is changed.
   * 1. Renders the page if it wasn't already rendered. This is the case when the page is changed manually using the slider and buttons.
   * 2. Calls saveHistory.
   * 3. Makes API call to save last page in the DB.
   * @param {number} pageNumber
   */
  private onPageChange = (pageNumber: number): void => {
    if (this.rendering.currentPage !== pageNumber) {
      this.changePage(pageNumber);
    }
    this.saveHistory(pageNumber);
    this.lastPageService.saveLastPage(this.book.id.toString(), pageNumber.toString());
  }

  private async changePage(pageNumber: number): Promise<void> {
    await this.spinningService.showSpinner();

    this.scrollPosMap.delete(pageNumber);
    this.unloadVideos();
    this.contextmenuService.toggleMenu('hide');
    this.ref.detectChanges();

    this.rendering.displayCfi(pageNumber.toString());
    this.emitMenuChanges(true);
    this.setNoteMarks();
  }

  public toggleHighlighting(enabled: boolean) {
    this.isHighlightingEnabled = enabled;
    if (enabled) {
      this.rendering.changeSelectionColor(this.contextmenuService.color);
      this.rendering.attachCallback('selected', this.onTextSelect);
    } else {
      this.rendering.changeSelectionColor('highlight');
      this.rendering.detachCallback('selected', this.onTextSelect);
    }
  }
}
