import { ToastService } from './../../../services/toast/toast.service';
import { Network } from '@capacitor/network';
import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ThemeService } from '../../../services/themes/theme.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { ToastController } from '@ionic/angular';
import { ContextmenuService } from '../../services/contextmenu/contextmenu.service';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';

@Component({
  selector: 'app-mobile-menu-modal',
  templateUrl: './mobile-menu-modal.component.html',
  styleUrls: ['./mobile-menu-modal.component.scss'],
})
export class MobileMenuModalComponent implements OnInit, OnDestroy {
  open: boolean = false;
  showZoomLabel: boolean = false;
  inactiveBurger: boolean = false;
  previousPageButton: boolean = false;
  currentPage: number = 0;

  public inactiveBurgerEvent: Subject<void> = new Subject<void>();

  emitEventToChild($event: any) {
    this.inactiveBurgerEvent.next($event);
  }

  @Input() scrollingContent: Observable<void> = new Observable<void>();
  @Input() pageHistory: Observable<void> = new Observable<void>();
  @Input() emitClosedActiveMenu: Observable<void> = new Observable<void>();
  private eventsSubscription: Subscription = new Subscription();

  @Input() attachmentsExists: boolean = false;
  @Input() zoom: number = 0;
  @Output() zoomIn = new EventEmitter<boolean>();
  @Output() zoomOut = new EventEmitter<boolean>();
  @Input() bookCoverSrc: string | undefined;
  @Input() bookTitle: string | undefined;
  @Input() selectedChapter: any | undefined;

  @Output() changeScrollingEnabled = new EventEmitter<boolean>();
  @Output() navigateToPrevPageAndSwap = new EventEmitter<boolean>();
  @Output() openTableOfContent = new EventEmitter<boolean>();
  @Output() toBookLibrary = new EventEmitter<boolean>();

  @Output() openSearchView = new EventEmitter<boolean>();
  @Output() openNoteOverview = new EventEmitter<boolean>();
  @Output() openAttachmentOverview = new EventEmitter<boolean>();

  @ViewChild('menuModal', { static: false }) menuModal: ElementRef | undefined;

  @Input() isHighlightingEnabled: boolean = false;
  @Output('toggleHighlighting') toggleHighlightingEmitter = new EventEmitter<boolean>();
  
  @Input() collectionExists: boolean = false;
  @Output() openCollectionOverview = new EventEmitter<boolean>();
  @Output() openCommunicationOverview = new EventEmitter<boolean>();

  constructor(
    public sanitizer: DomSanitizer,
    public toastController: ToastController,
    public themeService: ThemeService,
    public toastService: ToastService,
    public contextMenuService: ContextmenuService,
    private translate: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngOnInit() {
    this.eventsSubscription.add(
      this.scrollingContent.subscribe((event: any) => {
        if (this.open) this.open = false;
        if (event.detail.deltaY < -100) {
          this.inactiveBurger = false;
        } else if (event.detail.deltaY > 100) {
          this.inactiveBurger = event.detail.deltaY > 100;
        }
        this.emitEventToChild(this.inactiveBurger);
      })
    );

    this.eventsSubscription.add(
      this.pageHistory.subscribe((pageHistory: any) => {
        this.previousPageButton = pageHistory.length >= 2;
      })
    );

    this.eventsSubscription.add(
      this.emitClosedActiveMenu.subscribe(() => {
        this.setMenuClosedActive();
      })
    );
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  prevPageAndSwap() {
    this.setMenuClosedActive();
    this.navigateToPrevPageAndSwap.emit();
    this.previousPageButton = false;
  }

  tableOfContentVisibility() {
    this.setMenuClosedActive();
    this.openTableOfContent.emit();
  }

  goToBookLibrary() {
    this.toBookLibrary.emit();
  }

  zooming(value: string) {
    if (value === 'minus') {
      this.zoomOut.emit();
    }
    if (value === 'plus') {
      this.zoomIn.emit();
    }
  }

  toggleSearchView() {
    this.setMenuClosedActive();
    this.openSearchView.emit();
  }

  toggleNoteOverview() {
    this.setMenuClosedActive();
    this.openNoteOverview.emit();
  }

  async toggleAttachmentsOverview() {
    const online = Network.getStatus();
    if (!(await online).connected) {
      this.toastService.showErrorMessage('Anhänge können nur mit aktiver Internetverbindung geöffnet werden!');
      return;
    }
    this.inactiveBurger = false;
    this.open = false;
    this.openAttachmentOverview.emit();
    // const toast = await this.toastController.create({
    //   message: 'Coming soon!',
    //   duration: 500
    // });
    // toast.present();
  }

  async toggleCommunicationOverview() {
    const online = Network.getStatus();
    if (!(await online).connected) {
      this.toastService.showErrorMessage(this.translate.translate('NetworkRequired'));
      return;
    }
    this.inactiveBurger = false;
    this.open = false;
    this.openCommunicationOverview.emit();
  }

  toggleCollectionOverview() {
    this.inactiveBurger = false;
    this.open = false;
    this.openCollectionOverview.emit();
  }

  setMenuClosedActive() {
    this.inactiveBurger = false;
    this.open = false;
  }

  changeMenuValue() {
    this.inactiveBurger = false;
    this.open = !this.open;
    this.changeScrollingEnabled.emit(false);
    setTimeout(() => {
      this.changeScrollingEnabled.emit(true);
    }, 2000);
    this.emitEventToChild(this.inactiveBurger);
  }

  changeZoomLabelValue() {
    this.showZoomLabel = !this.showZoomLabel;
  }

  getTrustHtml(selectedChapter: any) {
    return this.sanitizer.bypassSecurityTrustHtml(selectedChapter);
  }

  toggleHighlighting() {
    this.isHighlightingEnabled = !this.isHighlightingEnabled;
    this.toggleHighlightingEmitter.emit(this.isHighlightingEnabled);
  }
}
