import { modules } from './../../PODO/modules';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ISyncService, Service } from '../rest-client/rest-client.service';
import { environment } from '../../../environments/environment';
import themes from '../../../environments/configs.json';
import { DatabaseService } from '../localDatabase/database-service.service';
import { Capacitor } from '@capacitor/core';
import { StorageService } from '../storage/storage.service';
import {
  COLOR_KEYS,
  EXCLUDED_ICON_KEYS,
  FONT_KEYS,
  GENERAL_COLOR_KEYS,
  IMAGE_KEYS,
  TEXT_KEYS,
} from './constants/theme-constants';
import { COLOR_OBJECT_DATA } from './constants/color_constants';
import { Network } from '@capacitor/network';
import { ICON_OBJECT_DATA } from './constants/icon_constants';
import { ReplaySubject, firstValueFrom } from 'rxjs';

const WEB_READER_DOMAIN = 'webreader';

const WEB_READER_URL = 'https://webreader.litello.com';

const STAGING_WEB_READER = 'test-webreader';

const EN_PREFIX = 'en-';

const LOCALE_KEY = 'locale';

const DEFAULT_TOOLBAR_LOGO_URL = '/assets/icon/lit-header-logo.png';
const DEFAULT_FOOTER_LOGO_URL = '/assets/icon/lit-footer-logo.png';
const DEFAULT_DASHBOARD_HEADER_LOGO_URL = '/assets/icon/lit-logo.png';

interface ThemeKeyObject {
  tableName: string;
  values: Array<string>;
  excludedKeys: Array<string>;
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {

  public isInitialized: ReplaySubject<boolean> = new ReplaySubject(1);
  
  logo = '';
  customerName: string = environment.defaultTheme.name;
  private customer: any;
  private icons: any;
  private dataStorage: ISyncService;
  public modul: modules = {
    resetPassword: 1,
    login: 1,
    registration: 1,
    dashboard: 1,
    profile: 1,
    aboutLitello: 1,
    credits: 1,
    legalNote: 1,
    visitShop: 1,
    DoubleIcons: 0,
    SSOLogin: 0,
    changePassword: 1,
    contactSupport: 1,
    themeSwitcher: 0,
  };

  private themeKeysObject: Array<ThemeKeyObject> = [
    {
      tableName: 'fonts',
      values: [],
      excludedKeys: [],
    },
    {
      tableName: 'color_palettes',
      values: [],
      excludedKeys: ['id'],
    },
    {
      tableName: 'icons',
      values: [],
      excludedKeys: ['login_logo', 'id', 'login_bg', 'dashboard_img'],
    },
  ];

  public toolbarLogoUrl: string | SafeUrl = this.getToolbarLogo();
  public footerLogoUrl: string | SafeUrl = this.getFooterLogo();
  public dashboardHeaderLogoUrl: SafeUrl | undefined = this.getDashboardHeaderLogo();
  public dashboardHeaderLogoUrl2: SafeUrl | undefined = this.getDashboardHeaderLogo2();
  public dashboardHeaderLogoUrl3: SafeUrl | undefined = this.getDashboardHeaderLogo3();
  public dashboardHeadline: string | null = this.getDashboardHeadline();
  public dashboardIntroText: string | null = this.getDashboardIntroText();
  public ebookHeadline: string | null = this.getEbookHeadline();
  public introImage: SafeUrl | undefined = this.getIntroImage();

  constructor(
    private restService: Service,
    private sanitizer: DomSanitizer,
    private dataBaseService: DatabaseService,
    private storageService: StorageService
  ) {
    this.dataStorage = Capacitor.isNativePlatform() ? this.dataBaseService : this.restService;
  }

  public isCustomer(customer: string) {
    return customer in themes;
  }

  injectTheme(data: any) {
    this.injectColors(data.colorpalette);
    this.injectIcons(data.icons);
    this.injectImages(data.icons);
    this.changeTitle(data.customer.title);
    this.injectFonts([data.font1, data.font2, data.font3, data.font4, data.font5, data.font6]);
    this.injectTextFonts(data.fonts);
    this.injectText(data.customer);
    this.setCustomer(data.customer);
    this.setIcons(data.icons);
  }

  public loadThemebyCustomerName(customer: any) {
    // only for Theme Switcher!
    if (!Capacitor.isNativePlatform()) {
      this.restService.getThemes(customer).subscribe(
        (data) => {
          this.modul = data.modules;
          this.injectTheme(data);
        },
        (error) => {
          console.log('Theme not exists for customer', customer);
          //window.location.href = this.getWebReaderUrl();
        }
      );
    }
  }

  public async loadTheme() {
    const status = await Network.getStatus();
    const isFirstTimeRun: boolean = await this.storageService
      .getPreferences('is_first_time_run')
      .then((res) => res === 'true');

    let url = window.location.href;
    this.customerName = this.extractCustomerNameFromUrl(url);

    // WEB Reader
    if (!Capacitor.isNativePlatform()) {
      this.restService.getThemes(this.customerName).subscribe(
        (data) => {
          this.modul = data.modules;
          this.injectTheme(data);
          this.isInitialized.next(true);
        },
        (error) => {
          console.log('Theme not exists for customer', this.customerName);
          this.isInitialized.next(true);
          //window.location.href = this.getWebReaderUrl();
        }
      );
    } else {
      let remoteTimestamp: number | null = null;
      if (status.connected) {
        try {
          remoteTimestamp = (await firstValueFrom(this.restService.getThemeSyncdate(this.customerName))).updated_at;
          this.dataBaseService.saveLastUpdateDate(remoteTimestamp, this.customerName)
        } catch (error) {
          console.error(error);
        }
      }

      const iconsTableFilled: boolean = await this.dataBaseService.hasTableAndRows('icons');
      const colorTableFilled: boolean = await this.dataBaseService.hasTableAndRows('color_palettes');
      const fontsTableFilled: boolean = await this.dataBaseService.hasTableAndRows('fonts');
      const fontsCatTableFilled: boolean = await this.dataBaseService.hasTableAndRows('fonts_category');
      const customerTableFilled: boolean = await this.dataBaseService.hasTableAndRows('customers');

      const isLocalThemeFilled: boolean =
        iconsTableFilled && colorTableFilled && fontsCatTableFilled && fontsTableFilled && customerTableFilled;

      const shouldSync: boolean | Promise<boolean> = isFirstTimeRun || !isLocalThemeFilled || (await this.RemoteDataChanged(remoteTimestamp));

      const shouldGetRemoteData: boolean = shouldSync && status.connected;
      const shouldGetLocalData: boolean = !shouldSync;

      if (shouldGetRemoteData) {
        this.restService.getThemes(this.customerName).subscribe(
          (data) => {
            let { icons, colorpalette, customer, fonts, ...fontCatObj } = data;
            let fontCatList = [];
            let customerAttrList: any = [];
            let fontsAttrList: any = [];
            let colorPaletteAttrList: any = [];
            let iconsList: any = [];
            for (let item in icons) {
              iconsList.push(icons[item]);
            }

            for (let item in data.colorpalette) {
              colorPaletteAttrList.push({
                key: Object.keys(data.colorpalette).find((key) => data.colorpalette[key] === data.colorpalette[item]),
                value: data.colorpalette[item],
              });
            }

            for (let item in fonts) {
              fontsAttrList.push(fonts[item]);
            }

            for (let item in customer) {
              customerAttrList.push(customer[item]);
            }

            for (let item in fontCatObj) {
              let newPair = { category_name: item.toString() };
              fontCatObj[item] = { ...fontCatObj[item], ...newPair };
              fontCatList.push(fontCatObj[item]);
            }

            this.dataBaseService
              .saveThemeOnLocalDb(
                fontCatList as [],
                iconsList as [],
                customerAttrList as [],
                fontsAttrList as [],
                colorPaletteAttrList as []
              )
              .then(() => {
                // Get the keys of the tables
                this.themeKeysObject.forEach((tableKeys: ThemeKeyObject) => {
                  this.dataBaseService.getAttr(tableKeys.tableName).then((res: any) => {
                    for (let i = 0; i < res.rows.length; i++) {
                      tableKeys.values.push(res.rows.item(i)['name'].toString());
                    }
                    tableKeys.values = tableKeys.values.filter(function (el: any) {
                      return !tableKeys.excludedKeys.includes(el);
                    });
                  });
                });
              })
              .then(() => {
                this.injectTheme(data);
                this.isInitialized.next(true);
              })
              .then(() => {
                this.storageService.savePreferences('is_first_time_run', 'false');
              });
          },
          (error) => {
            console.log('Theme not exists for customer!', this.customerName);
            this.useDefaultThemeData();
          }
        );
      } else if (shouldGetLocalData) {
        await this.dataStorage.getThemes('').subscribe((data) => {
          this.injectTheme(data);
          this.isInitialized.next(true);
          this.storageService.savePreferences('is_first_time_run', 'false');
        },
        (error) => {
          this.useDefaultThemeData();
        });
      } else {
        this.useDefaultThemeData();
      }
    }
  }

  private useDefaultThemeData() {
    this.injectColors(COLOR_OBJECT_DATA);
    this.injectImages(ICON_OBJECT_DATA);
    this.isInitialized.next(true);
  }

  private async RemoteDataChanged(remoteT: any) {
    if(!remoteT) return false;

    const localT = await this.dataBaseService.getLastUpdateDateByCustomer(this.customerName);
    return remoteT > localT;
  }

  private static getPropertyValue(keyDE: string, keyEN: string) {
    const locale = localStorage.getItem(LOCALE_KEY);
    if (locale && locale.startsWith(EN_PREFIX)) {
      return localStorage.getItem(keyEN);
    } else {
      return localStorage.getItem(keyDE);
    }
  }

  public getLoginLogo() {
    let loginLogo = localStorage.getItem('--login-logo');
    if (loginLogo) {
      return this.sanitizer.bypassSecurityTrustUrl(loginLogo);
    } else {
      return '/assets/icon/litelloLogo.svg';
    }
  }

  public getLoginLogo2() {
    let loginLogo = localStorage.getItem('--login-logo2');
    if (loginLogo) {
      return this.sanitizer.bypassSecurityTrustUrl(loginLogo);
    } else {
      return '/assets/icon/litelloLogo.svg';
    }
  }

  public getLoginLogo3() {
    let loginLogo = localStorage.getItem('--login-logo3');
    if (loginLogo) {
      return this.sanitizer.bypassSecurityTrustUrl(loginLogo);
    } else {
      return '/assets/icon/litelloLogo.svg';
    }
  }

  public getLoginTextLine1() {
    return ThemeService.getPropertyValue('--login-text-first-line-de', '--login-text-first-line-en');
  }
  public getLoginTextLine2() {
    let text = ThemeService.getPropertyValue('--login-text-second-line-de', '--login-text-second-line-en');
    if (text && text.split('?').length > 1)
      return { text1: text.slice(0, text.indexOf('?')) + '?', text2: text.slice(text.indexOf('?') + 1) };
    else return { text1: '', text2: text ? text : '' };
  }

  private getToolbarLogo(): SafeUrl | string {
    let headerTopRightLogo = localStorage.getItem('--dashboard-top-right-logo');
    if (headerTopRightLogo) {
      return this.sanitizer.bypassSecurityTrustUrl(headerTopRightLogo);
    } else {
      return DEFAULT_TOOLBAR_LOGO_URL;
    }
  }

  public getToolbarDefaultLogo(): string {
    return DEFAULT_TOOLBAR_LOGO_URL;
  }

  // public getDashboardBackgroundImage() {
  //   let dashboardBackgroundImage = localStorage.getItem('--dashboard-header-background-image');
  //   if (dashboardBackgroundImage) {
  //     return this.sanitizer.bypassSecurityTrustUrl(dashboardBackgroundImage);
  //   }
  // }

  // public getNewDashboardLogo() {
  //   let dashboardLogo = localStorage.getItem('--new-dashboard-logo');
  //   if (dashboardLogo) {
  //     return this.sanitizer.bypassSecurityTrustUrl(dashboardLogo);
  //   }
  // }

  public getDashboardTopText() {
    return ThemeService.getPropertyValue('--dashboard-top-text-right-de', '--dashboard-top-text-right-en');
  }

  private getDashboardHeaderLogo(): SafeUrl | string {
    let headerLogo = localStorage.getItem('--dashboard-header-logo');
    if (headerLogo) {
      return this.sanitizer.bypassSecurityTrustUrl(headerLogo);
    } else {
      return DEFAULT_DASHBOARD_HEADER_LOGO_URL;
    }
  }

  private getDashboardHeaderLogo2() {
    let headerLogo = localStorage.getItem('--dashboard-header-logo2');
    if (headerLogo) {
      return this.sanitizer.bypassSecurityTrustUrl(headerLogo);
    } else {
      return '/assets/icon/lit-logo.png';
    }
  }

  private getDashboardHeaderLogo3() {
    let headerLogo = localStorage.getItem('--dashboard-header-logo3');
    if (headerLogo) {
      return this.sanitizer.bypassSecurityTrustUrl(headerLogo);
    } else {
      return '/assets/icon/lit-logo.png';
    }
  }

  private getDashboardHeadline() {
    return ThemeService.getPropertyValue('--dashboard-headline-text-de', '--dashboard-headline-text-en');
  }

  private getDashboardIntroText() {
    return ThemeService.getPropertyValue('--dashboard-intro-text-de', '--dashboard-intro-text-en');
  }

  private getIntroImage() {
    let introImage = localStorage.getItem('--dashboard-intro-image');
    if (introImage) {
      return this.sanitizer.bypassSecurityTrustUrl(introImage);
    }
  }

  private getEbookHeadline() {
    return ThemeService.getPropertyValue('--dashboard-eBook-headline-text-de', '--dashboard-eBook-headline-text-en');
  }

  private getFooterLogo() {
    let footerIcon = localStorage.getItem('--dashboard-footer-icon');
    if (footerIcon) {
      return this.sanitizer.bypassSecurityTrustUrl(footerIcon);
    } else {
      return DEFAULT_FOOTER_LOGO_URL;
    }
  }

  public getFooterDefaultLogo() {
    return DEFAULT_FOOTER_LOGO_URL;
  }

  public getFooterText() {
    return ThemeService.getPropertyValue('--dashboard-footer-text-de', '--dashboard-footer-text-en');
  }

  public getLogo() {
    return document.documentElement.style.getPropertyValue('--logo');
  }

  public setCustomer(customer: any) {
    this.customer = customer;
  }

  public setIcons(icons: any) {
    this.icons = icons;
  }

  public getWebReaderUrl() {
    return WEB_READER_URL;
  }

  // IMPORTANT
  public injectColors(colorPalette: any) {
    if (colorPalette) {
      COLOR_KEYS.forEach((colorKey) => {
        let color = colorPalette[colorKey];
        if (color) {
          let propertyKey = colorKey;
          document.documentElement.style.setProperty(propertyKey, color);
        }
      });
      this.injectGeneralColors(colorPalette);
    }
  }

  public injectGeneralColors(colorPalette: any) {
    GENERAL_COLOR_KEYS.forEach((colorKey) => {
      let tmpKey = colorKey.replace('-', '_');
      let color = colorPalette[tmpKey];
      if (color) {
        let propertyKey = '--ion-color-' + colorKey;
        document.documentElement.style.setProperty(propertyKey, color);
      }
    });
  }

  public injectImages(images: any) {
    if (images) {
      IMAGE_KEYS.filter((elem) => !EXCLUDED_ICON_KEYS.includes(elem)).forEach((imageKey) => {
        let image = images[imageKey];

        if (image) {
          let propertyKey = '--' + imageKey;
          if (
            imageKey === 'dashboard-intro-image' ||
            imageKey === 'dashboard-header-logo' ||
            imageKey === 'dashboard-footer-icon' ||
            imageKey === 'dashboard-top-right-logo' ||
            imageKey === 'login-logo' ||
            imageKey === 'login-logo2' ||
            imageKey === 'login-logo3' ||
            imageKey === 'dashboard-header-logo2' ||
            imageKey === 'dashboard-header-logo3' ||
            imageKey === 'dashboard-header-background-image'
          ) {
            localStorage.getItem(propertyKey);
            localStorage.setItem(propertyKey, image);
          } else {
            document.documentElement.style.setProperty(propertyKey, image);
          }
        }
      });
    }
  }

  public injectText(textCollection: any) {
    if (textCollection) {
      TEXT_KEYS.forEach((textKey) => {
        let text = textCollection[textKey];
        if (text) {
          let propertyKey = '--' + textKey;
          localStorage.setItem(propertyKey, text);
        }
      });
    }
  }

  public injectTextFonts(fonts: any) {
    if (fonts) {
      FONT_KEYS.forEach((fontKey) => {
        let font = fonts[fontKey];
        if (font) {
          let propertyKey = '--' + fontKey;
          document.documentElement.style.setProperty(propertyKey, font);
        }
      });
    }
  }

  public injectIcons(icons: any) {
    if (icons && icons.logo) {
      let link = document.querySelector("link[rel*='icon']");
      // @ts-ignore
      link = icons.logo;
    }
  }

  public changeTitle(title: any) {
    document.title = title;
  }

  public injectFonts(fonts: any[]) {  
    ThemeService.createFonts(fonts);  
    fonts.forEach((font, index) => {  
      document.documentElement.style.setProperty(`--ion-font-family${index}`, font.family);  
    });  
  } 

  // This is a bad solution and should be replaced with a better one
  // The styles and weights are hardcoded and should be dynamic
  // That may require a change in the backend
  private static createFonts(fonts: any[]) {
    const fontStyles = `
        <style>
            @font-face {
              font-family: '${fonts[0].family}';
              src: url('${fonts[0].src}');
              font-style:   normal;
              font-weight:  700;
            }
            
            @font-face {
              font-family: '${fonts[1].family}';
              src: url('${fonts[1].src}');
              font-style:   italic;
              font-weight:  400;
            }
            
            @font-face {
              font-family: '${fonts[2].family}';
              src: url('${fonts[2].src}');
              font-style:   normal;
              font-weight:  400;
            }
            
            @font-face {
              font-family: '${fonts[3].family}';
              src: url('${fonts[3].src}');
              font-style:   normal;
              font-weight:  400;
            }
            
            @font-face {
              font-family: '${fonts[4].family}';
              src: url('${fonts[4].src}');
              font-style:   normal;
              font-weight:  700;
            }
            
            @font-face {
              font-family: '${fonts[5].family}';
              src: url('${fonts[5].src}');
              font-style:   normal;
              font-weight:  400;
            }
        </style>
        `;
    const head = document.querySelector('head');
    if (head) {
      head.innerHTML = head.innerHTML + '\n' + fontStyles;
    }
  }

  public getSideBarLogo1() {
    if (this.icons && this.icons.logo) {
      return this.icons.logo;
    } else {
      return '/assets/icon/bookIconYellow.svg'; // default
    }
  }

  public getSideBarLogo2() {
    if (this.icons && this.icons.logo) {
      return this.icons.logo2;
    } else {
      return '/assets/icon/bookIconYellow.svg'; // default
    }
  }

  private static isIPAddress(url: string) {
    return RegExp('^(([1-9]?\\d|1\\d\\d|2[0-4]\\d|25[0-5])(\\.(?!$)|(?=$))){4}$').test(url);
  }

  public isAllowed(email: string) {
    let url = window.location.href;
    let customerName = this.extractCustomerNameFromUrl(url);

    if (!environment.production || customerName === environment.defaultTheme.name) return true;
    if (this.customer && customerName === environment.defaultTheme.name) {
      return true;
    }
    if (this.customer && customerName === this.customer.sub_domain_name) {
      if (ThemeService.temporaryAllowedCheck(email)) {
        return true;
      }
      let email_domain = this.customer.email_domain_name.split(',');
      for (let domain of email_domain) {
        if (email.endsWith(domain)) {
          return true;
        }
      }
    }
    return false;
  }

  // TODO: This is very bloated and needs to be refactored
  // This seems to be a perfect candidate for a regex
  public extractCustomerNameFromUrl(url: string) {
    let https = 'https://';
    let http = 'http://';
    let wwwPart = 'www';
    let trimmedUrl = url;
    if (url.startsWith(http)) {
      trimmedUrl = url.substring(http.length);
    } else if (url.startsWith(https)) {
      trimmedUrl = url.substring(https.length);
    }
    let urlParts = trimmedUrl.split('.');
    if (urlParts && urlParts.length > 2 && urlParts[0] === wwwPart) {
      return urlParts[1];
    } else if (urlParts && urlParts.length > 2) {
      let ip = trimmedUrl.slice(0, trimmedUrl.indexOf(':')) || trimmedUrl.slice(0, trimmedUrl.indexOf('/'));
      if (ip && !ThemeService.isIPAddress(ip)) {
        let subDomain = urlParts[0];
        if (
          subDomain.toLowerCase() === WEB_READER_DOMAIN.toLowerCase() ||
          subDomain.toLowerCase() === STAGING_WEB_READER.toLowerCase()
        ) {
          return environment.defaultTheme.name;
        }
        return urlParts[0];
      }
    }

    return environment.defaultTheme.name;
  }

  private static temporaryAllowedCheck(email: string) {
    let allowedMails: Array<string> = environment.allowedUsers;
    for (let e of allowedMails) {
      if (e.toLowerCase() === email.toLowerCase()) {
        return true;
      }
    }
  }
}
