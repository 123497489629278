import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[litello-focus]'
})
export class FocusDirective implements AfterViewInit {
    private focus: boolean = false;
    
    @Input('litello-focus') public set autofocus(value: boolean) {
        this.focus = value;
        if(this.focus) this.focusElement();
    }
    
    constructor(private host: ElementRef) {}

    ngAfterViewInit(): void {
        if(this.focus) this.focusElement();
    }

    private focusElement(): void {
        this.host.nativeElement.focus();
    }
}