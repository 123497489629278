<ion-header class="ion-no-border create-note-header" #header>
  <div class="edit-note-header">
    <div id="create-note-title" class="title">{{ title }}</div>
    <div class="colors">
      <ion-button
        id="create-note-changeHighlightPrimary-btn"
        slot="start"
        (click)="toggleColor('--book-contextMenu-color1')"
        class="color-button primary"
      >
        <ion-icon *ngIf="selectedColorPropertyName === '--book-contextMenu-color1'" class="color" src="/assets/icon/checkmark-outline.svg">
        </ion-icon>
      </ion-button>
      <ion-button
        id="create-note-changeHighlightGreen-btn"
        slot="start"
        (click)="toggleColor('--book-contextMenu-color2')"
        class="color-button green"
      >
        <ion-icon *ngIf="selectedColorPropertyName === '--book-contextMenu-color2'" class="color" src="/assets/icon/checkmark-outline.svg"> </ion-icon>
      </ion-button>
      <ion-button
        id="create-note-changeHighlightBlue-btn"
        slot="start"
        (click)="toggleColor('--book-contextMenu-color3')"
        class="color-button blue"
      >
        <ion-icon *ngIf="selectedColorPropertyName === '--book-contextMenu-color3'" class="color" src="/assets/icon/checkmark-outline.svg"></ion-icon>
      </ion-button>
      <ion-button
        id="create-note-changeHighlightPurple-btn"
        slot="start"
        (click)="toggleColor('--book-contextMenu-color4')"
        class="color-button purple"
      >
        <ion-icon *ngIf="selectedColorPropertyName === '--book-contextMenu-color4'" class="color" src="/assets/icon/checkmark-outline.svg"> </ion-icon>
      </ion-button>
    </div>
    <div class="divider"></div>
  </div>
</ion-header>

<ion-content
  class="create-note-content"
  [ngStyle]="{ height: scrollUnlocked ? '100%' : 'calc(100% - var(--ion-safe-area-bottom, 0) - 13rem)' }"
  scrollEvents="{{ scrollUnlocked }}"
  appHideHeader
  [header]="header"
>
  <div class="note-info">
    <div class="note-page-number">
      <span l10nTranslate>NoteOverview.PageAbbreviation</span>
      <span class=""> {{ pageNumber }}</span>
    </div>
    <div class="note-page-timestamp">
      {{ timestamp }}
    </div>
  </div>

  <div class="note-selected-text">
    <p [ngClass]="selectedColor">{{ text }}</p>
  </div>

  <div class="note-user-note">
    <ion-textarea
      placeholder="{{ notePlaceholder }}"
      [(ngModel)]="notetext"
      l10nTranslate
      (ionChange)="this.onNoteChange()"
      mode="md"
    >
    </ion-textarea>
  </div>
</ion-content>

<ion-footer class="ion-no-border">
  <div class="note-buttons">
    <ion-button class="note-button-cancel" (click)="exitEditor()" l10nTranslate>Dialog.Close</ion-button>
  </div>
</ion-footer>
<div id="append-modal"></div>
