import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { None, Option } from 'space-lift';
import { EPUB_AREA_ID } from '../../book/book.component';
import { SlidePanelState } from '../../components/slide-panel/slide-panel.component';

@Injectable()
export class ContextmenuService {
  readonly maxwidth = 600;
  readonly positiveIndex = 1;
  private _contextmenuElement: Option<HTMLElement> = None;
  private _isTop: boolean = true;
  private _isNewSelection: boolean = false;
  private _text: string = '';
  private _cfiRange: string = '';
  private _color: string = getComputedStyle(document.documentElement).getPropertyValue('--book-contextMenu-color1');
  public color$: ReplaySubject<string> = new ReplaySubject();
  private _notetext: string = '';
  private _page: number = 0;
  //             this is a so-called type guard
  private _timestamp: number | undefined;
  private _isDismiss: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _slidePanelState: SlidePanelState = SlidePanelState.CLOSED;
  private newTop2: number = 0;

  constructor(private plt: Platform) {}

  public updatePanelState(state: SlidePanelState) {
    this._slidePanelState = state;
  }

  private isPanelOpened = () => this._slidePanelState === SlidePanelState.OPENED;

  public toggleMenu(command: 'hide' | 'show'): void {
    if (this._contextmenuElement.isDefined()) {
      if (command == 'hide') {
        this._contextmenuElement.get().style.display = 'none';
        this._contextmenuElement.get().classList.remove('is-bottom-fixed');
        this._contextmenuElement.get().classList.remove('is-top-fixed');
        this._isDismiss.next(false);
      } else {
        this._contextmenuElement.get().style.display = 'block';
        this._isDismiss.next(true);
      }
    }
  }

  //TODO (Eugen): Magic numbers entfernen
  //TODO (Eugen): Position des Contextmenues je nach sichtbaren Inhalt anpassen
  //Die Klassen is-bottom und is-top können nicht mit Hilfe von Angular Class Binding hinzugeüfgt werden, da sonst ein Fehler beim Wechsel der Klassen entsteht
  public async displayContextMenu(
    text: string,
    mobileDevice: boolean,
    rects: DOMRectList,
    cfiRangeAsString: string
  ) {
    if(!rects?.length) return;

    this.cfiRange = cfiRangeAsString;
    this.text = text;
    this._contextmenuElement = Option(document.getElementById('contextmenu'));
    if (this._contextmenuElement.isDefined()) {
      const left = rects[0].left;
      const top = rects[0].top;
      const right = rects[rects.length - this.positiveIndex].right;
      const bottom = rects[rects.length - this.positiveIndex].bottom;

      const contextmenuWidth = 318;
      const contextmenuHeight = 51;
      const contextmenuArrow = contextmenuWidth / 4;
      const epubContainer = document.querySelector('.epub-container')!;
      const ionContent: HTMLIonContentElement | null = document.querySelector(`#${EPUB_AREA_ID}`);

      const translateToLeft = this.isPanelOpened() ? -300 : 0;
      epubContainer.appendChild(document.getElementById('contextmenu')!);
      if (epubContainer && ionContent) {
        this._contextmenuElement.get().classList.remove('is-bottom');
        this._contextmenuElement.get().classList.remove('is-top');
        this._contextmenuElement.get().classList.remove('is-bottom-right');
        this._contextmenuElement.get().classList.remove('is-top-middle');
        this._contextmenuElement.get().classList.remove('is-bottom-middle');
        this._contextmenuElement.get().classList.remove('is-top-right');
        this._contextmenuElement.get().classList.remove('is-bottom-fixed');
        this._contextmenuElement.get().classList.remove('is-top-fixed');
        let scrollTop: number = 0;

        await ionContent.getScrollElement().then((res: HTMLElement) => (scrollTop = res.scrollTop));
        let horNew = left;
        this.newTop2 = top;
        //add context menu below selection
        if (top - scrollTop < 60) {
          this._isTop = false;

          //Unter der Markierung ist genug Platz für das Contextmenue
          if (ionContent.clientHeight > contextmenuHeight + bottom) {
            this._contextmenuElement.get().style.top = `${this.newTop2 + 30}px`;
            if (left > 222 && !(left > contextmenuWidth) && left < contextmenuWidth) {
              let b = bottom + 8;
              let r = right - 190;
              this._contextmenuElement.get().style.top = `${b}px`;
              this._contextmenuElement.get().classList.add('is-bottom-middle');
              this._contextmenuElement.get().style.left = `${r}px`;
            } else if (left > contextmenuWidth) {
              if (epubContainer.clientWidth - right < contextmenuWidth) {
                let b = bottom + 8;
                let r = right - 315;
                this._contextmenuElement.get().style.top = `${b}px`;
                this._contextmenuElement.get().classList.add('is-top-right');
                this._contextmenuElement.get().style.left = `${r}px`;
              } else {
                let b = bottom + 8;
                let r = right - 27;
                this._contextmenuElement.get().style.top = `${b}px`;
                this._contextmenuElement.get().style.left = `${r}px`;
                this._contextmenuElement.get().classList.add('is-bottom');
              }
            } else {
              let b = bottom + 8;
              let r = right - 300;
              this._contextmenuElement.get().style.top = `${b}px`;
              this._contextmenuElement.get().classList.add('is-top-right');
              this._contextmenuElement.get().style.left = `${r}px`;
            }

            //Unter der Markierung ist nicht genug Platz für das Contextmenue und muss in die Markierung gelegt werden
          } else {
            let newTop = bottom - scrollTop - 76;

            //catch special case, sometimes context menu outside screen displayed

            this._contextmenuElement.get().style.top = `${bottom}px`;
            if (epubContainer.clientWidth > contextmenuWidth + right + 16) {
              let r = right - 25;
              this._contextmenuElement.get().style.left = `${r}px`;
              this._contextmenuElement.get().classList.add('is-bottom');
            } else {
              if (epubContainer.clientWidth - right < contextmenuWidth) {
                let b = bottom + 8;
                let r = right - 200;
                this._contextmenuElement.get().style.top = `${b}px`;
                this._contextmenuElement.get().classList.add('is-bottom-middle');
                this._contextmenuElement.get().style.left = `${r}px`;
              } else {
                let r = right - 20;
                this._contextmenuElement.get().style.left = `${r}px`;
                this._contextmenuElement.get().classList.add('is-bottom');
              }
            }
          }

          //Ueber der Markierung ist genug Platz für das Contextmenue
        } else {
          const newTop = top - scrollTop;
          this._isTop = true;
          this._contextmenuElement.get().style.top = `${this.newTop2 - 50}px`;
          if (left > 222 && contextmenuWidth < 650 && !(left > contextmenuWidth) && left < contextmenuWidth) {
            let l = horNew - 160;
            this._contextmenuElement.get().classList.add('is-top-middle');
            this._contextmenuElement.get().style.left = `${l}px`;
          } else if (left > contextmenuWidth) {
            let l = horNew - 292;
            this._contextmenuElement.get().classList.add('is-bottom-right');
            this._contextmenuElement.get().style.left = `${l}px`;
          } else {
            let h = horNew - 25;
            this._contextmenuElement.get().classList.add('is-top');
            this._contextmenuElement.get().style.left = `${h}px`;
          }
        }

        if (mobileDevice && window.innerWidth < this.maxwidth) {
          this._contextmenuElement.get().classList.remove('is-bottom');
          this._contextmenuElement.get().classList.remove('is-top');
          this._contextmenuElement.get().classList.remove('is-bottom-right');
          this._contextmenuElement.get().classList.remove('is-top-middle');
          this._contextmenuElement.get().classList.remove('is-bottom-middle');
          this._contextmenuElement.get().classList.remove('is-top-right');
          this._contextmenuElement.get().classList.remove('is-bottom-fixed');
          this._contextmenuElement.get().classList.remove('is-top-fixed');
          if (top - scrollTop < 60) {
            this._contextmenuElement.get().style.top = `${bottom}px`;
            this._contextmenuElement.get().style.left = `${epubContainer.clientWidth / 2 - 170}px`;
          } else {
            this._contextmenuElement.get().style.top = `${this.newTop2 - 45}px`;
            this._contextmenuElement.get().style.left = `${epubContainer.clientWidth / 2 - 170}px`;
          }
        }

        this.toggleMenu('show');
      }
    }
  }

  get page(): number {
    return this._page;
  }

  set page(page) {
    this._page = page;
  }

  get color(): string {
    return this._color;
  }

  set color(color: string) {
    this._color = color;
    this.color$.next(color);
  }

  get text(): string {
    return this._text;
  }

  set text(text: string) {
    this._text = text;
  }

  get cfiRange(): string {
    return this._cfiRange;
  }

  set cfiRange(cfiRange: string) {
    this._cfiRange = cfiRange;
  }

  get isTop(): boolean {
    return this._isTop;
  }

  set isTop(isTop: boolean) {
    this._isTop = isTop;
  }

  get notetext(): string {
    return this._notetext;
  }

  set notetext(notetext: string) {
    this._notetext = notetext;
  }

  get timestamp(): number | undefined {
    return this._timestamp;
  }

  set timestamp(timestamp: number | undefined) {
    this._timestamp = timestamp;
  }

  get isNewSelection(): boolean {
    return this._isNewSelection;
  }

  public isOpened(): boolean {
    return this._isDismiss.value;
  }

  set isNewSelection(isNewSelection: boolean) {
    this._isNewSelection = isNewSelection;
  }

  get isDismiss(): BehaviorSubject<boolean> {
    return this._isDismiss;
  }

  public updateDismiss(isDismiss: boolean) {
    this._isDismiss.next(isDismiss);
  }
}
