import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  public async savePreferences(k: string, v: string) {
    await Storage.set({
      key: k,
      value: v,
    });
  }

  public async getPreferences(k: string) {
    const { value } = await Storage.get({ key: k });
    return value;
  }

  public async removePreferences(k: string) {
    await Storage.remove({ key: k });
  }
}
