import { Component, Inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Network } from '@capacitor/network';
import { ModalController, PopoverController } from '@ionic/angular';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { environment } from '../../../environments/environment';
import { Profile, Service } from '../../services/rest-client/rest-client.service';
import { S3Service } from '../../services/rest-client/s3.service';
import { SpinnerService } from '../../services/spinner/spinner.service';
import { ToastService } from '../../services/toast/toast.service';
import { UserService } from '../../services/user/user.service';
import { DeviceDetectorService } from '../../util/device-detector/device-detector.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { ThemeService } from './../../services/themes/theme.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  public profile: Profile | undefined;
  @Input() public isEditMode: boolean = false;
  public formGroup: UntypedFormGroup | undefined;
  imgSrc: string | null = `${environment.USER_ASSETS_URL}/avatar/${this.userService.getUserID()}.png`;
  public online: boolean = true;
  public avatarExists: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: UntypedFormBuilder,
    public deviceDetector: DeviceDetectorService,
    private restService: Service,
    private userService: UserService,
    private spinnerService: SpinnerService,
    public themeService: ThemeService,
    private toast: ToastService,
    private popover: PopoverController,
    private s3: S3Service,
    public translationService: L10nTranslationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {
    Network.addListener('networkStatusChange', (status) => {
      this.online = status.connected;
      this.ngOnInit();
    });
  }

  async ngOnInit() {
    this.online = (await Network.getStatus()).connected;
    if (this.online) {
      await this.spinnerService.showSpinner();
      this.loadProfile();
    }
  }

  private loadProfile() {
    const userID = this.userService.getUserID();
    if (userID) {
      this.restService.getProfile(userID).subscribe(
        (profile) => {
          this.profile = profile;
          this.prepareForm();
          this.spinnerService.hideSpinner();
        },
        (error) => {
          console.error(error);
          this.spinnerService.hideSpinner();
        }
      );
    }
  }

  public prepareForm() {
    if (this.profile) {
      this.formGroup = this.formBuilder.group({
        firstname: [this.profile.firstname, Validators.compose([Validators.required])],
        lastname: [this.profile.lastname, Validators.compose([Validators.required])],
        nickname: [this.profile.nickname, Validators.compose([Validators.required])],
        email: [this.profile.email, Validators.compose([Validators.required])],
        biography: [this.profile.biography, null],
      });
    }
  }

  public updateProfile() {
    this.spinnerService.showSpinner();
    try {
      if (this.formGroup && this.profile) {
        const updateProfile: Profile = new Profile({
          id: this.profile.id,
          email: this.formGroup.value.email,
          active: this.profile.active,
          nickname: this.formGroup.value.nickname,
          firstname: this.formGroup.value.firstname,
          lastname: this.formGroup.value.lastname,
          biography: this.formGroup.value.biography,
        });
        const userID = this.userService.getUserID();
        this.restService.updateProfile(userID, updateProfile).subscribe(
          (updatedProfile) => {
            this.profile = updatedProfile;
            this.toggleEditMode();
            this.prepareForm();
            this.spinnerService.hideSpinner();
          },
          (error) => {
            this.spinnerService.hideSpinner();
            console.error(error);
          }
        );
      }
    } catch (e: any) {
      this.spinnerService.hideSpinner();
      console.error(e?.message);
    }
  }

  public toggleEditMode() {
    this.isEditMode = !this.isEditMode;
  }

  public async closeProfile() {
    this.isEditMode = false;
    await this.modalCtrl.dismiss();
  }

  public async changePassword() {
    await this.modalCtrl.dismiss();
    let cssClass = this.deviceDetector.isDesktop() ? 'ion-main-modal' : 'ion-main-modal-mobile';
    const popover = await this.modalCtrl.create({
      component: ChangePasswordComponent,
      cssClass: cssClass,
    });
    return await popover.present();
  }

  public async onEditAvatar(event: MouseEvent): Promise<void> {
    if(!this.avatarExists) {
      this.editAvatar();
      return;
    }

    const popover = await this.popover.create({
      component: AvatarPopoverComponent,
      event
    });
    await popover.present();
    const { data: action } = await popover.onWillDismiss();
    switch (action) {
      case 'edit':
        this.editAvatar();
        break;
      case 'delete':
        this.deleteAvatar();
        break;
    }
  }

  private editAvatar(): void {
    const fileInput: HTMLInputElement = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*'
    fileInput.addEventListener('change', this.onAvatarSelected.bind(this));
    fileInput.click();
  }

  private async deleteAvatar(): Promise<any> {
    // // let cssClass = 'confirm-dialog';
    // // if (this.deviceDetector.isMobile()) cssClass = 'confirm-dialog-mobile';
    // // if (this.deviceDetector.isTabletSize()) cssClass = 'confirm-dialog-tablet';

    // // const dialog = await this.modalCtrl.create({
    // //   component: DialogComponent,
    // //   cssClass: cssClass,
    // //   backdropDismiss: false,
    // //   componentProps: {
    // //     message: `Are you sure?`,
    // //     buttons: [
    // //       {
    // //         text: this.translationService.translate('Dialog.Close'),
    // //         method: () => false,
    // //       },
    // //       {
    // //         text: this.translationService.translate('Dialog.OK'),
    // //         method: () => true,
    // //       },
    // //     ],
    // //   },
    // // });
    // // await dialog.present();
    // // const result = await dialog.onWillDismiss();
    // if(result) {
    //   return await this.s3.delete(`${this.userService.getUserID()}.png`, this.userService.getUserID());
    // }
    
    const response = await this.s3.delete(this.userService.getUserID());
    if(response) {
      document.querySelectorAll('.avatar__loaded').forEach(el => el.classList.remove('avatar__loaded'));
      this.imgSrc = null;
    }
  }

  onAvatarSelected = (event: Event) => {
    const image: File | undefined = (<HTMLInputElement>event.target)?.files?.[0];
    if(image) {
      this.readFileAsDataUrl(image, async (event) => {
        const fileReader: FileReader | null = <FileReader>event?.target;
        if(fileReader?.result) {
          const imageSource: string = fileReader.result instanceof ArrayBuffer ? URL.createObjectURL(new Blob([fileReader.result])) : fileReader.result;
          
          const modal = await this.modalCtrl.create({
            component: ImageCropperComponent,
            componentProps: { imageSource },
            cssClass: 'image-modal-fullscreen',
            backdropDismiss: false
          });
          modal.onWillDismiss().then((imageData: any) => {
            if(imageData?.data) {
              this.toast.showSuccessMessage(this.translationService.translate('UploadSuccess'));
              this.imgSrc = imageData?.data;
            }

          });
          await modal.present();
        }
      });
    }
  }

  private readFileAsDataUrl(avatar: File, callback: (e: Event) => void) {
    let reader = new FileReader();
    reader.addEventListener('load', callback);
    reader.readAsDataURL(avatar);
  }

  public getUserInitials(): string {
    return `${this.profile?.firstname?.[0]}${this.profile?.lastname?.[0]}`;
  }

  public onAvatarLoad(element: HTMLElement): void {
    element.classList.add('avatar__loaded');
    this.avatarExists = true;
  }

  public onAvatarError(element: HTMLElement): void {
    element.classList.add('avatar_error');
    this.imgSrc = null;
  }
}

@Component({
  selector: 'litello-avatar-popover',
  template: `
  <ion-content>
    <ion-list>
      <ion-item (click)="select('edit')" button><ion-label>Edit</ion-label></ion-item>
      <ion-item (click)="select('delete')" button><ion-label>Remove</ion-label></ion-item>
    </ion-list>
  </ion-content>
  `
})
export class AvatarPopoverComponent {

  constructor(
    private popoverController: PopoverController
  ) {

  }

  select(action: 'edit' | 'delete') {
    this.popoverController.dismiss(action);
  }

}