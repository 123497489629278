import {
  Component,
  OnInit,
  AfterContentInit,
  OnChanges,
  SimpleChanges,
  Input,
  ViewChild,
  ContentChildren,
  ElementRef,
  QueryList,
  Renderer2,
  Output,
  EventEmitter,
} from '@angular/core';
import { Platform } from '@ionic/angular';
import { DeviceDetectorService } from '../../../util/device-detector/device-detector.service';
import { Observable, Subscription } from 'rxjs';

export interface SlidePanelData {
  width: number;
  posX: number;
  lastPosX: number;
  ionContentRef?: any;
}

export enum SlidePanelState {
  CLOSED = 0,
  OPENED = 1,
}

@Component({
  selector: 'app-slide-panel',
  templateUrl: './slide-panel.component.html',
  styleUrls: ['./slide-panel.component.scss'],
})
export class SlidePanelComponent implements OnInit, AfterContentInit, OnChanges {
  @Input() state: SlidePanelState = SlidePanelState.CLOSED;
  @Input() isMenuMobileView: Observable<boolean> = new Observable<boolean>();
  mobileView: boolean = false;
  private eventsSubscription: Subscription = new Subscription();

  @ViewChild('panel', { read: ElementRef }) private panelElm: ElementRef | undefined;

  @ContentChildren('ionSlidePanel') private dragElms!: QueryList<any>;

  @Output() readonly opening = new EventEmitter<any>();
  @Output() readonly closing = new EventEmitter<any>();

  protected panelData: SlidePanelData;

  constructor(
    private platform: Platform,
    private renderer: Renderer2,
    private deviceDetector: DeviceDetectorService
  ) {
    this.panelData = {
      width: this.deviceDetector.isDesktop() && !this.mobileView ? 400 : this.platform.width(),
      posX: window.innerWidth,
      lastPosX: 0,
    };

    this.platform.resize.subscribe(async () => {
      this.panelData.width =
        (this.deviceDetector.isDesktop()) && !this.mobileView ? 400 : this.platform.width();
      this.updateUI();
    });
  }

  ngOnInit() {
    // Switching back to Litello browser tab from another tab
    this.platform.resume.subscribe(() => {
      this.updateUI();
    });
  }

  ngAfterContentInit() {
    this.initState();
    this.updateUI();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.state) {
      if (changes.state.currentValue === changes.state.previousValue) return;

      switch (this.state) {
        case SlidePanelState.CLOSED:
          this.close();
          break;
        case SlidePanelState.OPENED:
          this.open();
          break;
        default:
          console.log('SlidePanelState has impossible state.');
          break;
      }
    } else if (changes.isMenuMobileView) {
      this.eventsSubscription = this.isMenuMobileView.subscribe((value: any) => {
        this.mobileView = value;
      });
    }
  }

  private initState() {
    this.state = SlidePanelState.CLOSED;
  }

  private computeWidth() {
    const lastPosX = this.deviceDetector.isDesktop() && !this.mobileView ? 60 : 0;
    if (this.state === SlidePanelState.CLOSED) {
      this.panelData.lastPosX = window.innerWidth - lastPosX;
      this.renderer.setStyle(this.panelElm!.nativeElement, 'width', 0);
      this.renderer.setStyle(this.panelElm!.nativeElement, 'left', 100 + 'vw');
      this.renderer.setStyle(
        this.panelElm!.nativeElement,
        '-webkit-transform',
        `translateX(${this.panelData.lastPosX}px)`
      );
      this.renderer.setStyle(
        this.panelElm!.nativeElement,
        'transform',
        `translateX(${this.panelData.lastPosX}px)`
      );
      if (this.deviceDetector.isDesktop() && !this.mobileView)
        this.renderer.setStyle(this.panelElm!.nativeElement, 'transition', `700ms ease-in-out`);
    } else {
      this.panelData.lastPosX = this.panelData.width;
      this.renderer.setStyle(this.panelElm!.nativeElement, 'width', this.panelData.width + 'px');
      this.renderer.setStyle(this.panelElm!.nativeElement, 'left', window.innerWidth - lastPosX + 'px');
      this.renderer.setStyle(
        this.panelElm!.nativeElement,
        '-webkit-transform',
        `translateX(-${this.panelData.lastPosX}px)`
      );
      this.renderer.setStyle(
        this.panelElm!.nativeElement,
        'transform',
        `translateX(-${this.panelData.lastPosX}px)`
      );
      if (this.deviceDetector.isDesktop() && !this.mobileView)
        this.renderer.setStyle(this.panelElm!.nativeElement, 'transition', `500ms ease-in-out`);
    }
  }

  private updateUI() {
    if (this.panelElm) {
      this.computeWidth();
      this.renderer.setStyle(this.panelElm!.nativeElement, 'transition', 'none');
    } else {
      console.log('No panel element known so far.');
    }
  }

  //TODO Rework opening and closing process, remove magic numbers
  private open() {
    if (this.panelElm) {
      this.computeWidth();

      // For moving book content
      this.opening.emit(this.panelData.width);
    } else {
      console.log('No panel element known.');
    }
  }

  private close() {
    if (this.panelElm) {
      this.computeWidth();

      // For moving book content
      this.closing.emit(this.panelData.width);
    } else {
      console.log('No panel element known.');
    }
  }
}
