<div class="book-header">
  <span class="book-header-vl-vertical"></span>
  <div class="book-header-content">
    <div class="row1">
      <img class="dashboard-img" (click)="goToBookLibrary3()" src="../../../../assets/icon/new-dashboard-logo.svg" />
    </div>
    <div class="row2">
      <img class="support-btn" (click)="openContactSupport()" src="../../../../assets/icon/support.svg" />
    </div>
    <div class="row3">
      <div class="toc-container">
        <div class="backArrow">
          <ion-icon *ngIf="previousPageButton" src="/assets/icon/headerBackArrow.svg"
            (click)="goToPrevPage()"></ion-icon>
        </div>
        <div class="chapter" (click)="tableOfContentView()">
          <ion-icon class="chapterIcon" color="primary" src="/assets/icon/chapterIcon.svg"></ion-icon>
          <div class="selectedChapter" [innerHTML]="sanitizer.bypassSecurityTrustHtml(selectedChapter)"></div>
          <ion-icon class="dropdownArrow"
            [ngClass]="{ chapterArrowClosed: tableOfContentsOpened, chapterArrowOpened: !tableOfContentsOpened }"
            src="/assets/icon/dropdownArrow.svg"></ion-icon>
        </div>
        <div class="spacer" *ngIf="previousPageButton"></div>
      </div>
    </div>
    <div class="row4 color-primary-gray" (click)="toggleHighlighting()"
      [style.background]="isHighlightingEnabled ? contextMenuService.color : null"
      [style.border]="isHighlightingEnabled ? 'none' : null"
      [style.color]="(contextMenuService.color === contrastColor && isHighlightingEnabled) ? 'white' : null">
      <ion-icon src="/assets/icon/highlighter-pen-marker.svg"></ion-icon>
    </div>
    <div class="row5">
      <div class="zoom-icons">
        <div class="size-characters">
          <span class="first-character">A</span>
          <span class="sec-character">a</span>
        </div>
        <ion-icon class="zoom-icon" name="remove-circle-outline" (click)="onZoomOut($event)"></ion-icon>
        <ion-label class="zoom-label">{{ 100 + zoom * 10 }}%</ion-label>
        <ion-icon class="zoom-icon" name="add-circle-outline" (click)="onZoomIn($event)"></ion-icon>
      </div>
      <span class="book-header-vl-vertical"></span>
      <p>{{ bookTitle }}</p>
      <img class="book-logo" src="{{ bookCoverSrc }}" />
    </div>




  </div>
</div>