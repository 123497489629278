import { Pipe, PipeTransform } from '@angular/core';
import { ClientBook } from '../../PODO/clientBook';

@Pipe({
  name: 'lastReadBookFilter',
})
export class LastReadBookFilterPipe implements PipeTransform {
  transform(books: Array<ClientBook>): Array<ClientBook> {
    if (!books) {
      return books;
    }

    books.sort((a, b) => {
      return b.lastRead - a.lastRead;
    });

    return books;
  }
}
