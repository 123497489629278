import packageInfo from './configs.json';

export const environment = {
  production: true,
  log: false,
  defaultTheme: packageInfo.themes.litello,
  allowedUsers: packageInfo.mails,
  // API-Data of Shopware 6
  REGISTER_ENDOPOINT: 'https://shop.litello.com/store-api/account/register',
  ACCESS_KEY: 'SWSCRJKWWMYXQXV3RDFJRZZHUW',
  CONTEXT_TOKEN: 'Vbov9XQ5a56YoFVO4BRyIY8UERVO8gkH',
  STOREFRONT_URL: 'https://shop.litello.com',
  USER_ASSETS_URL: 'https://atboox-cover-bucket.s3.amazonaws.com',
  SALUTATION_ID_FEMALE: '0ec07979e913451b9389e95e67330249',
  SALUTATION_ID_MALE: 'b520ce36a5b64919a034276e83750503',
  COUNTRY_ID_GERMANY: 'ae6c22854dc745c08bc1844f6235cbe0',
  SALES_CHANNEL_ID: '44be7798b20349ff8c2af88a0c19b100',
};
