<ion-content>
  <div class="background">
    <ion-grid class="content">
      <ion-row class="ion-justify-content-center">
        <img
          class="top-logo"
          [src]="themeService.getLoginLogo()"
        />
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col></ion-col>
        <ion-col class="container-col ion-text-center" size-lg="8" size-xs="12" size-md="10">
          <!--- WEBREADER -->

          <div class="form">
            <ion-row>
              <ion-col>
                <app-language-selection class="ion-float-right"></app-language-selection>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center">
              <ion-label class="ion-padding">
                <p *ngIf="status==='verifying'" id="title" class="title" l10nTranslate>
                  Authentication.verifying
                </p>
                <p *ngIf="status==='verified'" id="title" class="title" l10nTranslate>
                  Authentication.verified
                </p>
                <p *ngIf="status==='activating'" id="title" class="title" l10nTranslate>
                  Authentication.activating
                </p>
                <p *ngIf="status==='activated'" id="title" class="title" l10nTranslate>
                  Authentication.activated
                </p>
                <p *ngIf="status==='linkError'" id="title" class="title" l10nTranslate>
                  Authentication.invalidLink
                </p>
              </ion-label>
            </ion-row>
            <ion-row class="ion-padding" class="ion-justify-content-center">
              <ion-col size-lg="4" size-md="4" size-sm="10" size-xs="11">
                <ion-button id="back-btn" class="backButton" expand="full" (click)="openLoginPage()">
                  <!--ion-icon src="../../../assets/icon/arrowDown.svg"></ion-icon-->
                  <p l10nTranslate>Authentication.backToLogin</p>
                </ion-button>
              </ion-col>
            </ion-row>
          </div>
        </ion-col>
        <ion-col></ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <app-footer></app-footer>
</ion-content>
