import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { RoundProgressModule, ROUND_PROGRESS_DEFAULTS } from 'angular-svg-round-progressbar';
import { ComponentsModule } from '../components/components.module';
import { LastReadBookFilterPipe } from '../services/filter/last-read-book-filter.pipe';
import { BooksComponent } from './books/books.component';
@NgModule({
  imports: [IonicModule, CommonModule, ComponentsModule, RoundProgressModule],
  providers: [
    {
      provide: ROUND_PROGRESS_DEFAULTS,
      useValue: {
        color: '#f00',
        background: '#0f0',
      },
    },
    LastReadBookFilterPipe,
  ],
  declarations: [BooksComponent, LastReadBookFilterPipe],
})
export class BooksModule {}
