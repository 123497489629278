import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { L10nTranslationModule } from 'angular-l10n';
import { BookRoutingModule } from './book-routing.module';
import { BookComponent } from './book/book.component';
import { IonicModule } from '@ionic/angular';
import { EpubRenderService } from './services/rendition/epubrender.service';
import { ContextmenuService } from './services/contextmenu/contextmenu.service';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { TexthighlightService } from './services/texthighlight/texthighlight.service';
import { NoteOverviewComponent } from './components/note-overview/note-overview.component';
import { CreateNoteComponent } from './components/create-note/create-note.component';
import { ImagePopupComponent } from './components/image-popup/image-popup.component';
import { PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { VideoPlaceholderComponent } from './components/video-placeholder/video-placeholder.component';
import { SlidePanelComponent } from './components/slide-panel/slide-panel.component';
import { ComponentsModule } from '../components/components.module';
import { TableContentsComponent } from './components/table-contents/table-contents.component';
import { MaterialModule } from '../barrel.module';
import { BookHeaderComponent } from './components/book-header/book-header.component';
import { SearchComponent } from './components/search/search.component';
import { MobileMenuModalComponent } from './components/mobile-menu-modal/mobile-menu-modal.component';
import { BurgerComponent } from './components/mobile-menu-modal/burger/burger.component';
import { HideHeaderDirective } from './directives/hide-header.directive';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { AttachmentOverviewComponent } from './components/attachment-overview/attachment-overview.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AttachmentModalComponent } from './components/attachment-overview/attachment-modal/attachment-modal.component';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { DirectivesModule } from '../directives/directives.module';
import { CollectionOverviewComponent } from './components/collection-overview/collection-overview.component';
import { CommunicationOverviewComponent } from './components/communication-overview/communication-overview.component';
import { FixDateFormatPipe } from '../pipes/fix-date-format.pipe';
import { SearchService } from './services/search/search.service';
import { ScrollToNoteDirective } from './directives/scroll-to-note.directive';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        BookRoutingModule,
        L10nTranslationModule,
        FormsModule,
        ReactiveFormsModule,
        PinchZoomModule,
        ComponentsModule,
        MaterialModule,
        NgCircleProgressModule,
        DirectivesModule
    ],
    declarations: [
        BookComponent,
        ContextMenuComponent,
        NoteOverviewComponent,
        CreateNoteComponent,
        ImagePopupComponent,
        VideoPlaceholderComponent,
        SlidePanelComponent,
        TableContentsComponent,
        BookHeaderComponent,
        SearchComponent,
        MobileMenuModalComponent,
        BurgerComponent,
        HideHeaderDirective,
        AttachmentOverviewComponent,
        AttachmentModalComponent,
        CollectionOverviewComponent,
        CommunicationOverviewComponent,
        FixDateFormatPipe,
        ScrollToNoteDirective
    ],
    exports: [MobileMenuModalComponent, HideHeaderDirective],
    providers: [EpubRenderService, ContextmenuService, TexthighlightService, ScreenOrientation, FileOpener, SearchService]
})
export class BookModule {}
