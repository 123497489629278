import { BooksService } from './../rest-client/rest-client.service';
import { UserService } from './../user/user.service';
import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';

const FORMAT_EPUB = 'zip';

@Injectable({
  providedIn: 'root',
})
export class LinkGeneratorService {
  private baseUrl = 'https://api.litello.com';

  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(private http: HttpClient, private userService: UserService, private booksService: BooksService) {}

  /**
   * Start to prepare download book with bookId into logged in user directory
   * @param bookId
   */
  public getDownloadLink(bookId: number) {
    return new Promise((resolve, reject) => {
      try {
        const email = this.userService.getEmail();
        if (email) {
          this.booksService.downloadGet(FORMAT_EPUB, bookId).subscribe(
            (downloadData) => {
              if (downloadData.signedUrl) resolve(downloadData.signedUrl);
            },
            (error) => {
              console.log('Error occured during get download data of book: ', bookId);
              console.error(error);
              reject(error);
            }
          );
        }
      } catch (e: any) {
        console.log('Error happen on prepare download book with id: ', bookId);
        console.error(e);
        reject(e);
        //Sentry.captureException(e);
      }
    });
  }

  /**
   * @return 200 response
   */
  public getSampleBookLink(format: string, bookid: string): Observable<any> {
    let url_ = this.baseUrl + '/books/samples/{bookid}?';
    if (bookid === undefined || bookid === null) throw new Error("The parameter 'bookid' must be defined.");
    url_ = url_.replace('{bookid}', encodeURIComponent('' + bookid));
    if (format === undefined || format === null)
      throw new Error("The parameter 'format' must be defined and cannot be null.");
    else url_ += 'format=' + encodeURIComponent('' + format) + '&';
    url_ = url_.replace(/[?&]$/, '');

    let options_: any = {
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    };

    return this.http
      .request('get', url_, options_)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processSamples3(response_);
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processSamples3(<any>response_);
            } catch (e: any) {
              return <Observable<any>>(<any>_observableThrow(e));
            }
          } else return <Observable<any>>(<any>_observableThrow(response_));
        })
      );
  }
  protected processSamples3(response: HttpResponseBase): Observable<any> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse
        ? response.body
        : (<any>response).error instanceof Blob
        ? (<any>response).error
        : undefined;

    let _headers: any = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return this.blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText) => {
          let result200: any = null;
          result200 = _responseText === '' ? null : <any>JSON.parse(_responseText, this.jsonParseReviver);
          return _observableOf(result200);
        })
      );
    } else if (status !== 200 && status !== 204) {
      return this.blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText) => {
          return this.throwException('An unexpected server error occurred.', status, _responseText, _headers);
        })
      );
    }
    return _observableOf<any>(<any>null);
  }

  private blobToText(blob: any): Observable<string> {
    return new Observable<string>((observer: any) => {
      if (!blob) {
        observer.next('');
        observer.complete();
      } else {
        let reader = new FileReader();
        reader.onload = (event) => {
          observer.next((<any>event.target).result);
          observer.complete();
        };
        reader.readAsText(blob);
      }
    });
  }

  throwException(
    message: string,
    status: number,
    response: string,
    headers: { [key: string]: any },
    result?: any
  ): Observable<any> {
    if (result !== null && result !== undefined) return _observableThrow(result);
    else {
      return _observableThrow(result);
    }
  }
}
