import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'litello-dropdown-list',
  templateUrl: './dropdown-list.component.html',
  styleUrls: ['./dropdown-list.component.scss']
})
export class DropdownListComponent implements OnInit, OnDestroy {

  @HostListener('window:resize', ['$event']) onWindowResize() {}

  @Input() public noSelectionValue: string | null = null;
  @Input() public noSelectionDisplayText: string | null = null;
  @Input() public listItems: any[] | undefined = [];
  @Input() public listItemValueProperty: string | null = null;
  @Input() public listItemDisplayTextProperty: string = '';
  @Input() public selectElement: HTMLElement | undefined;

  private ionRootMutationObserver: MutationObserver;
  private ionPopoverMutationObserver: MutationObserver;

  constructor() {
    this.ionRootMutationObserver = new MutationObserver(this.onMutateIonRoot.bind(this));
    this.ionPopoverMutationObserver = new MutationObserver(this.onMutateIonPopover.bind(this));
  }

  ngOnInit(): void {
    if(this.selectElement) {
      this.selectElement.addEventListener('click', this.onSelectElementClicked);
      this.selectElement.addEventListener('touchstart', this.onSelectElementClicked);
    }
  }

  ngOnDestroy(): void {
    this.ionRootMutationObserver.disconnect();
    this.ionPopoverMutationObserver.disconnect();
  }

  private updateDropdownWidth(width: string = 'auto'): void {
    const containerElement = document.querySelector('ion-popover .popover-content') as HTMLElement;
    if(containerElement) {
      containerElement.style.width = width;
    }
  }

  private updateDropdownPosition(top: string, left: string): void {
    const containerElement = document.querySelector('ion-popover .popover-content') as HTMLElement;
    if(containerElement) {
      containerElement.style.top = top;
      containerElement.style.left = left;
      
      const popoverArrow = document.querySelector('ion-popover .popover-arrow') as HTMLElement;
      if(popoverArrow) popoverArrow.style.display = 'none';
    }
  }

  private onSelectElementClicked = (event: MouseEvent | TouchEvent): void => {
    event.stopPropagation();
 
    this.onWindowResize = this.onResize;

    const ionRoot = document.querySelector('ion-app');
    if(ionRoot) {
      this.ionRootMutationObserver.observe(ionRoot, { childList: true });
    };
  }

  private onResize(): void {
    setTimeout(() => {
      const popover = document.querySelector('ion-popover .popover-content');
      if(!popover || !this.selectElement) return;
  
      const elementStyle = window.getComputedStyle(this.selectElement);
      const elementHorizontalPadding: number = parseFloat(elementStyle.paddingLeft) + parseFloat(elementStyle.paddingRight);
      const elementVerticalPadding: number = parseFloat(elementStyle.paddingTop) + parseFloat(elementStyle.paddingBottom);
      const dropdownTopOffset: number = this.selectElement.getBoundingClientRect().top + parseFloat(elementStyle.height) + elementVerticalPadding;
      const dropdownLeftOffset: number = this.selectElement.getBoundingClientRect().left;
  
      this.updateDropdownWidth(`${parseFloat(elementStyle.width)}px`);
      this.updateDropdownPosition(`${dropdownTopOffset}px`, `${dropdownLeftOffset}px`);
    });
  };

  private onMutateIonRoot: MutationCallback = (mutations: MutationRecord[]) => {
    const ionPopoverMutation = mutations.find(mutation => (<HTMLElement[]>Array.from(mutation.addedNodes)).some(node => node.nodeName === 'ION-POPOVER'));
    const ionPopover = ionPopoverMutation ? Array.from(ionPopoverMutation.addedNodes).find(node => node.nodeName === 'ION-POPOVER') : undefined;
    if(ionPopover) {
      this.ionPopoverMutationObserver.observe(ionPopover, { childList: true });
    }
    
    const isPopoverRemoved = mutations.some(mutation => (mutation.removedNodes[0] as HTMLElement)?.nodeName === 'ION-POPOVER');
    if(isPopoverRemoved) {
      this.onWindowResize = () => {};
      this.ionRootMutationObserver.disconnect();
      this.ionPopoverMutationObserver.disconnect();
    }
  }

  private onMutateIonPopover: MutationCallback = (mutations: MutationRecord[]) => {
    const isPopoverWrapperAppended = mutations.some(mutation => (<HTMLElement[]>Array.from(mutation.addedNodes)).some(node => node?.classList?.contains('popover-wrapper')));
    if(isPopoverWrapperAppended) {
      this.updateDropdownWidth(this.selectElement ? window.getComputedStyle(this.selectElement).width : undefined);
    }
  }
}
