<app-menu *ngIf="deviceDetector.isDesktop()"></app-menu>

<!--div to show the content of the epub -->
<div
  [hidden]="tableOfContentsOpened"
  [ngStyle]="{ 'padding-bottom': activatedViewId === '' ? '60px' : '0px' }"
  [ngClass]="
    deviceDetector.isDesktop() && !menuMobileView
      ? activatedViewId !== ''
        ? 'right-menu-expanded'
        : 'right-menu-not-expanded'
      : ''
  "
  class="full-height"
  id="book-content"
>
  <ion-content
    class="epubArea"
    [scrollEvents]="scrollingEnabled"
    (ionScroll)="emitEventToChild($event)"
    (ionScrollEnd)="onBookContentScrollEnd()"
    #epubArea
    slot="fixed"
    [id]="EPUB_AREA_ID"
  >
  </ion-content>
</div>

<app-mobile-menu-modal
  id="menu-modal"
  *ngIf="!deviceDetector.isDesktop() || menuMobileView"
  bookCoverSrc="{{ bookCoverUrl }}"
  bookTitle="{{ bookTitle }}"
  selectedChapter="{{ selectedChapter }}"
  [zoom]="zoom"
  [attachmentsExists]="attachmentsExists"
  [collectionExists]="!!bookCollection"
  [pageHistory]="pageHistory.asObservable()"
  [emitClosedActiveMenu]="emitClosedActiveMenu.asObservable()"
  (changeScrollingEnabled)="onChangeScrollingEnabled($event)"
  (navigateToPrevPageAndSwap)="navigateToPrevPageAndSwap()"
  [scrollingContent]="scrolling.asObservable()"
  (openTableOfContent)="toggleTableOfContentsView()"
  (toBookLibrary)="openBookLibrary()"
  (zoomIn)="zoomIn()"
  (zoomOut)="zoomOut()"
  (openSearchView)="toggleSearchView()"
  (openNoteOverview)="toggleNoteOverview()"
  (openAttachmentOverview)="openAttachmentOverview()"
  (openCollectionOverview)="toggleCollectionOverview()"
  (openCommunicationOverview)="toggleCommunicationOverview()"
  [isHighlightingEnabled]="isHighlightingEnabled"
  (toggleHighlighting)="toggleHighlighting($event)"
>
</app-mobile-menu-modal>

<app-table-contents
  (onTogglePanel)="toggleTableOfContentsView()"
  [ngStyle]="{
    'padding-top': (deviceDetector.isMobile() && menuMobileView) || !this.deviceDetector.isDesktop() ? '0px' : '60px'
  }"
  *ngIf="tableOfContentsOpened"
  [chapters]="chapters"
  [toc]="toc"
  (onNavigate)="onNavigate()"
></app-table-contents>

<ion-footer *ngIf="!tableOfContentsOpened" id="footer">
  <ion-toolbar id="footerbarIdForSideBar" class="footerbar">
    <div class="sliderContainer">
      <div class="sliderSectionContainer">
        <ion-range
          [(ngModel)]="currentPage"
          color="grey"
          [min]="0"
          [max]="maxPages - 1"
          [step]="1"
        >
          <div class="slider-label" slot="start">
            <ion-icon id="book-previous-btn" class="prev" src="/assets/icon/pageArrow.svg" (click)="prev()"></ion-icon>
            <ion-label class="range-number start">{{ currentPage + 1 }}</ion-label>
          </div>
          <div class="slider-label" slot="end">
            <ion-label class="range-number end">{{ maxPages}}</ion-label>
            <ion-icon id="book-next-btn" class="next" src="/assets/icon/pageArrow.svg" (click)="next()"></ion-icon>
          </div>
        </ion-range>
      </div>
    </div>
  </ion-toolbar>
</ion-footer>

<app-slide-panel
  (opening)="panelOpened($event)"
  (closing)="panelClosed()"
  [state]="slidePanelState"
  [isMenuMobileView]="menuMobileViewChanged.asObservable()"
>
  <ion-content
    #ionSlidePanel
    [ngStyle]="{
      height: !deviceDetector.isMobile() && !menuMobileView && deviceDetector.isDesktop() ? 'calc(100% - 60px)' : '100%'
    }"
  >
    <ion-header *ngIf="deviceDetector.isMobile() || menuMobileView || !deviceDetector.isDesktop()" class="mobileHeader">
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col size="2"></ion-col>
          <ion-col size="8" style="text-align: center">
            <div *ngIf="activatedViewId === 'overview' || activatedViewId === 'note'" class="title" l10nTranslate="">
              NoteOverview.Highlights
            </div>
            <div *ngIf="activatedViewId === 'search'" class="title" l10nTranslate="">Search.Title</div>
            <div *ngIf="activatedViewId === 'toc'" class="title" l10nTranslate="">Search.Title</div>
            <div *ngIf="activatedViewId === 'attachment'" class="title" l10nTranslate="">Attachment.Download</div>
            <div *ngIf="activatedViewId === 'collection'" class="title" l10nTranslate="">Collections.Collection</div>
            <div *ngIf="activatedViewId === 'communication' && !selectedThread" class="title" l10nTranslate="">Communication.Posts</div>
          </ion-col>
          <ion-col size="2" style="text-align: right">
            <ion-icon
              id="book-closeSearch-btn"
              class="menuclose"
              style="font-size: 20px; margin-left: 7px; margin-top: 5px"
              *ngIf="activatedViewId === 'search'"
              (click)="toggleSearchView()"
              src="../../assets/icon/appMenuClose.svg"
            ></ion-icon>
            <ion-icon
              id="book-close-attachment-btn"
              class="menuclose"
              style="font-size: 20px; margin-left: 7px; margin-top: 5px"
              *ngIf="activatedViewId === 'attachment'"
              (click)="toggleAttachmentView()"
              src="../../assets/icon/appMenuClose.svg"
            ></ion-icon>
            <ion-icon
              id="book-closeTOC-btn"
              class="menuclose"
              style="font-size: 20px; margin-left: 7px; margin-top: 5px"
              *ngIf="activatedViewId === 'toc'"
              (click)="toggleSearchView()"
              src="../../assets/icon/appMenuClose.svg"
            ></ion-icon>
            <ion-icon
              id="book-closeNoteOverview-btn"
              class="menuclose"
              style="font-size: 20px; margin-left: 7px; margin-top: 5px"
              *ngIf="activatedViewId === 'overview'"
              (click)="toggleNoteOverview()"
              src="../../assets/icon/appMenuClose.svg"
            ></ion-icon>
            <ion-icon
              id="book-close-collection-btn"
              class="menuclose"
              style="font-size: 20px; margin-left: 7px; margin-top: 5px"
              *ngIf="activatedViewId === 'collection'"
              (click)="toggleCollectionOverview()"
              src="../../assets/icon/appMenuClose.svg"
            ></ion-icon>
            <ng-container *ngIf="activatedViewId === 'communication'">
              <ion-icon *ngIf="!selectedThread"
                id="book-close-communication-btn"
                class="menuclose"
                style="font-size: 20px; margin-left: 7px; margin-top: 5px"
                (click)="toggleCommunicationOverview()"
                src="../../assets/icon/appMenuClose.svg"
              ></ion-icon>
              <ion-icon *ngIf="selectedThread"
                id="book-close-communication-btn"
                class="menuclose"
                style="font-size: 20px; margin-left: 7px; margin-top: 5px"
                (click)="selectedThread = null"
                src="../../assets/icon/headerBackArrow.svg"
              ></ion-icon>
            </ng-container>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-header>

    <div *ngIf="!deviceDetector.isMobile() && !menuMobileView && deviceDetector.isDesktop()" class="header">
      <div class="row">
        <ion-icon *ngIf="activatedViewId === 'search'" class="search" src="/assets/icon/search.svg"></ion-icon>
        <div *ngIf="activatedViewId === 'search'" class="title" l10nTranslate="">Search.Title</div>

        <ion-icon *ngIf="activatedViewId === 'attachment'" class="search" src="/assets/icon/attachment.svg"></ion-icon>
        <div *ngIf="activatedViewId === 'attachment'" class="title" l10nTranslate="">Attachment.Download</div>
        <ion-icon
          *ngIf="activatedViewId === 'overview' || activatedViewId === 'note'"
          class="icon"
          src="/assets/icon/noteOverview2.svg"
        >
        </ion-icon>
        <div *ngIf="activatedViewId === 'overview' || activatedViewId === 'note'" class="title" l10nTranslate="">
          NoteOverview.Highlights
        </div>

        <ng-container *ngIf="activatedViewId === 'collection'">
          <ion-icon class="search" src="/assets/icon/collection.svg"></ion-icon>
          <div class="title" l10nTranslate="">Collections.Collection</div>
        </ng-container>

        <ng-container *ngIf="activatedViewId === 'communication'">
          <ion-icon class="search" src="/assets/icon/messageBubble.svg"></ion-icon>
          <div class="title" l10nTranslate>Communication.Posts</div>
          <div style="flex-grow: 1;"></div>
          <ion-icon *ngIf="selectedThread" (click)="selectedThread = null" class="search" src="/assets/icon/headerBackArrow.svg"></ion-icon>
        </ng-container>
      </div>
      <ion-button class="close-button" size="small" fill="clear" (click)="closePanel()">
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </div>
    <div class="content">
      <app-search *ngIf="activatedViewId === 'search'" (onEdit)="toggleBookView($event)"> </app-search>
      <app-attachment-overview
        *ngIf="activatedViewId === 'attachment'"
        [book]="book"
        [(attachmentLink)]="attachmentLink"
      >
      </app-attachment-overview>
      <app-note-overview
        *ngIf="activatedViewId === 'overview'"
        (onEdit)="toggleBookView($event)"
        (menuValue)="emitMenuChanges($event)"
        [book]="book"
        [shouldRefresh]="shouldRefresh"
        [chapters]="chapters"
      >
      </app-note-overview>

      <app-create-note
        *ngIf="isNoteActivated()"
        (onColorChange)="onColorChange($event)"
        (onTogglePanel)="togglePanel($event)"
        [currentPage]="currentPage"
        [isEdit]="isEdit"
        [navigateAway]="navigateAway"
      >
      </app-create-note>

      <app-collection-overview
        *ngIf="activatedViewId === 'collection'"
        [book]="book"
        [collection]="bookCollection"
        (togglePanel)="togglePanel({ navigateTo: 'collection' })"
      >
      </app-collection-overview>

      <app-communication-overview
        *ngIf="activatedViewId === 'communication'"
        [book]="book"
        [(selectedThread)]="selectedThread"
      >
      </app-communication-overview>
    </div>
  </ion-content>
</app-slide-panel>
<app-context-menu
  style="display: none"
  id="contextmenu"
  (openPanel)="togglePanel($event)"
  (onTextHighlightCreate)="cleanTextHighlight()"
  (onContextMenuColorChange)="onContextMenuColorChange($event)"
  (openNoteOverview)="toggleNoteOverview()"
  [cp]="currentPage"
  [isHighlightingEnabled]="isHighlightingEnabled"
></app-context-menu>

<!--The right sidebar-->
<ion-toolbar *ngIf="deviceDetector.isDesktop() && !menuMobileView" class="rightSideBar">
  <ion-buttons>
    <ion-button id="search" fill="clear" (click)="togglePanel({ navigateTo: $event.target.id, editing: isEditing() })">
      <ion-icon class="search" [src]="activatedViewId === 'search'
        ? '/assets/icon/searchYellow.svg'
        : '/assets/icon/searchWhite.svg'
      "></ion-icon>
    </ion-button>

    <ion-button
      id="overview"
      fill="clear"
      (click)="togglePanel({ navigateTo: $event.target.id, editing: isEditing() })"
    >
      <ion-icon
        [src]="activatedViewId === 'overview' || activatedViewId === 'note'
          ? '/assets/icon/noteOverviewYellow.svg'
          : '/assets/icon/noteOverviewWhite.svg'
        "
      ></ion-icon>
    </ion-button>

    <ion-button
      id="attachment"
      fill="clear"
      *ngIf="attachmentsExists"
      (click)="togglePanel({ navigateTo: $event.target.id, editing: isEditing() })"
    >
      <ion-icon
        [src]="activatedViewId === 'attachment'
          ? '/assets/icon/attachment-active.svg'
          : '/assets/icon/attachment-inactive.svg'
        "
      ></ion-icon>
    </ion-button>

    <ion-button
      id="collection"
      fill="clear"
      *ngIf="bookCollection"
      (click)="togglePanel({ navigateTo: $event.target.id, editing: isEditing() })"
    >
      <ion-icon style="padding: 0 7px"
        [src]="activatedViewId === 'collection' ? '/assets/icon/collection-active.svg' : '/assets/icon/collection-inactive.svg'"
      ></ion-icon>
    </ion-button>

    <!-- <ion-button
      id="communication"
      fill="clear"
      *ngIf="isOnline"
      (click)="togglePanel({ navigateTo: $event.target.id, editing: isEditing() })"
    >
      <ion-icon style="padding: 0 7px"
        [src]="activatedViewId === 'communication' ? '/assets/icon/messageBubble-active.svg' : '/assets/icon/messageBubble-inactive.svg'"
      ></ion-icon>
    </ion-button> -->
    <img class="bottom-sidebarLogo1" src="{{ themeService.getSideBarLogo1() }}" />
    <img
      class="bottom-sidebarLogo2"
      *ngIf="themeService.modul.DoubleIcons === 1"
      src="{{ themeService.getSideBarLogo2() }}"
    />
  </ion-buttons>
</ion-toolbar>
