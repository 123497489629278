<ion-header>
  <ion-toolbar color="white">
    <ion-buttons slot="start" #toolBar>
      <ion-button *ngIf="!isMenuOpen" (click)="openMenu()">
        <ion-icon src="/assets/icon/appMenuBurger.svg"></ion-icon>
      </ion-button>
      <ion-button *ngIf="isMenuOpen" (click)="closeMenu()">
        <ion-icon src="/assets/icon/appMenuClose.svg"></ion-icon>
      </ion-button>
    </ion-buttons>
    <p slot="end" class="on-header dashboard-title">{{ themeService.getDashboardTopText() }}</p>
    <img #logo slot="end" class="on-header book-icon dashboard-top-logo" 
      [src]="themeService.toolbarLogoUrl"
      (error)="themeService.toolbarLogoUrl = themeService.getToolbarDefaultLogo()"
    />
  </ion-toolbar>
</ion-header>
