<ion-content
  class="collection-overview-content"
  id="collection-overview-content"
>
  <div class="collectionContainer">
    <ng-container *ngFor="let book of collection">
      <div class="book" *ngIf="book.id !== currentlyOpenedBook.id"
        [ngClass]="{'disabled': !canOpenBook(book)}"
      >
        <div class="book__title">
          {{book.title}}
        </div>
        <div class="book__container">
          <div class="image">
            <img [src]="book.urlCover">
            <ion-button [disabled]="!canOpenBook(book)" (click)="canOpenBook(book) ? openBook(book) : null" l10nTranslate>OfflineMode.Open</ion-button>
          </div>
          <div class="analytics">
            <!-- <div class="progress progress__percentage">
              <ion-chip>
                <ion-icon src="/assets/icon/progress.svg"></ion-icon>
                <ion-label l10nTranslate>Analytics.Progress</ion-label>
                <span class="flex-spacer"></span>
                <span class="value">{{ '78%' }}</span>
              </ion-chip>
            </div>
            <div class="progress progress__details">
              <div class="progress-item">
                <div class="progress-circle" [attr.data-progress]="'2/4'" [style]="'--progress: ' + 180+'deg;'">36%</div>
                <div class="progress-text">Modules</div>
              </div>
            </div>
            <div class="progress progress__page">
              <div class="text">30 von 40 Seiten gelesen</div>
              <ion-progress-bar class="book-progress-bar" color="primary" value="0.5"></ion-progress-bar>
            </div> -->
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</ion-content>
