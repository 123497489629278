import { trigger, transition, style, animate, state, AnimationMetadata } from '@angular/animations';

const defaultAnimationTimeAndEasing = '200ms ease-in';
const defaultAnimate = animate(defaultAnimationTimeAndEasing);
export const Animations = [
  trigger('slidesFadeInOut', []),
  trigger('zoomFadeInOut', [
    state('reader', style({ boxShadow: '0 0 0 0 #ccc', transform: 'scale(1)' })),
    state('flippage', style({ boxShadow: '0 0 4px #ccc', transform: 'scale(0.7)' })),
    transition('* => *', [defaultAnimate]),
  ]),
  trigger('slideInOutHeader', [
    state('void', style({ transform: 'translateY(-100%)' })),
    state('flippage', style({ transform: 'translateY(0%)' })),
    transition('* => *', [defaultAnimate]),
  ]),
  trigger('slideInOutFooter', [
    state('void', style({ transform: 'translateY(100%)' })),
    state('flippage', style({ transform: 'translateY(0%)' })),
    transition('* => *', [defaultAnimate]),
  ]),
];

/* export function createSlideInOutTransition(which: 'footer' | 'header'): AnimationMetadata[] {
  let startPosition = '';
  switch (which) {
    case 'footer': startPosition = 'translateY(100%)'; break;
    case 'header': startPosition = 'translateY(-100%)'; break;
  }
  return [
    state('void', style({ transform: startPosition })),
    state('flippage', style({ transform: 'translateY(0%)' })),
    transition('* => *', [
      defaultAnimate
    ])];
} */

// export const Animations = [
//   trigger('zoomInOut', [
//     state('reader', style({boxShadow: '0 0 0 0 #ccc', transform: 'scale(1)'})),
//     state('flippage', style({boxShadow: '0 0 4px #ccc', transform: 'scale(0.7)'})),
//     transition('* => *', [
//       animate(defaultAnimationTimeAndEasing)
//     ])
//   ]),
//   trigger('slideInOutHeader', [
//     transition(':enter', [
//       style({ transform: 'translateY(-100%)' }),
//       animate(defaultAnimationTimeAndEasing, style({ transform: 'translateY(0%)' }))
//     ]),
//     transition(':leave', [
//       animate(defaultAnimationTimeAndEasing, style({ transform: 'translateY(-100%)' }))
//     ])
//   ]),
//   trigger('slideInOutFooter', [
//     transition(':enter', [
//       style({ transform: 'translateY(100%)' }),
//       animate(defaultAnimationTimeAndEasing, style({ transform: 'translateY(0%)' }))
//     ]),
//     transition(':leave', [
//       animate(defaultAnimationTimeAndEasing, style({ transform: 'translateY(100%)' }))
//     ])
//   ])
// ];
