import { BooksService, ResetPassword } from './../../services/rest-client/rest-client.service';
import { Login } from '../../services/rest-client/rest-client.service';
import { Injectable } from '@angular/core';
import { LoginService } from '../../services/rest-client/rest-client.service';
import { UserService } from '../../services/user/user.service';
import { ThemeService } from '../../services/themes/theme.service';

const SHOP_ROOT_URL = 'https://shop.litello.com';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private restLoginService: LoginService,
    private userService: UserService,
    private themeService: ThemeService,
    private restClient: BooksService
  ) {}

  public setToken(token: string) {
    localStorage.setItem('token', token);
  }

  public resetPassword(email: string) {
    const payload: ResetPassword = { email: email, storefrontUrl: SHOP_ROOT_URL };
    return this.restLoginService.resetPassword(payload);
  }

  public loginUser(email: string, password: string) {
    const credentials: Login = { email: email, passHashed: password };
    return this.restLoginService.loginPost(credentials);
  }

  public loginWithToken(customerName: string, token: string) {
    const tokenBody = {
      token: token,
      customername: customerName,
    };
    return this.restLoginService.loginTokenPost(tokenBody);
  }

  public loginWithSSOToken(customerName: string, token: string) {
    const tokenBody = {
      token: token,
      customername: customerName,
    };
    return this.restLoginService.postSSOTokenID(tokenBody);
  }

  public loginWithSSO() {
    this.restClient.getSSO().subscribe((response) => {
      window.open(response.URL, '_self');
    });
  }

  public resendVerificationToken(email: string) {
    return this.restLoginService.resendVerificationToken(email);
  }

  public verifyAccount(userid: string, code: string){
    return this.restLoginService.verifyAccount(userid, code);
  }

  public activateAccount(userid: string, code: string){
    return this.restLoginService.activateAccount(userid, code);
  }

  public async logoutUser() {
    this.userService.deleteUser();
    await localStorage.clear();
    await this.themeService.loadTheme();
  }
}
