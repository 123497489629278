<ion-footer>
  <ion-toolbar>
    <ion-grid>
      <ion-row *ngIf="!native">
        <ion-col size-xl="1" size-lg="1" size-md="1" size-sm="0" size-xs="0">
          <ion-label> </ion-label>
        </ion-col>

        <ion-col size-xl="8" size-lg="7" size-md="7" size-sm="4" size-xs="4">
          <ion-label> ©{{ currentYear }} Litello GmbH </ion-label>
        </ion-col>

        <ion-col size-xl="1" size-lg="2" size-md="2" size-sm="3" size-xs="3">
          <ion-label class="links" l10nTranslate="" (click)="browse('impressum')"> Menu.Imprint </ion-label>
        </ion-col>
        <ion-col size-xl="1" size-lg="2" size-md="2" size-sm="4" size-xs="4">
          <ion-label class="links" l10nTranslate="" (click)="browse('datenschutz')"> Footer.PrivacyPolicy </ion-label>
        </ion-col>
        <ion-col size-xl="1" size-lg="0" size-md="0" size-sm="0" size-xs="0">
          <ion-label> </ion-label>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="native">
        <ion-col>
          <ion-label class="links-m-dat" l10nTranslate="" (click)="browse('datenschutz')">
            Footer.PrivacyPolicy
          </ion-label>
        </ion-col>

        <ion-col class="col-impressum-m">
          <ion-label class="links-m-imp" l10nTranslate="" (click)="browse('impressum')"> Menu.Imprint </ion-label>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-footer>
