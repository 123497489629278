import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { L10nTranslationModule } from 'angular-l10n';
import { AppMenuComponent } from './app-menu/app-menu.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChangelogComponent } from './changelog/changelog.component';
import { ContactSupportComponent } from './contact-support/contact-support.component';
import { HelpComponent } from './contact-support/help/help.component';
import { SupportComponent } from './contact-support/support/support.component';
import { CreditsComponent } from './credits/credits.component';
import { DataPrivacyComponent } from './data-privacy/data-privacy.component';
import { DialogComponent } from './dialog/dialog.component';
import { HeaderComponent } from './header/header.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { ImprintComponent } from './imprint/imprint.component';
import { LanguageSelectionComponent } from './language-selection/language-selection.component';
import { LocalStorageComponent } from './local-storage/local-storage.component';
import { NetworkChangedComponent } from './network-changed/network-changed.component';
import { AvatarPopoverComponent, ProfileComponent } from './profile/profile.component';
import { ThemeSwitcherComponent } from './theme-switcher/theme-switcher.component';
import { DropdownListComponent } from './dropdown-list/dropdown-list.component';

@NgModule({
    imports: [FormsModule, IonicModule, CommonModule, L10nTranslationModule, ReactiveFormsModule],
    declarations: [
        HeaderComponent,
        CreditsComponent,
        DataPrivacyComponent,
        ImprintComponent,
        ChangelogComponent,
        ProfileComponent,
        ChangePasswordComponent,
        AppMenuComponent,
        DialogComponent,
        LanguageSelectionComponent,
        LocalStorageComponent,
        NetworkChangedComponent,
        ContactSupportComponent,
        ThemeSwitcherComponent,
        HelpComponent,
        SupportComponent,
        DropdownListComponent,
        ImageCropperComponent,
        AvatarPopoverComponent
    ],
    exports: [HeaderComponent, L10nTranslationModule, AppMenuComponent, LanguageSelectionComponent, DropdownListComponent, DropdownListComponent]
})
export class ComponentsModule {}
