import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private loadingSpinner: HTMLIonLoadingElement | undefined;

  constructor(
    private loadingController: LoadingController
  ) {}

  private async createLoadingSpinner(): Promise<HTMLIonLoadingElement> {
    return await this.loadingController.create({
      spinner: 'crescent',
      cssClass: 'custom-spinner',
    });
  }

  public async showSpinner(): Promise<void> {
    await this.hideSpinner();
    this.loadingSpinner = await this.createLoadingSpinner();
    await this.loadingSpinner.present();
  }

  public async hideSpinner(): Promise<void> {
    await this.loadingSpinner?.dismiss?.();
  }
}
