import { Injectable } from '@angular/core';
import { DialogComponent } from '../../components/dialog/dialog.component';
import { L10nTranslationService } from 'angular-l10n';
import { LoadingController, ModalController } from '@ionic/angular';
import { BooksService } from '../books/books.service';
import { ThemeService } from '../themes/theme.service';
import { OfflineModeService } from '../offline-mode/offline-mode.service';
import { UserService } from '../user/user.service';
import { StorageService } from '../storage/storage.service';
import { DeviceDetectorService } from '../../util/device-detector/device-detector.service';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDlgService {
  constructor(
    private booksService: BooksService,
    public themeService: ThemeService,
    public offlineModeService: OfflineModeService,
    public userService: UserService,
    public storageService: StorageService,
    private localization: L10nTranslationService,
    private modalController: ModalController,
    private deviceDetector: DeviceDetectorService
  ) {}

  public async showCustomizedDlg(title: string, listOfButtons: Array<{ text: string; method: () => any }>) {
    let cssClass = 'confirm-dialog';
    const closeBtn = {
      text: 'Close',
      method: this.closeDialog.bind(this),
    };
    let buttons: Array<any> = [];
    listOfButtons.map((item) => {
      buttons.push({
        text: item.text,
        method: () => this.actionBtnHandler(() => item.method()),
        style: 'fill',
      });
    });
    if (this.deviceDetector.isMobile()) cssClass = 'confirm-dialog-mobile';
    if (this.deviceDetector.isTabletSize()) cssClass = 'confirm-dialog-tablet';

    const dialog = await this.modalController.create({
      component: DialogComponent,
      cssClass: cssClass,
      backdropDismiss: false,
      enterAnimation: undefined,
      leaveAnimation: undefined,
      componentProps: {
        message: title,
        buttons: [closeBtn, ...buttons],
      },
    });
    await dialog.present();
  }

  public async showConfirmationDlg(book: any, yesBtnText: string, noBtnText: string, yesBtnHandler: () => void) {
    if (book) {
      let cssClass = 'confirm-dialog';
      if (this.deviceDetector.isMobile()) cssClass = 'confirm-dialog-mobile';
      if (this.deviceDetector.isTabletSize()) cssClass = 'confirm-dialog-tablet';

      const dialog = await this.modalController.create({
        component: DialogComponent,
        cssClass: cssClass,
        backdropDismiss: false,
        enterAnimation: undefined,
        leaveAnimation: undefined,
        componentProps: {
          message: this.localization.translate('Dialog.DeleteBookReally'),
          buttons: [
            {
              text: noBtnText,
              method: this.closeDialog.bind(this),
            },
            {
              text: yesBtnText,
              method: async () => {
                await yesBtnHandler();
                await this.closeDialog();
              },
              style: 'fill',
            },
          ],
        },
      });
      await dialog.present();
    }
  }

  private async closeDialog() {
    const modalElem = await this.modalController.getTop();
    if (modalElem) {
      modalElem.dismiss();
    }
  }

  private async actionBtnHandler(cbFn: () => void) {
    cbFn();
    await this.closeDialog();
  }
}
