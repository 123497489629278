import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
    ViewRef
} from '@angular/core';
import {OfflineModeService} from '../../../services/offline-mode/offline-mode.service';
import {ClientBook} from '../../../PODO/clientBook';
import {BookDatabaseService} from '../../../services/book-database/book-database.service';
import {HttpClient, HttpEventType} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {saveAs} from 'file-saver';
import {DeviceDetectorService} from '../../../util/device-detector/device-detector.service';
import {IonContent, ModalController, ToastController} from '@ionic/angular';
import {Capacitor} from '@capacitor/core';
import {Attachment, BooksService} from '../../../services/rest-client/rest-client.service';
import {AttachmentModalComponent} from "./attachment-modal/attachment-modal.component";
import {ScreenOrientation} from "@awesome-cordova-plugins/screen-orientation/ngx";
import { Subscription } from "rxjs";
import moment from "moment";
import {DialogComponent} from "../../../components/dialog/dialog.component";
import {Browser} from '@capacitor/browser';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { popoverDropdownEnterAnimation } from '../../../../animations/dropdown.animation';

type AttachmentCategory = {
    name: string;
    attachments: Attachment[]
}

@Component({
    selector: 'app-attachment-overview',
    templateUrl: './attachment-overview.component.html',
    styleUrls: ['./attachment-overview.component.scss']
})
export class AttachmentOverviewComponent implements OnInit, OnDestroy {
    public readonly dropdownAnimation = popoverDropdownEnterAnimation;

    @ViewChild(IonContent) content: IonContent | undefined;

    @Input() book: ClientBook | undefined;
    private _attachmentLink: string = '';
    public get attachmentLink(): string {
        return this._attachmentLink;
    }
    @Input() public set attachmentLink(value: string) {
        this._attachmentLink = value;
        if(this.attachmentLink) {
            this.selectedCategory = this.allCategoryString;
        }
        if(this.attachments) {
            this.attachmentsByCategories = this.getAttachmentsByCategory(this.attachments, this.attachmentLink);
        }
        this.attachmentLinkChange.emit(this.attachmentLink);
    }
    @Output() attachmentLinkChange = new EventEmitter();

    selectedCategory: string = "all"
    allCategoryString: string = "all"

    attachments: Attachment[] | undefined;

    private _attachmentsByCategories: AttachmentCategory[] | undefined;
    public get attachmentsByCategories(): AttachmentCategory[] | undefined {
        return this._attachmentsByCategories;
    }
    public set attachmentsByCategories(value: AttachmentCategory[] | undefined) {
        this.ngZone.run(() => this._attachmentsByCategories = value);
    }

    scrollPosition: number = 0;
    public scrollUnlocked: boolean = false;

    private subscriptions: Subscription[] = [];

    constructor(
        public offlineModeService: OfflineModeService,
        public bookDatabaseService: BookDatabaseService,
        public bookService: BooksService,
        public http: HttpClient,
        public sanitizer: DomSanitizer,
        public deviceDetector: DeviceDetectorService,
        public modalCtrl: ModalController,
        private ref: ChangeDetectorRef,
        public screenOrientation: ScreenOrientation,
        public toastCtrl: ToastController,
        public localization: L10nTranslationService,
        private ngZone: NgZone,
        @Inject(L10N_LOCALE) public locale: L10nLocale
    ) {
        this.subscriptions.push(
            this.screenOrientation.onChange().subscribe(async () => {
                if (!this.deviceDetector.isDesktop()) {
                    this.scrollUnlocked = this.screenOrientation.type === 'landscape'
                        || this.screenOrientation.type === 'landscape-primary'
                        || this.screenOrientation.type === 'landscape-secondary';
                    if (this.content) {
                        await this.content.scrollToPoint(0, this.scrollPosition);
                    }
                }
            })
        );
    }

    ngOnInit(): void {
        this.offlineModeService.checkPermissionForReadAndWrite();
        if (!this.deviceDetector.isDesktop()) {
            this.scrollUnlocked = this.screenOrientation.type === 'landscape'
                || this.screenOrientation.type === 'landscape-primary'
                || this.screenOrientation.type === 'landscape-secondary';
        }

        if (this.book?.id) {
            this.bookService.getAttachments(this.book?.id).subscribe(
                (attachmentsList: Attachment[]) => {
                    this.attachments = attachmentsList || [];
                    this.attachmentsByCategories = this.getAttachmentsByCategory(this.attachments, this.attachmentLink);
                },
                error => {
                    this.attachments = [];
                }
            );
        }
    }

    showAllAttachments() {
        this.attachmentLink = '';
    }

    private getAttachmentsByCategory(attachments: Attachment[], attachmentLink?: string): AttachmentCategory[] {
        return attachments.reduce((categories: AttachmentCategory[], attachment: Attachment) => {
            if(
                !attachmentLink || this.isAttachmentLinkedTo(attachment, attachmentLink)
            ) {
                const categoryName = attachment.filename.split('#')[0];
                const categoryIndex = categories.findIndex((category: AttachmentCategory) => category.name === categoryName);
                if (categoryIndex === -1) {
                    categories.push({
                        name: categoryName,
                        attachments: [attachment]
                    })
                } else {
                    categories[categoryIndex].attachments.push(attachment);
                }
                this.checkIfAttachmentIsDownloaded(attachment);
            }
            return categories;
        }, []);
    }

    private checkIfAttachmentIsDownloaded(attachment: Attachment): void {
        if (this.deviceDetector.isDesktop()) {
            attachment.percent = 0;
            return;
        };

        const attachmentName = this.getAttachmentName(attachment);

        this.offlineModeService.readAttachmentFromDevice(attachmentName).then(async res => {
            if (!res) {
                attachment.percent = 0;
                this.notifyChanges();
            } else {
                attachment.percent = 100;
                this.notifyChanges();
            }
        }).catch(e => {
            attachment.percent = 0;
            this.notifyChanges();
        });
    }


    async download(attachment: Attachment) {
        let attachmentName = this.getAttachmentName(attachment);
        let trimmedUrl = this.trimUrl(attachment.url);

        //ANDROID MOBILE APP
        let androidNativeCheck = await this.isAndroidAndShouldBeDownloaded();
        if (androidNativeCheck) {
            await Browser.open({url: trimmedUrl});
            return;
        } else if (attachment.percent === 100 && this.deviceDetector.isMobile()) {
            //CHECK IF ATTACHMENT ALREADY EXIST ON DEVICE
            await this.offlineModeService.readAttachmentFromDevice(attachmentName)
                .then(async res => {
                    if (!res) {
                        await this.fistTimeDownloading(attachment);
                    } else {
                        await this.downloadAgainModal(this.getSecPartOfSting(attachmentName, '#'), attachment);
                    }
                }).catch(async e => {
                    this.notifyChanges();
                });
        } else {
            await this.fistTimeDownloading(attachment);
        }
    }

    public async fistTimeDownloading(attachment: Attachment, isAndroid?: boolean) {
        const isModalOpened = await this.modalCtrl.getTop();
        if(isModalOpened) await this.modalCtrl.dismiss(undefined, undefined, "download-again")
        attachment.percent = 0;
        let trimmedUrl = this.trimUrl(attachment.url);
        let attachmentName = this.getAttachmentName(attachment);

        this.offlineModeService.downloadFile(trimmedUrl).subscribe(
            async (event) => {
                if (event.type === HttpEventType.DownloadProgress) {
                    if (event.total) {
                        attachment.percent = await Math.round((100 * event.loaded) / event.total);
                        this.notifyChanges();
                    }
                } else if (event.type === HttpEventType.Response) {
                    attachment.percent = 100;
                    this.notifyChanges();
                    if (event.body) {
                        let blob = event.body;
                        let base64Data = await this._imageEncode(blob);

                        //DOWNLOAD IF APP IS NATIVE (BECAUSE IMPLEMENTATION FOR ANDROID
                        //IS ON TOP THIS PART IS JUST FOR IOS)
                        if (!this.deviceDetector.isDesktop() &&
                            !(this.deviceDetector.isMobileWeb() && !Capacitor.isNativePlatform())) {
                            await this.downloadMobile(attachmentName, base64Data, isAndroid);
                            return;
                        }
                        //DOWNLOAD DESKTOP AND MOBILE BROWSER
                        else {
                            await this.downloadDesktop(blob, trimmedUrl, attachmentName)
                        }
                    }
                }
            },
            async (err: any) => {
                console.log('error', err);

            });
    }

    public async downloadMobile(attachmentName: string, base64Data: any, isAndroid: any) {
        await this.offlineModeService
            .deviceDownloadAttachment(base64Data, attachmentName, this.deviceDetector.isIOS())
            .then(async res => {
                if (!isAndroid)
                    await this.showAttachmentInModal(res);
            })
            .catch(error => {
                console.log(error);
                this.toastCtrl.create({message: this.localization.translate('Attachment.DownloadError')});
            });
    }

    public async downloadDesktop(blob: any, url: any, attachmentName: string) {
        //IOS SAFARI SOLUTION
        if (!this.safariDetect() && this.deviceDetector.isIOS()) {
            let a = document.createElement("a");
            a.href = url;
            a.download = attachmentName;
            a.target = "_blank"
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
            }, 10);
            return;
        } else
            saveAs(blob, attachmentName);
    }

    public async isAndroidAndShouldBeDownloaded() {
        let android = false;
        if (!this.deviceDetector.isDesktop() && this.deviceDetector.isAndroid()) {
            android = true;
        }
        return android;
    }

    async downloadAgainModal(name: string, attachment: any) {
        let modalDiv = document.getElementById('append-modal');

        let cssClass = 'confirm-dialog';
        if (this.deviceDetector.isMobile()) cssClass = 'confirm-dialog-mobile';
        if (this.deviceDetector.isTabletSize()) cssClass = 'confirm-dialog-tablet';

        const dialog = await this.modalCtrl.create({
            id: "download-again",
            component: DialogComponent,
            cssClass: cssClass,
            backdropDismiss: false,
            enterAnimation: undefined,
            leaveAnimation: undefined,
            componentProps: {
                message: this.localization.translate('Attachment.AlreadyDownloaded', {name: name}),
                buttons: [
                    {
                        text: this.localization.translate('Attachment.Open'),
                        method: this.closeDownloadAgainModal.bind(this, attachment)
                    },
                    {
                        text: this.localization.translate('Attachment.DownloadAgain'),
                        method: this.fistTimeDownloading.bind(this, attachment),
                        style: 'fill'
                    }
                ],
                bookNoteCustomCss: true
            }
        });
        modalDiv?.appendChild(dialog);
        await dialog.present();
    }


    private async closeDownloadAgainModal(attachment?: Attachment) {
        const modalElem = await this.modalCtrl.getTop();
        if (modalElem) {
            await modalElem.dismiss();
        }

        if (attachment) {
            let attachmentName = this.getAttachmentName(attachment);
            await this.offlineModeService.getAttachmentUri(attachmentName).then(async res => {
                await this.showAttachmentInModal(res);
            })
        }
    }

    async showAttachmentInModal(data: any) {
        let cssClass = this.deviceDetector.isDesktop() ? 'ion-main-modal' : 'ion-main-modal-mobile'
        let modal = await this.modalCtrl.create({
            component: AttachmentModalComponent,
            cssClass: cssClass,
            componentProps: {
                data: data
            }
        });
        await modal.present();
    }

    public getAttachmentName(attachment: Attachment): string {
        let attachmentName = this.getSecPartOfSting(attachment.filename, '#');
        if (attachmentName.indexOf(".") <= 0) {
            let type = this.getSecPartOfSting(attachment.type, '/')
            return attachmentName + "." + type;
        }
        return attachmentName;
    }

    trimUrl(url: any): string {
        let _url = url;
        if (url.indexOf('?') >= 0) {
            _url = url.split('?');
            return _url[0];
        }
        return _url;
    }

    baseToBlob(base64: any, contentType?: string) {
        if (!contentType) contentType = 'application/pdf';
        if (!(base64.indexOf("base65,") >= 0)) {
            base64 = "data:" + contentType + ";base64," + base64;
        }
        const byteCharacters = atob(base64);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], {type: contentType});
    }

    _imageEncode(blob: Blob): Promise<any> {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    toggleCategory(name: any) {
        this.selectedCategory = name;
    }

    public transformDate(date: any) {
        return moment(date).calendar(null, {
            sameDay: 'DD.MM.YYYY',
            nextWeek: 'DD.MM.YYYY',
            lastDay: 'DD.MM.YYYY',
            lastWeek: 'DD.MM.YYYY',
            sameElse: 'DD.MM.YYYY',
        });
    }

    public getSecPartOfSting(str: any, separator: string) {
        let _type = str;
        if (str.indexOf(separator) >= 0) {
            _type = str.split(separator);
            return _type[1];
        }
        return _type;
    }

    formatSizeUnits(bytes: any) {
        if ((bytes >> 30) & 0x3FF)
            bytes = (bytes >>> 30) + '.' + (bytes & (3 * 0x3FF)) + ' GB';
        else if ((bytes >> 20) & 0x3FF)
            bytes = (bytes >>> 20) + '.' + (bytes & (2 * 0x3FF)) + ' MB';
        else if ((bytes >> 10) & 0x3FF)
            bytes = (bytes >>> 10) + '.' + (bytes & (0x3FF)) + ' KB';
        else if ((bytes >> 1) & 0x3FF)
            bytes = (bytes >>> 1) + 'Bytes';
        else
            bytes = bytes + 'Byte';
        return bytes;
    }


    public safariDetect() {
        let browser = (function () {
            let test = function (regexp: any) {
                return regexp.test(window.navigator.userAgent)
            }
            switch (true) {
                case test(/edg/i):
                    return "Microsoft Edge";
                case test(/trident/i):
                    return "Microsoft Internet Explorer";
                case test(/firefox|fxios/i):
                    return "Mozilla Firefox";
                case test(/opr\//i):
                    return "Opera";
                case test(/ucbrowser/i):
                    return "UC Browser";
                case test(/samsungbrowser/i):
                    return "Samsung Browser";
                case test(/chrome|chromium|crios/i):
                    return "Google Chrome";
                case test(/safari/i):
                    return "Apple Safari";
                default:
                    return "Other";
            }
        })();
        return browser === "Apple Safari"
    }

    public notifyChanges() {
        if (!(this.ref as ViewRef)?.destroyed) {
            this.ref.detectChanges();
        }
    }

    onIonScroll(event: any) {
        if (event.detail.scrollTop !== 0) {
            this.scrollPosition = event.detail.scrollTop;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    public getAttachmentElementId(attachment: Attachment): string {
        return 'attachment-list-' + decodeURI(attachment.filename);
    }

    public isAttachmentLinkedTo(attachment: Attachment, attachmentLink = this.attachmentLink) {
        return attachment.filename.normalize().toLowerCase() === decodeURI(attachmentLink).normalize().toLowerCase();
    }
}
