import { Component, Inject, OnInit } from '@angular/core';
import { L10N_CONFIG, L10nConfig, L10nSchema, L10nTranslationService } from 'angular-l10n';

interface Language {
  localeCode: String;
  langCode: String;
  icon: String;
}

@Component({
  selector: 'app-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.scss'],
})
export class LanguageSelectionComponent implements OnInit {
  languages: Language[];
  schema: L10nSchema[];
  selectedLang: Language;

  constructor(
    private translation: L10nTranslationService,
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
  ) {
    this.languages = [
      {
        localeCode: 'de-DE',
        langCode: 'DE',
        icon: '../../../assets/icon/de.svg',
      },
      {
        localeCode: 'en-GB',
        langCode: 'EN',
        icon: '../../../assets/icon/en.svg',
      },
    ];

    this.selectedLang = this.languages[0];

    let lang = localStorage.getItem('defaultLocale');
    if (lang && (lang === 'en-GB' || lang === 'en-US' || lang === 'en')) {
      this.selectedLang = this.languages[1];
    }

    this.schema = this.l10nConfig.schema;
  }

  ngOnInit() { }

  changeLanguage(lang: any) {
    localStorage.setItem('locale', lang.localeCode);
    localStorage.setItem('defaultLocale', lang.localeCode);
    this.translation.setLocale({language: lang.localeCode});
    this.selectedLang = lang;
  }
}
