import { ClientBook } from './../../PODO/clientBook';
import { TextHighlight } from './../rest-client/rest-client.service';
import { Capacitor } from '@capacitor/core';
import { Injectable } from '@angular/core';
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { Note } from '../rest-client/rest-client.service';
import { OfflineModeService } from './../offline-mode/offline-mode.service';
@Injectable({
  providedIn: 'root',
})
export class BookDatabaseService {
  sqlDB: any;
  NotesList: Note[] = [];
  note: Note | undefined;
  HighlightList: TextHighlight[] = [];
  booksList: ClientBook[] = [];

  constructor(private sqlite: SQLite, private offlineModeService: OfflineModeService) {
    if (Capacitor.isNativePlatform()) {
      this.sqlite
        .create({
          name: 'book-data.db',
          location: 'default',
        })
        .then((db: SQLiteObject) => {
          db.executeSql(
            'create table IF NOT EXISTS books (id INTEGER, userId TEXT, bookId INTEGER, lastPage INTEGER, lastSynchronized TEXT, synchronized TEXT, downloaded INTEGER, quizzes INTEGER,  lastOpened TIMESTAMP , title TEXT ,creator TEXT, urlCover TEXT, signedUrl TEXT, lastRead INTEGER, webBookUrl TEXT, opf_file TEXT, examples INTEGER, exercises INTEGER, pages INTEGER, videos INTEGER, size TEXT, UNIQUE(id, bookId))',
            []
          )
            .then(() => console.log('created table books'))
            .catch((e) => console.log(e));

          db.executeSql(
            'create table IF NOT EXISTS contents (bookId INTEGER, id INTEGER, de TEXT, en TEXT, value INTEGER, UNIQUE(id, bookId))',
            []
          )
            .then(() => console.log('created table contents'))
            .catch((e) => console.log(e));

          this.sqlDB = db;
        })

        .catch((e) => console.log(e));
    }
  }
  // CRUD FOR BOOKS

  public saveBook(
    id: string,
    userId: string,
    bookId: number,
    lastPage: number,
    lastSynchronized: number,
    synchronized: number,
    downloaded: boolean,
    quizzes: number,
    lastOpened: number,
    title: string,
    creator: string,
    urlCover: string,
    signedUrl: string,
    lastRead: number,
    webBookUrl: string,
    opf_file: string,
    examples: number,
    exercises: number,
    pages: number,
    videos: number,
    size: number
  ) {
    let q = 'INSERT INTO books VALUES (?, ?,?, ?, ?, ?, ?, ?,?,?,?, ?,?, ?, ?, ?, ?, ?,?,?,?)';
    this.sqlDB
      .executeSql(q, [
        id,
        userId,
        bookId,
        lastPage,
        lastSynchronized,
        synchronized,
        downloaded,
        quizzes,
        lastOpened,
        title,
        creator,
        urlCover,
        signedUrl,
        lastRead,
        webBookUrl,
        opf_file,
        examples,
        exercises,
        pages,
        videos,
        size,
      ])
      .then(/*() => console.log('Executed saving Book ' + id, userId)*/)
      .catch((e: any) => {
        console.log('error in saveNote' + JSON.stringify(e));
      });
  }

  public updateBook(
    id: string,
    userId: string,
    bookId: number,
    lastPage: number,
    lastSynchronized: number,
    synchronized: number,
    downloaded: boolean,
    quizzes: number,
    lastOpened: number,
    title: string,
    creator: string,
    urlCover: string,
    signedUrl: string,
    lastRead: number,
    webBookUrl: string,
    opf_file: string,
    examples: number,
    exercises: number,
    pages: number,
    videos: number,
    size: number
  ) {
    let q =
      'UPDATE books set id = ?, userId = ?, lastPage = ?, lastSynchronized = ?, synchronized = ?, downloaded = ?, quizzes = ?,  lastOpened = ? , title = ? ,creator = ?, urlCover = ?, signedUrl = ?, lastRead = ?, webBookUrl = ?, opf_file = ?, examples = ?, exercises = ?, pages = ?, videos = ?, size = ? WHERE bookId = ?';
    this.sqlDB
      .executeSql(q, [
        id,
        userId,
        bookId,
        lastPage,
        lastSynchronized,
        synchronized,
        downloaded,
        quizzes,
        lastOpened,
        title,
        creator,
        urlCover,
        signedUrl,
        lastRead,
        webBookUrl,
        opf_file,
        examples,
        exercises,
        pages,
        videos,
        size,
        bookId,
      ])
      .catch((e: any) => {
        console.log('error in updateBook' + JSON.stringify(e));
      });
  }

  public updateLastOpenedDate(date: number, id: number) {
    let q = 'UPDATE books set lastRead = ? WHERE bookId = ?';
    this.sqlDB.executeSql(q, [date, id]).catch((e: any) => {
      console.log('error in updateLastOpenedDate' + JSON.stringify(e));
    });
  }

  public setSynchronizedState(state: string, bookId: number) {
    let q = 'UPDATE books set synchronized = ? WHERE bookId = ?';
    this.sqlDB.executeSql(q, [state, bookId]).catch((e: any) => {
      console.log('error in setSynchronizedState' + JSON.stringify(e));
    });
  }

  public setlastSynchronizationDatebyBookId(bookId: number) {
    let q = 'UPDATE books set lastSynchronized = ? WHERE bookId = ?';
    this.sqlDB.executeSql(q, [Date.now(), bookId]).catch((e: any) => {
      console.log('error in setSynchronizedState' + JSON.stringify(e));
    });
  }

  public async getLastSynchronizationDateByBookId(bookId: number) {
    let date: string = '';
    let q = 'SELECT lastSynchronized FROM books WHERE bookId = ?';
    return this.sqlDB
      .executeSql(q, [bookId])
      .then((res: any) => {
        if (res.rows.length > 0) {
          for (let i = 0; i < res.rows.length; i++) {
            date = res.rows.item(i).lastSynchronized;
            return date;
          }
        }
      })
      .catch((e: any) => {
        console.log('error in getAllBooks' + JSON.stringify(e));
      });
  }

  public getAllBooksByUserId(userId: string) {
    this.booksList = [];
    let q = 'SELECT * FROM books WHERE userId = ?';
    this.sqlDB
      .executeSql(q, [userId])
      .then(async (res: any) => {
        if (res.rows.length > 0) {
          let downloaded = false;

          for (let i = 0; i < res.rows.length; i++) {
            await this.offlineModeService
              .checkIfFileExists(res.rows.item(i).bookId.toString())
              .then((res) => (downloaded = !!res?.size));
            let book: ClientBook | undefined = {
              id: res.rows.item(i).bookId,
              title: res.rows.item(i).title,
              creator: res.rows.item(i).creator,
              urlCover: await Capacitor.convertFileSrc(
                (await this.offlineModeService.getApplicationPath(res.rows.item(i).urlCover)).uri.toString()
              ),
              signedUrl: res.rows.item(i).signedUrl,
              lastRead: res.rows.item(i).lastRead,
              webBookUrl: res.rows.item(i).webBookUrl,
              opf_file: res.rows.item(i).opf_file,
              quizzes: res.rows.item(i).quizzes,
              examples: res.rows.item(i).examples,
              exercises: res.rows.item(i).exercises,
              pages: res.rows.item(i).pages,
              videos: res.rows.item(i).videos,
              downloaded: downloaded,
              size: res.rows.item(i).size,
              contents: this.getContentByBookID(res.rows.item(i).bookId),
              synchronized: res.rows.item(i).synchronized,
              lastPage: res.rows.item(i).lastPage,
            };
            this.booksList.push(book);
          }
        }
      })
      .catch((e: any) => {
        console.log('error in getAllBooks' + JSON.stringify(e));
      });
    return this.booksList;
  }

  public getBookByUserIdAndBookID(userId: string, bookId: number) {
    let q = 'SELECT * FROM books WHERE userId = ? AND bookId = ?';
    this.sqlDB
      .executeSql(q, [userId, bookId])
      .then(async (res: any) => {
        if (res.rows.length > 0) {
          for (let i = 0; i < res.rows.length; i++) {
            let book: ClientBook | undefined = {
              id: res.rows.item(i).bookId,
              title: res.rows.item(i).title,
              creator: res.rows.item(i).creator,
              urlCover: res.rows.item(i).urlCover,
              signedUrl: res.rows.item(i).signedUrl,
              lastRead: res.rows.item(i).lastRead,
              webBookUrl: res.rows.item(i).webBookUrl,
              opf_file: res.rows.item(i).opf_file,
              quizzes: res.rows.item(i).quizzes,
              examples: res.rows.item(i).examples,
              exercises: res.rows.item(i).exercises,
              pages: res.rows.item(i).pages,
              videos: res.rows.item(i).videos,
              downloaded: false,
              size: res.rows.item(i).size,
              contents: await this.getContentByBookID(res.rows.item(i).bookId),
              synchronized: res.rows.item(i).synchronized,
              lastPage: res.rows.item(i).lastPage,
            };
            return book;
          }
        }
      })
      .catch((e: any) => {
        console.log('error in getBookByUserIdAndBookID' + JSON.stringify(e));
      });
  }

  public getAllBooksbyUserIdAndSynchronized(userId: string, synchronized: string) {
    this.booksList = [];
    let q = 'SELECT * FROM books WHERE userId = ? AND synchronized = ?';
    this.sqlDB
      .executeSql(q, [userId, synchronized])
      .then((res: any) => {
        if (res.rows.length > 0) {
          for (let i = 0; i < res.rows.length; i++) {
            let book: ClientBook | undefined = {
              id: res.rows.item(i).bookId,
              title: res.rows.item(i).title,
              creator: res.rows.item(i).creator,
              urlCover: res.rows.item(i).urlCover,
              signedUrl: res.rows.item(i).signedUrl,
              lastRead: res.rows.item(i).lastRead,
              webBookUrl: res.rows.item(i).webBookUrl,
              opf_file: res.rows.item(i).opf_file,
              quizzes: res.rows.item(i).quizzes,
              examples: res.rows.item(i).examples,
              exercises: res.rows.item(i).exercises,
              pages: res.rows.item(i).pages,
              videos: res.rows.item(i).videos,
              downloaded: false,
              size: res.rows.item(i).size,
              contents: this.getContentByBookID(res.rows.item(i).bookId),
              synchronized: res.rows.item(i).synchronized,
              lastPage: res.rows.item(i).lastPage,
            };
            this.booksList.push(book);
          }
        }
      })
      .catch((e: any) => {
        console.log('error in getAllBooks' + JSON.stringify(e));
      });
    return this.booksList;
  }

  public DeleteAllBooksByUserId(userId: string) {
    let q = 'DELETE FROM books WHERE userId = ?';
    this.sqlDB
      .executeSql(q, [userId])
      .then((res: any) => {
        console.log('row deleted');
      })
      .catch((e: any) => {
        console.log('error in DeleteAllBooksByUserId' + JSON.stringify(e));
      });
  }

  public DeleteBookByBookId(userId: string, bookId: number) {
    let q = 'DELETE FROM books WHERE userId = ? AND bookId = ?';
    this.sqlDB
      .executeSql(q, [userId, bookId])
      .then((res: any) => {
        console.log('row deleted');
      })
      .catch((e: any) => {
        console.log('error in DeleteBookByBookId' + JSON.stringify(e));
      });
  }

  public DeleteAllBooks() {
    let q = 'DELETE FROM books';
    this.sqlDB
      .executeSql(q)
      .then((res: any) => {
        console.log('table deleted');
      })
      .catch((e: any) => {
        console.log('error in DeleteBookByBookId' + JSON.stringify(e));
      });
  }

  //CRUD FOR CONTENTS

  public saveContent(bookId: number, id: number, de: string, en: string, value: number) {
    let q = 'INSERT INTO contents VALUES (?,?,?,?,?)';
    this.sqlDB
      .executeSql(q, [bookId, id, de, en, value])
      .then((res: any) => {
        console.log('content saved');
      })
      .catch((e: any) => {
        console.log('error in saveContent' + JSON.stringify(e));
      });
  }

  public getContentByBookID(bookId: number) {
    let contents: any[] = [];
    contents = [];
    let q = 'SELECT * FROM contents WHERE bookId = ?';
    this.sqlDB
      .executeSql(q, [bookId])
      .then((res: any) => {
        if (res.rows.length > 0) {
          for (let i = 0; i < res.rows.length; i++) {
            let content: { id: string; de: string; en: string; value: number } = {
              id: res.rows.item(i).id,
              de: res.rows.item(i).de,
              en: res.rows.item(i).en,
              value: res.rows.item(i).value,
            };
            contents.push(content);
          }
        } else {
        }
      })
      .catch((e: any) => {
        console.log('error in getBookByUserIdAndBookID' + JSON.stringify(e));
      });
    return contents;
  }

  public updateContent(bookId: number, id: number, de: string, en: string, value: number) {
    let q = 'UPDATE contents set de = ?, en = ?, value = ? WHERE bookId = ? AND id = ?';
    this.sqlDB
      .executeSql(q, [de, en, value, bookId, id])
      .then((res: any) => {
        console.log('content updated');
      })
      .catch((e: any) => {
        console.log('error in updateContent' + JSON.stringify(e));
      });
  }

  public deleteContentByBookID(bookId: number) {
    let q = 'DELETE FROM contents WHERE bookId = ? ';
    this.sqlDB
      .executeSql(q)
      .then((res: any) => {
        console.log('table deleted');
      })
      .catch((e: any) => {
        console.log('error in deleteContentByBookID' + JSON.stringify(e));
      });
  }

  public deleteAllContents() {
    let q = 'DELETE FROM contents';
    this.sqlDB
      .executeSql(q)
      .then((res: any) => {
        console.log('table deleted');
      })
      .catch((e: any) => {
        console.log('error in deleteAllContents' + JSON.stringify(e));
      });
  }
}
