import { Component, Inject, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ModalController } from "@ionic/angular";
import { DeviceDetectorService } from "../../../../util/device-detector/device-detector.service";
import { Capacitor } from "@capacitor/core";
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

@Component({
  selector: 'app-attachment-modal',
  templateUrl: './attachment-modal.component.html',
  styleUrls: ['./attachment-modal.component.scss'],
})
export class AttachmentModalComponent implements OnInit {

  @Input() data: any;

  constructor(public sanitizer: DomSanitizer,
    public modalCtrl: ModalController,
    public deviceDetector: DeviceDetectorService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {
  }

  ngOnInit() {
    if (this.deviceDetector.isIOS()) {
      this.data = Capacitor.convertFileSrc(this.data)
    }
  }

  async closeAttachmentModal() {
    await this.modalCtrl.dismiss();
  }

  isVideo(data: string): boolean {
    let _url = data.split('.');
    if (_url.length > 0) {
      let lastIndex = _url.length - 1;
      console.log("IS VIDEO :: 2 :: ", _url[lastIndex])
      console.log(_url[lastIndex] === ("mp4" || "mov" || "m4v" || "3gp"))
      return ["mp4", "mov", "m4v", "3gp"].indexOf(_url[lastIndex]) >= 0;
    }
    return false;
  }

  isImage(data: string): boolean {
    let _url = data.split('.');
    if (_url.length > 0) {
      let lastIndex = _url.length - 1;
      return ["image", "png", "jpg", "jpeg", "gif", "bitmoji"].indexOf(_url[lastIndex]) >= 0;
    }
    return false;
  }

  public sanitizerData() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.data);
  }

}
