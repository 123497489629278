import { Capacitor } from '@capacitor/core';
import { Component, Inject, OnInit } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { DeviceDetectorService } from '../../util/device-detector/device-detector.service';
import { ModalController } from '@ionic/angular';
import { DataPrivacyComponent } from '../../components/data-privacy/data-privacy.component';
import { ImprintComponent } from '../../components/imprint/imprint.component';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public currentYear: String = '';
  public native: boolean = false;

  constructor(
    private deviceDetector: DeviceDetectorService,
    private modalCtrl: ModalController,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngOnInit() {
    this.native = Capacitor.isNativePlatform();
    this.currentYear = new Date().getFullYear().toString();
  }

  public async openDataPrivacy() {
    const popover = await this.modalCtrl.create({
      component: DataPrivacyComponent,
    });
    return await popover.present();
  }

  public async browse(target: string) {
    let lng = localStorage.getItem('defaultLocale');
    let lngEN = lng === 'en';
    switch (target) {
      case 'datenschutz':
        {
          if (this.deviceDetector.isDesktop()) {
            await Browser.open({
              url: lngEN ? 'https://litello.com/en/privacy-policy' : 'https://litello.com/datenschutz',
            });
          } else {
            const popover = await this.modalCtrl.create({
              component: DataPrivacyComponent,
            });
            return await popover.present();
          }
        }
        break;
      case 'impressum':
        {
          if (this.deviceDetector.isDesktop()) {
            await Browser.open({
              url: lngEN ? 'https://litello.com/en/imprint' : 'https://litello.com/impressum',
            });
          } else {
            const popover = await this.modalCtrl.create({
              component: ImprintComponent,
            });
            return await popover.present();
          }
        }
        break;
    }
  }
}
