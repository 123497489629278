<ion-content>
  <div class="login-background">
    <ion-grid>
      <ion-row class="ion-justify-content-center">
        <ion-col class="login-logo-col" size-lg="4" size-sm="4" size-xs="12">
          <img
            *ngIf="themeService.modul.DoubleIcons === 1"
            [ngStyle]="{ 'margin-top': native ? '30px' : '50px' }"
            class="double-logo"
            [src]="themeService.getLoginLogo2()"
          />
          <img
            *ngIf="themeService.modul.DoubleIcons === 1"
            [ngStyle]="{ 'margin-top': native ? '30px' : '50px' }"
            class="double-logo2"
            [src]="themeService.getLoginLogo3()"
          />
        </ion-col>

        <ion-col class="login-logo-col" size-lg="4" size-sm="4" size-xs="12"
          ><img
            *ngIf="themeService.modul.DoubleIcons === 0"
            [ngStyle]="{ 'margin-top': native ? '30px' : '50px' }"
            class="top-logo"
            [src]="themeService.getLoginLogo()"
        /></ion-col>
        <ion-col class="login-logo-col" size-lg="4" size-sm="4" size-xs="12"></ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col></ion-col>
        <ion-col class="container-col" size-lg="8" size-xs="12" size-md="10">
          <!---  Webreader  -->
          <div *ngIf="!native && themeService.modul.SSOLogin === 0" class="login-form">
            <ion-row>
              <ion-col>
                <app-language-selection class="ion-float-right"></app-language-selection>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center">
              <ion-col size-lg="9" size-xs="10"
                ><p
                  [ngStyle]="{ 'font-size': native ? '24px' : '32px' }"
                  id="login-title"
                  class="title"
                  l10nTranslate=""
                >
                  Authentication.LoginTitle
                </p></ion-col
              >
              <ion-col size-lg="9" size-xs="10">
                <p [ngStyle]="{ 'font-size': native ? '16px' : '18px' }" id="login-text1" class="login-text">
                  {{ themeService.getLoginTextLine1() }}
                </p></ion-col
              >
              <ion-col size-lg="9" size-xs="10">
                <p [ngStyle]="{ 'font-size': native ? '16px' : '18px' }" id="login-text2" class="login-text">
                  <span class="login-text1" *ngIf="themeService.getLoginTextLine2().text1 !== ''">{{
                    themeService.getLoginTextLine2().text1
                  }}</span>
                  <br />
                  <span>{{ themeService.getLoginTextLine2().text2 }}</span>
                </p>
              </ion-col>
            </ion-row>

            <form [formGroup]="formGroup">
              <ion-row class="ion-justify-content-center">
                <ion-col size-lg="9" size-xs="10">
                  <ion-item lines="none">
                    <ion-input
                      id="login-email"
                      #email
                      formControlName="email"
                      name="email"
                      type="email"
                      placeholder="{{ emailPlaceholder }}"
                      (ionFocus)="resetForm()"
                      autocomplete
                      (keyup)="submitOnEnter($event)"
                    ></ion-input>
                    <ion-icon
                      class="invalidIcon"
                      (click)="resetMailField()"
                      name="close-circle"
                      *ngIf="
                        !formGroup.controls.email.valid &&
                        (formGroup.controls.email.dirty || formGroup.controls.email.touched)
                      "
                    ></ion-icon>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-input
                      id="login-pw"
                      class="pInput"
                      #password
                      formControlName="password"
                      name="password"
                      type="password"
                      placeholder="{{ passwordPlaceholder }}"
                      (ionFocus)="resetForm()"
                      (keyup)="submitOnEnter($event)"
                    ></ion-input>
                    <ion-icon
                      class="invalidIcon"
                      (click)="resetPasswordField()"
                      name="close-circle"
                      *ngIf="
                        !formGroup.controls.password.valid &&
                        (formGroup.controls.password.dirty || formGroup.controls.password.touched)
                      "
                    ></ion-icon>
                  </ion-item>
                </ion-col>
                <ion-col *ngIf="wrongCredentialsError || noNetworkConnection" size-lg="9" size-xs="11">
                  <p class="errorMessage" *ngIf="wrongCredentialsError" l10nTranslate="">
                    Authentication.WrongCredentialsError
                  </p>
                  <p class="errorMessage" *ngIf="noNetworkConnection" l10nTranslate="">
                    Authentication.NoNetworkConnection
                  </p>
                </ion-col>
              </ion-row>
            </form>
            <ion-row class="ion-justify-content-center buttons">
              <ion-col *ngIf="themeService.modul.registration === 1" size-lg="4.5" size-md="4" size-xs="10"
                ><ion-button id="login-signup-btn" class="signUp-btn" (click)="signUp()" expand="full"
                  ><p l10nTranslate>Authentication.SignUpButton</p></ion-button
                ></ion-col
              >
              <ion-col size-lg="4.5" size-md="4" size-xs="10">
                <ion-button
                  id="login-signin-btn"
                  class="signIn-btn"
                  [disabled]="!formGroup.valid"
                  (click)="signIn()"
                  expand="full"
                  ><p l10nTranslate>Authentication.LoginButton</p></ion-button
                ></ion-col
              >
            </ion-row>

            <ion-row class="ion-justify-content-center">
              <p
                *ngIf="themeService.modul.registration === 1"
                id="passwordForgot"
                l10nTranslate=""
                (click)="navigateResetPassword()"
              >
                Authentication.GoToResetButton
              </p>
            </ion-row>
          </div>

          <!--SSO-->

          <div *ngIf="!native && themeService.modul.SSOLogin === 1" class="login-form">
            <ion-row>
              <ion-col>
                <app-language-selection class="ion-float-right"></app-language-selection>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center">
              <ion-col size-lg="9" size-xs="10"
                ><p class="SSO-title" l10nTranslate>Authentication.SSO-Title</p></ion-col
              >
              <ion-col size-lg="9" size-xs="10">
                <p [ngStyle]="{ 'font-size': native ? '16px' : '18px' }" id="login-text1" class="login-text-SSO">
                  {{ themeService.getLoginTextLine1() }}
                </p></ion-col
              >
            </ion-row>

            <ion-row class="ion-justify-content-center buttons">
              <ion-col size-lg="4.5" size-md="4" size-xs="10">
                <ion-button id="login-signin-btn" (click)="SSOlogin()" class="signIn-btn" expand="full"
                  ><p l10nTranslate>Authentication.SSOButton</p></ion-button
                ></ion-col
              >
            </ion-row>

            <ion-row class="ion-justify-content-center"> </ion-row>
          </div>

          <!---  MOBILE APP  -->
          <div *ngIf="native">
            <ion-row class="ion-justify-content-center">
              <ion-col size-lg="9" size-xs="10"
                ><p
                  id="login-title"
                  class="title"
                  [ngStyle]="{ 'font-size': native ? '24px' : '32px' }"
                  l10nTranslate=""
                >
                  Authentication.LoginTitle
                </p></ion-col
              >
              <ion-col size-lg="9" size-xs="10"
                ><p id="login-text1" class="login-text" [ngStyle]="{ 'font-size': native ? '16px' : '18px' }">
                  {{ themeService.getLoginTextLine1() }}
                </p></ion-col
              >
            </ion-row>

            <form [formGroup]="formGroup">
              <ion-row class="ion-justify-content-center">
                <ion-col size-lg="9" size-xs="11">
                  <ion-item lines="none">
                    <ion-input
                      id="login-email"
                      #email
                      formControlName="email"
                      name="email"
                      type="email"
                      placeholder="{{ emailPlaceholder }}"
                      (ionFocus)="resetForm()"
                      autocomplete
                      (keyup)="submitOnEnter($event)"
                    ></ion-input>
                    <ion-icon
                      class="invalidIcon"
                      (click)="resetMailField()"
                      name="close-circle"
                      *ngIf="
                        !formGroup.controls.email.valid &&
                        (formGroup.controls.email.dirty || formGroup.controls.email.touched)
                      "
                    ></ion-icon>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-input
                      id="login-pw"
                      class="pInput"
                      #password
                      formControlName="password"
                      name="password"
                      type="password"
                      placeholder="{{ passwordPlaceholder }}"
                      (ionFocus)="resetForm()"
                      (keyup)="submitOnEnter($event)"
                    ></ion-input>
                    <ion-icon
                      class="invalidIcon"
                      (click)="resetPasswordField()"
                      name="close-circle"
                      *ngIf="
                        !formGroup.controls.password.valid &&
                        (formGroup.controls.password.dirty || formGroup.controls.password.touched)
                      "
                    ></ion-icon>
                  </ion-item>
                </ion-col>
              </ion-row>

              <ion-row>
                <ion-col *ngIf="wrongCredentialsError || noNetworkConnection" size-lg="9" size-xs="11">
                  <p class="errorMessage" *ngIf="wrongCredentialsError" l10nTranslate="">
                    Authentication.WrongCredentialsError
                  </p>
                  <p class="errorMessage" *ngIf="noNetworkConnection" l10nTranslate="">
                    Authentication.NoNetworkConnection
                  </p>
                </ion-col>
              </ion-row>
            </form>
            <ion-row class="ion-justify-content-center m-buttons">
              <ion-col size-lg="9" size-xs="11">
                <ion-button
                  id="login-signin-btn"
                  class="m-signIn-btn"
                  [disabled]="!formGroup.valid"
                  (click)="signIn()"
                  expand="full"
                  ><p l10nTranslate>Authentication.LoginButton</p></ion-button
                ></ion-col
              >
            </ion-row>
            <ion-row class="ion-justify-content-center">
              <ion-col size-lg="9" size-xs="11">
                <p id="login-text2" class="noAccountText" l10nTranslate="">
                  Authentication.NoAccountYet
                  <a (click)="signUp()" class="registerlink" l10nTranslate="">Authentication.RegisterHere</a>
                </p></ion-col
              >
              <ion-col size-lg="9" size-xs="11">
                <p id="passwordForgot" l10nTranslate="" (click)="navigateResetPassword()">
                  Authentication.GoToResetButton
                </p>
              </ion-col>
            </ion-row>
            <ion-row class="ion-justify-content-center">
              <app-language-selection></app-language-selection>
            </ion-row>
          </div>
        </ion-col>
        <ion-col></ion-col>
      </ion-row>
    </ion-grid>
  </div>
  <app-footer></app-footer>
</ion-content>
