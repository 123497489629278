import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ThemeService } from '../services/themes/theme.service';

@Injectable({
  providedIn: 'root'
})
export class ThemeInitializationGuard implements CanActivate {

  constructor(
    private themeService: ThemeService
  ) {}

  canActivate(): Observable<boolean> {
    return this.themeService.isInitialized;
  }
}
