import { ISyncService, TextHighlight } from './../rest-client/rest-client.service';
import { Capacitor } from '@capacitor/core';
import { Injectable } from '@angular/core';
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { Note } from '../rest-client/rest-client.service';
import { from, Observable } from 'rxjs';
import { ThemeType } from './dataTypes/themeType';
import { HttpClient } from '@angular/common/http';
import { SQLitePorter } from '@awesome-cordova-plugins/sqlite-porter/ngx';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Http, HttpDownloadFileResult } from '@capacitor-community/http';
import { IMAGE_KEYS } from '../themes/constants/theme-constants';
import { IconsInfoType } from './dataTypes/iconsInfoType';

const packOfValue: string = '?, ?, ?, ?, ?';
const dbVal: string = '?, ';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService implements ISyncService {
  sqlDB: any;

  NotesList: Note[] = [];

  note: Note | undefined;

  HighlightList: TextHighlight[] = [];

  themeType: ThemeType | undefined = {} as ThemeType;

  constructor(private sqlite: SQLite, private httpClient: HttpClient, private sqlPorter: SQLitePorter) {
    if (Capacitor.isNativePlatform()) {
      this.sqlite
        .create({
          name: 'data.db',
          location: 'default',
        })
        .then((db: SQLiteObject) => {
          this.sqlDB = db;
          this.createTables();
          db.executeSql(
            `create table IF NOT EXISTS textHighlights(
                                                          id VARCHAR(255) UNIQUE,
                                                          userId VARCHAR(255),
                                                          bookId INT(11),
                                                          cfi VARCHAR(255) UNIQUE,
                                                          start VARCHAR(255),
                                                          end VARCHAR(255),
                                                          spine INT(11),
                                                          color VARCHAR(255),
                                                          creationDate TIMESTAMP DEFAULT CURRENT_TiMESTAMP NOT NULL,
                                                          synchronized VARCHAR(255)
             )`,
            []
          )
            .then(() => console.log('created table texthighlights'))
            .catch((e) => console.log(e));

          db.executeSql(
            `create table IF NOT EXISTS notes(
                                                 id VARCHAR(255) UNIQUE,
                                                 bookId INT(11),
                                                 note VARCHAR(255),
                                                 pageId VARCHAR(255) UNIQUE,
                                                 color VARCHAR(255),
                                                 pageNumber INT(11),
                                                 creationDate TIMESTAMP DEFAULT CURRENT_TiMESTAMP NOT NULL,
                                                 synchronized VARCHAR(255)
             )`,
            []
          )
            .then(() => console.log('created table notes'))
            .catch((e) => console.log(e));
        })
        .catch((e) => console.log(e));
    }
  }

  /*
  SERVICE TO SAVE HIGHLIGHTS AND NOTES IN LOCAL DATABASE

  EXPLANATION FOR ATTRIBUTE 'synchronized' IN TABLE 'texthiglights' AND 'notes'

  Value = Description

  '1' = texthighlight or note has been successfully updated
  '2' = texthighlight or note has been created locally bot not synchronized with the backend
  '3' = texthighlight or note has been changed (updated) locally but not synchronized with the backend
  '4' = texthighlight or note has been deleted locally but not synchronized with the backend

  Value '2', '3', '4' will change to value '1' once the entry has been synchronized

  Synchronization will be triggered when the internet connection is active AND when saving a note or a highlight, by opening a book or 
  by manually pushing the button 'synchronize' under the menu-option 'local storage'.
  */

  createTables() {
    this.httpClient.get('assets/db/litello.sql', { responseType: 'text' }).subscribe((data: any) => {
      this.sqlPorter
        .importSqlToDb(this.sqlDB, data)
        .then((x: number) => console.log(`litello sqlite file ran ${x} commands!`))
        .catch((error) => console.error(error));
    });
  }

  async hasTableAndRows(tableName: string) {
    const q = "SELECT name FROM sqlite_master WHERE type='table' AND name=?";
    return this.sqlDB.executeSql(q, [tableName]).then((res: any) => {
      if (res.rows.length !== 0) {
        const countQuery = `SELECT COUNT(*) FROM 'icons'`;
        return this.sqlDB.executeSql(countQuery, []).then((res: any) => {
          return res.rows.item(0)['COUNT(*)'] > 0;
        });
      } else {
        return false;
      }
    });
  }

  async hasRow(tableName: string) {}

  // CRUD FOR THEME

  async saveThemeOnLocalDb(
    fontCatList: any,
    iconsList: any,
    customerAttrList: any,
    fontsAttrList: any,
    colorPaletteAttrList: any
  ) {
    await this.saveFontCat(fontCatList as []).then(() => {
      this.getDownloadObjInfoFontCat().then((r: object[]) => {
        this.downloadAndUpdateFontCat(r);
      });
    });
    await this.saveBackgroundColors(iconsList as []).then(() => {
      this.saveIconsAfterDownloading(iconsList as []);
    });
    await this.saveCustomer(customerAttrList as []);
    await this.saveFonts(fontsAttrList as []);
    await this.saveColorPalette(colorPaletteAttrList as []);
  }

  saveLastUpdateDate(time: any, customer: string) {
    let q = `INSERT OR REPLACE INTO lastUpdateDate VALUES ('${customer}', '${time}');`;
    this.sqlDB
      .executeSql(q, [])
      .then(() => console.log('Executed saving LastUpdateDate'))
      .catch((e: any) => {
        console.log('error in saveLastUpdateDate table' + JSON.stringify(e));
      });
  }

  async getLastUpdateDateByCustomer(customer: string) {
    let date: number = 0;
    let q = `SELECT lastUpdated FROM lastUpdateDate WHERE customer = '${customer}'`;
    await this.sqlDB
      .executeSql(q, [])
      .then((res: any) => {
        if (res.rows.length > 0) {
          date = res.rows.item(0).lastUpdated;
        }
      })
      .catch((e: any) => {
        console.log('error in getLastUpdateDateByCustomer' + JSON.stringify(e));
      });

    return date;
  }

  // CRUD FOR color_palette

  public async saveColorPalette(attrList: any) {
    let test = "('kea','valusi')";
    let k = 0;
    for (let item in attrList) {
      test = test + ",('" + attrList[item].key + "','" + attrList[item].value + "')";
      k = k + 1;
    }

    let q = `INSERT INTO color_palettes (varName, varValue) VALUES ${test};`;
    console.log(q);
    return this.clearTable('color_palettes').then(() => {
      this.sqlDB
        .executeSql(q, [])
        .then(() => console.log('Executed saving color_palettes'))
        .catch((e: any) => {
          console.log('error in color_palettes table' + JSON.stringify(e));
        });
    });
  }

  // CRUD FOR CUSTOMER

  public async saveCustomer(attrList: []) {
    let q = `INSERT INTO customers VALUES (${packOfValue}, ${packOfValue}, ${packOfValue}, ${packOfValue}, ${packOfValue}, ${packOfValue}, ?, ?)`;
    return this.clearTable('customers').then(() => {
      this.sqlDB
        .executeSql(q, attrList)
        .then(() => console.log('Executed saving customer'))
        .catch((e: any) => {
          console.log('error in customer table' + JSON.stringify(e));
        });
    });
  }

  // CRUD FOR FONTS

  public async saveFonts(attrList: []) {
    let q = `INSERT INTO fonts VALUES (${packOfValue}, ${packOfValue}, ${packOfValue}, ?, ?, ?, ?)`;
    return this.clearTable('fonts').then(() => {
      this.sqlDB
        .executeSql(q, attrList)
        .then(() => console.log('Executed saving fonts'))
        .catch((e: any) => {
          console.log('error in fonts' + JSON.stringify(e));
        });
    });
  }

  // CRUD FOR FONT_CAT

  public async saveFontCat(attrList: []) {
    let q = 'INSERT INTO fonts_category VALUES (?, ?, ?, ?, ?)';
    return this.clearTable('fonts_category').then(() => {
      attrList.forEach((row: any) => {
        this.sqlDB
          .executeSql(q, [row.id, row.family, row.src, row.weight, row.category_name])
          .then(() => console.log('Executed saving font category'))
          .catch((e: any) => {
            console.log('error in saveFontCat' + JSON.stringify(e));
          });
      });
    });
  }

  public async getDownloadObjInfoFontCat() {
    let q = 'SELECT id, family, src FROM fonts_category';
    return await this.sqlDB.executeSql(q, []).then((res: any) => {
      let resultArray: string[] = [];
      for (let i = 0; i < res.rows.length; i++) {
        resultArray.push(res.rows.item(i));
      }
      return resultArray;
    });
  }

  public async downloadAndUpdateFontCat(objInfoList: object[]) {
    await this.mkdir('fonts');
    objInfoList.forEach((objInfo: any) => {
      let newObjInfo: any = {
        id: '',
        src: '',
        family: '',
        format: '',
      };
      newObjInfo.format = objInfo.src.split(/[. ]+/).pop();
      newObjInfo.id = objInfo.id;
      this.downloadFile(objInfo.src, `fonts/${objInfo.family}.${newObjInfo.format}`).then((path) => {
        newObjInfo.src = path;
        this.updateFontCat(newObjInfo);
      });
    });
  }

  public async updateFontCat(objInfo: any) {
    console.log('fonts_cat: ', objInfo);
    let q = 'UPDATE fonts_category SET src=? WHERE id=?';
    return this.sqlDB
      .executeSql(q, [objInfo.src, objInfo.id])
      .then(() => console.log('Executed updating fonts_cat'))
      .catch((e: any) => {
        console.log('error in  updating fonts_cat' + JSON.stringify(e));
      });
  }

  // CRUD FOR ICONS
  async writeOnFile(path: string, data: any, directory: any, encoding: any) {
    return await Filesystem.writeFile({
      path: path,
      data: data,
      directory: directory,
      encoding: encoding,
    });
  }

  async downloadFile(url_: string, filePath: any) {
    const options = {
      url: url_,
      filePath: filePath,
      fileDirectory: Directory.Data,
      method: 'GET',
    };
    const response: HttpDownloadFileResult = await Http.downloadFile(options);
    return response ? Capacitor.convertFileSrc(response.path!.toString()) : undefined;
  }

  async fetchIcons(url_: string) {
    return await Http.request({
      method: 'GET',
      url: url_,
    });
  }

  async mkdir(name: string) {
    try {
      await Filesystem.mkdir({
        path: name,
        directory: Directory.Data,
        recursive: false,
      });
    } catch (e: any) {
      console.error('Unable to make directory', e);
    }
  }

  public async saveBackgroundColors(attributesList: []) {
    const keys: string[] = IMAGE_KEYS;
    this.clearTable('icons').then(() => {
      attributesList.forEach((attr: string, idx: number) => {
        let linksObject: IconsInfoType = {
          key: '',
          link: '',
          format: '',
          shouldWrapByUrl: false,
        };
        if (!attr.toString().includes('http')) {
          linksObject.link = attr;
          linksObject.format = '';
          linksObject.key = keys[idx];
          linksObject.shouldWrapByUrl = false;
          this.insetRowIntoIcons([keys[idx], attr]);
        }
      });
    });
  }

  public async saveIconsAfterDownloading(attributesList: string[]) {
    const keys: string[] = IMAGE_KEYS;
    await this.mkdir('icons');
    attributesList.forEach((attr: string, idx: number) => {
      let linksObject: IconsInfoType = {
        key: '',
        link: '',
        format: '',
        shouldWrapByUrl: false,
      };
      if (attr.toString().includes('http')) {
        if (attr.toString().includes('url')) {
          linksObject.key = keys[idx];
          linksObject.shouldWrapByUrl = true;
          let regExp = /\(([^)]+)\)/;
          let matches = regExp.exec(attr.toString());
          linksObject.format = matches![1].split(/[. ]+/).pop();
          if (linksObject.format !== '') {
            this.downloadFile(matches![1], `icons/${linksObject.key}.${linksObject.format}`).then((path) => {
              linksObject.link = `url(${path})`;
              this.insetRowIntoIcons([linksObject.key, `icons/${linksObject.key}.${linksObject.format}`]);
            });
          } else {
            linksObject.link = attr;
            this.insetRowIntoIcons([linksObject.key, attr]);
          }
        } else {
          linksObject.format = attr.split(/[. ]+/).pop();
          linksObject.key = keys[idx];
          this.downloadFile(attr, `icons/${linksObject.key}.${linksObject.format}`).then((path) => {
            linksObject.link = path;
            this.insetRowIntoIcons([linksObject.key, `icons/${linksObject.key}.${linksObject.format}`]);
          });
          linksObject.shouldWrapByUrl = false;
        }
      }
    });
  }

  public async insetRowIntoIcons(attrList: any[]) {
    let q = 'INSERT INTO icons VALUES (?, ?)';
    return this.sqlDB
      .executeSql(q, attrList)
      .then(() => console.log('Executed saving icons'))
      .catch((e: any) => {
        console.log('error in saveIcons' + JSON.stringify(e));
      });
  }

  // CRUD FOR NOTES

  public async saveNote(
    id: string,
    bookId: number,
    note: string,
    pageId: string,
    color: string,
    pageNumber: number,
    date: number,
    synchronized: string
  ) {
    let isSaved: boolean = true;
    let q = 'INSERT OR REPLACE INTO notes VALUES (?,?,?,?,?,?,?,?)';
    isSaved = await this.sqlDB
      .executeSql(q, [id, bookId, note, pageId, color, pageNumber, date, synchronized])
      .then(() => (isSaved = true))
      .catch((e: any) => {
        console.log('error in saveNote' + JSON.stringify(e));
        isSaved = false;
      });
    return isSaved;
  }

  public updateNote(
    id: string,
    bookId: number,
    note: string,
    pageId: string,
    color: string,
    pageNumber: number,
    synchronized: string
  ) {
    let q =
      'UPDATE notes set  bookId = ?, note = ?, pageId = ?, color = ?, pageNumber = ?, creationDate= ?, synchronized = ? WHERE id = ?';
    this.sqlDB
      .executeSql(q, [bookId, note, pageId, color, pageNumber, Date.now(), synchronized, id])
      .catch((e: any) => {
        console.log('error in updateNote' + JSON.stringify(e));
      });
  }

  public getNoteById(id: string) {
    let q = 'SELECT * FROM notes WHERE id = ?';
    this.sqlDB
      .executeSql(q, [id])
      .then((res: any) => {
        let row_data = [];
        if (res.rows.length > 0) {
          for (var i = 0; i < res.rows.length; i++) {
            row_data.push(res.rows.item(i));
          }
          return row_data;
        }
      })
      .catch((e: any) => {
        console.log('error in getNoteById' + JSON.stringify(e));
      });
  }

  async clearTable(tableName: string) {
    const clearQuery = `DELETE FROM '${tableName}'`;
    const countQuery = `SELECT COUNT(*) FROM '${tableName}'`;
    await this.sqlDB
      .executeSql(countQuery)
      .then((res: any) => console.log('Table is cleared'))
      .catch((e: any) => console.log(e));
    await this.sqlDB.executeSql(clearQuery).catch((e: any) => console.log(e));
  }

  public getAttrOfFontCat(): Promise<any> {
    let fontCatQuery = "SELECT name FROM PRAGMA_TABLE_INFO('fonts_category')";
    return this.sqlDB.executeSql(fontCatQuery, []);
  }

  public getAttrOfFonts(): Promise<any> {
    let fontsQuery = "SELECT name FROM PRAGMA_TABLE_INFO('fonts')";
    return this.sqlDB.executeSql(fontsQuery, []);
  }

  public getAttrOfColors(): Promise<any> {
    let colorQuery = "SELECT name FROM PRAGMA_TABLE_INFO('color_palettes')";
    return this.sqlDB.executeSql(colorQuery, []);
  }

  public getAttr(tableName: string): Promise<any> {
    let q = `SELECT name FROM PRAGMA_TABLE_INFO(?)`;
    return this.sqlDB.executeSql(q, [tableName]);
  }

  public async getThemeObject(): Promise<ThemeType> {
    const fontCatQuery = 'SELECT id, family, src, weight FROM fonts_category';
    const iconQuery = 'SELECT * FROM icons';
    const fontsQuery = 'SELECT * FROM fonts';
    const customerQuery = 'SELECT * FROM customers';
    const colorPaletteQuery = 'SELECT varName, varValue FROM color_palettes';

    await this.sqlDB.executeSql(iconQuery, []).then((res: any) => {
      let iconObj: any = {
        id: 0,
        logo: '',
        login_logo: '',
        login_bg: '',
        dashboard_img: '',
        'dashboard-footer-icon': '',
        'dashboard-intro-image': '',
        'dashboard-header-logo': '',
        'dashboard-header-background-image': '',
        'dashboard-top-right-logo': '',
        'forgot-password-page-background-image': '',
        'registration-page-background-image': '',
        'login-page-background-image': '',
        'login-logo': '',
      };

      let iconNamesList: string[] = [];
      for (let i = 0; i < res.rows.length; i++) {
        iconNamesList.push(res.rows.item(i).iconName);
        let filePath: string = '';
        this.getApplicationPath(res.rows.item(i).iconValue).then(async (res2) => {
          const path = await Capacitor.convertFileSrc(res2.uri.toString());
          filePath = path!;
          iconObj[res.rows.item(i).iconName] = filePath;
        });
      }
      this.themeType!.icons = iconObj;
    });
    await this.sqlDB.executeSql(fontCatQuery, []).then((res: any) => {
      this.themeType!.font1 = { ...res.rows.item(0) };
      this.themeType!.font2 = { ...res.rows.item(1) };
      this.themeType!.font3 = { ...res.rows.item(2) };
      this.themeType!.font4 = { ...res.rows.item(3) };
      this.themeType!.font5 = { ...res.rows.item(4) };
      this.themeType!.font6 = { ...res.rows.item(5) };
    });
    await this.sqlDB.executeSql(customerQuery, []).then((res: any) => {
      this.themeType!.customer = { ...res.rows.item(0) };
    });
    await this.sqlDB.executeSql(fontsQuery, []).then((res: any) => {
      this.themeType!.fonts = { ...res.rows.item(0) };
    });
    await this.sqlDB.executeSql(colorPaletteQuery, []).then((res: any) => {
      var obj = {};
      for (let i = 0; i < res.rows.length; i++) {
        let keys: string = res.rows.item(i).varName;
        let value: string = res.rows.item(i).varValue;
        let pair = { keys: value };

        Object.assign(obj, { ...pair });
      }
      this.themeType!.colorpalette = obj;
    });
    return new Promise<ThemeType>((resolve) => {
      resolve(this.themeType!);
    });
  }

  getThemes(customerName: string): Observable<any> {
    return from(this.getThemeObject());
  }

  public async getNotesByBookId(bookId: number) {
    this.NotesList = [];
    let q = "SELECT * FROM notes WHERE bookId = ? AND synchronized != '4' ";
    return await this.sqlDB
      .executeSql(q, [bookId])
      .then((res: any) => {
        if (res.rows.length > 0) {
          for (var i = 0; i < res.rows.length; i++) {
            this.NotesList.push({
              id: res.rows.item(i).id,
              bookId: res.rows.item(i).bookId,
              color: res.rows.item(i).color,
              creationDate: res.rows.item(i).creationDate,
              note: res.rows.item(i).note,
              pageId: res.rows.item(i).pageId,
              pageNumber: res.rows.item(i).pageNumber,
            });
          }
        }
        return this.NotesList;
      })
      .catch((e: any) => {
        console.log('error in getNotesByBookId' + JSON.stringify(e));
      });
  }

  public getAllNotes() {
    let q = 'SELECT * FROM notes';
    this.sqlDB
      .executeSql(q)
      .then((res: any) => {
        let row_data = [];
        if (res.rows.length > 0) {
          for (var i = 0; i < res.rows.length; i++) {
            row_data.push(res.rows.item(i));
          }
          return row_data;
        }
      })
      .catch((e: any) => {
        console.log('error in getAllNotes' + JSON.stringify(e));
      });
  }

  public deleteNoteById(id: string) {
    let q = 'DELETE FROM notes WHERE id = ?';
    this.sqlDB
      .executeSql(q, [id])
      .then((res: any) => {
        console.log('row deleted' + id);
      })
      .catch((e: any) => {
        console.log('error in deleteNoteById' + JSON.stringify(e));
      });
  }

  public deleteNotesBybookIdAndSynchronized(bookId: number, synchronized: string) {
    let q = 'DELETE FROM notes WHERE bookId = ? AND synchronized = ?';
    this.sqlDB
      .executeSql(q, [bookId, synchronized])
      .then(console.log('Table Notes deleted!'))
      .catch((e: any) => {
        console.log('error in deleteAllNotes' + JSON.stringify(e));
      });
  }

  public deleteNoteByBookId(bookId: number) {
    let q = 'DELETE FROM notes WHERE id = ?';
    this.sqlDB
      .executeSql(q, [bookId])
      .then((res: any) => {
        console.log('row deleted' + bookId);
      })
      .catch((e: any) => {
        console.log('error in deleteNoteByBookId' + JSON.stringify(e));
      });
  }

  public async delNoteByBookId(bookId: number) {
    let q = `DELETE FROM notes WHERE bookId = ? AND synchronized = 1`;
    return this.sqlDB
      .executeSql(q, [bookId])
      .then(() => console.log(`delete the notes data related to bookId=${bookId}`))
      .catch((e: any) => {
        console.log('error in deleting record from notes table' + JSON.stringify(e));
      });
  }

  // CRUD FOR Highlights

  private async getUnsyncHighlights(bookId: number) {
    let q = 'SELECT * FROM textHighlights WHERE id = ? AND synchronized = 2';
    return this.sqlDB
      .executeSql(q, [bookId])
      .then(() => console.log(`select the unsynced textHighlights data related to bookId=${bookId}`))
      .catch((e: any) => {
        console.log('error in seleccting unsynced record from textHighlights table' + JSON.stringify(e));
      });
  }

  public async delTxtHighLightByBookId(bookId: number) {
    let q = `DELETE FROM textHighlights WHERE bookId = ? AND synchronized = 1`;
    return this.sqlDB
      .executeSql(q, [bookId])
      .then(() => console.log(`delete the textHighlights data related to bookId=${bookId}`))
      .catch((e: any) => {
        console.log('error in deleting record from textHighlights table' + JSON.stringify(e));
      });
  }

  public saveHighlight(
    id: string,
    userId: string,
    bookid: number,
    cfi: string,
    start: string,
    end: string,
    spine: number,
    color: string,
    date: number,
    synchronized: string
  ) {
    let q = 'INSERT INTO textHighlights VALUES (?, ?, ?, ?, ?, ?,?,?, ?,?)';
    this.sqlDB
      .executeSql(q, [id, userId, bookid, cfi, start, end, spine, color, date, synchronized])
      .then(() => console.log('Executed saving highlights'))
      .catch((e: any) => {
        console.log('error in saveHighlight' + JSON.stringify(e));
      });
  }

  public getHighlightById(id: string) {
    let q = 'SELECT * FROM textHighlights WHERE id = ?';
    this.sqlDB
      .executeSql(q, [id])
      .then((res: any) => {
        let row_data = [];
        if (res.rows.length > 0) {
          for (var i = 0; i < res.rows.length; i++) {
            row_data.push(res.rows.item(i));
          }
          return row_data;
        }
      })
      .catch((e: any) => {
        console.log('error in getHighlightById' + JSON.stringify(e));
      });
  }

  public updateHighlight(
    id: string,
    userId: string,
    bookid: number,
    cfi: string,
    start: string,
    end: string,
    spine: number,
    color: string,
    synchronized: string
  ) {
    let q =
      'UPDATE textHighlights set userId = ?, bookId = ?, cfi = ?, start = ?, end = ?, spine = ?, color = ?, creationDate = ?, synchronized = ? WHERE id = ?';
    this.sqlDB
      .executeSql(q, [userId, bookid, cfi, start, end, spine, color, Date.now(), synchronized, id])
      .catch((e: any) => {
        console.log('error in updateHighlight' + JSON.stringify(e));
      });
  }

  public async getHighlightByBookId(bookId: number) {
    this.HighlightList = [];

    let q = "SELECT * FROM textHighlights WHERE bookId = ? AND synchronized != '4'";
    return await this.sqlDB
      .executeSql(q, [bookId])
      .then((res: any) => {
        if (res.rows.length > 0) {
          for (var i = 0; i < res.rows.length; i++) {
            this.HighlightList.push({
              id: res.rows.item(i).id,
              bookId: res.rows.item(i).bookId,
              cfi: res.rows.item(i).cfi,
              color: res.rows.item(i).color,
              creationDate: res.rows.item(i).creationDate,
              end: res.rows.item(i).end,
              spine: res.rows.item(i).spine,
              start: res.rows.item(i).start,
              userId: res.rows.item(i).userId,
            });
          }
        }
        return this.HighlightList;
      })
      .catch((e: any) => {
        console.log('error in getHighlightByBookId' + JSON.stringify(e));
      });
  }

  public getAllTextHighlights() {
    let q = 'SELECT * FROM textHighlights';
    this.sqlDB
      .executeSql(q)
      .then((res: any) => {
        let row_data = [];
        if (res.rows.length > 0) {
          for (var i = 0; i < res.rows.length; i++) {
            row_data.push(res.rows.item(i));
          }
          return row_data;
        }
      })
      .catch((e: any) => {
        console.log('error in getAllTextHighlights' + JSON.stringify(e));
      });
  }

  public deletetextHighlightById(id: string) {
    let q = 'DELETE FROM textHighlights WHERE id = ?';
    this.sqlDB
      .executeSql(q, [id])
      .then(console.log('Row Deleted!'))
      .catch((e: any) => {
        console.log('error in deletetextHighlightById' + JSON.stringify(e));
      });
  }

  public deleteHighlightsBybookIdAndSynchronized(bookId: number, synchronized: string) {
    let q = 'DELETE FROM textHighlights WHERE bookId = ? AND synchronized = ?';
    this.sqlDB
      .executeSql(q, [bookId, synchronized])
      .then(console.log('Highlights Deleted!'))
      .catch((e: any) => {
        console.log('error in deleteAllHighlights' + JSON.stringify(e));
      });
  }

  public deletetextHighlightByBookId(id: string) {
    let q = 'DELETE FROM textHighlights WHERE bookId = ?';
    this.sqlDB
      .executeSql(q, [id])
      .then(console.log('Row Deleted!'))
      .catch((e: any) => {
        console.log('error in deletetextHighlightByBookId' + JSON.stringify(e));
      });
  }

  public compareDatabase() {
    let q = 'INSERT INTO items VALUES (?, ?, ?, ?)';
  }

  /*
   Synchronization functions

   */

  public async getUnsynchronizedHighlightsByBookId(bookId: number, synchronized: string) {
    this.HighlightList = [];

    let q = 'SELECT * FROM textHighlights WHERE bookId = ? AND synchronized = ?';
    return await this.sqlDB
      .executeSql(q, [bookId, synchronized])
      .then((res: any) => {
        if (res.rows.length > 0) {
          for (var i = 0; i < res.rows.length; i++) {
            this.HighlightList.push({
              id: res.rows.item(i).id,
              bookId: res.rows.item(i).bookId,
              cfi: res.rows.item(i).cfi,
              color: res.rows.item(i).color,
              creationDate: res.rows.item(i).creationDate,
              end: res.rows.item(i).end,
              spine: res.rows.item(i).spine,
              start: res.rows.item(i).start,
              userId: res.rows.item(i).userId,
            });
          }
        }
        return this.HighlightList;
      })
      .catch((e: any) => {
        console.log('error in getUnsynchronizedHighlightsByBookId ' + JSON.stringify(e));
      });
  }

  public async getUnsynchronizedNotesByBookId(bookId: number, synchronized: string) {
    this.NotesList = [];
    let q = 'SELECT * FROM notes WHERE bookId = ? AND synchronized = ? ';
    return await this.sqlDB
      .executeSql(q, [bookId, synchronized])
      .then((res: any) => {
        if (res.rows.length > 0) {
          for (var i = 0; i < res.rows.length; i++) {            
            this.NotesList.push({
              id: res.rows.item(i).id,
              bookId: res.rows.item(i).bookId,
              color: res.rows.item(i).color,
              creationDate: res.rows.item(i).creationDate,
              note: res.rows.item(i).note,
              pageId: res.rows.item(i).pageId,
              pageNumber: res.rows.item(i).pageNumber,
            });
          }
        }
        return this.NotesList;
      })
      .catch((e: any) => {
        console.log('error in getUnsynchronizedNotesByBookId ' + JSON.stringify(e));
      });
  }

  // CRUD FOR SYNCHRONIZATION
  public async setSyncDateByBookId(bookId: number, syncAt: string) {
    let q = `INSERT OR REPLACE INTO synchronization VALUES (?, ?)`;
    return this.sqlDB
      .executeSql(q, [bookId, syncAt])
      .then(() => console.log('Executed saving syncData'))
      .catch((e: any) => {
        console.log('error in synchronization table' + JSON.stringify(e));
      });
  }

  public async delSyncDateByBookId(bookId: number) {
    let q = `DELETE FROM synchronization WHERE bookId = ?`;
    return this.sqlDB
      .executeSql(q, [bookId])
      .then(() => console.log(`delete the sync data related to bookId=${bookId}`))
      .catch((e: any) => {
        console.log('error in deleting record from synchronization table' + JSON.stringify(e));
      });
  }

  public async getSyncDateByBookId(bookId: number) {
    let q = `SELECT * FROM synchronization WHERE bookId = ?`;
    return this.sqlDB
      .executeSql(q, [bookId])
      .then((res: any) => res.rows.item(0).lastSyncAt)
      .catch((e: any) => {
        console.log('error in synchronization table' + JSON.stringify(e));
      });
  }

  public async getApplicationPath(filePath: string) {
    return await Filesystem.getUri({
      path: filePath,
      directory: Directory.Data,
    });
  }
}
