import { ContactSupportComponent } from './../contact-support/contact-support.component';
import { ThemeService } from './../../services/themes/theme.service';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { InAppBrowserObject } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { EMPTY, Observable } from 'rxjs';
import packageInfo from '../../../../package.json';
import { AuthService } from '../../authentication/services/auth.service';
import { Constants } from '../../PODO/constants';
import { UserService } from '../../services/user/user.service';
import { ChangelogComponent } from '../changelog/changelog.component';
import { CreditsComponent } from '../credits/credits.component';
import { ProfileComponent } from '../profile/profile.component';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { BooksService } from '../../services/books/books.service';
import { DataPrivacyComponent } from '../data-privacy/data-privacy.component';
import { DeviceDetectorService } from '../../util/device-detector/device-detector.service';
import { LocalStorageComponent } from '../local-storage/local-storage.component';
import { ImprintComponent } from '../imprint/imprint.component';
import { ThemeSwitcherComponent } from '../theme-switcher/theme-switcher.component';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-menu',
  templateUrl: 'app-menu.component.html',
  styleUrls: ['app-menu.component.scss'],
})
export class AppMenuComponent implements OnInit {
  @Input() readonly isAuthPage: boolean = true;
  @Output() readonly menuToggle = new EventEmitter<boolean>();
  public isNative: boolean = false;
  public isDesktop: boolean = false;

  public appPages = [
    {
      title: 'Books.Title',
      url: '/books',
      icon: 'chevron-forward-outline',
    },
  ];
  public currentYear: string = '1009 ';
  public browser: Observable<InAppBrowserObject> = EMPTY;
  public version: string = packageInfo.version;
  public userID: string = '';

  constructor(
    public platform: Platform,
    private authService: AuthService,
    private router: Router,
    private modalCtrl: ModalController,
    private jwtHelper: JwtHelperService,
    private userService: UserService,
    public booksService: BooksService,
    public deviceDetector: DeviceDetectorService,
    public themeService: ThemeService,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let authPage: boolean = event.url.includes('authentication');
        if (!authPage) {
          this.checkUserAndLoadInvitationSize();
        }
      }
    });
  }

  ngOnInit() {
    this.checkUserAndLoadInvitationSize();
    this.currentYear = new Date().getFullYear().toString();
    // check running native on mobile
    if (Capacitor.isNativePlatform()) {
      this.isNative = true;
      this.isDesktop = false;
    } else {
      this.isNative = false;
      this.isDesktop = true;
    }
    this.userID = this.userService.getUserID();
  }

  public checkUserAndLoadInvitationSize() {
    if (!this.jwtHelper.tokenGetter())
      return;
    
    const token = this.jwtHelper.tokenGetter();
    if (typeof token === 'string')
      this.userService.setUser(token);
  }

  public navigate(url: string) {
    this.router.navigateByUrl(url);
  }

  public browse(target: string) {
    let lng = localStorage.getItem('defaultLocale');
    let lngEN = lng === 'en-GB';

    switch (target) {
      case 'shop':
        {
          Browser.open({
            url: lngEN ? 'https://shop.litello.com/en' : 'https://shop.litello.com/',
          });
        }
        break;
      case 'litello':
        {
          Browser.open({
            url: lngEN ? 'https://litello.com/en' : 'https://litello.com/',
          });
        }
        break;
      case 'impressum':
        {
          Browser.open({
            url: lngEN ? 'https://litello.com/en/imprint' : 'https://litello.com/impressum',
          });
        }
        break;
      case 'datenschutz':
        {
          Browser.open({
            url: lngEN ? 'https://litello.com/en/privacy-policy' : 'https://litello.com/datenschutz',
          });
        }
        break;
    }
  }

  /**
   * Opens a modal with the given component
   * @param component The component to open
   * @returns popover The modal
   */
  private async openModel(component: any) {
    let cssClass = this.deviceDetector.isDesktop() ? 'ion-main-modal' : 'ion-main-modal-mobile';
    const popover = await this.modalCtrl.create({
      component: component,
      cssClass: cssClass,
    });
    return await popover.present();
  }

  public async openCredits() {
    return this.openModel(CreditsComponent);
  }

  public async openLocalStorage() {
    return this.openModel(LocalStorageComponent);
  }

  public async openDataPrivacy() {
    return this.openModel(DataPrivacyComponent);
  }

  public async openContactSupport() {
    return this.openModel(ContactSupportComponent);
  }

  public async openThemeSwitcher() {
    return this.openModel(ThemeSwitcherComponent);
  }

  public async openImprint() {
    return this.openModel(ImprintComponent);
  }

  public async openChangelog() {
    return this.openModel(ChangelogComponent);
  }

  public async openProfile() {
    return this.openModel(ProfileComponent);
  }

  public async logoutUser() {
    await this.authService.logoutUser();
    this.booksService.setOpendedStatus(false);
    await this.router.navigate([Constants.URL.Authentication]);
  }

  public menuOpened() {
    this.checkUserAndLoadInvitationSize();
    this.menuToggle.emit(true);
  }

  public menuClosed() {
    this.menuToggle.emit(false);
  }
}
