<ion-header class='ion-modal-header'>
  <ion-toolbar class='header-toolbar'>
    <ion-buttons slot='end'>
      <ion-button (click)="cancel()">
        <ion-icon name='close-outline'></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class='header-title ion-text-left' l10nTranslate>
      Menu.Profile
    </ion-title>
  </ion-toolbar>
</ion-header>

<div #container class="container">
  <img [src]="imageSource" (load)="onImageLoaded($event.target)">
</div>

<ion-footer>
  <ion-button
    class="two-buttons"
    (click)="confirm()"
    l10nTranslate
  >
    Dialog.Save
  </ion-button>
</ion-footer>