import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import marked from 'marked';
import { Capacitor } from '@capacitor/core';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { Observable } from 'rxjs';

const CHANGELOG_WEB = 'assets/CHANGELOG.md';
const CHANGELOG_IOS = 'assets/CHANGELOG_IOS.md';
const CHANGELOG_ANDROID = 'assets/CHANGELOG_ANDROID.md';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss'],
})
export class ChangelogComponent implements OnInit {
  public loading: boolean = false;

  constructor(
    private modalCtrl: ModalController,
    private http: HttpClient,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngOnInit() {
    this.loadChangelog();
  }

  public refresh(event: any) {
    this.loadChangelog();
    event.target.complete();
  }

  private loadChangelog() {
    this.loading = true;
    this.getChangelogData().subscribe({
      next: (changelog) => {
        let cl = marked(changelog);
        let contents = document.getElementById('contents');
        if (contents) {
          contents.innerHTML = cl;

          let child = contents.children;
          for (let i = 0; i < child.length; i++) {
            let el = child[i] as HTMLElement;

            if (el.tagName === 'UL' || el.tagName === 'ul') {
              el.style.borderBottom = '1px solid #d8d8d8';
              el.style.paddingBottom = '1rem';
              el.style.fontFamily = 'var(--ion-font-family6)';
            }
          }
        }
        this.loading = false;
      },
      error: (error) => {
        this.loading = false;
      }
    });
  }

  /**
   * Returns the changelog data based on the platform
   * @returns Observable<string> - The changelog data
   */
  private getChangelogData(): Observable<string> {
    let platform: string = Capacitor.getPlatform();

    if (platform === "ios")
      return this.http.get(CHANGELOG_IOS, { responseType: 'text' });
    if (platform === "android")
      return this.http.get(CHANGELOG_ANDROID, { responseType: 'text' });
    // Default to web
    return this.http.get(CHANGELOG_WEB, { responseType: 'text' });
  }

  public async closeChangelog() {
    this.modalCtrl.dismiss();
  }
}
