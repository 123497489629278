import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { Injectable } from '@angular/core';
import { BooksService, LastPage } from '../../../services/rest-client/rest-client.service';
import { UserService } from '../../../services/user/user.service';
import { StorageService } from '../../../services/storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class LastPageService {
  private readonly LAST_PAGE_STORAGE_KEY: string = 'book_last_page';
  private readonly ZOOM_LEVEL_STORAGE_KEY: string = 'book_zoom_level';
  
  private bookPageCount: number = 0;
  
  constructor(
    private userService: UserService,
    private restService: BooksService,
    private storageService: StorageService,
  ) {}

  /**
   * Get last opened page for a given book ID.
   * @param bookID 
   * @returns {Promise<LastPage>}
   */
  public async getLastPage(bookID: number): Promise<LastPage> {
    const online = await Network.getStatus();
    const localLastPage: LastPage | undefined = await this.getLastPageOffline(bookID);
    const remoteLastPage: LastPage | undefined = online.connected ? await this.getLastPageOnline(bookID) : undefined;

    if (localLastPage?.cfi && remoteLastPage?.cfi) {
      return localLastPage.cfi > remoteLastPage.cfi ? localLastPage : remoteLastPage;
    } else {
      return localLastPage || remoteLastPage || this.createLastPageObject(bookID, '0');
    }
  }

  
 /**
   * Save the last opened page for a given book ID.
   * Stored online in the DB. 
   * Stored locally in local storage, Android SharedPreferences or iOS UserDefaults.
   * @param {string} bookId 
   * @returns {LastPage | undefined}
   */
  public async saveLastPage(bookId: number, pageNumber: string): Promise<void> {
    const lastPage: LastPage = {
      bookId,
      creationDate: Date.now(),
      cfi: pageNumber,
      pageCount: this.bookPageCount,
    }

    const online = await Network.getStatus();

    if(online.connected) {
      this.restService.lastpagePost(bookId, lastPage).subscribe();
    }

    if (Capacitor.isNativePlatform()) {
      this.storageService.savePreferences(
        `${this.LAST_PAGE_STORAGE_KEY}_${this.userService.getUserID()}_${bookId}`,
        JSON.stringify(lastPage)
      );
    }
  }

   /**
   * Get the last open page for a given book ID.
   * Fetched from API.
   * @param {string} bookId 
   * @returns {Promise<LastPage | undefined> | undefined}
   */
  private getLastPageOnline(bookId: number): Promise<LastPage | undefined> | undefined {
    if (this.userService.checkIfIDIsSet()) {
      return this.restService
        .lastpageGet(bookId)
        .toPromise()
        .catch((error) => {
          return undefined;
        });
    } else {
      return undefined;
    }
  }

  /**
   * Get the last open page for a given book ID.
   * Fetched from local storage, Android SharedPreferences or iOS UserDefaults.
   * @param {string} bookId 
   * @returns {LastPage | undefined}
   */
  private async getLastPageOffline(bookId: number): Promise<LastPage | undefined> {
    const lastPageString: string | null = await this.storageService.getPreferences(`${this.LAST_PAGE_STORAGE_KEY}_${this.userService.getUserID()}_${bookId}`);
    if(lastPageString) {
      return JSON.parse(lastPageString);
    } else {
      return undefined;
    }
  }

  private deleteLastPageOffline(bookId: number): void {
    this.storageService.removePreferences(`${this.LAST_PAGE_STORAGE_KEY}_${this.userService.getUserID()}_${bookId}`);
  }

  private createLastPageObject(bookId: number, pagenumber: string): LastPage {
    const newLastpage: LastPage = {
      bookId,
      userId: this.userService.getUserID(),
      cfi: pagenumber,
      creationDate: Date.now(),
    };
    return newLastpage;
  }

  public setPageCount(pageCount: number): void {
    this.bookPageCount = pageCount;
  }

  /**
   * Save text zoom level for a given book ID.
   * @param {string} bookId 
   * @param {number} zoomLevel - integer in range between -10 and 10 
   */
  public saveZoomLevelByBookId = (bookId: number, zoomLevel: number): void => {
    this.storageService.savePreferences(
      `${this.ZOOM_LEVEL_STORAGE_KEY}_${bookId}`,
      JSON.stringify(zoomLevel)
    );

    Network.getStatus().then((online) => {
      if (online.connected) {
        this.restService.postZoomLevel(bookId, { fontPercent: zoomLevel }).subscribe();
      }
    });
  }

  /**
   * Get the last stored text zoom level for a given book ID.
   * @param {string} bookId 
   * @returns {number} zoom level between -10 and 10
   */
  public async getZoomLevelByBookId(bookId: number): Promise<number | undefined> {
    const online = await Network.getStatus();
    if (online.connected) {
      const zoomLevel: number = (await this.restService.getZoomLevel(bookId).toPromise())?.fontPercent;
      return zoomLevel;
    } else {
      const zoomLevelValue: string | null = await this.storageService.getPreferences(`${this.ZOOM_LEVEL_STORAGE_KEY}_${bookId}`);
      if(zoomLevelValue != null) {
        return parseInt(zoomLevelValue);
      }
    }
  }
}
