export const i18nAsset = {
    'de-DE': {
        "Check": "Was ist los?",
        "UnknownError": "Ups! Irgendwas lief schief.\n\nVersuchen Sie, diese Seite zu aktualisieren, oder kontaktieren Sie uns, wenn das Problem weiterhin besteht.",
        "FileSizeError": "Die Dateigröße überschreitet die Grenze von 350kB.",
        "UploadSuccess": "Datei erfolgreich hochgeladen!",
        "Books": {
            "Title": "Meine Workbooks",
            "WelcomeTitle": "Willkommen in deinem Litello Desktop-Bereich!",
            "WelcomeMessage": "Deine gekauften Litello eBooks kannst du in deiner Litello App downloaden und stehen dir anschließend auch hier zur Verfügung. Und das hat viele Vorteile: Denn du kannst stets an der Stelle im eBook weiterarbeiten, an der du mobil aufgehört hast, und andersrum. Das macht dich beim Lernen super flexibel. Außerdem kannst du dir ganz bequem Notizen machen, diese speichern und dich, genauso wie an deinem Smartphone, mit deiner Lerngruppe austauschen. Du siehst: Ob unterwegs oder Zuhause, so wird das Lernen zum Kinderspiel.",
            "LastOpenedAt": "Zuletzt geöffnet",
            "Contents": "Inhalt",
            "Downloading": "Geladen",
            "Initializing": "Initialisieren",
            "Unzipping": "Entpacken"
        },
        "Footer": {
            "PrivacyPolicy": "Datenschutz"
        },
        "Book": {
            "Of": "von",
            "download": {
                "start": "Vorgang wurde gestartet",
                "success": "Vorgang wurde erfolgreich abgeschlossen",
                "failure": "Server Fehler! Versuche es später noch einmal",
                "server": {
                    "glitch": "Server Fehler! Bitte versuche es erneut"
                }
            },
            "NotFound": "Buch nicht gefunden.\n\nVersuchen Sie, diese Seite zu aktualisieren, oder kontaktieren Sie uns, wenn das Problem weiterhin besteht."
        },
        "Collections": {
            "Collection": "Sammlung"
        },
        "Communication": {
            "Posts": "Beiträge",
            "Comments": "Comments",
            "WritePost": "Beitrag verfassen...",
            "NoGroups": "Keine Gruppen zur Auswahl.",
            "NoThreads": "Keine Beiträge.",
            "SuccessfullyDeleted": "Erfolgreich gelöscht."
        },
        "NetworkRequired": "Sie müssen mit einem Netzwerk verbunden sein, um diese Funktion nutzen zu können.",
        "Analytics": {
            "Progress": "Fortschritt"
        },
        "Authentication": {
            "SSOButton": "Anmelden",
            "SSO-Title": "Willkommen!",
            "NewCustomer": "Ich bin Neukunde",
            "YourAddress": "Ihre Adresse",
            "WelcomeTitle": "Herzlich willkommen - wir zeigen dir jetzt, wie Litello dir beim Lernen helfen kann",
            "WelcomeLoginText": "Wenn du einen Account hast, kannst du dich ",
            "WelcomeLoginLinkText": "Anmelden",
            "SignUpButton": "Jetzt registrieren",
            "SignUpButton2": "Registrieren",
            "RegistrationBackButton": "zurück",
            "RegistrationTitle": "Registrierung",
            "RegistrationText": "Einfach die folgenden Angaben ausfüllen und das smarte Litello Lernformat steht Ihnen für alles offen!",
            "RegistrationPersonalInfo": "Persönliche Informationen",
            "RegistrationAdress": "Adresse",
            "RegistrationCredentials": "Zugangsdaten",
            "RegistrationInfo": "Registriere Dich jetzt, um Litello voll nutzen zu können",
            "RegistrationSucceedHeader": "Registrierung erfolgreich!",
            "RegistrationSucceedMessage": "Bitte melde dich nun mit deiner E-Mail-Adresse und deinem Passwort an",
            "RegistrationSucceedButton": "OK",
            "ProceedWithoutRegistration": "Testen ohne Registrierung",
            "EmailTakenError": "Die Email-Adresse ist bereits vergeben",
            "Username": "Benutzername*",
            "UsernameTakenError": "Der Anzeigename ist bereits vergeben",
            "UsernamePatternError": "Der Anzeigename darf maximal 32 Zeichen lang sein. Vor und hinter einem Leerzeichen dürfen ausschließlich Buchstaben und/oder Zahlen stehen. Sonstige Sonderzeichen sind nicht möglich.",
            "LoginTitle": "Anmelden",
            "LoginText": "Um Litello bequem am Desktop nutzen zu können, melde dich jetzt mit deinen Zugangsdaten aus der App an. ",
            "LoginText2": "Du bist noch nicht registriert? Das kannst du bereits mit ein paar Klicks ändern.",
            "NoAccountYet": "Sie besitzen noch keinen Account?",
            "RegisterHere": "Hier registrieren.",
            "LoginButton": "Jetzt anmelden",
            "MailNotProvided": "Bitte geben sie die E-Mail Adresse ein an dem die Bestätigung-Code versendet werden soll.",
            "ResendConfirmation": "Bestätigungsmail erneut senden",
            "NotVerifiedHeader": "E-Mail nicht verifiziert",
            "NotVerified": "Wir haben Ihnen eine E-Mail-Bestätigung gesendet. Sie müssen Ihre E-Mail-Adresse bestätigen, bevor Sie sich anmelden können.",
            "ConfirmationResent": "Wir haben die Bestätigung erneut gesendet. Bitte überprüfen Sie Ihren Posteingang.",
            "WrongCredentialsError": "E-Mail-Adresse oder Passwort ungültig",
            "NoNetworkConnection": "Keine Internetverbindung, bitte überprüfe deine Netzwerverbindung",
            "LogoutButton": "Abmelden",
            "ForgotPassword": "Du hast deinen Nutzernamen oder dein Passwort vergessen?",
            "ForgotPasswordLink": "Klicke hier",
            "GenderLabel": "Anrede*",
            "GenderFemale": "Frau",
            "GenderMale": "Herr",
            "Firstname": "Vorname*",
            "Lastname": "Nachname*",
            "Email": "E-Mail-Adresse*",
            "RepeatEmail": "E-Mail-Adresse wiederholen*",
            "Password": "Passwort*",
            "RepeatPassword": "Passwort wiederholen*",
            "Street": "Straße und Hausnummer*",
            "Plz": "PLZ*",
            "Ort": "Ort*",
            "Land": "Land*",
            "PasswordError": "Die Passwörter müssen übereinstimmen",
            "EmailError": "Die Emails müssen übereinstimmen",
            "CheckBoxError": "Bitte erst Datenschutzerklärung lesen und akzeptieren!",
            "PasswordTip": "Ihr Passwort muss mindestens 8 Zeichen umfassen.\nBerücksichtigen Sie Groß- und Kleinschreibung.",
            "DSGVOTip": "Bitte bestätigen, wenn du die Datenschutzerklärung gelesen hast und akzeptiert.",
            "DSGVOCheckBoxTextPartOne": "Ich akzeptiere die ",
            "DSGVO": "Datenschutzerklärung*",
            "GoToResetButton": "Passwort vergessen?",
            "ResetTitle": "Passwort zurücksetzen",
            "ResetText": "Sie erhalten von uns eine Bestätigungs-E-Mail an Ihre angemeldete E-Mail-Adresse. Diese erhält einen Link und weitere Anweisungen zum Zurücksetzen Ihres Passwortes.",
            "ResetButton": "E-Mail senden",
            "BackButton": "Zurück",
            "ResetSentTitle": "Dein Passwort wurde zurückgesetzt.",
            "ResetSentText": "Bitte folge den Anweisungen in der E-Mail und kehre dann in die App zurück.",
            "ResetSentButton": "Zum Login",
            "NotAllowed": "Sie dürfen diesen Webreader nicht betreten!",
            "400": "Ups! Irgendwas lief schief.\n\nVersuchen Sie, diese Seite zu aktualisieren, oder kontaktieren Sie uns, wenn das Problem weiterhin besteht.",
            "500": "500 Serverfehler\n\n Ups! Irgendwas lief schief.\n\nVersuchen Sie, diese Seite zu aktualisieren, oder kontaktieren Sie uns, wenn das Problem weiterhin besteht.",
            "403": "Ihr Konto ist derzeit gesperrt.\nBitte kontaktieren Sie uns, um Ihr Konto zu entsperren.",
            "InvalidToken": "Ungültiger Token! \nSie werden zur Loginseite weitergeleitet!",
            "InvalidEmail": "Ungültige E-Mail!",
            "verifying": "Account wird verfiziert...",
            "veryfied": "Account erfolgreich verfiziert!",
            "activating": "Account wird aktiviert...",
            "activated": "Account erfolgreich aktiviert! Sie können sich nun einloggen.",
            "invalidLink": "Link ist ungültig",
            "backToLogin": "zum Login"
        },
        "Contextmenu": {
            "PrivateNoteLabel": "Notiz",
            "Copied": "Kopiert!"
        },
        "Bookmenu": {
            "TableOfContent": "Inhaltsverzeichnis",
            "BackArrow": "Zurück zur vorherigen Position"
        },
        "Search": {
            "Title": "Suche",
            "Search": "suchen",
            "ResultsFor": "Ergebnisse für",
            "Back": "zurück",
            "Next": "weiter",
            "List": "Liste",
            "Single": "Einzel",
            "Keyword": "Suchbegriff",
            "KeywordTooShort": "Der Suchbegriff muss mindestens 3 Zeichen lang sein.",
        },
        "Attachment": {
            "Download": "Downloads",
            "Title": "Attachment",
            "AllCategories": "Alle Kategorien",
            "AddedOn": "Hinzugefügt am",
            "ShowAllAttachments": "Alle Downloads anzeigen",
            "AlreadyDownloaded": "Sie haben bereits heruntergeladen {{ name }}. Möchten Sie es erneut herunterladen?",
            "Open": "Öffnen",
            "DownloadAgain": "Erneut herunterlade",
            "DownloadError": "Etwas ist schief gelaufen",
            "NoAttachments": "Es gibt keine Anhänge für dieses Buch!"
        },
        "NoteOverview": {
            "ChapterSearch": "Kapitelsuche",
            "OnlyNotes": "Notizen",
            "Highlights": "Markierungen",
            "Notes": "Notizen",
            "Color": "Farbe",
            "Chapter": "Kapitel",
            "All": "Alle Kapitel",
            "Green": "Grün",
            "Yellow": "Gelb",
            "Blue": "Blau",
            "Purple": "Lila",
            "SortByColor": "Nach Farbe sortieren",
            "SortByChapter": "Nach Kapitel sortieren",
            "PageAbbreviation": "Seite",
            "Today": "heute",
            "Yesterday": "gestern",
            "ColorSelection": "Farbauswahl:"
        },
        "CreateNote": {
            "Title": "Notiz hinzufügen",
            "EditTitle": "Notiz hinzufügen",
            "NewNote": "Notiz zur Markierung",
            "DeleteNote": "Notiz löschen",
            "NotePlaceholder": "Notiz verfassen….",
            "Today": "Heute"
        },
        "ImageModal": {
            "Image": "Bild",
            "Back": "Zurück"
        },
        "Dialog": {
            "AlertMessage": "Achtung! Ihre Änderungen gehen verloren, wenn Sie diese nicht speichern.",
            "Cancel": "Abbrechen",
            "Save": "speichern",
            "DoNotSave": "Nicht speichern",
            "Delete": "Löschen",
            "DeletePostReally": "Wollen Sie den Beitrag wirklich löschen?",
            "DeleteNoteReally": "Wollen Sie wirklich die Notizen löschen?",
            "DeleteBookReally": "Wollen Sie wirklich das Buch löschen?",
            "NoteTextNeeded": "Text für Notiz ist erforderlich!",
            "OK": "OK",
            "SyncBeforeDelete": "Du musst erst deine Änderungen synchronisieren, bevor du das Buch löschst",
            "Close": "Schließen"
        },
        "Menu": {
            "shop": "Zum Shop",
            "AboutLitello": "Über Litello",
            "Credits": "Credits",
            "Changelog": "Changelog",
            "Imprint": "Impressum",
            "DataPrivacy": "Datenschutzerklärung",
            "Profile": "Profil",
            "ChangePassword": "Passwort ändern",
            "LocalStorage": "Lokaler Speicher",
            "Support": "Hilfe und Support",
            "ThemeSwitcher": "Themeswitcher"
        },
        "MenuTooltip": {
            "Edit": "Bearbeiten",
            "Locate": "Lokalisieren",
            "Delete": "Löschen"
        },
        "ThemeSwitcher": {
            "intro": "DEV-ONLY! Hier ist eine Übersicht aller Kunden-Themes. Klicken Sie auf activate um das entsprechende Theme zu aktivieren.",
            "activate": "Aktivieren"
        },
        "Credits": {
            "Credits": "Credits",
            "Component": "Component",
            "Owner": "Owner",
            "License": "License",
            "NetworkMessage": "Du musst eine Internetverbindung haben, damit du die Credits sehen kannst."
        },

        "HelpAndSupport": {
            "Header": "Hilfe und Support",
            "Title": "Welche Funktionen stehen ihnen zur Verfügung",
            "IntroText": "Hier finden Sie Hilfestellungen für die einzelnen Funktionen in Litello. Sollten dennoch Fragen unbeantwortet bleiben oder technische Probleme auftreten, kontaktieren Sie den Support.",
            "Bib": "Bibliothek",
            "Search": "Suche",
            "Marks": "Markierungen",
            "Downloads": "Downloads",
            "ContactSupport": "Support kontaktieren"
        },
        "Support": {
            "Header": "Support kontaktieren",
            "BackButton": " zurück ",
            "Title": " Support kontaktieren",
            "IntroText": "Haben sie Probleme mit der Anwendung oder einem litelloBook? Dann schreiben Sie uns. Bitte wählen Sie den Betreff über die Dropdowns aus und fügen eine kurze Nachricht an, welche das Problem grob beschreibt.",
            "Email1": "Kontakt E-Mail Adresse*",
            "Email2": "E-Mail Adresse bestätigen*",
            "EmailError1": "Geben sie bitte eine Email im richtigen Format ein",
            "EmailError2": "Bitte Email eintragen",
            "EmailError3": "Die Emails müssen übereinstimmen!",
            "Subject": "Betreff",
            "Subject1": "Problem mit einem LitelloBook",
            "Subject2": "Problem mit dem Webreader",
            "Subject3": "Sonstiges",
            "SubjectError1": "Bitte Betreff auswählen!",
            "SubjectValue": "Betreff eintragen*",
            "Book": "Buch wählen*",
            "SubjectValueError": "Bitte Betreff eintragen*",
            "BookError": "Bitte Buch auswählen*",
            "Message": "Nachricht*",
            "MessageError": "Bitte Nachricht eintragen",
            "Notice": "(Um Ihr Anliegen zu bearbeiten, benötigen wir eine Kontakt Email-Adresse. Diese wird ausschließlich zur Beantwortung ihrer Anfrage genutzt)*",
            "SendButton": "Support kontaktieren",
            "emailPlaceholder": "Email",
            "subjectPlaceholder": "Betreff wählen",
            "bookPlaceholder": "Buch wählen",
            "subjectValuePlaceholder": "Grund"
        },
        "Help": {
            "HeaderMarks": "Hilfe und Support | Markierungen",
            "HeaderSearch": "Hilfe und Support | Suche",
            "HeaderDownloads": "Hilfe und Support | Downloads",
            "BackButton": " zurück "
        },
        "DataPrivacy": {
            "Title": "Datenschutzerklärung"
        },
        "Imprint": {
            "Title": "Impressum"
        },

        "Changelog": {
            "Changelog": "Changelog"
        },
        "Settings": {
            "NetworkConnected": "Network Connected",
            "NetworkDisconnected": "Network Disconneted"
        },
        "Profile": {
            "Avatar": "Avatar:",
            "Firstname": "Vorname:",
            "Lastname": "Nachname:",
            "Nickname": "Nickname:",
            "Bio": "Bio:",
            "Email": "E-Mail:",
            "NotVerified": "nicht verifiziert",
            "Verified": "verifiziert",
            "ChangePassword": "Passwort ändern",
            "OldPassword": "Altes Passwort",
            "NewPassword": "Neues Passwort",
            "NewPasswordConfirm": "Neues Passwort bestätigen",
            "NewPasswordSave": "Passwort ändern",
            "Save": "Speichern",
            "Edit": "Bearbeiten",
            "NetworkMessage": "Du musst eine Internetverbindung haben, damit du dein Profil sehen kannst.",
            "ChangeProfileImage": "Profilbild bearbeiten"
        },
        "LocalStorage": {
            "Synchronizing": "Synchronisieren",
            "RemoveDownload": "Download entfernen",
            "LastSync": "letzte Synchronisierung",
            "NoBookDownloaded": "Sie haben noch keine eBooks heruntergeladen.",
            "notSynchronizedYet": "Noch nicht synchronisiert"
        },
        "OfflineMode": {
            "Download": "Herunterladen",
            "Open": "Öffnen"
        },
        "InternetConnection": {
            "InOfflineMode": "Keine Internetverbindung. Du befindest dich nun im Offline-Modus.",
            "NoInternetConnection": "Keine Internetverbindung.",
            "Online": "Online!"
        },
        "Toast": {
            "Close": "Zum Schließen klicken",
            "Favorite": "Favoriten",
            "Done": "Erledigt"
        }
    },
    "en-GB": {
        "Check": "Whats going on?",
        "UnknownError": "Oops something went wrong. \n\nTry to refresh this page or feel free to contact us if the problem persists.",
        "FileSizeError": "The file size exceeds the limit of 350kB.",
        "UploadSuccess": "File successfully uploaded!",
        "Books": {
            "Title": "My workbooks",
            "WelcomeTitle": "Welcome to your Litello desktop area!",
            "WelcomeMessage": "You can download your purchased Litello e-books in the Litello app, after which they will also be available here. This has many benefits: You can pick up in the e-book exactly where you left off on your mobile device, and vice versa. This makes learning really flexible. You can also conveniently create notes, save them and share them with your learning group, just like on your smartphone. You will see that learning is fun whether you’re on the go or at home.",
            "LastOpenedAt": "Last opened",
            "Contents": "Contents",
            "Downloading": "Downloading",
            "Initializing": "Initializing",
            "Unzipping": "Unzipping"
        },
        "Footer": {
            "PrivacyPolicy": "Privacy Policy"
        },
        "Book": {
            "Of": "from",
            "download": {
                "start": "Process started",
                "success": "Process completed successfully",
                "failure": "Server error! Try again later",
                "server": {
                    "glitch": "Server error! Try again"
                }
            },
            "NotFound": "Book not found.\n\nTry to refresh this page or feel free to contact us if the problem persists."
        },
        "Collections": {
            "Collection": "Collection"
        },
        "Communication": {
            "Posts": "Posts",
            "Comments": "Comments",
            "WritePost": "Write a post...",
            "NoGroups": "No groups to select.",
            "NoThreads": "No posts.",
            "SuccessfullyDeleted": "Successfully deleted."
        },
        "NetworkRequired": "You need to be connected to a network in order to use this feature.",
        "Analytics": {
            "Progress": "Progress"
        },
        "Authentication": {
            "SSOButton": "Sign in",
            "SSO-Title": "Welcome!",
            "NewCustomer": "I am a new customer",
            "YourAddress": "Your address",
            "WelcomeTitle": "Welcome. We will now show you how Litello can help you learn.",
            "WelcomeLoginText": "If you have an account, you can ",
            "WelcomeLoginLinkText": "Log in",
            "SignUpButton": "Sign up",
            "SignUpButton2": "Sign up",
            "RegistrationBackButton": "back",
            "RegistrationTitle": "Registration",
            "RegistrationInfo": "Sign up now to use the full functionality of Litello",
            "RegistrationSucceedHeader": "Registration successful!",
            "RegistrationSucceedMessage": "Sign in using your email address and password",
            "RegistrationSucceedButton": "OK",
            "RegistrationText": "Complete the form below to sign up for our smart Litello service.",
            "RegistrationPersonalInfo": "Personal info",
            "RegistrationAdress": "Adress",
            "RegistrationCredentials": "Credentials",
            "ProceedWithoutRegistration": "Try out without signing up",
            "EmailTakenError": "The email address is already associated with an account",
            "Username": "Forum nickname",
            "UsernameTakenError": "The display name is already taken",
            "UsernamePatternError": "The display name can have a maximum of 32 characters. Only letters and/or numbers are permitted on either side of space characters. Other special characters are not permitted.",
            "LoginText": "To use Litello on your desktop, log in now using your login details from the app. ",
            "LoginTitle": "Log in",
            "LoginText2": "Haven’t signed up yet? You can change this in a couple of clicks.",
            "NoAccountYet": "You dont have an account?",
            "RegisterHere": "Register here.",
            "LoginButton": "Log in",
            "WrongCredentialsError": "Email address or password invalid",
            "NoNetworkConnection": "No internet connection, please check your network connection",
            "MailNotProvided": "Please enter the email address to which the confirmation code should be sent.",
            "ResendConfirmation": "Resend confirmation email",
            "ConfirmationResent": "We have resent the confirmation. Please check your inbox.",
            "NotVerifiedHeader": "E-Mail not verified",
            "NotVerified": "We have sent you an email confirmation. You need to confirm at your email before you can login.",
            "LogoutButton": "Log out",
            "ForgotPassword": "Have you forgotten your username or password?",
            "ForgotPasswordLink": "Click here",
            "GenderLabel": "Title*",
            "GenderFemale": "Ms",
            "GenderMale": "Mr",
            "Firstname": "First name*",
            "Lastname": "Surname*",
            "Email": "Email address*",
            "RepeatEmail": "Confirm Email*",
            "Password": "Password*",
            "RepeatPassword": "Confirm password*",
            "Street": "Street and building no.*",
            "Plz": "Postcode*",
            "Ort": "Town/City*",
            "Land": "Country*",
            "PasswordError": "The passwords must match",
            "EmailError": "The e-mails must match",
            "CheckBoxError": "Please read and accept the privacy policy!",
            "PasswordTip": "Your password must contain at least 8 characters.\nTake note of upper and lowercase spelling.",
            "DSGVOTip": "Please confirm, that you have read and accept the privacy policy (DSGVO)",
            "DSGVOCheckBoxTextPartOne": "I accept the ",
            "DSGVO": "Privacy Policy",
            "DSGVOCheckBoxTextPartThree": " and accept it.",
            "GoToResetButton": "Forgot your password?",
            "ResetTitle": "Reset password",
            "ResetText": "You will receive a confirmation mail. This mail contains a link and instructions to reset your password",
            "ResetButton": "Send email",
            "BackButton": "Back",
            "ResetSentTitle": "Your password has been reset.",
            "ResetSentText": "Please follow the instructions in the email and then return to the app.",
            "ResetSentButton": "To Log in",
            "NotAllowed": "Your are not allowed to enter into this web reader!",
            "500": "500 Server Error\n\n Oops something went wrong. \n\nTry to refresh this page or feel free to contact us if the problem persists.",
            "400": "Oops something went wrong. \n\nTry to refresh this page or feel free to contact us if the problem persists.",
            "403": "Your account is currently blocked. Please contact us to unblock your account.",
            "InvalidToken": "Invalid token! \nRedirecting to login page!",
            "InvalidEmail": "Invalid email!",
            "verifying": "Account is being verified...",
            "veryfied": "Account successfully verified!",
            "activating": "Account is being activated...",
            "activated": "Account successfully activated! You can now log in.",
            "invalidLink": "Link ist invalid",
            "backToLogin": "to Log in"
        },
        "Contextmenu": {
            "PrivateNoteLabel": "Note",
            "Copied": "It is copied!"
        },
        "Group": {
            "Username": "Username",
            "Title": "My groups",
            "InvitesTitle": "My invitations",
            "CreateGroup": "Create group",
            "GroupName": "Group name",
            "cancel": "Cancel",
            "back": "Back",
            "inviteGroupMember": "Invite members",
            "addMember": "Add",
            "inputEmail": "User’s email address",
            "sendInvite": "Send invitations",
            "Delete": "Delete",
            "Groups": "Group",
            "Invites": "Invitations",
            "Accept": "Accept",
            "Decline": "Decline",
            "alternative": "or upload an Excel file with e-mail addresses as a list: ",
            "SelectFile": "Select file...",
            "from": "From:",
            "InviteEmailError": "Please check that all email addresses were entered correctly. You can separate multiple email addresses using , or ;",
            "InvitationFailed": "The invitations could not be sent. Please check the details you entered or try again later",
            "MemberAlreadyInGroup": "User is already a member of this group.",
            "MemberNotExists": "User not exists.",
            "CreateGroupPatternError": "You can only use letters, numbers, spaces and the characters '&’ and '-'",
            "CreateGroupFailed": "Unfortunately, the group could not be created Please check the details you entered or try again later",
            "role": "Role:"
        },
        "Bookmenu": {
            "TableOfContent": "Table of Contents",
            "BackArrow": "Back to previous position"
        },
        "Search": {
            "Title": "Search",
            "Search": "search",
            "ResultsFor": "Results for",
            "Back": "back",
            "Next": "next",
            "List": "List",
            "Single": "Single",
            "Keyword": "Keyword",
            "KeywordTooShort": "The keyword must be at least 3 characters long",
        },
        "Attachment": {
            "Download": "Downloads",
            "Title": "Attachment",
            "AllCategories": "All categories",
            "AddedOn": "Added on",
            "ShowAllAttachments": "Show all downloads",
            "AlreadyDownloaded": "You already downloaded {{ name }}. Do you want to download it again?",
            "Open": "Open",
            "DownloadAgain": "Download again",
            "DownloadError": "Something went wrong",
            "NoAttachments": "There are no attachments for this book!"
        },
        "NoteOverview": {
            "ChapterSearch": "Chapter search",
            "OnlyNotes": "Notes",
            "Highlights": "Highlighted items",
            "Notes": "Notes",
            "Color": "Colour",
            "Chapter": "Chapter",
            "All": "All chapters",
            "Green": "Green",
            "Yellow": "Yellow",
            "Blue": "Blue",
            "Purple": "Purple",
            "SortByColor": "Sort by colour",
            "SortByChapter": "Sort by chapter",
            "PageAbbreviation": "Page",
            "Today": "Today",
            "Yesterday": "Yesterday",
            "ColorSelection": "Color selection:"
        },
        "CreateNote": {
            "Title": "Add note",
            "EditTitle": "Edit highlighting",
            "NewNote": "Note on highlighting",
            "DeleteNote": "Delete note",
            "NotePlaceholder": "Your note...",
            "Today": "Today"
        },
        "ImageModal": {
            "Image": "Image",
            "Back": "Back"
        },
        "Dialog": {
            "AlertMessage": "Warning! Your changes will be lost if you don’t save them.",
            "Cancel": "Cancel",
            "Save": "Save",
            "DoNotSave": "Don’t save",
            "Delete": "Delete",
            "DeletePostReally": "Do you really want to delete the post?",
            "DeleteNoteReally": "Do you really want to delete the note?",
            "DeleteBookReally": "Do you really want to delete the book?",
            "NoteTextNeeded": "Note text is required!",
            "OK": "OK",
            "SyncBeforeDelete": "You must synchronize the changes before deleting a book",
            "Close": "Close"
        },
        "Menu": {
            "shop": "Visit shop",
            "AboutLitello": "About Litello",
            "Credits": "Credits",
            "Changelog": "Changelog",
            "Imprint": "Imprint",
            "DataPrivacy": "Data Privacy",
            "Profile": "Profile",
            "ChangePassword": "Change password",
            "LocalStorage": "Local Storage",
            "Support": "Help and support",
            "ThemeSwitcher": "Themeswitcher"
        },
        "MenuTooltip": {
            "Edit": "Edit",
            "Locate": "Locate",
            "Delete": "Delete"
        },
        "ThemeSwitcher": {
            "intro": "DEV-ONLY! Here is an overview of all existing Themes. Click on activate to switch to a specific theme.",
            "activate": "Activate"
        },
        "Credits": {
            "Credits": "Credits",
            "Component": "Component",
            "Owner": "Owner",
            "License": "Licence",
            "NetworkMessage": "You need to have a network connection in order to see the credits."
        },
        "Changelog": {
            "Changelog": "Changelog"
        },
        "HelpAndSupport": {
            "Header": "Help und Support",
            "Title": " What functions are available to you ",
            "IntroText": " Here you will find help for the individual functions in Litello. However, if any questions remain unanswered or technical problems occur, please contact support.",
            "Bib": " Library ",
            "Search": "Search",
            "Marks": "Markings",
            "Downloads": "Downloads",
            "ContactSupport": "Contact support"
        },
        "Support": {
            "Header": "Contact support",
            "BackButton": " go back ",
            "Title": " Contact support",
            "IntroText": " Do you have problems with the application or a litelloBook? Then write to us. Please select the subject from the dropdowns and attach a short message roughly describing the problem.",
            "Email1": " Contact email address *",
            "Email2": " Confirm email address *",
            "EmailError1": " Please enter an email in the correct format",
            "EmailError2": " Please enter email",
            "EmailError3": " The emails must match!",
            "Subject": "Subject",
            "Subject1": " Problem with a LitelloBook",
            "Subject2": "Problem with the Webreader",
            "Subject3": " Other ",
            "SubjectError1": " Please select subject!",
            "SubjectValue": " Enter subject*",
            "Book": "Select book*",
            "SubjectValueError": " Please enter subject*",
            "BookError": " Please select book *",
            "Message": " Message*",
            "MessageError": " Please enter message",
            "Notice": "(In order to process your request, we need a contact email address. This will only be used to answer the request.)*",
            "SendButton": "Contact support",
            "emailPlaceholder": "Email",
            "subjectPlaceholder": "Select subject",
            "bookPlaceholder": "Select book",
            "subjectValuePlaceholder": "Reason"
        },
        "Help": {
            "HeaderMarks": "Help und Support | Markings",
            "HeaderSearch": "Help und Support | Search",
            "HeaderDownloads": "Help und Support | Downloads",
            "BackButton": " go back"
        },
        "DataPrivacy": {
            "Title": "Data Privacy"
        },

        "Imprint": {
            "Title": "Imprint"
        },
        "Settings": {
            "NetworkConnected": "Network connected",
            "NetworkDisconnected": "Network disconnected"
        },
        "Profile": {
            "Avatar": "Avatar:",
            "Firstname": "Firstname:",
            "Lastname": "Lastname:",
            "Nickname": "Nickname:",
            "Bio": "Bio",
            "Email": "E-Mail:",
            "NotVerified": "not verified",
            "Verified": "verified",
            "ChangePassword": "Change password",
            "OldPassword": "Old password",
            "NewPassword": "New password",
            "NewPasswordConfirm": "Confirm new password",
            "NewPasswordSave": "Save password",
            "Save": "Save",
            "Edit": "Edit",
            "NetworkMessage": "You need to have a network connection in order to see your profile.",
            "ChangeProfileImage": "Edit profile picture"
        },
        "LocalStorage": {
            "Synchronizing": "Synchronize",
            "RemoveDownload": "Remove Download",
            "LastSync": "Last Synchronization",
            "NoBookDownloaded": "There is currently no eBook downloaded.",
            "notSynchronizedYet": "Not synchronized yet"
        },
        "OfflineMode": {
            "Download": "Download",
            "Open": "Open"
        },
        "InternetConnection": {
            "InOfflineMode": "No Internet Connection. You are now in Offline-Mode.",
            "NoInternetConnection": "No Internet connection.",
            "Online": "Online!"
        },
        "Toast": {
            "Close": "Click to Close",
            "Favorite": "Favorite",
            "Done": "Done"
        }
    }
};