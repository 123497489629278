<ion-header class="ion-modal-header">
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="header-title ion-text-left" l10nTranslate> Menu.ChangePassword </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="themeService.modul.changePassword === 1">
  <div class="change-password-content">
    <form class="change-password-form" [formGroup]="formGroup">
      <div class="cp-form-item">
        <ion-label l10nTranslate>Profile.OldPassword</ion-label>
        <div class="old-pass-status">
          <ion-input formControlName="oldPassword" type="password"></ion-input>
          <ion-icon
            class="verified-icon icon-padding"
            *ngIf="oldPasswordCheck !== undefined && oldPasswordCheck"
            name="checkmark"
          ></ion-icon>
          <ion-icon
            class="not-verified-icon icon-padding"
            *ngIf="oldPasswordCheck !== undefined && !oldPasswordCheck"
            name="close"
          ></ion-icon>
        </div>
      </div>
      <div class="cp-form-item">
        <ion-label l10nTranslate>Profile.NewPassword</ion-label>
        <ion-input formControlName="newPassword" type="password">
          <ion-icon
            class="verified-icon"
            name="checkmark"
            *ngIf="
              !formGroup?.controls.newPassword.invalid &&
              (formGroup?.controls.newPassword.dirty || formGroup?.controls.newPassword.touched)
            "
          ></ion-icon>
          <ion-icon
            class="not-verified-icon"
            name="close"
            *ngIf="
              !formGroup?.controls.newPassword.valid &&
              (formGroup?.controls.newPassword.dirty || formGroup?.controls.newPassword.touched)
            "
          ></ion-icon>
        </ion-input>
      </div>
      <div class="cp-form-item">
        <ion-label l10nTranslate>Profile.NewPasswordConfirm</ion-label>
        <ion-input formControlName="newPasswordConfirm" type="password">
          <ion-icon
            class="verified-icon"
            name="checkmark"
            *ngIf="
              !formGroup?.controls.newPasswordConfirm.invalid &&
              (formGroup?.controls.newPasswordConfirm.dirty || formGroup?.controls.newPasswordConfirm.touched)
            "
          ></ion-icon>
          <ion-icon
            class="not-verified-icon"
            name="close"
            *ngIf="
              !formGroup?.controls.newPasswordConfirm.valid &&
              (formGroup?.controls.newPasswordConfirm.dirty || formGroup?.controls.newPasswordConfirm.touched)
            "
          ></ion-icon>
        </ion-input>
      </div>
    </form>
  </div>
</ion-content>

<ion-footer class="change-password-footer">
  <ion-button class="footer-cp-cancel-btn" (click)="closeModal()" l10nTranslate> Dialog.Cancel </ion-button>
  <ion-button class="footer-cp-save-btn" (click)="changePassword()" [disabled]="!formGroup?.valid" l10nTranslate>
    Profile.NewPasswordSave
  </ion-button>
</ion-footer>
