<ion-header class='ion-modal-header'>
    <ion-toolbar class='header-toolbar'>
        <ion-buttons slot='end'>
            <ion-button (click)="closeAttachmentModal()">
                <ion-icon name='close-outline'></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title class='header-title ion-text-left' l10nTranslate>
            Attachment.Title
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class='attachment-modal'>
    <iframe *ngIf="!(this.isImage(data) || this.isVideo(data))" width="100%"  height="100%" frameborder="0"
            [src]='sanitizerData()'></iframe>

    <div class="img-video-content" *ngIf="this.isImage(data) || this.isVideo(data)">
        <img *ngIf="isImage(data)" [src]='sanitizerData()'/>

        <video *ngIf="isVideo(data)" preload="metadata" loop autoplay playsinline
               width="100%" controls="true">
            <source [src]="sanitizerData()" type="video/mp4">
            <source [src]="sanitizerData()" type="video/mov">
            <source [src]="sanitizerData()" type="video/m4v">
            <source [src]="sanitizerData()" type="video/3gp">
        </video>
    </div>
</ion-content>
