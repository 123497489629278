import { Injectable } from '@angular/core';

const LOCALE_KEY = 'locale';
const EN_PREFIX = 'en-';
const DE_PREFIX = 'de-';
const DE_KEY = 'de';
const EN_KEY = 'en';

/**
 * Service to check current locale
 */
@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  constructor() {}

  public static getCurrentLocale() {
    return localStorage.getItem(LOCALE_KEY);
  }

  public static getCurrentLocaleKey() {
    const locale = localStorage.getItem(LOCALE_KEY);
    if (locale && locale.startsWith(EN_PREFIX)) {
      return EN_KEY;
    } else {
      return DE_KEY;
    }
  }
}
