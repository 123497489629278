import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, firstValueFrom } from 'rxjs';

export type SignedUrlRequest = {
    filename: string;
}
export type SignedUrlResponse = {
    url: string;
    s3Key: string;
}

@Injectable({
  providedIn: 'root',
})
export class S3Service {
    private baseApiUrl: string = environment.production ? 'https://api.litello.com' : 'https://api-staging.litello.com';

    constructor(private http: HttpClient) {}

    private getS3SignedUrl(filename: string, userId: string): Observable<SignedUrlResponse> {
        const headers = new HttpHeaders({
            "Accept": "application/json",
            AUTHORIZATION: localStorage.getItem('token') || ""
          });
        return this.http.put<SignedUrlResponse>(`${this.baseApiUrl}/users/${userId}/profiles/avatar`, <SignedUrlRequest>{ filename }, {headers});
    }

    async upload(filename: string, userId: string, file: File): Promise<any> {
        if (!filename || !userId || !file) {
            throw new Error("Function parameters must be defined.");
        }
        const signedUrl = (await firstValueFrom(this.getS3SignedUrl(filename, userId)))?.url;
        if (!signedUrl) {
            throw new Error("The parameter 'signedUrl' must be defined.");
        }

        return this.http.put<any>(signedUrl, file).toPromise();
    }

    async delete(userId: string): Promise<any> {
        if (!userId) {
            throw new Error("Function parameters must be defined.");
        }
        const headers = new HttpHeaders({
            "Accept": "application/json",
            AUTHORIZATION: localStorage.getItem('token') || ""
          });

        return this.http.delete(`${this.baseApiUrl}/users/${userId}/profiles/avatar`, {headers}).toPromise();
    }
}
