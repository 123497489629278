import { StatusBar, Style } from '@capacitor/status-bar';
import { ConnectionStatus, Network } from '@capacitor/network';
import { BookDatabaseService } from './../../services/book-database/book-database.service';
import { OfflineModeService } from '../../services/offline-mode/offline-mode.service';
import { MenuController, Platform } from '@ionic/angular';
import { BooksService } from '../../services/books/books.service';
import { Component, Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClientBook } from '../../PODO/clientBook';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '../../PODO/constants';
import { ThemeService } from '../../services/themes/theme.service';
import { ToastService } from '../../services/toast/toast.service';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import moment from 'moment';
import { LocalizationService } from '../../services/localization-service/localization-service.service';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { ConfirmationDlgService } from '../../services/dialogs/confirmation-dlg.service';
import { NoteService } from '../../book/services/note/note.service';
import { TexthighlightService } from '../../book/services/texthighlight/texthighlight.service';
import { DatabaseService } from '../../services/localDatabase/database-service.service';
import { Note, TextHighlight } from '../../services/rest-client/rest-client.service';
import { AlertDlgService } from '../../services/dialogs/alert-dlg.service';
import { UserService } from '../../services/user/user.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.scss'],
})
@Injectable()
export class BooksComponent implements OnDestroy {
  public userId: any;
  public books: ClientBook[] = [];
  public booksCount: number = 0;
  public columnSizeXl: number = 0;
  public columnSizeLg: number = 0;
  public columnSizeMd: number = 0;
  public columnSizeSm: number = 0;
  public columnSizeXs: number = 0;
  public contentLocaleKey = 'de';
  public devWidth = this.platform.width();
  public isNative: boolean = false;
  public loading: boolean = false;
  private readonly TIMEOUT_MILLISECONDS = 7 * 23 * 60 * 60 * 1000;
  private subscriptions: Subscription[] = [];
  private initialFetchingAt: number | undefined;
  private bookIdToOpen: string | null = '';
  networkListener: PluginListenerHandle | undefined;
  networkStatus: ConnectionStatus | undefined;

  public constructor(
    private userService: UserService,
    private offlineBooksService: OfflineModeService,
    private router: Router,
    private route: ActivatedRoute,
    private booksService: BooksService,
    private menuCtrl: MenuController,
    private platform: Platform,
    public translationService: L10nTranslationService,
    public themeService: ThemeService,
    private toastService: ToastService,
    private confirmationDlgService: ConfirmationDlgService,
    private alertDlgService: AlertDlgService,
    public noteService: NoteService,
    public textHighlightService: TexthighlightService,
    private bookDatabaseService: BookDatabaseService,
    private dataBaseService: DatabaseService,
    public sanitizer: DomSanitizer,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {
    this.initializeComponent();
  }

  public async initializeComponent() {
    this.books = [];
    if (Capacitor.isNativePlatform()) {
      this.isNative = true;
    }
    this.networkStatus = await Network.getStatus();
    this.checkSignedUrlExpiration();
    this.networkListener = Network.addListener('networkStatusChange', async (status) => {
      this.networkStatus = status;
      await this.initializeBooks();
    });
    this.bookIdToOpen = this.route.snapshot.paramMap.get('id');
    this.translationService.onChange().subscribe({
      next: () => {
        this.contentLocaleKey = LocalizationService.getCurrentLocaleKey();
      },
    });
  }

  async ionViewDidEnter() {
    if (this.userId !== this.userService.getUserID()) {
      this.books = [];
    }
    this.userId = this.userService.getUserID();
    this.loading = true;
    await this.initializeBooks();
    this.menuCtrl.enable(true, 'main-menu');
    if (Capacitor.getPlatform() === 'ios') {
      StatusBar.setStyle({ style: Style.Light });
    }
  }

  public ngOnDestroy() {
    this.networkListener!.remove();
    this.subscriptions = [];
  }

  public async initializeBooks() {
    this.loading = true;
    if (!(this.books.length > 0)) {
      await this.loadBooks();
      this.checkSignedUrlExpiration();
      this.subscriptions.push(
        this.booksService.books$.subscribe((books) => {
          this.onBooksLoadSuccess(books);
        })
      );
      this.subscriptions.push(
        this.platform.resume.subscribe(() => {
          this.checkSignedUrlExpiration();
        })
      );
      if (Capacitor.isNativePlatform()) {
        this.isDownloaded();
      }
      this.redirect();
    }

    this.books.sort(function (obj1, obj2) {
      if (obj1.lastRead > obj2.lastRead) {
        return -1;
      } else if (obj1.lastRead < obj2.lastRead) {
        return 1;
      } else {
        return 0;
      }
    });
    this.loading = false;
  }

  public async refreshBooks(event: any) {
    this.loading = true;
    this.books = [];
    await this.initializeBooks();
    event.target.complete();
  }

  public async getApplicationPath(filePath: string) {
    return await Filesystem.getUri({
      path: filePath,
      directory: Directory.Data,
    });
  }

  public deleteBook(book: any) {
    let unSyncData: Array<any> = [];
    this.dataBaseService.getUnsynchronizedHighlightsByBookId(book.id, '2').then((res: TextHighlight[]) => {
      unSyncData = [...unSyncData, ...res];
      this.dataBaseService.getUnsynchronizedNotesByBookId(book.id, '2').then((res: Note[]) => {
        unSyncData = [...unSyncData, ...res];
        if (unSyncData.length === 0) {
          this.confirmationDlgService.showConfirmationDlg(
            book,
            this.translationService.translate('Dialog.Delete'),
            this.translationService.translate('Dialog.Cancel'),
            () => {
              this.offlineBooksService
                .deleteBook(book)
                .catch((error) => console.log('while deleting a book, got error: ', error))
                .then(() => {
                  this.dataBaseService.delTxtHighLightByBookId(book.id).then(() => {
                    this.dataBaseService.delNoteByBookId(book.id);
                  });
                });
            }
          );
        } else {
          if (this.networkStatus?.connected) {
            this.noteService.synchronizeData(book.id).then(() => {
              this.textHighlightService.synchronizeData(book.id).then(() => {
                this.noteService.loadNotes(book.id).then(() => {
                  this.dataBaseService.setSyncDateByBookId(book.id, Date.now().toString()).then(() => {
                      this.confirmationDlgService.showConfirmationDlg(
                        book,
                        this.translationService.translate('Dialog.Delete'),
                        this.translationService.translate('Dialog.Cancel'),
                        () => {
                          this.offlineBooksService
                            .deleteBook(book)
                            .catch((error) => console.log('while deleting a book, got error: ', error))
                            .then(() => {
                              this.dataBaseService.delTxtHighLightByBookId(book.id).then(() => {
                                this.dataBaseService.delNoteByBookId(book.id);
                              });
                            })
                        }
                      );
                  });
                });
              });
            });
          } else {
            this.alertDlgService.showAlertDlg(this.translationService.translate('Dialog.SyncBeforeDelete'));
          }
        }
      });
    });
  }

  public async downloadBook(element: any, book: ClientBook) {
    await this.offlineBooksService.downloadBookWithBlob(element, book);
  }

  public async isDownloaded() {
    this.books.forEach((book) => {
      this.offlineBooksService.checkIfFileExists(book.id.toString()).then((res) => (book.downloaded = !!res?.size));
    });

  }

  public setColSize() {
    this.booksCount = this.books.length;

    if (this.booksCount < 6) {
      this.columnSizeXl = 6;
      this.columnSizeLg = 6;
      this.columnSizeMd = 12;
      this.columnSizeSm = 12;
      this.columnSizeXs = 12;
    } else if (this.booksCount >= 6) {
      this.columnSizeXl = 4;
      this.columnSizeLg = 6;
      this.columnSizeMd = 6;
      this.columnSizeSm = 12;
      this.columnSizeXs = 12;
    }
  }

  public transformDate(milliseconds: number) {
    return moment(milliseconds).calendar(null, {
      sameDay: 'DD.MM.YYYY',
      nextWeek: 'DD.MM.YYYY',
      lastDay: 'DD.MM.YYYY',
      lastWeek: 'DD.MM.YYYY',
      sameElse: 'DD.MM.YYYY',
    });
  }

  public redirect(): boolean {
    // The following code snippet will open the book automatically if there is only one book in the library

    /*
    * let count: number = this.books.length;
    * if (count === 1 && !this.booksService.getOpendedStatus()) {
    *  if(!Capacitor.isNativePlatform()){
    *    this.openBook(this.books[0].id);
    *  } else if (this.books[0].downloaded) {
    *    this.openDownloadedBook(this.books[0])
    *  }
    *  return false;
    * } 
    * else
    */ 
    if (this.bookIdToOpen && this.bookIdToOpen.length != 0) {
      let bookIdToNumber = parseInt(this.bookIdToOpen);
      if(Capacitor.isNativePlatform() && this.books[0].downloaded){
        this.openDownloadedBook(this.books[0])
      } else {
        this.openBook(bookIdToNumber);
      }
      return false;
    } else {
      return true;
    }
  }

  public openBook(id: number) {
    let book = this.books.find((book) => book.id === id);
    if (book) {
      book.lastRead = Date.now();
      this.router
        .navigate([`/${Constants.URL.Book}`, id])
        .then((navigated) => {
          if (!navigated) {
            console.error('Route is not navigated');
            this.toastService.showErrorMessage(this.translationService.translate('UnknownError'));
          }
        })
        .catch((error) => {
          console.error(error);
          console.log(error);
          this.toastService.showErrorMessage(this.translationService.translate('UnknownError'));
        });
    } else {
      console.warn(`Book with id: ${id} not found!`);
      this.toastService.showErrorMessage(this.translationService.translate('Book.NotFound'));
    }
  }

  private checkSignedUrlExpiration() {
    if (Date.now() - this.initialFetchingAt! > this.TIMEOUT_MILLISECONDS) {
      window.location.reload();
    }
  }

  private async loadBooks() {
    await this.booksService.loadBooks(this.onBooksLoadFailure.bind(this));
  }

  private onBooksLoadSuccess(books: any[]) {
    this.books = [];
    if (this.books.length === 0) {
      this.books = books;
    }
    this.setColSize();
    //this.isDownloaded();
    if (this.initialFetchingAt == undefined) {
      this.initialFetchingAt = Date.now();
    }
  }

  private onBooksLoadFailure(error: any) {
    console.error(`error: ${error}`);
    this.toastService.showErrorMessage(this.translationService.translate('UnknownError'));
  }

  async openDownloadedBook(book: any) {
    if (Capacitor.isNativePlatform() && this.networkStatus?.connected) {
      await this.dataBaseService.setSyncDateByBookId(book.id, Date.now().toString());
    }
    this.updateLastOpened(book);
    this.router.navigate([`/${Constants.URL.Book}`, book.id], { queryParams: { bookOffline: true } });
  }

  public updateLastOpened(book: any) {
    if (Capacitor.isNativePlatform()) {
      book!.lastRead = Date.now();
      this.bookDatabaseService.updateLastOpenedDate(book.lastRead, book.id);
    } else if (Capacitor.isNativePlatform() && this.networkStatus?.connected) {
      this.changeSynchronizedState('2', book.id);
    }
  }

  public changeSynchronizedState(state: string, bookId: number) {
    this.bookDatabaseService.setSynchronizedState(state, bookId);
  }
}
