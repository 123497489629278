import { Component, Inject, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

@Component({
  selector: 'app-network-changed',
  templateUrl: './network-changed.component.html',
  styleUrls: ['./network-changed.component.scss'],
})
export class NetworkChangedComponent implements OnInit {
  @Input() isDesktop: boolean | undefined;
  @Input() status: boolean | undefined;

  constructor(
    public modalController: ModalController,
    @Inject(L10N_LOCALE) public locale: L10nLocale
  ) {}

  ngOnInit() {}

  dismiss() {
    this.modalController.dismiss();
  }
}
