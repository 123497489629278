import { Injectable } from '@angular/core';
import { BooksService } from '../books/books.service';
import { ThemeService } from '../themes/theme.service';
import { OfflineModeService } from '../offline-mode/offline-mode.service';
import { UserService } from '../user/user.service';
import { StorageService } from '../storage/storage.service';
import { L10nTranslationService } from 'angular-l10n';
import { ModalController } from '@ionic/angular';
import { DeviceDetectorService } from '../../util/device-detector/device-detector.service';
import { DialogComponent } from '../../components/dialog/dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AlertDlgService {
  constructor(
    private booksService: BooksService,
    public themeService: ThemeService,
    public offlineModeService: OfflineModeService,
    public userService: UserService,
    public storageService: StorageService,
    private localization: L10nTranslationService,
    private modalController: ModalController,
    private deviceDetector: DeviceDetectorService
  ) {}

  public async showAlertDlg(title: string) {
    let cssClass = 'confirm-dialog';
    if (this.deviceDetector.isMobile()) cssClass = 'confirm-dialog-mobile';
    if (this.deviceDetector.isTabletSize()) cssClass = 'confirm-dialog-tablet';

    const dialog = await this.modalController.create({
      component: DialogComponent,
      cssClass: cssClass,
      backdropDismiss: false,
      enterAnimation: undefined,
      leaveAnimation: undefined,
      componentProps: {
        message: title,
        buttons: [
          {
            text: this.localization.translate('Dialog.Close'),
            method: this.closeDialog.bind(this),
          },
        ],
      },
    });
    await dialog.present();
  }

  private async closeDialog() {
    const modalElem = await this.modalController.getTop();
    if (modalElem) {
      modalElem.dismiss();
    }
  }
}
