<ion-header class='ion-modal-header'>
  <ion-toolbar class='header-toolbar'>
    <ion-buttons slot='end'>
      <ion-button (click)="closeProfile()">
        <ion-icon name='close-outline'></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class='header-title ion-text-left' l10nTranslate>
      Menu.Profile
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content *ngIf="!online">
  <ion-grid>
    <ion-row>
      <ion-col size="2">
      </ion-col>

      <ion-col size="8">
        <ion-text color="dark">
          <h5 l10nTranslate>Profile.NetworkMessage</h5>
        </ion-text>
      </ion-col>

      <ion-col size="2">
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-content class='content-not-edit-mode' *ngIf='profile && !isEditMode && online'>
  <div class='content-nem'>
    <ion-avatar #avatarImg [class.avatar_error]="!imgSrc" class='avatar content-nem-avatar'>
      <img class="avatar__image" *ngIf="imgSrc"
        [src]='imgSrc'
        (load)="onAvatarLoad(avatarImg.el)"
        (error)="onAvatarError(avatarImg.el)"
      >
      <div class="avatar__initials">{{ getUserInitials() }}</div>
    </ion-avatar>
    <!--ion-icon class="edit-avatar-icon" src="../../assets/icon/editIcon.svg"></ion-icon-->
    <p class='content-nem-user-name'>{{profile.firstname}} {{profile.lastname}} </p>
    <p class='content-nem-nickname'>@{{profile.nickname}}</p>

    <div class='content-nem-data'>
      <div class='content-nem-data__email' l10nTranslate>Profile.Email</div>
      <div class="email-status {{profile.active ? 'verified' : 'not-verified' }}">
        <div class='email-text'>{{profile.email}}</div>
        <ion-icon class='verified-icon' *ngIf='profile.active' name='checkmark-outline'></ion-icon>
        <ion-icon class='not-verified-icon' *ngIf='!profile.active' name='close'></ion-icon>
      </div>
      <div class='content-nem-data__bio' l10nTranslate>Profile.Bio</div>
      <div class='bio-text'>{{profile.biography}}</div>
    </div>
  </div>
</ion-content>

<ion-footer class='profile-footer' *ngIf='profile && !isEditMode && online'>
  <ion-button (click)='toggleEditMode()' l10nTranslate>
    Profile.Edit
  </ion-button>
</ion-footer>


<ion-content *ngIf='profile && isEditMode && online && themeService.modul.profile === 1'>
  <div class='edit-profile-content'>
    <div class='edit-profile-right'>
      <div (click)="onEditAvatar()" class="pointer no-select flex-column flex-center">
        <ion-avatar #avatarImg2 [class.avatar_error]="!imgSrc" class='avatar avatar-edit'>
          <img class="avatar__image"
            *ngIf="imgSrc"
            [src]='imgSrc'
            (load)="onAvatarLoad(avatarImg2.el)"
            (error)="onAvatarError(avatarImg2.el)"
          >
          <ion-icon class="avatar-edit-icon" src="../../assets/icon/editIcon.svg"></ion-icon>
          <div class="avatar__initials">{{ getUserInitials() }}</div>
        </ion-avatar>
        <p class="edit-profile-img-txt" l10nTranslate>Profile.ChangeProfileImage</p>
      </div>
    </div>
    <div class='edit-profile-left'>
      <form [formGroup]='formGroup'>
        <div class='ep-form-item'>
          <ion-label l10nTranslate>Profile.Firstname</ion-label>
          <ion-input formControlName='firstname' type='text'></ion-input>
        </div>
        <div class='ep-form-item'>
          <ion-label l10nTranslate>Profile.Lastname</ion-label>
          <ion-input formControlName='lastname' type='text'></ion-input>
        </div>
        <div class='ep-form-item'>
          <ion-label l10nTranslate>Profile.Nickname</ion-label>
          <ion-input formControlName='nickname' type='text'></ion-input>
        </div>
        <div class='ep-form-item before-password'>
          <ion-label l10nTranslate>Profile.Email</ion-label>
          <div class="email-status {{profile.active ? 'verified' : 'not-verified' }}">
            <ion-input formControlName='email' type='text'></ion-input>
            <ion-icon class='verified-icon' *ngIf='profile.active' name='checkmark-outline'></ion-icon>
            <ion-icon class='not-verified-icon' *ngIf='!profile.active' name='close'></ion-icon>
          </div>
        </div>
        <a *ngIf="themeService.modul.changePassword === 1" class='ep-form-change-password' (click)='changePassword()' l10nTranslate>
          Profile.ChangePassword
        </a>
        <div class='ep-form-item after-password'>
          <ion-label l10nTranslate>Profile.Bio</ion-label>
          <ion-textarea rows="5" formControlName='biography'></ion-textarea>
        </div>
      </form>
    </div>
  </div>
</ion-content>


<ion-footer class='edit-profile-footer' *ngIf='profile && isEditMode && online'>
  <ion-button class='footer-ep-cancel-btn' (click)='toggleEditMode()' l10nTranslate>
    Dialog.Cancel
  </ion-button>
  <ion-button class='footer-ep-save-btn' (click)='updateProfile()'
              [disabled]='!formGroup.valid' l10nTranslate>
    Profile.Save
  </ion-button>
</ion-footer>
  
