<ion-header class="ion-modal-header">
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="header-title ion-text-left"> Theme Switcher </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-label l10nTranslate>ThemeSwitcher.intro</ion-label>
    </ion-row>

    <ion-row class="ion-justify-content-center">
      <ion-card *ngFor="let item of customers">
        <ion-card-header>
          <ion-card-subtitle>Customer: </ion-card-subtitle>
          <ion-card-title>{{ item.sub_domain_name }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-button color="success" (click)="activateTheme(item.sub_domain_name)" l10nTranslate
            >ThemeSwitcher.activate</ion-button
          >
        </ion-card-content>
      </ion-card>
    </ion-row>
  </ion-grid>
</ion-content>
<ion-footer class="contact-support-footer"> </ion-footer>
