<ion-header #header>
  <div class="attachment-overview-header">
    <!-- TODO https://github.com/ionic-team/ionic-framework/issues/12179 -->

    <ion-select #attachmentSelect
      id="attachment-overview-chapter"
      class="select-chapter"
      interface="popover"
      [interfaceOptions]="{
        enterAnimation: dropdownAnimation
      }"
      [value]="selectedCategory"
      (ionChange)="toggleCategory($event.detail.value)"
    >
      <litello-dropdown-list
        [listItems]="attachmentsByCategories"
        [listItemValueProperty]="'name'"
        [listItemDisplayTextProperty]="'name'"
        [noSelectionValue]="allCategoryString"
        [noSelectionDisplayText]="localization.translate('Attachment.AllCategories')"
        [selectElement]="attachmentSelect.el"
      ></litello-dropdown-list>
    </ion-select>
  </div>
</ion-header>

<ion-content
  class="attachment-overview-content"
  [ngStyle]="{ height: scrollUnlocked ? '100%' : 'calc(100% - var(--ion-safe-area-bottom, 0) - 10rem)' }"
  scrollEvents="{{ scrollUnlocked }}"
  (ionScroll)="onIonScroll($event)"
  appHideHeader
  [header]="header"
>
  <div class="no-attachments-message" *ngIf="attachments && attachments.length === 0" l10nTranslate>
    Attachment.NoAttachments
  </div>

  <div
    class="show-all-attachments"
    *ngIf="attachmentLink && attachmentsByCategories && attachmentsByCategories.length === 1"
    (click)="showAllAttachments()"
    l10nTranslate
  >
    Attachment.ShowAllAttachments
  </div>

  <div class="attachments-by-category" *ngFor="let category of attachmentsByCategories">
    <div *ngIf="selectedCategory === category.name || selectedCategory === allCategoryString">
      <div class="attachment-category">
        {{ category.name }}
      </div>

      <div
        *ngFor="let attachment of category.attachments"
        class="attachment-list"
        [litello-focus]="isAttachmentLinkedTo(attachment)"
        [id]="getAttachmentElementId(attachment)"
        (click)="download(attachment)"
      >
        <span class="attachment-list__name">{{
          attachment.title ? attachment.title : getSecPartOfSting(attachment.filename, '#')
        }}</span>
        <div class="attachment-list__details">
          <div class="al__info">
            <span
              class="info-description"
              *ngIf="attachment.description && attachment.description !== 'None' && attachment.description !== 'none'"
              >{{ attachment.description }}</span
            >
            <span class="info-date"
              ><span l10nTranslate>Attachment.AddedOn</span><span> {{ transformDate(attachment.created) }}</span></span
            >
            <span class="info-type-size"
              >{{ getSecPartOfSting(attachment.type, '/') }} · {{ formatSizeUnits(attachment.size) }}</span
            >
          </div>

          <div class="al__download">
            <ion-icon
              [hidden]="attachment.percent !== 100 && attachment.percent !== 0"
              src="../../assets/icon/download-01.svg"
            ></ion-icon>
            <span [hidden]="attachment.percent === 100 || attachment.percent === 0">{{ attachment.percent }}%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
<div id="append-modal"></div>
