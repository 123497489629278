<ion-header class="ion-modal-header">
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="end">
      <ion-button (click)="closeModal()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="header-title ion-text-left" l10nTranslate> Support.Header </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form [formGroup]="form" (ngSubmit)="onSubmitTemplateBased(form)">
  <ion-grid>
    <ion-row>
      <ion-label (click)="goBack()" class="backButton" l10nTranslate> Support.BackButton</ion-label>
    </ion-row>
    <div class="Content">
    <ion-row>
      <ion-label class="title" l10nTranslate> Support.Title
      </ion-label>
    </ion-row>
    <ion-row>
      <ion-label class="introText" l10nTranslate> Support.IntroText
      </ion-label>
    </ion-row>
      <ion-row class="EmailRow">
        <ion-col  sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="6">
          <ion-label class="inputLabel" l10nTranslate> Support.Email1</ion-label>
          <ion-input formControlName="email" placeholder="{{emailPlaceholder}}" type="email">
          </ion-input>
          <ion-label *ngIf="form.get('email')!.hasError('pattern') && form.get('email')!.touched" class="warning" l10nTranslate> Support.EmailError1</ion-label>
          <ion-label *ngIf="form.get('email')!.hasError('required') && form.get('email')!.touched" class="warning" l10nTranslate> Support.EmailError2</ion-label>
        </ion-col>
        <ion-col  sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="6">
          <ion-label class="inputLabel" l10nTranslate> Support.Email2</ion-label>
          <ion-input formControlName="email2" placeholder="{{emailPlaceholder}}" type="email">
          </ion-input>
          <ion-label *ngIf="form.get('email2')!.hasError('pattern') && form.get('email2')!.touched" class="warning" l10nTranslate> Support.EmailError1</ion-label>
          <ion-label *ngIf="form.get('email2')!.hasError('required') && form.get('email2')!.touched" class="warning" l10nTranslate> Support.EmailError2</ion-label>
          <ion-label *ngIf="form.get('email2')?.errors?.EmailsNotMatching && form.get('email2')!.touched" class="warning" l10nTranslate> Support.EmailError3</ion-label>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="6">
          <ion-label class="inputLabel"l10nTranslate> Support.Subject</ion-label>
          <ion-select formControlName="subject" placeholder="{{subjectPlaceholder}}">
            <ion-select-option value="Problem mit einem LitelloBook" l10nTranslate> Support.Subject1</ion-select-option>
            <ion-select-option value="Problem mit dem Webreader" l10nTranslate> Support.Subject2</ion-select-option>
            <ion-select-option value="Sonstiges" l10nTranslate> Support.Subject3</ion-select-option>
          </ion-select>
          <ion-label *ngIf="form.get('subject')!.hasError('required') && form.get('subject')!.touched" class="warning" l10nTranslate> Support.SubjectError1</ion-label>
        </ion-col>
        <ion-col sizeXs="12" sizeSm="6" sizeMd="6" sizeLg="6">
          <ion-label *ngIf="form.get('subject')!.value === 'Sonstiges'" class="inputLabel" l10nTranslate> Support.SubjectValue
          </ion-label>
          <ion-label *ngIf="form.get('subject')!.value === 'Problem mit einem LitelloBook'" class="inputLabel" l10nTranslate> Support.Book
          </ion-label>
          <ion-input *ngIf="form.get('subject')!.value === 'Sonstiges'" formControlName="subjectValue" class="reason" type="text">
          </ion-input>
          <ion-label *ngIf="form.get('subjectValue')!.hasError('required') && form.get('subjectValue')!.touched" class="warning" l10nTranslate> Support.SubjectValueError</ion-label>
          <ion-select *ngIf="form.get('subject')!.value === 'Problem mit einem LitelloBook'" formControlName="book" class="reason" placeholder="{{bookPlaceholder}}">
              <ion-select-option *ngFor="let item of books" value="{{item.title}}">{{item.title}}</ion-select-option>
          </ion-select>
          <ion-label *ngIf="form.get('book')!.hasError('required') && form.get('book')!.touched" class="warning" l10nTranslate> Support.BookError</ion-label>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12">
          <div class="message">
            <ion-label  class="inputLabel" l10nTranslate> Support.Message</ion-label>
            <ion-textarea formControlName="message"  name="message">
            </ion-textarea>
            <ion-label *ngIf="form.get('message')!.hasError('required') && form.get('message')!.touched" class="warning" l10nTranslate> Support.MessageError</ion-label>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-text class="notice" l10nTranslate> Support.Notice
        </ion-text>
      </ion-row>
    </div>
  </ion-grid>
  <ion-footer class="contact-support-footer">
    <ion-button [disabled]="!form.valid" type="submit" class="footer-cs-save-btn" l10nTranslate> Support.SendButton </ion-button>
  </ion-footer>
</form>
</ion-content>

