<div
  #menuModal
  class="menu-modal"
  [ngClass]="{
    'menu-modal-is-opened': open,
    'menu-modal-is-closed': !open && !inactiveBurger,
    'menu-modal-is-closed-inactive': !open && inactiveBurger
  }"
>
  <div class="menu-burger">
    <app-burger [open]="open" [inactive]="inactiveBurgerEvent.asObservable()" (click)="changeMenuValue()"></app-burger>
  </div>
  <div class="menu-modal-content">
    <div class="grid">
      <div class="row1"></div>
      <div class="row2">
        <ion-icon
          class="go-to-prev-icon"
          [ngStyle]="{ display: previousPageButton ? 'block' : 'none' }"
          (click)="prevPageAndSwap()"
          src="/assets/icon/headerBackArrow.svg"
        ></ion-icon>
        <div
          class="row2-chapter"
          [ngStyle]="{ width: previousPageButton ? '90%' : '100%' }"
          (click)="tableOfContentVisibility()"
        >
          <ion-icon src="/assets/icon/chapterIcon.svg"></ion-icon>
          <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(selectedChapter)"></div>
        </div>
      </div>
      <div class="row3">
        <div class="book-info">
          <img class="book-cover-logo" src="{{ bookCoverSrc }}" />
          <h4>{{ bookTitle }}</h4>
        </div>
        <img class="sidebar-logo" [src]="sanitizer.bypassSecurityTrustUrl(themeService.getSideBarLogo1())" />
      </div>
    </div>

    <div class="tools">
      <div class="tools-left">
        <div class="dashboard-img-background">
          <img class="dashboard-img" (click)="goToBookLibrary()" src="../../../../assets/icon/new-dashboard-logo.svg" />
        </div>
        <span class="vl-horizontal"></span>
        <ion-icon
          class="search"
          [ngClass]="{ 'display-none': showZoomLabel }"
          (click)="toggleSearchView()"
          src="/assets/icon/searchMobile.svg"
        ></ion-icon>
        <ion-icon
          class="highlight-toggle color-primary-gray"
          [style.color]="isHighlightingEnabled ? contextMenuService.color : null"
          [ngClass]="{
            'display-none': showZoomLabel
          }"
          (click)="toggleHighlighting()"
          src="/assets/icon/highlighter-pen-marker.svg"
        ></ion-icon>
        <ion-icon
          class="note"
          [ngClass]="{ 'display-none': showZoomLabel }"
          (click)="toggleNoteOverview()"
          src="/assets/icon/noteOverviewMobile.svg"
        ></ion-icon>
        <ion-icon
          *ngIf="attachmentsExists"
          class="attachments"
          [ngClass]="{ 'display-none': showZoomLabel }"
          (click)="toggleAttachmentsOverview()"
          src="/assets/icon/attachmentMobile.svg"
        ></ion-icon>
        <ion-icon
          *ngIf="collectionExists"
          class="attachments"
          [ngClass]="{ 'display-none': showZoomLabel }"
          (click)="toggleCollectionOverview()"
          src="/assets/icon/collection-mobile.svg"
        ></ion-icon>
        <!-- <ion-icon
          class="attachments"
          [ngClass]="{ 'display-none': showZoomLabel }"
          (click)="toggleCommunicationOverview()"
          src="/assets/icon/messageBubble-mobile.svg"
        ></ion-icon> -->
      </div>

      <div class="tools-right" [ngClass]="{ 'width-100': showZoomLabel }">
        <div class="zoom-label" [ngClass]="{ 'display-flex': showZoomLabel, 'display-none': !showZoomLabel }">
          <ion-icon (click)="zooming('minus')" class="zoom-icon" name="remove-circle-outline"></ion-icon>
          <ion-label>{{ 100 + zoom * 10 }}%</ion-label>
          <ion-icon (click)="zooming('plus')" class="zoom-icon" name="add-circle-outline"></ion-icon>
        </div>
        <div class="size-option">
          <div class="font-size-option" (click)="changeZoomLabelValue()">
            <span
              class="first-character"
              [ngClass]="{ 'color-primary': showZoomLabel, 'color-primary-gray': !showZoomLabel }"
              >A</span
            >
            <span
              class="sec-character"
              [ngClass]="{ 'color-primary': showZoomLabel, 'color-primary-gray': !showZoomLabel }"
              >a</span
            >
          </div>
        </div>
      </div>
    </div>
    <!--[src]='themeService.getNewDashboardLogo()' />-->
    <!--[src]='themeService.getDashboardTopLogo()' />-->
  </div>
</div>
