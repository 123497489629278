<ion-header class="ion-no-border search-header" #header>
  <div class="search-toolbar">
    <div class="search-header">
      <ion-item class="search-header-item">
        <ion-input
          [(ngModel)]="keyword"
          class="search-header-item-input"
          (keyup.enter)="!searching && keyword.length > 0 && searchKeyword()"
          placeholder="{{searchPlaceHolder}}"
          clearInput
        ></ion-input>
      </ion-item>
      <button
        class="search-header-item-btn"
        l10nTranslate
        (click)="searchKeyword()"
        [disabled]="searching || keyword.length === 0"
      >
        Search.Search
      </button>
    </div>
  </div>

  <div class="search-toggle-result-info">
    <div class="search-toggle">
      <span l10nTranslate>Search.List</span>
      <ion-toggle mode="ios" (ionChange)="toggleLayoutMode($event)"></ion-toggle>
      <span l10nTranslate>Search.Single</span>
    </div>
    <div *ngIf="!searching && finalKeyword.length > 0">
      <span>{{ searchResults.length }} </span>
      <span l10nTranslate>Search.ResultsFor</span>
      <span> „{{ finalKeyword }}“</span>
    </div>
  </div>
</ion-header>

<ion-content
  class="search-content"
  [ngStyle]="{ height: scrollUnlocked ? '100%' : 'calc(100% - var(--ion-safe-area-bottom, 0) - 17rem)' }"
  scrollEvents="{{ scrollUnlocked }}"
  (ionScroll)="onIonScroll($event)"
  appHideHeader
  [header]="header"
>
  <div *ngIf="searching" class="spinner-div">
    <ion-spinner name="crescent"></ion-spinner>
  </div>

  <div *ngIf="!showSingle" class="all-search">
    <div (click)="goTo(result)" *ngFor="let result of searchResults; let i = index" class="all-search-result">
      <div class="page-details">
        <span l10nTranslate="">NoteOverview.PageAbbreviation</span>
        <span> {{ result.page }}</span>
      </div>
      <div class="result-text" [innerHTML]="result.text"></div>
      <ion-button fill="clear" class="location-button">
        <ion-icon color="grey" src="/assets/icon/shareIcon.svg"></ion-icon>
      </ion-button>
      <hr />
    </div>
  </div>

  <div *ngIf="showSingle" class="single-search">
    <div
      *ngIf="currentSearchResult"
      class="single-search-info"
      (click)="goTo(currentSearchResult)"
    >
      <div class="page-details">
        <span>{{ searchIndex + 1 }}/{{ searchResults.length }} (</span>
        <span l10nTranslate>NoteOverview.PageAbbreviation</span>
        <span> {{ currentSearchResult.page }})</span>
      </div>
      <div class="result-text" [innerHTML]="currentSearchResult.text"></div>
      <ion-button fill="clear" class="location-button">
        <ion-icon color="grey" src="/assets/icon/shareIcon.svg"></ion-icon>
      </ion-button>
    </div>

    <div class="single-search-buttons">
      <div class="single-search-button-back" (click)="searchBack()">
        <ion-icon class="back" src="/assets/icon/pageArrowSearch.svg"></ion-icon>
        <span l10nTranslate>Search.Back</span>
      </div>
      <div class="single-search-button-next" (click)="searchNext()">
        <ion-icon class="next" src="/assets/icon/pageArrowSearch.svg"></ion-icon>
        <span l10nTranslate>Search.Next</span>
      </div>
    </div>
  </div>
</ion-content>
