<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="refreshBooks($event)">
    <ion-refresher-content>
      <ion-row class="ion-justify-content-center">
        <ion-spinner name="crescent"></ion-spinner>
      </ion-row>
    </ion-refresher-content>
  </ion-refresher>
  <ion-grid class="ion-no-padding">
    <div class="dashboard-background">
      <ion-row class="ion-justify-content-center">
        <ion-col class="header-logo-col" size-lg="4" size-sm="4" size-xs="12">
          <img
            *ngIf="themeService.modul.DoubleIcons === 1"
            class="double-logo"
            [src]="themeService.dashboardHeaderLogoUrl2"
          />
          <img
            *ngIf="themeService.modul.DoubleIcons === 1"
            class="double-logo2"
            [src]="themeService.dashboardHeaderLogoUrl3"
          />
        </ion-col>
        <ion-col class="header-logo-col" size-lg="4" size-sm="4" size-xs="12">
          <img
            *ngIf="themeService.modul.DoubleIcons === 0"
            class="header-logo"
            [src]="themeService.dashboardHeaderLogoUrl"
          />
        </ion-col>
        <ion-col class="header-logo-col" size-lg="4" size-sm="4" size-xs="12"> </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center dashboard-head">
        <ion-col class="text" size-lg="6" size-md="8" size-xs="12">
          <div class="text-col">
            <ion-label
              ><p class="headline-text">{{ themeService.dashboardHeadline }}</p></ion-label
            >
            <ion-label class="ion-text-wrap"
              ><p class="intro-text item-word-wrap">{{ themeService.dashboardIntroText }}</p></ion-label
            >
          </div>
        </ion-col>
        <ion-col class="intro-img" text-center size-lg="6" size-md="4" size-sm="0" size-xs="0">
          <img class="intro-img-img" [src]="themeService.introImage" />
        </ion-col>
      </ion-row>
    </div>

    <ion-row class="ebook-headline-row">
      <ion-label>
        <h1 class="ebook-headline">{{ themeService.ebookHeadline }}</h1>
      </ion-label>
    </ion-row>
    <ion-row *ngIf="loading" class="ion-justify-content-center">
      <ion-spinner name="crescent"></ion-spinner>
    </ion-row>
    <ion-row class="ion-align-items-stretch cards">
      <ng-container *ngFor="let book of books | lastReadBookFilter">
        <ion-col
          style="padding: 10px"
          [attr.size-xl]="columnSizeXl"
          [attr.size-lg]="columnSizeLg"
          [attr.size-md]="columnSizeMd"
          [attr.size-sm]="columnSizeSm"
          [attr.size-xs]="columnSizeXs"
          *ngIf="book.webBookUrl || book.opf_file"
        >
          <ng-container *ngIf="!isNative">
            <ion-item-sliding>
              <ion-item lines="none" class="card-item" style="margin-inline: 0">
                <ion-card
                  id="books-openBook-btn"
                  (click)="openBook(book.id)"
                >
                  <ion-card-header class="ion-no-border">
                    <ion-icon class="teaser-button" src="/assets/icon/openBookArrowIcon.svg" name="icon-only"></ion-icon>
        
                    <ion-card-title class="ion-no-padding teaser-title">
                      {{ book.title }}
                    </ion-card-title>
                    <ion-card-subtitle class="teaser-author">{{ book.creator }}</ion-card-subtitle>
                  </ion-card-header>
                  <ion-card-content class="ion-padding">
                    <ion-row>
                      <ion-col size-xl="5" size-lg="5" size-md="6" size-sm="6" size-xs="6">
                        <img class="teaser-cover" [src]="book.urlCover" />
                      </ion-col>
                      <ion-col size-xl="7" size-lg="7" size-md="6" size-sm="6" size-xs="6">
                        <ion-list class="teaser-info-section">
                          <div *ngIf="book.lastRead" class="last-opened" l10nTranslate>
                            <ion-icon class="section-icon" src="/assets/icon/lastOpenedEyeIcon.svg"></ion-icon>
                            <p>Books.LastOpenedAt</p>
                          </div>
                          <div class="last-opened-date" *ngIf="book.lastRead">
                            <ion-label>{{ transformDate(book.lastRead) }}</ion-label>
                          </div>
        
                          <div *ngIf="book.contents" class="last-opened content" l10nTranslate>
                            <ion-icon class="section-icon" src="/assets/icon/viewContentIcon.svg"></ion-icon>
                            <p>Books.Contents</p>
                          </div>
                          <div class="book-content" *ngFor="let content of book.contents">
                            <ion-label *ngIf="content['value'] > 0"
                              >{{ content['value'] }} {{ content[contentLocaleKey] }}</ion-label
                            >
                          </div>
                        </ion-list>
                      </ion-col>
                    </ion-row>
                  </ion-card-content>
                  <ion-card-footer> </ion-card-footer>
                </ion-card>
              </ion-item>
            </ion-item-sliding>
          </ng-container>
  
          <ng-container *ngIf="isNative">
            <ion-item-sliding>
              <ion-item lines="none" class="card-item" style="margin-inline: 0">
                <ion-card>
                  <ion-card-header class="ion-no-border">
                    <ion-card-title class="ion-no-padding teaser-title">
                      {{ book.title }}
                    </ion-card-title>
                    <ion-card-subtitle class="teaser-author">{{ book.creator }}</ion-card-subtitle>
                  </ion-card-header>
                  <ion-card-content class="ion-padding">
                    <ion-row>
                      <ion-col size="6">
                        <img class="teaser-cover" [src]="sanitizer.bypassSecurityTrustUrl(book.urlCover)" />
                      </ion-col>
                      <ion-col size="6">
                        <ion-list class="teaser-info-section">
                          <div class="last-opened" *ngIf="book.lastRead" l10nTranslate>
                            <ion-icon class="section-icon" src="/assets/icon/lastOpenedEyeIcon.svg"></ion-icon>
                            <p>Books.LastOpenedAt</p>
                          </div>
                          <div class="last-opened-date" *ngIf="book.lastRead">
                            <ion-label>{{ transformDate(book.lastRead) }}</ion-label>
                          </div>
    
                          <div class="last-opened content" l10nTranslate>
                            <ion-icon class="section-icon" src="/assets/icon/viewContentIcon.svg"></ion-icon>
                            <p>Books.Contents</p>
                          </div>
                          <div class="book-content" *ngFor="let content of book.contents">
                            <ion-label *ngIf="content['value'] > 0"
                              >{{ content['value'] }} {{ content[contentLocaleKey] }}</ion-label
                            >
                          </div>
                        </ion-list>
                      </ion-col>
                    </ion-row>
                  </ion-card-content>
                  <div>
                    <ion-row>
                      <ion-col *ngIf="!book.downloaded" l10nTranslate
                        ><ion-button
                          [attr.disabled]="networkStatus && networkStatus.connected ? false : true"
                          id="books-openBook-btn"
                          (click)="downloadBook($event.target, book)"
                          class="open-btn"
                          >OfflineMode.Download</ion-button
                        ></ion-col
                      >
                      <ion-col *ngIf="book.downloaded" l10nTranslate
                        ><ion-button id="books-openBook-btn" (click)="openDownloadedBook(book)" class="open-btn"
                          >OfflineMode.Open</ion-button
                        ></ion-col
                      >
                    </ion-row>
                  </div>
                </ion-card>
              </ion-item>
    
              <ion-item-options side="end">
                <ion-item-option id="books-deleteBook-btn" *ngIf="book.downloaded" (click)="deleteBook(book)"
                  ><ion-icon class="trash-icon" src="/assets/icon/trash-outline.svg"></ion-icon
                ></ion-item-option>
              </ion-item-options>
            </ion-item-sliding>
          </ng-container>
        </ion-col>
      </ng-container>
    </ion-row>
    <footer class="dashboard-footer">
      <div class="corporate-brand">
        <img class="corporate-icon"
          [src]="themeService.footerLogoUrl"
          (error)="themeService.footerLogoUrl = themeService.getFooterDefaultLogo()"
        />
      </div>
    </footer>
  </ion-grid>
</ion-content>
