<ion-header class="ion-modal-header">
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="end">
      <ion-button (click)="closeDataPrivacy()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="header-title ion-text-left" l10nTranslate=""> Imprint.Title </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-grid>
    <ion-refresher slot="fixed">
      <ion-refresher-content> </ion-refresher-content>
    </ion-refresher>
    <ion-spinner class="spinner" *ngIf="loading" name="crescent"></ion-spinner>
    <ion-row>
      <ion-col>
        <div
          class="contents"
          [ngClass]="{ 'padding-iphone-landscape': deviceDetector.isIOS() }"
          [innerHTML]="imprintHtml"
        ></div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
