<ion-header #header>
  <div class="note-overview-header">
    <!-- TODO https://github.com/ionic-team/ionic-framework/issues/12179 -->

    <ion-select #chapterSelect
      style="--max-width: 100% !important"
      id="note-overview-chapter"
      class="select-chapter"
      interface="popover"
      [interfaceOptions]="{
        enterAnimation: dropdownAnimation
      }"
      [value]="selectedChapter"
      (ionChange)="toggleChapter($event.detail.value)"
    >
      <litello-dropdown-list
        [listItems]="chapters"
        [listItemValueProperty]="'label'"
        [listItemDisplayTextProperty]="'label'"
        [noSelectionValue]="allChapterString"
        [noSelectionDisplayText]="allChapterString"
        [selectElement]="chapterSelect.el"
      ></litello-dropdown-list>
    </ion-select>

    <div class="color-selector-content">
      <span  l10nTranslate>NoteOverview.ColorSelection</span>

      <ion-item class="colorButtons" lines="none">
        <ion-button
          id="note-overvview-primary-btn"
          slot="start"
          (click)="toggleColor('--book-contextMenu-color1')"
          class="color-button primary"
        >
          <ion-icon
            *ngIf="selectedColors.includes('--book-contextMenu-color1')"
            class="color"
            src="/assets/icon/checkmark-outline.svg"
          >
          </ion-icon>
        </ion-button>
        <ion-button
          id="note-overvview-green-btn"
          slot="start"
          (click)="toggleColor('--book-contextMenu-color2')"
          class="color-button green"
        >
          <ion-icon
            *ngIf="selectedColors.includes('--book-contextMenu-color2')"
            class="color"
            src="/assets/icon/checkmark-outline.svg"
          >
          </ion-icon>
        </ion-button>
        <ion-button
          id="note-overvview-blue-btn"
          slot="start"
          (click)="toggleColor('--book-contextMenu-color3')"
          class="color-button blue"
        >
          <ion-icon
            *ngIf="selectedColors.includes('--book-contextMenu-color3')"
            class="color"
            src="/assets/icon/checkmark-outline.svg"
          >
          </ion-icon>
        </ion-button>
        <ion-button
          id="note-overvview-purple-btn"
          slot="start"
          (click)="toggleColor('--book-contextMenu-color4')"
          class="color-button purple"
        >
          <ion-icon
            *ngIf="selectedColors.includes('--book-contextMenu-color4')"
            class="color"
            src="/assets/icon/checkmark-outline.svg"
          >
          </ion-icon>
        </ion-button>
      </ion-item>
    </div>

    <div>
      <div class="checkboxContainer">
        <input type="radio" [checked]="onlyNotes" (click)="changeMarkVisibility(!onlyNotes)" />
        <ion-label l10nTranslate>NoteOverview.OnlyNotes</ion-label>
        <input
          class="only-error-marks"
          *ngIf="errorMarks.length > 0"
          type="radio"
          [checked]="onlyErrorMarks"
          (click)="changeErrorMarkVisibility(!onlyErrorMarks)"
        />
        <ion-icon
          *ngIf="errorMarks.length > 0"
          style="font-size: 20px"
          color="danger"
          name="alert-circle-outline"
        ></ion-icon>
      </div>
    </div>
  </div>
</ion-header>
<ion-content
  class="note-overview-content"
  id="note-overview-content"
  [ngStyle]="{ height: scrollUnlocked ? '100%' : 'calc(100% - var(--ion-safe-area-bottom, 0) - 15rem)' }"
  scrollEvents="{{ scrollUnlocked }}"
  (ionScroll)="onIonScroll($event)"
  appHideHeader
  [header]="header"
  #content
>
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>

  <div *ngIf="onlyErrorMarks" class="notesContainer">
    <p class="chaptername">{{ selectedChapter }}</p>
    <ion-spinner *ngIf="refreshing && errorMarks.length === 0" name="crescent" color="primary"></ion-spinner>
    <div class="cardContainer" *ngFor="let mark of errorMarks; let i = index">
      <div class="card-header-content">
        <p class="card-header-content-title" *ngIf="selectedChapter === allChapterString && displayChaptername(i)">
          {{ mark.chapterName }}
        </p>
        <div class="card-header-info">
          <ion-label class="page-label" slot="start" l10nTranslate="">
            NoteOverview.PageAbbreviation<span> {{ mark.pageNumber }}</span></ion-label
          >
          <ion-label class="date-label">{{ transformDate(mark.creationDate) }}</ion-label>
        </div>
      </div>
      <div class="card-contents">
        <p [ngStyle]="{ 'background-color': mark.color + '70' }" class="cite">{{ mark.text }}</p>
        <p class="note" *ngIf="mark.noteText">{{ mark.noteText }}</p>
        <div class="toolIcons">
          <ion-button
            id="note-overview-confirmation-btn"
            matTooltip="{{ tooltipDelete }}"
            [matTooltipPosition]="'below'"
            (click)="showConfirmationDialog(mark.color, mark.cfi, mark.noteText)"
            fill="clear"
            slot="end"
          >
            <ion-icon color="grey" src="/assets/icon/trashbinIcon.svg"></ion-icon>
          </ion-button>
        </div>
      </div>
      <div class="divider"></div>
    </div>
  </div>

  <div *ngIf="!onlyErrorMarks" class="notesContainer">
    <p class="chaptername">{{ selectedChapter }}</p>
    <ion-spinner *ngIf="refreshing && filteredMarks.length === 0" name="crescent" color="primary"></ion-spinner>
    <div
      class="cardContainer"
      (click)="deviceDetector.isDesktop() && mark.text && displayCfi(mark.cfi)"
      *ngFor="let mark of filteredMarks; let i = index"
      [scrollToNote]="{ content: content, mark: mark }"
    >
      <div class="card-header-content" (click)="!deviceDetector.isDesktop() && mark.text && displayCfi(mark.cfi)">
        <p class="card-header-content-title" *ngIf="selectedChapter === allChapterString && displayChaptername(i)">
          {{ mark.chapterName }}
        </p>
        <div class="card-header-info">
          <ion-label class="page-label" slot="start" l10nTranslate="">
            NoteOverview.PageAbbreviation<span> {{ mark.pageNumber }}</span></ion-label
          >
          <ion-label class="date-label">{{ transformDate(mark.creationDate) }}</ion-label>
        </div>
      </div>
      <div class="card-contents">
        <p [ngStyle]="{ 'background-color': mark.color + '70' }" class="cite">{{ mark.text }}</p>
        <p class="note" *ngIf="mark.noteText">{{ mark.noteText }}</p>
        <div class="toolIcons">
          <ion-button
            id="note-overvview-edit-btn"
            matTooltip="{{ tooltipEdit }}"
            [matTooltipPosition]="'below'"
            (click)="editMark(mark)"
            fill="clear"
            slot="end"
          >
            <ion-icon src="/assets/icon/editIcon.svg"></ion-icon>
          </ion-button>
          <ion-button
            id="note-overview-share-btn"
            matTooltip="{{ tooltipLocate }}"
            (click)="displayCfi(mark.cfi)"
            [matTooltipPosition]="'below'"
            fill="clear"
            slot="end"
          >
            <ion-icon src="/assets/icon/shareIcon.svg"></ion-icon>
          </ion-button>
          <ion-button
            id="note-overview-confirmation-btn"
            matTooltip="{{ tooltipDelete }}"
            [matTooltipPosition]="'below'"
            (click)="showConfirmationDialog(mark.color, mark.cfi, mark.noteText)"
            fill="clear"
            slot="end"
          >
            <ion-icon src="/assets/icon/trashbinIcon.svg"></ion-icon>
          </ion-button>
        </div>
      </div>
      <div class="divider"></div>
    </div>
  </div>
</ion-content>
<div id="append-modal"></div>
